import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { default as qs } from 'qs'
import { Pagination } from 'react-bootstrap'

import { AppDispatch } from '~/config/store'
import { fetchProducts } from '~/async-actions/products-async-actions'

interface ProductsGridPagerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters: Record<string, any>
  totalPages: number
  visiblePages?: number
}

const ProductsGridPager = ({ filters, totalPages, visiblePages = 7 }: ProductsGridPagerProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  const getVisiblePages = useCallback(() => {
    return totalPages < visiblePages ? totalPages : visiblePages
  }, [totalPages, visiblePages])

  const currentPageNum = useCallback(() => {
    return parseInt(filters.page, 10) || 1
  }, [filters.page])

  const nextPageNum = useCallback(() => {
    return currentPageNum() + 1
  }, [currentPageNum])

  const prevPageNum = useCallback(() => {
    return currentPageNum() - 1
  }, [currentPageNum])

  const goToPage = useCallback(
    (page: number) => {
      if (currentPageNum() === page) return false

      const newFilters = { ...filters, page }
      // Fetch records
      dispatch(fetchProducts({ queryParams: newFilters }))
      // Update url
      navigate({
        pathname: '/products',
        search: qs.stringify(newFilters)
      })
      return true
    },
    [dispatch, filters, navigate, currentPageNum]
  )

  const prevPage = useCallback(() => {
    const page = prevPageNum()
    if (page >= 1) {
      return goToPage(page)
    }
    return false
  }, [goToPage, prevPageNum])

  const nextPage = useCallback(() => {
    const page = nextPageNum()
    if (page <= totalPages) {
      return goToPage(page)
    }
    return false
  }, [goToPage, totalPages, nextPageNum])

  const getPages = useCallback(() => {
    const currentPage = currentPageNum()
    const visiblePages = getVisiblePages()
    const pages: number[] = []
    const half = Math.floor(visiblePages / 2)
    let start = currentPage - half + 1 - (visiblePages % 2)
    let end = currentPage + half

    // Handle boundary case
    if (start <= 0) {
      start = 1
      end = visiblePages
    }
    if (end > totalPages) {
      start = totalPages - visiblePages + 1
      end = totalPages
    }

    let itPage = start
    while (itPage <= end) {
      pages.push(itPage)
      itPage++
    }

    return pages
  }, [currentPageNum, getVisiblePages, totalPages])

  const pages = getPages()

  if (pages.length <= 1) return null

  return (
    <nav aria-label="Products grid pages">
      <Pagination size="sm">
        <Pagination.Prev onClick={prevPage} />

        {pages.map(pageNum => (
          <Pagination.Item key={pageNum} active={currentPageNum() === pageNum} onClick={() => goToPage(pageNum)}>
            {pageNum}
          </Pagination.Item>
        ))}

        <Pagination.Next onClick={nextPage} />
      </Pagination>
    </nav>
  )
}

export default ProductsGridPager
