import axios, { AxiosRequestConfig } from 'axios'

import snakeToCamelCase from '~/lib/snake-to-camel-case'
import { BASE_URL } from './constants'
import ManifestItem from '~/types/au-post-manifest'
import AuPostShipment from '~/types/au-post-shipment'
import AuPostApiResponse from '~/types/au-post-api'
import AuPostShipmentPrice from '~/types/au-post-shipment-price'

export async function fetchRecord(id: number) {
  return axios.get<AuPostShipment>(`${BASE_URL}/api/au_post_shipments/${id}`).then(res => snakeToCamelCase(res.data))
}

export async function updateRecord(id: number, params: AuPostShipment) {
  const data = {
    auPostShipment: params
  }
  console.log('[INFO]', 'updateRecord')
  console.log('[DEBUG] data =', data)
  return axios
    .patch<AuPostShipment>(`${BASE_URL}/api/au_post_shipments/${id}`, data)
    .then(res => snakeToCamelCase(res.data))
}

export async function destroyList(ids: number[]) {
  const data = {
    selected_ids: ids
  }
  console.log('[INFO]', 'destroyList')
  console.log('[DEBUG] data =', data)
  return axios
    .post<void>(`${BASE_URL}/api/au_post_shipments/delete_list`, data, {
      snake: false
    } as AxiosRequestConfig)
    .then(res => snakeToCamelCase(res.data))
}

export async function combineRecords(ids: number[]) {
  const data = {
    shipment_ids: ids
  }
  console.log('[INFO]', 'combineRecords')
  console.log('[DEBUG] data =', data)
  return axios
    .post<ManifestItem>(`${BASE_URL}/api/au_post_shipments/combine`, data, {
      snake: false
    } as AxiosRequestConfig)
    .then(res => snakeToCamelCase(res.data) as ManifestItem)
}

// TODO: what type does this endpoint return?
export async function fetchShipmentPrice(id: number) {
  console.log('[INFO]', 'get Shipments Prices data')
  console.log('[DEBUG] shipment id =', id)
  return axios
    .get(`${BASE_URL}/api/au_post_shipment_jobs/${id}/get_shipment_price`)
    .then(res => snakeToCamelCase(res.data) as AuPostApiResponse<AuPostShipmentPrice>)
}
