import { createAsyncThunk } from '@reduxjs/toolkit'
import { default as qs } from 'qs'
import axios, { AxiosError } from 'axios'
import MenuItem, { MenuItemKindEnum } from '~/types/menu-item'

const _ApiPath_ = (kind: MenuItemKindEnum) => `/api/${kind}_menu_entries`

// TODO: type this properly
export interface QueryParams {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

interface FetchListPayload {
  items: MenuItem[]
}

export const fetchList = createAsyncThunk<
  MenuItem[],
  { kind: MenuItemKindEnum; queryParams: QueryParams },
  { rejectValue: AxiosError }
>('menuItems/fetchList', async ({ kind, queryParams }) => {
  const response = await axios.get<FetchListPayload>(`${_ApiPath_(kind)}?` + qs.stringify(queryParams))
  return response.data.items
})

export const fetchRecord = createAsyncThunk<
  MenuItem,
  { id: number; kind: MenuItemKindEnum },
  { rejectValue: AxiosError }
>('menuItems/fetchRecord', async ({ id, kind }) => {
  const response = await axios.get<MenuItem>(`${_ApiPath_(kind)}/${id}`)
  return response.data
})

export const createRecord = createAsyncThunk<
  MenuItem,
  { params: MenuItem; kind: MenuItemKindEnum },
  { rejectValue: AxiosError }
>('menuItems/createRecord', async ({ params, kind }) => {
  const response = await axios.post<MenuItem>(_ApiPath_(kind), params)
  return response.data
})

export const updateRecord = createAsyncThunk<
  MenuItem,
  { params: MenuItem; kind: MenuItemKindEnum },
  { rejectValue: AxiosError }
>('menuItems/updateRecord', async ({ params, kind }) => {
  const response = await axios.patch<MenuItem>(`${_ApiPath_(kind)}/${params.id}`, params)
  return response.data
})

export const destroyRecord = createAsyncThunk<
  MenuItem,
  { record: MenuItem; kind: MenuItemKindEnum },
  { rejectValue: AxiosError }
>('menuItems/destroyRecord', async ({ record, kind }) => {
  await axios.delete(`${_ApiPath_(kind)}/${record.id}`)
  return record
})

export const searchMenuEntries = createAsyncThunk<MenuItem[], QueryParams, { rejectValue: AxiosError }>(
  'menuItems/searchMenuEntries',
  async queryParams => {
    const response = await axios.get<MenuItem[]>(`/api/shopfront_menu_entries/search?` + qs.stringify(queryParams))
    return response.data
  }
)

export const sortListAsync = createAsyncThunk<
  MenuItem[],
  { orderedIds: number[]; kind: MenuItemKindEnum },
  { rejectValue: AxiosError }
>('menuItems/sortListAsync', async ({ kind, orderedIds }) => {
  const response = await axios.patch<FetchListPayload>(`${_ApiPath_(kind)}/sort_items`, { ordered_ids: orderedIds })
  return response.data.items
})
