import { useImperativeHandle, forwardRef, ChangeEvent, useState, useCallback, useEffect } from 'react'
import { useFormikContext, Field } from 'formik'
import _ from 'lodash'

import standingDataService from '~/services/standing-data-service'
import useSettings from '~/contexts/useSettings'
import { BsInput, BsSelectInput, ReactSelectInputCreatable } from '~/components/form-inputs/formik/inputs-decorated'
import { OrderFormValues } from './use-order-details'
import { Option } from './form'
import { CustomsClassificationEnum } from '~/types/order'

const customsClassificationOptions = (value?: CustomsClassificationEnum | null) => {
  const options = [
    { value: '', label: 'Please select' },
    { value: 'sale', label: 'Sale of Goods' },
    { value: 'gift', label: 'Gift' },
    { value: 'document', label: 'Document' },
    { value: 'commercialSample', label: 'Commercial Sample' },
    { value: 'returnedGoods', label: 'Returned Goods' },
    { value: 'other', label: 'Other' }
  ]

  // Merchandise should not be available for new orders
  if (value === 'merchandise') {
    options.push({ value: 'merchandise', label: 'Merchandise' })
  }

  return options
}

export interface PackageDetailsInternationalRef {
  addCustomsDescription: (customsDescriptionOptions: Option[]) => void
}

interface PackageDetailsInternationalProps {
  countryOptions: Option[]
}

const PackageDetailsInternational = forwardRef(({ countryOptions }: PackageDetailsInternationalProps, ref) => {
  const [customsDescriptionOptions, setCustomsDescriptionOptions] = useState<Option[]>([])
  const [customsDescriptionOptionsLoaded, setCustomsDescriptionOptionsLoaded] = useState(false)
  const { settings: accountSettings } = useSettings()

  const { values: formValues, setFieldValue } = useFormikContext<OrderFormValues>()
  const { isDomestic, customsClassification } = formValues
  const isInternational = !isDomestic

  const _handleCustomsClassificationChange = (e: ChangeEvent<HTMLInputElement>) => {
    let customsClassificationExplanation: string | undefined = undefined
    if (e.target.value === 'other') {
      customsClassificationExplanation = accountSettings?.customsDefaults?.classificationExplanation
    }
    setFieldValue('customsClassificationExplanation', customsClassificationExplanation)
  }

  const fetchCustomsDescriptionOptions = useCallback(async () => {
    if (customsDescriptionOptionsLoaded) return

    const result = await standingDataService.fetchCustomsDescriptionOptions()
    const options = [{ value: '', label: 'Please select' }, ...result]
    setCustomsDescriptionOptions(options)
    setCustomsDescriptionOptionsLoaded(true)
  }, [customsDescriptionOptionsLoaded])

  useImperativeHandle(ref, () => ({
    addCustomsDescription: (customsDescriptionOptions: Option[]) => {
      setCustomsDescriptionOptions(prevCustomsDescriptions =>
        _.uniqBy([...prevCustomsDescriptions, ...customsDescriptionOptions], 'value')
      )
      standingDataService.addCustomsDescriptionOptions(customsDescriptionOptions)
    }
  }))

  useEffect(() => {
    fetchCustomsDescriptionOptions()
  }, [fetchCustomsDescriptionOptions])

  if (!isInternational) return null

  return (
    <fieldset>
      <legend>Customs Declaration &amp; Package Details</legend>
      <Field
        required
        name="customsDescription"
        label="Description"
        component={ReactSelectInputCreatable}
        options={customsDescriptionOptions}
      />
      <Field
        required
        name="customsClassification"
        label="Classification"
        component={BsSelectInput}
        options={customsClassificationOptions(customsClassification)}
        onChange={_handleCustomsClassificationChange}
      />

      {customsClassification === 'other' && (
        <Field
          required
          name="customsClassificationExplanation"
          label="Classification explanation"
          component={BsInput}
        />
      )}

      <div className="row mb-3">
        <label
          className="col-sm-4 col-md-4 col-lg-4 col-form-label fw-bold text-end"
          htmlFor="orderCustomsTariffNumber">
          Tarrif No
        </label>
        <div className="col-sm-8 col-md-8 col-lg-8 d-flex justify-content-between align-items-start">
          <Field
            id="orderCustomsTariffNumber"
            name="customsTariffNumber"
            label={null}
            component={BsInput}
            wrapperClassName="flex-grow-1"
            inputWrapperClassName="dummy"
          />
          <Field
            required
            name="customsOriginCountryCode"
            label="Origin"
            wrapperClassName="flex-grow-1 d-flex justify-content-end align-items-start"
            labelClassName="ms-3 align-items-start"
            inputWrapperClassName="dummy"
            component={BsSelectInput}
            options={countryOptions}
          />
        </div>
      </div>

      <div className="row mb-3">
        <label
          className="col-sm-4 col-md-4 col-lg-4 col-form-label fw-bold text-end"
          htmlFor="orderCustomsDeclaredValue">
          Value
        </label>
        <div className="col-sm-8 col-md-8 col-lg-8 d-flex justify-content-between align-items-start">
          <Field
            required
            name="customsDeclaredValue"
            id="orderCustomsDeclaredValue"
            wrapperClassName="flex-grow-1"
            inputWrapperClassName="dummy"
            label={null}
            component={BsInput}
          />
          <Field
            name="insuranceCost"
            label="Insurance"
            component={BsInput}
            wrapperClassName="flex-grow-1 d-flex justify-content-end align-items-start"
            labelClassName="ms-3 align-items-start"
            inputWrapperClassName="dummy"
          />
        </div>
      </div>

      <Field required name="packageWeight" label="Item Weight" component={BsInput} />
    </fieldset>
  )
})

PackageDetailsInternational.displayName = 'PackageDetailsInternational'

export default PackageDetailsInternational
