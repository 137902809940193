import { createAsyncThunk } from '@reduxjs/toolkit'
import { default as qs } from 'qs'
import axios, { AxiosError } from 'axios'

import ProductSearchLink from '~/types/product-search-link'
import PriorityItem, { PriorityItemKindEnum } from '~/types/priority-item'
import { ListMeta } from '~/reducers/priority-items-reducer'

const _ApiPath_ = (kind: PriorityItemKindEnum) => `/api/${kind}_priority_entries`

// TODO: type this properly
export interface QueryParams {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

interface FetchListPayload {
  items: PriorityItem[]
  meta: ListMeta
}

type PriorityItemPayload = Pick<PriorityItem, 'itemType' | 'itemId' | 'priority'>

export const fetchList = createAsyncThunk<
  FetchListPayload,
  { kind: PriorityItemKindEnum; queryParams: QueryParams },
  { rejectValue: AxiosError }
>('priorityItems/fetchList', async ({ kind, queryParams }) => {
  const response = await axios.get<FetchListPayload>(`${_ApiPath_(kind)}?` + qs.stringify(queryParams))
  return response.data
})

export const fetchRecord = createAsyncThunk<
  PriorityItem,
  { id: number; kind: PriorityItemKindEnum },
  { rejectValue: AxiosError }
>('priorityItems/fetchRecord', async ({ id, kind }) => {
  const response = await axios.get<PriorityItem>(`${_ApiPath_(kind)}/${id}`)
  return response.data
})

export const createRecord = createAsyncThunk<
  PriorityItem,
  { params: PriorityItemPayload; kind: PriorityItemKindEnum },
  { rejectValue: AxiosError }
>('priorityItems/createRecord', async ({ params, kind }) => {
  const response = await axios.post<PriorityItem>(_ApiPath_(kind), params)
  return response.data
})

export const updateRecord = createAsyncThunk<
  PriorityItem,
  { params: PriorityItem; kind: PriorityItemKindEnum },
  { rejectValue: AxiosError }
>('priorityItems/updateRecord', async ({ params, kind }) => {
  const response = await axios.patch<PriorityItem>(`${_ApiPath_(kind)}/${params.id}`, params)
  return response.data
})

export const destroyRecord = createAsyncThunk<
  PriorityItem,
  { record: PriorityItem; kind: PriorityItemKindEnum },
  { rejectValue: AxiosError }
>('priorityItems/destroyRecord', async ({ record, kind }) => {
  await axios.delete(`${_ApiPath_(kind)}/${record.id}`)
  return record
})

export const sortListAsync = createAsyncThunk<
  FetchListPayload,
  { orderedIds: number[]; kind: PriorityItemKindEnum },
  { rejectValue: AxiosError }
>('priorityItems/sortListAsync', async ({ kind, orderedIds }) => {
  const response = await axios.patch<FetchListPayload>(`${_ApiPath_(kind)}/sort_items`, { ordered_ids: orderedIds })
  return response.data
})

export const searchMenuEntries = createAsyncThunk<ProductSearchLink[], QueryParams, { rejectValue: AxiosError }>(
  'priorityItems/searchMenuEntries',
  async queryParams => {
    const response = await axios.get<ProductSearchLink[]>(
      `/api/products/mobile_items_search?` + qs.stringify(queryParams)
    )
    return response.data
  }
)
