import Product from '~/types/product'
import EbaySalesRecord from '~/types/ebay-sales-record'
import AuPostShipment, { ShipmentStatusEnum } from '~/types/au-post-shipment'
import Customer from '~/types/customer'

export enum OrderCreatedWithEnum {
  POS = 'pos',
  EBAY_IMPORT = 'ebay_import',
  GENERIC_IMPORT = 'generic_import',
  MOBILE = 'mobile',
  SHOPFRONT = 'shopfront'
}

export enum OrderStatusEnum {
  PROCESSED = 'processed',
  UNPROCESSED = 'unprocessed'
}

export interface OrderCustomerInfo {
  id: number | null
  name: string | null
}

export enum ShippingCountryTypeEnum {
  DOMESTIC = 'domestic',
  INTERNATIONAL = 'international'
}

export enum ShippingServiceEnum {
  NO_SHIPPING = 'no_shipping',
  REGULAR_EPARCEL = 'regular_eparcel',
  EXPRESS_EPARCEL = 'express_eparcel',
  INTERNATIONAL_AIRMAIL = 'international_airmail',
  ECI_DOC = 'eci_doc',
  ECI_MERCH = 'eci_merch',
  PACK_AND_TRACK = 'pack_and_track',
  REG_POST_INTERNATIONAL = 'reg_post_international',
  INTL_STD_SIGNATURE = 'intl_std_signature'
}

export enum CustomsClassificationEnum {
  MERCHANDISE = 'merchandise',
  GIFT = 'gift',
  DOCUMENT = 'document',
  COMMERCIAL_SAMPLE = 'commercial_sample',
  RETURNED_GOODS = 'returned_goods',
  OTHER = 'other',
  SALE = 'sale'
}

export interface LineItemsAttributes extends Pick<OrderLineItem, 'subtotal' | 'productId' | 'unitPrice' | 'quantity'> {
  id?: number
}

export interface OrderFormPayload
  extends Pick<
    Order,
    | 'orderDate'
    | 'discountAmount'
    | 'shippingCost'
    | 'shippingService'
    | 'customerId'
    | 'customerEmail'
    | 'shippingContactName'
    | 'shippingAddress1'
    | 'shippingCity'
    | 'shippingState'
    | 'shippingCountry'
    | 'shippingCountryName'
    | 'shippingPostcode'
    | 'createdWith'
    | 'nettPrice'
    | 'insuranceCost'
    | 'customsClassification'
    | 'totalPrice'
    | 'lineItemsContainGst'
  > {
  lineItemsAttributes: LineItemsAttributes[]
}

export interface OrderLineItem {
  id: number
  product: Product | null
  productId: number | null | undefined
  salesRecord: EbaySalesRecord | null
  unitPrice: number
  quantity: number
  subtotal: number
  importedScancode?: string | null
}

interface Order {
  id: number
  trackingNum: string | null
  trackingService: string | null
  createdWith: OrderCreatedWithEnum
  status: OrderStatusEnum
  shipment: AuPostShipment | null
  isDomestic: boolean
  shippingCountryType: ShippingCountryTypeEnum
  salesRecordNumber: string | null
  reference: string | null
  orderDate: string
  customerReference: string | null
  customerEmail: string | null
  customerId: number | null
  shippingContactName: string
  shippingAddress1: string | null
  shippingAddress2: string | null
  shippingAddress3: string | null
  shippingAddress4: string | null
  shippingVirtualTrackingNum: string | null
  shippingCity: string | null
  shippingState: string | null
  shippingCountry: string
  shippingCountryName: string
  shippingPostcode: string
  shippingContactPhone: string | null
  shippingService: ShippingServiceEnum
  packageLength: number | null
  packageWidth: number | null
  packageHeight: number | null
  packageWeight: number
  nettPrice: number
  shippingCost: number
  insuranceCost: number
  totalPrice: number
  customsClassificationExplanation: string | null
  customsClassification: CustomsClassificationEnum | null
  customsOriginCountryCode: string | null
  customsTariffNumber: string | null
  customsDescription: string | null
  customsDeclaredValue: string | null
  shippingBusinessName: string | null
  createdAt: string
  updatedAt: string
  lineItemsContainGst: boolean
  discountAmount: number | null
  shipmentStatus: ShipmentStatusEnum
  lineItems: OrderLineItem[]
  mainSalesRecord: EbaySalesRecord | null
  customer: Customer
  importedShippingService: string | null
}

export default Order
