import { InputHTMLAttributes, ChangeEvent, FocusEvent } from 'react'
import _ from 'lodash'

import FieldLayout, { FieldLayoutProps } from './field-layout'
import { fieldLayoutKeys } from './field-layout-keys'
import omitProps from '~/lib/omit-props'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface SelectInputProps<V = any, FormValues = any>
  extends Omit<InputHTMLAttributes<HTMLSelectElement>, 'form'>,
    Omit<FieldLayoutProps<V, FormValues>, 'children'> {
  options: { value: string; label: string }[]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BsSelectInput = <V = any, FormValues = any>(props: SelectInputProps<V, FormValues>) => {
  const {
    field: { value, onChange: formikOnChange, onBlur: formikOnBlur, ...otherFields },
    options,
    onChange: customOnChange,
    onBlur: customOnBlur,
    ...allRest
  } = props
  const rest = omitProps<SelectInputProps<V, FormValues>, keyof FieldLayoutProps<V, FormValues>>(
    allRest,
    fieldLayoutKeys
  )

  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    formikOnChange(e)
    customOnChange?.(e)
  }

  const onBlur = (e: FocusEvent<HTMLSelectElement>) => {
    formikOnBlur(e)
    customOnBlur?.(e)
  }

  return (
    <FieldLayout {...props}>
      {({ id, value }) => (
        <select
          className="form-select"
          id={id}
          value={(value as string | number | readonly string[] | undefined) ?? ''}
          onChange={onChange}
          onBlur={onBlur}
          {...otherFields} // name, onBlur, onChange, checked, multiple
          {...rest}>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      )}
    </FieldLayout>
  )
}

export default BsSelectInput
