import styled from 'styled-components'
import { Field, FieldProps } from 'formik'

import { Button } from '~/components/forms'
import Icon from '~/components/icon'
import { ProductImage } from '~/types/product'
import User from '~/types/user'

const Download = styled.a`
  margin-left: 10px;
  font-size: 11px;
`

const Tags = styled.div`
  font-size: 11px;
  font-style: italic;
`

const ImageContainer = styled.div`
  height: 170px;
  width: 170px;
  background-color: #f1f4f7;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-height: 100%;
  }
`

interface GridItemProps {
  className?: string
  imageField: string
  image: ProductImage
  onRemove: (image: ProductImage, imageField: string) => void
  isAccountMobileEnabled: boolean
  userProfile?: User
}
const GridItem = ({ className, imageField, image, onRemove, isAccountMobileEnabled, userProfile }: GridItemProps) => (
  <div className={className}>
    {!image.mobile && (
      /* Don't allow deleting images if the mobile checkbox is enabled */
      <Button variant="link" className="mpl-photo-grid-item-remove-btn" onClick={() => onRemove(image, imageField)}>
        <Icon icon="times" aria-hidden="true" />
      </Button>
    )}

    <Icon icon="arrows-alt" className="mpl-photo-grid-item-sort-btn" aria-hidden="true" />

    <ImageContainer>
      <img className="img-fluid" src={image.id ? image.photo.grid.url : image._file?.preview} alt="product" />
    </ImageContainer>

    <Tags>{image.id && image.tags.join(', ')}</Tags>

    {isAccountMobileEnabled && userProfile && userProfile.kind !== 'staff_limited' && (
      <label htmlFor="mobile">
        <Icon icon="mobile" />
        &nbsp;
        <Field name={`${imageField}.mobile`} type="checkbox">
          {({ field }: FieldProps) => (
            <input
              {...field}
              checked={field.value}
              type="checkbox"
              className="form-check-input"
              onChange={e => {
                field.onChange(e)
                // additional logic if needed
              }}
            />
          )}
        </Field>
      </label>
    )}

    {image.id && <Download href={image.downloadUrl}>download</Download>}

    <Field name={`${imageField}.sortIndex`} type="hidden" as="input" />
    <Field name={`${imageField}._destroy`} type="hidden" as="input" />

    {!image.id && <label className="mpl-photo-grid-item-new-badge badge bg-primary">new</label>}
    {!image.id && <Field name={`${imageField}._new`} type="hidden" as="input" />}
    {!image.id && <Field name={`${imageField}._file`} type="hidden" as="input" />}
  </div>
)

const GridItemStyled = styled(GridItem)`
  width: 170px;
  vertical-align: top;
  position: relative;

  .mpl-photo-grid-item-remove-btn {
    display: none;
    cursor: pointer;
    position: absolute;
    top: 9px;
    right: 9px;
    color: #fff;
    background: #da0f0f;
    border-radius: 4px;
    font-size: 14px;
  }

  &:hover .mpl-photo-grid-item-remove-btn {
    display: block;
  }

  .mpl-photo-grid-item-sort-btn {
    visibility: hidden;
    cursor: pointer;
    position: absolute;
    bottom: 9px;
    right: 0px;
    -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
  }

  &:hover .mpl-photo-grid-item-sort-btn {
    visibility: visible;
  }

  .mpl-photo-grid-item-new-badge {
    position: absolute;
    top: 9px;
    left: 9px;
  }
`

export default GridItemStyled
