import ErrorDetails from '~/components/csv-imports/error-details'
import moment from 'moment'

import CsvImport from '~/types/csv-import'

interface ImportCompletedProps {
  lastCsvImport: CsvImport
}

const ImportCompleted = (props: ImportCompletedProps) => {
  const { lastCsvImport } = props
  const { failed, errors } = lastCsvImport

  return lastCsvImport.completed ? (
    <div className="card mb-4">
      <div className="card-body">
        <div className="text-notice">
          <strong>Summary of last csv import: </strong>&nbsp;
          <span className="text-danger">{moment(lastCsvImport.completedAt).fromNow()}</span>
          &nbsp;
          {lastCsvImport?.summary != null && (
            <div className="mb-2">
              {lastCsvImport?.summary?.createdRecords > 0 && (
                <span>
                  Records created: <strong>{lastCsvImport.summary.createdRecords}</strong>
                  &nbsp;
                </span>
              )}

              {lastCsvImport.summary.updatedRecords > 0 && (
                <span>
                  Records updated: <strong>{lastCsvImport.summary.updatedRecords}</strong>
                  &nbsp;
                </span>
              )}

              {lastCsvImport.summary.skippedRecords > 0 && (
                <span>
                  Records skipped: <strong>{lastCsvImport.summary.skippedRecords}</strong>
                  &nbsp;
                </span>
              )}

              {lastCsvImport.summary.notFound > 0 && (
                <span>
                  Records not found: <strong>{lastCsvImport.summary.notFound}</strong>&nbsp;
                </span>
              )}

              {lastCsvImport.summary.errorRecords > 0 && (
                <span>
                  Records with errors: <strong>{lastCsvImport.summary.errorRecords}</strong>&nbsp;
                </span>
              )}
            </div>
          )}
          {!failed && errors && errors.length > 0 && (
            <>
              <h3>Issues during import</h3>
              <p>
                Last csv file import has completed, but also triggered the following errors. Records containing errors
                have been skipped.
              </p>
            </>
          )}
        </div>

        {errors != null && errors?.length > 0 && <ErrorDetails errors={errors} />}
      </div>
    </div>
  ) : null
}

export default ImportCompleted
