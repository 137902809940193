import { AxiosResponse } from 'axios'
import _ from '~/lib/lodash'
import snakeToCamelCase from './snake-to-camel-case'

export interface ActiveRecordErrors {
  [key: string]: string[]
}

export function transformErrors(errors: ActiveRecordErrors) {
  return _.mapValues(
    _.mapKeys(errors, (_value: string[], key: string) => snakeToCamelCase(key)),
    v => (Array.isArray(v) ? v[0] : v)
  )
}

export function handleFormErrorResponse(
  response: AxiosResponse | undefined,
  setErrors: (options: { [key: string]: string }) => void
) {
  const errorType = response?.data?.errorType
  const activeRecordErrors = response?.data?.errors || []

  if (errorType === 'ActiveRecord::RecordInvalid') {
    const transformed = transformErrors(activeRecordErrors)
    console.log('[DEBUG] transformed =', transformed)
    setErrors(transformed)
  } else {
    setErrors({
      baseErrors: 'An error occurred. Please contact support if the problem persists.'
    })
  }
}

// Add an error to a field in Formik, without overwriting any existing errors
//
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addCustomError = (errors: Record<string, any>, setErrors: any, fieldName: string, customError: string) => {
  setErrors({
    ...errors,
    [fieldName]: errors[fieldName] ? `${errors[fieldName]}, ${customError}` : customError
  })
}

// Remove an error from a field in Formik, without overwriting any existing errors
export const removeCustomError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: Record<string, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setErrors: any,
  fieldName: string,
  customErrorToRemove: string
) => {
  const currentError = errors[fieldName]
  if (currentError) {
    // Remove the custom error from the current error string
    const updatedError = currentError
      .split(', ')
      .filter((e: string) => e !== customErrorToRemove)
      .join(', ')

    setErrors({
      ...errors,
      [fieldName]: updatedError || undefined
    })
  }
}

// Updates Formik error state, with errors returned from ActiveRecord / rails.
//
// Errors is in the form of:
//
// {
//   "customSku": "has been taken",
//   "productImportMatchers[1].scancode": [
//      "has already been taken"
//   ]
// }
//
export const setErrorsFromResponse = (
  errors: ActiveRecordErrors,
  setFieldError: (field: string, message: string | undefined) => void
) => {
  if (errors == null) return errors

  // Iterate through errors and call setFieldError for each key
  return Object.keys(errors).forEach(key => {
    const error = errors[key]

    // If the error is an array (for nested fields), join the errors into a single string.
    // Otherwise, use the error as is.
    const errorMessage = Array.isArray(error) ? error.join(', ') : error

    setFieldError(key, errorMessage)
  })
}
