import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

const bugsnagClient = Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY || '',
  plugins: [new BugsnagPluginReact()],
  releaseStage: import.meta.env.VITE_ENV || 'development',
  enabledReleaseStages: ['production', 'staging']
})

export default bugsnagClient
