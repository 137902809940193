import { useState } from 'react'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import _ from 'lodash'

import { Button } from '~/components/forms'
import { changeGridVisibleColumns, PersistableDataState } from '~/reducers/persistable-data-reducer'
import { AppDispatch } from '~/config/store'
import { GridColumn, ModelWithId } from './create-config'

const ModalWrapped = styled(Modal)`
  .items {
    -webkit-column-count: 3; /* Chrome, Safari, Opera */
    -moz-column-count: 3; /* Firefox */
    column-count: 3;
  }

  .item {
    padding-left: 20px;
  }
`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface CustomizerModalProps<Model extends ModelWithId = any, RV = object> {
  className?: string
  visibleColumnIds?: string[]
  recordType: keyof PersistableDataState['grid']
  onHide: () => void
  columns?: GridColumn<Model, RV>[]
  show?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomizerModal = <Model extends ModelWithId = any, RV = object>({
  className,
  visibleColumnIds = [],
  recordType,
  onHide,
  columns,
  show = false
}: CustomizerModalProps<Model, RV>) => {
  const [visibleIds, setVisibleIds] = useState<string[]>(visibleColumnIds)
  const dispatch = useDispatch<AppDispatch>()

  const toggleSelected = (columnId: string) => {
    setVisibleIds(prevVisibleIds => {
      const newVisibleIds = [...prevVisibleIds]
      const index = newVisibleIds.indexOf(columnId)

      if (index === -1) {
        newVisibleIds.push(columnId)
      } else {
        newVisibleIds.splice(index, 1)
      }

      return newVisibleIds
    })
  }

  const save = () => {
    dispatch(changeGridVisibleColumns({ recordType, visibleColumnIds: visibleIds }))
    onHide()
  }

  const renderItem = (column: GridColumn<Model, RV>) => (
    <div key={column.attr} className="item">
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          checked={column.attr != null && visibleIds.includes(column.attr)}
          id={column.attr}
          value={column.attr}
          disabled={column.locked === true && column.attr != null && visibleIds.includes(column.attr)}
          onChange={() => column.attr != null && toggleSelected(column.attr)}
        />
        <label className="form-check-label" htmlFor={column.attr}>
          {column.heading || _.startCase(column.attr)}
        </label>
      </div>
    </div>
  )

  return (
    <ModalWrapped className={className} show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Customise Grid</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h5>Visible Columns</h5>
        <div className="items">{_.map(columns, column => renderItem(column))}</div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={save}>
          Save Changes
        </Button>
      </Modal.Footer>
    </ModalWrapped>
  )
}

export default CustomizerModal
