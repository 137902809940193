import { ChangeEvent } from 'react'
import styled from 'styled-components'
import { OverlayTrigger, Popover } from 'react-bootstrap'

import Icon from '~/components/icon'

const Container = styled.div``
const TagInput = styled.input``
const TagLabel = styled.label``
const TagInfo = styled(Icon)`
  padding-left: 5px;
`

interface TagUploadedImagesProps {
  uploadedTags: (tags: string) => void
}

const TagUploadedImages = ({ uploadedTags }: TagUploadedImagesProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    uploadedTags(e.target.value)
  }

  const TagInformation =
    'Use the "mobile-app" tag if you wish the images to appear on the mobile-app. You can use other tags for other purposes.'
  const TagPopover = (
    <Popover id="popover">
      <Popover.Body>{TagInformation}</Popover.Body>
    </Popover>
  )

  return (
    <Container>
      <TagLabel>Tag Uploaded Images</TagLabel>
      <OverlayTrigger placement="top" overlay={TagPopover}>
        <TagInfo icon="info-circle" aria-hidden="true" />
      </OverlayTrigger>
      <div>
        <TagInput
          className="form-control"
          type="text"
          placeholder='Enter some tags such as "mobile-app"'
          onChange={handleChange}
        />
      </div>
    </Container>
  )
}

export default TagUploadedImages
