import { ReactNode } from 'react'
import _ from 'lodash'

import { AppDispatch } from '~/config/store'
import { DataRowProps } from '~/components/grid/data-row'

export interface ModelWithId {
  id: number
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface GridColumnRenderProps<Model extends ModelWithId = any, RV = object> extends DataRowProps<Model, RV> {
  dispatch: AppDispatch
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface GridColumn<Model extends ModelWithId = any, RV = object> {
  id?: string
  attr?: string
  heading?: string
  locked?: boolean
  visible?: boolean
  render?: (record: Model, props: GridColumnRenderProps<Model, RV>) => JSX.Element | ReactNode | string | undefined
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface GridConfig<Model extends ModelWithId = any, RV = object> {
  columns?: GridColumn<Model, RV>[]
  customizerModal?: boolean
  pagination?: boolean
  actions?: {
    edit?: boolean
    delete?: boolean
  }
  checkboxesColumn?: boolean
  checkboxesColumnChecked?: boolean
  rowClass?: (record: Model) => string
  onItemSelected?: (props: { selectedIds: number[] }) => void
  onRowClick?: (item: Model) => void
  onSortEnd?: (props: { oldIndex: number; newIndex: number }) => void
  onEdit?: (item: Model) => void
  onDelete?: (item: Model) => void
}

const defaultConfig = {
  columns: [{ attr: 'id' }],
  customizerModal: true,
  pagination: true,
  actions: {
    edit: true,
    delete: true
  },
  checkboxesColumn: false,
  checkboxesColumnChecked: false
} as GridConfig

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface CreateConfigProps<Model extends ModelWithId = any, RV = object> {
  config?: GridConfig<Model, RV>
}

const createConfig = <Model extends ModelWithId, RV>(props: CreateConfigProps<Model, RV>) => {
  const defaultConfigCopy = _.cloneDeep(defaultConfig)
  const config = _.merge(defaultConfigCopy, props.config) as GridConfig<Model, RV>

  return config
}

export type CreateConfigResult = ReturnType<typeof createConfig>

export default createConfig
