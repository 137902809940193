import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { Button } from '~/components/forms'
import Icon from '~/components/icon'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  h1,
  h2.heading {
    margin-top: 0;
  }
`

const handleOnClick = () => {
  window.location.href = '/'
}

const FallbackError = () => {
  const navigate = useNavigate()
  return (
    <Wrapper>
      <h1>An Error Occurred!</h1>
      <br />

      <div>
        <Button variant="light" onClick={() => navigate(-1)}>
          <Icon icon="arrow-left" /> Go Back
        </Button>{' '}
        &nbsp;&nbsp;
        <Button variant="light" onClick={() => navigate('/')}>
          <Icon icon="home" /> Home
        </Button>{' '}
        &nbsp;&nbsp;
        <Button variant="light" onClick={handleOnClick}>
          Refresh
        </Button>
      </div>
    </Wrapper>
  )
}

export default FallbackError
