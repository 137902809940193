import colors from '../colors'

const prod = import.meta.env.VITE_ENV === 'production'

const theme = {
  colors,

  breakpoint: {
    sm: '576px', // Small devices (landscape phones)
    md: '768px', // Medium devices (tablets)
    lg: '992px', // Large devices (desktops)
    xl: '1200px' // Extra large devices (large desktops)
  },

  fonts: {
    headings: '"Montserrat", "Helvetica Neue", sans-serif',
    body: '"Roboto", "Helvetica Neue", sans-serif',
    monospace: 'Courier, monospace'
  },

  borderRadius: '4px',

  Body: {
    textColor: '#333333'
  },

  SideBar: {
    Menu: {
      backgroundColor: !prod ? '#4276a4' : '#5e6673',
      textColor: !prod ? '#C9DFF5' : '#efefef',
      hoverBackgroundColor: !prod ? '#497FAE' : '#545f6e',
      activeBackgroundColor: !prod ? '#d64634' : '#31c7b2'
    }
  },

  Alert: {
    common: {
      borderRadius: '3px',
      fontFamily: 'Open Sans'
    },
    info: {
      text: colors.info.dark,
      background: colors.info.lightest
    },
    warning: {
      text: colors.warning.darkest,
      background: colors.warning.lightest
    },
    danger: {
      text: colors.danger.dark,
      background: colors.danger.lightest
    },
    success: {
      text: colors.success.dark,
      background: colors.success.lightest
    }
  },

  Input: {
    borderColor: colors.gray.lighter,
    borderHoverColor: colors.primary.base,
    backgroundColor: colors.white,
    disabledBackgroundColor: colors.gray.lightest2,
    textColor: colors.gray.darkest,
    placeholderColor: colors.gray.base
  },

  Header: {
    backgroundColor: colors.gray.darkest,

    Logo: {
      fontFamily: '"Homenaje", "Helvetica Neue", sans-serif'
    }
  },

  Footer: {
    backgroundColor: '#5e6673',
    textColor: '#efefef'
  },

  Button: {
    default: {
      text: colors.white,
      background: colors.gray.base,
      backgroundHover: colors.gray.dark,
      backgroundDisabled: colors.gray.lighter
    },
    primary: {
      text: colors.white,
      background: colors.primary.base,
      backgroundHover: colors.primary.dark,
      backgroundDisabled: colors.primary.lighter
    },
    warning: {
      text: colors.white,
      background: colors.warning.base,
      backgroundHover: colors.warning.dark,
      backgroundDisabled: colors.warning.lighter
    },
    success: {
      text: colors.white,
      background: colors.success.light,
      backgroundHover: colors.success.base,
      backgroundDisabled: colors.success.lighter
    },
    danger: {
      text: colors.danger.lightest2,
      background: colors.danger.base,
      backgroundHover: colors.danger.base,
      backgroundDisabled: colors.danger.lighter
    }
  },

  Fieldset: {
    borderColor: colors.gray.lightest,
    backgroundColor: colors.gray.lightest3
  },

  Card: {
    borderColor: colors.gray.light,
    backgroundColor: colors.white,
    maxWidth: 'auto',

    hover: {
      borderColor: colors.gray.dark
    },

    sm: {
      borderColor: colors.gray.dark
    }
  },

  Table: {
    borderColor: colors.gray.lightest,
    backgroundColor: 'transparent',
    backgroundColorAlt: colors.gray.lightest3,
    cellPadding: '8px'
  }
}

export default theme
