import _ from 'lodash'

import { CsvImportError } from '~/types/csv-import'

interface ErrorDetailsProps {
  errors: CsvImportError[]
}
const ErrorDetails = ({ errors }: ErrorDetailsProps) => {
  return (
    <div className="validation-errors">
      <p className="text-danger">
        <strong>Warning:</strong> The following errors occurred processing the file
      </p>
      <table className="table table-nonfluid">
        <tbody>
          {_.map(errors, (error, index) => (
            <tr key={`${error}-${index}`}>
              <td className="line-number">{error.lineNum ? `Line number: ${error.lineNum}` : ''}</td>
              <td className="error-messages">{error.errors}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default ErrorDetails
