import { Link } from 'react-router-dom'
import _ from 'lodash'

import EparcelsTrackingCsvImport, {
  EparcelsTrackingCsvImportSummary,
  GroupEnum,
  InfoTableRow
} from '~/types/eparcels-tracking'

interface InfoTableProps {
  lastCsvImport: EparcelsTrackingCsvImport
  infoTable: EparcelsTrackingCsvImportSummary['infoTable']
}
const InfoTable = ({ lastCsvImport, infoTable }: InfoTableProps) => {
  if (!lastCsvImport.completed) return null

  const printGroup = (group: GroupEnum) => {
    if (group !== GroupEnum.SKIPPED) {
      return _.upperFirst(group.replace('_', ' ')) + '.'
    }
  }

  const printInviteInfo = (row: InfoTableRow) =>
    (row.inviteError ? ` Invite Error: ${row.inviteError}` : '') ||
    (row.inviteSentTo ? ` Invite sent to ${row.inviteSentTo}` : '')

  const printEbayInfo = (row: InfoTableRow) => {
    if (row.ebayUpdated) {
      if (row.ebayUpdated.success) return ' Tracking code sent to eBay'
      return ` Tracking code FAILED to update. ${row.ebayUpdated.error}`
    }
  }

  const groupClass = (group: GroupEnum) => {
    switch (group) {
      case GroupEnum.ERROR:
        return 'text-danger'
      case GroupEnum.UPDATED:
        return 'text-success'
      case GroupEnum.SKIPPED:
        return 'text-primary'
      case GroupEnum.NOT_FOUND:
        return 'text-danger'
      default:
        return undefined
    }
  }

  return (
    <table className="table table-bordered mb-5">
      <thead>
        <tr>
          <th>Order</th>
          <th>Tracking Number</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {infoTable.map((row, index) => (
          <tr key={index}>
            <td>
              {row.id && <Link to={{ pathname: `/orders/${row.id}/edit` }}>{row.reference}</Link>}
              {!row.id && <span>{row.reference}</span>}
            </td>
            <td>
              <a
                href={`https://auspost.com.au/mypost/track/#/details/${row.trackingNum}`}
                target="_blank"
                rel="noopener noreferrer">
                {row.trackingNum}
              </a>
            </td>
            <td>
              <span className={groupClass(row.group)}>
                {printGroup(row.group)} {printInviteInfo(row)} {printEbayInfo(row)}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default InfoTable
