import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Routes, Route, Navigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import _ from 'lodash'

import Spinner from '~/components/spinner'
import { fetchAllUsers, destroyUser, createUser, updateUser } from '~/api/users'
import { addOrUpdateItem, loadItems, deleteItem } from '~/reducers/users-reducer'
import { useConfirm } from '~/hooks'
import { AppState, AppDispatch } from '~/config/store'
import Form from './form'
import ListView from './list-view'
import User, { UserFormParams } from '~/types/user'

interface MatchParams {
  recordId?: string
}

interface RenderFormProps {
  items?: User[]
}
const RenderForm = ({ items }: RenderFormProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const findItemById = (id: number) => _.find(items, ['id', id])
  const { recordId } = useParams<keyof MatchParams>()
  const item = recordId ? findItemById(parseInt(recordId, 10)) : undefined

  const onUpdateOrCreate = async (values: UserFormParams) => {
    if (values.id > 0) {
      const item = await updateUser(values as User)
      dispatch(addOrUpdateItem(item))
    } else {
      const item = await createUser(values as User)
      dispatch(addOrUpdateItem(item))
    }
  }

  if (recordId && !item) {
    return <Navigate to="/users" />
  }

  return <Form item={item} onUpdateOrCreate={onUpdateOrCreate} />
}

const UsersPage = () => {
  const { items, pending } = useSelector((state: AppState) => state.users)
  const dispatch = useDispatch<AppDispatch>()
  const [confirm] = useConfirm()

  useEffect(() => {
    fetchAllUsers().then(items => dispatch(loadItems(items)))
  }, [dispatch])

  const onDelete = async (item: User) => {
    if (!(await confirm({ message: 'Are you sure you want to delete this user?' }))) return

    return destroyUser(item.id)
      .then(() => dispatch(deleteItem(item)))
      .then(() => toast.success('Deleted!'))
      .catch(() => toast.error('Error: failed to delete.'))
  }

  const renderListView = () => <ListView items={items} onDelete={onDelete} />

  return pending ? (
    <Spinner />
  ) : (
    <Routes>
      <Route path="/new" element={<RenderForm />} />
      <Route path="/:recordId/edit" element={<RenderForm items={items} />} />
      <Route path="/" element={renderListView()} />
    </Routes>
  )
}

export default UsersPage
