import styled from 'styled-components'
import { useFormik, FormikProvider } from 'formik'
import { Card } from 'react-bootstrap'

import { ActionBar } from '~/components/forms'
import { BaseErrors, FlexibleInputGroup } from '~/components/forms/formik'
import useSubmitHandler from '~/containers/settings/submit-handler'
import { ShopfrontSmsTemplates, AccountSettingKeys } from '~/types/account-settings'
import { SettingsProviderState } from '~/contexts/settings'

const Container = styled.div`
  width: 600px;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Label = styled.label``

interface ShopfrontSmsTemplatesFormProps {
  redirectPath: string
  initialValues: ShopfrontSmsTemplates
  settingsKey: AccountSettingKeys
  updateAction: SettingsProviderState['updateAction']
}

const ShopfrontSmsTemplatesForm = (props: ShopfrontSmsTemplatesFormProps) => {
  const { redirectPath, initialValues = {} } = props
  const { submitHandler } = useSubmitHandler()

  const formik = useFormik({
    initialValues: {
      invitationInstructions: '',
      resetPasswordInstructions: '',
      confirmationInstructions: '',
      ...initialValues
    } as ShopfrontSmsTemplates,
    onSubmit: (values, helpers) =>
      submitHandler<ShopfrontSmsTemplates, ShopfrontSmsTemplatesFormProps>(values, helpers, props)
  })

  const { getFieldProps } = formik

  return (
    <FormikProvider value={formik}>
      <Wrapper>
        <Container>
          <Card body={true} className="mb-4">
            <div>
              <strong>Invitation Placeholders:</strong> __INVITATION_LINK__
            </div>
            <div>
              <strong>Reset Password Placeholders:</strong> __RESET_LINK__
            </div>
            <div>
              <strong>Confirmation Placeholders:</strong> __CONFIRMATION_LINK__
            </div>
          </Card>

          <BaseErrors />

          <Label>Invitation instructions</Label>
          <FlexibleInputGroup label="" type="textarea" {...getFieldProps('invitationInstructions')} />

          <Label>Reset password instructions</Label>
          <FlexibleInputGroup label="" type="textarea" {...getFieldProps('resetPasswordInstructions')} />

          <Label>Confirmation instructions</Label>
          <FlexibleInputGroup label="" type="textarea" {...getFieldProps('confirmationInstructions')} />

          <ActionBar {...{ isSubmitting: formik.isSubmitting, redirectPath }} onSubmit={formik.handleSubmit} />
        </Container>
      </Wrapper>
    </FormikProvider>
  )
}

export default ShopfrontSmsTemplatesForm
