import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, Routes, Route, Navigate, useParams } from 'react-router-dom'
import _ from 'lodash'
import { Alert } from 'react-bootstrap'

import { Button } from '~/components/forms'
import Spinner from '~/components/spinner'
import { loadItems, deleteItem } from '~/async-actions/weight-based-shipping-pricing-profiles-async-actions'
import { useConfirm } from '~/hooks'
import Form from './form'
import ListView from './list-view'
import { AppDispatch, AppState } from '~/config/store'
import WeightBasedShippingPricingProfile from '~/types/weight-based-shipping-pricing-profile'

import styles from './index.module.css'

interface MatchParams {
  recordId?: string
}

interface RenderFormProps {
  items?: WeightBasedShippingPricingProfile[]
}
const RenderForm = ({ items }: RenderFormProps) => {
  const findItemById = (id: number) => _.find(items, ['id', id])
  const { recordId } = useParams<keyof MatchParams>()
  const item = recordId ? findItemById(parseInt(recordId, 10)) : undefined

  if (recordId && !item) {
    return <Navigate to="/settings/weight_based_shipping_pricing_profiles" />
  }

  return <Form item={item} redirectPath="/settings/weight_based_shipping_pricing_profiles" />
}

const WeightBasedShippingPricingProfiles = () => {
  const { items, loading } = useSelector((state: AppState) => state.weightBasedShippingPricingProfiles)
  const dispatch = useDispatch<AppDispatch>()
  const [confirm] = useConfirm()

  const getProfilesByType = (type: string) => _.filter(items, ['type', type])

  const onDelete = async (item: WeightBasedShippingPricingProfile) => {
    if (!(await confirm({ message: 'Are you sure you want to delete this pricing profile?' }))) {
      return
    }

    return dispatch(deleteItem(item))
  }

  const renderListView = () => {
    return (
      <div>
        <Alert variant="light">
          <p>Weight-based shipping profiles are used when customers order multiple items in the one order.</p>
          <p>
            Setup your weight-based shipping profiles for each of the different zones. When a customer from that zone
            orders, their order will be priced according to that profile (and whether they have selected regular or
            express shipping).
          </p>
        </Alert>

        <Link className={styles.newButton} to="/settings/weight_based_shipping_pricing_profiles/new">
          <Button>New</Button>
        </Link>

        <div>
          <h3>Regular Shipping</h3>
          <ListView items={getProfilesByType('regular')} onDelete={onDelete} />
        </div>

        <div>
          <h3>Express Shipping</h3>
          <ListView items={getProfilesByType('express')} onDelete={onDelete} />
        </div>
      </div>
    )
  }

  useEffect(() => {
    dispatch(loadItems())
  }, [dispatch])

  return loading.loadItems ? (
    <Spinner />
  ) : (
    <Routes>
      <Route path="/new" element={<RenderForm />} />
      <Route path="/:recordId" element={<RenderForm items={items} />} />
      <Route path="/" element={renderListView()} />
    </Routes>
  )
}

export default WeightBasedShippingPricingProfiles
