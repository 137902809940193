import _ from 'lodash'

// similar to lodash snakeCase, but doesn't remove square brackets
//
// i.e. without this function, lodash would convert
//
// product_import_matcher[0].scan_code to productImportMatcher0ScanCode
//
// whereas it needs to be converted to productImportMatcher[0].scanCode
function customCamelCase(str: string) {
  // Placeholder characters
  const placeholderOpenBracket = '\uFFF0' // Placeholder for '['
  const placeholderCloseBracket = '\uFFF1' // Placeholder for ']'
  const placeholderDot = '\uE000' // Placeholder for '.'

  // Replace '[', ']', and '.' with placeholders
  let processedStr = str
    .replace(/\[/g, placeholderOpenBracket)
    .replace(/\]/g, placeholderCloseBracket)
    .replace(/\./g, placeholderDot)

  // Apply lodash camelCase
  processedStr = _.camelCase(processedStr)

  // Replace placeholders back with '[', ']', and '.'
  processedStr = processedStr
    .replace(new RegExp(placeholderOpenBracket, 'g'), '[')
    .replace(new RegExp(placeholderCloseBracket, 'g'), ']')
    .replace(new RegExp(placeholderDot, 'g'), '.')

  return processedStr
}

/**
 * @example
 *   import keysToCamelCase from './snake-to-camel-case'
 *   keysToCamelCase({bad_key: 1})   => {badKey: 1}
 *   keysToCamelCase([{bad_key: 1}]) => [{badKey: 1}]
 */

function keysToCamelCase(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any,
  options: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  } = { ignoreChildKeys: [] }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  let camelCaseObject = _.cloneDeep(object)

  if (_.isString(object) || _.isNumber(object) || _.isBoolean(object)) return object

  if (_.isArray(camelCaseObject)) {
    return _.map(camelCaseObject, item => keysToCamelCase(item, options))
  }

  camelCaseObject = _.mapKeys(camelCaseObject, (_value, key) => {
    const newKey = customCamelCase(key)
    // Don't remove leading underscores, as rails needs these
    if (key.substring(0, 1) === '_') return `_${newKey}`
    return newKey
  })

  // Recursively apply throughout object
  return _.mapValues(camelCaseObject, (value, key) => {
    if (_.isPlainObject(value) && (!options.ignoreChildKeys || options.ignoreChildKeys.indexOf(key) === -1)) {
      return keysToCamelCase(value, options)
    }

    if (_.isArray(value)) {
      return _.map(value, item => keysToCamelCase(item, options))
    }

    return value
  })
}

export default keysToCamelCase
