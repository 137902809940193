import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

import WeightBasedShippingPricingProfile from '~/types/weight-based-shipping-pricing-profile'

import {
  loadItems,
  addOrUpdateItem,
  deleteItem
} from '~/async-actions/weight-based-shipping-pricing-profiles-async-actions'

export interface WeightBasedShippingPricingProfilesState {
  items: WeightBasedShippingPricingProfile[]
  loading: {
    loadItems: boolean
    addOrUpdateItem: boolean
    deleteItem: boolean
    reSortItems: boolean
  }
  error: {
    loadItems: string | null
    addOrUpdateItem: string | null
    deleteItem: string | null
    reSortItems: string | null
  }
}

const initialState: WeightBasedShippingPricingProfilesState = {
  items: [],
  loading: {
    loadItems: false,
    addOrUpdateItem: false,
    deleteItem: false,
    reSortItems: false
  },
  error: {
    loadItems: null,
    addOrUpdateItem: null,
    deleteItem: null,
    reSortItems: null
  }
}

const weightBasedShippingPricingProfilesSlice = createSlice({
  name: 'weight_based_shipping_pricing_profiles',
  initialState,
  reducers: {
    loadItems: (state, action: PayloadAction<WeightBasedShippingPricingProfile[]>) => {
      state.items = action.payload
    },
    addOrUpdateItem: (state, action: PayloadAction<WeightBasedShippingPricingProfile>) => {
      state.items = addToOrUpdateList(state.items, action.payload)
    },
    deleteItem: (state, action: PayloadAction<WeightBasedShippingPricingProfile>) => {
      state.items = removeFromList(state.items, action.payload)
    }
  },
  extraReducers: builder => {
    builder
      // loadItems async action
      .addCase(loadItems.pending, state => {
        state.loading.loadItems = true
        state.error.loadItems = null
      })
      .addCase(loadItems.fulfilled, (state, action) => {
        state.items = action.payload
        state.loading.loadItems = false
      })
      .addCase(loadItems.rejected, (state, action) => {
        state.loading.loadItems = false
        state.error.loadItems = action.payload?.message || 'Error fetching weight-based shipping pricing profiles'
      })

      // addOrUpdateItem async action
      .addCase(addOrUpdateItem.pending, state => {
        state.loading.addOrUpdateItem = true
        state.error.addOrUpdateItem = null
      })
      .addCase(addOrUpdateItem.fulfilled, (state, action) => {
        state.items = addToOrUpdateList(state.items, action.payload)
        state.loading.addOrUpdateItem = false
      })
      .addCase(addOrUpdateItem.rejected, (state, action) => {
        state.loading.addOrUpdateItem = false
        state.error.addOrUpdateItem =
          action.payload?.message || 'Error adding or updating weight-based shipping pricing profile'
      })

      // deleteItem async action
      .addCase(deleteItem.pending, state => {
        state.loading.deleteItem = true
        state.error.deleteItem = null
      })
      .addCase(deleteItem.fulfilled, (state, action) => {
        state.items = removeFromList(state.items, action.payload)
        state.loading.deleteItem = false
      })
      .addCase(deleteItem.rejected, (state, action) => {
        state.loading.deleteItem = false
        state.error.deleteItem = action.payload?.message || 'Error deleting weight-based shipping pricing profile'
      })
  }
})

// export const { loadItems, addOrUpdateItemS, deleteItem } = weightBasedShippingPricingProfilesSlice.actions

export default weightBasedShippingPricingProfilesSlice.reducer

const addToOrUpdateList = (items: WeightBasedShippingPricingProfile[], newItem: WeightBasedShippingPricingProfile) => {
  const newList = _.clone(items)
  const index = _.findIndex(newList, ['id', newItem.id])
  if (index === -1) {
    newList.push(newItem)
  } else {
    newList.splice(index, 1, newItem)
  }
  return newList
}

const removeFromList = (items: WeightBasedShippingPricingProfile[], itemToDelete: WeightBasedShippingPricingProfile) =>
  _.filter(items, i => i.id !== itemToDelete.id)
