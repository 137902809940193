import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'

import { changeSelectedUnmatchedRecord } from '~/reducers/unmatched-sales-records-page-reducer'
import { goToNextProcessingOrder } from '~/reducers/order-processing-reducer'
import UploadSummary from './upload-summary'
import { Button } from '~/components/forms'
import Icon from '~/components/icon'
import { AppState, AppDispatch } from '~/config/store'
import { ProcessingStep } from './index'
import OrdersToProcessList from './orders-to-process-list'

import styles from './upload-results-screen.module.css'

interface UploadResultsScreenProps {
  onStartProcessing: (step: ProcessingStep) => void
  onStartNew: () => void
}
const UploadResultsScreen = ({ onStartProcessing, onStartNew }: UploadResultsScreenProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const unmatchedList = useSelector((state: AppState) => state.unmatchedSalesRecordsPage.list)
  const ordersList = useSelector((state: AppState) => state.orderProcessing.list)

  const unmatchedRecordsCount = unmatchedList.length
  const unprocessedOrdersCount = _.filter(ordersList, ['status', 'unprocessed']).length
  const hasUnmatchedRecords = unmatchedRecordsCount > 0
  const hasUnprocessedOrders = unprocessedOrdersCount > 0

  return (
    <>
      <h1>Order Upload Results</h1>

      <div className="d-flex justify-content-between align-items-start">
        <dl>
          <dd>
            <strong>{unmatchedRecordsCount}</strong> order lines not matched to a product, to process.
          </dd>
          <dd>
            <strong>{unprocessedOrdersCount}</strong> unprocessed orders to process.
          </dd>
        </dl>
        {/* button to start _processing_ the orders */}
        {(hasUnmatchedRecords || hasUnprocessedOrders) && (
          <div className={classnames('d-flex justify-content-between align-items-center', styles.buttonContainer)}>
            {hasUnmatchedRecords && (
              <Button
                variant="success"
                size="lg"
                onClick={() => {
                  dispatch(changeSelectedUnmatchedRecord(unmatchedList[0]))
                  onStartProcessing?.(ProcessingStep.PROCESS_UNMATCHED_RECORDS)
                }}>
                Process ({unmatchedRecordsCount}) Unmatched Sales Records <Icon icon="arrow-right" />
              </Button>
            )}
            {!hasUnmatchedRecords && hasUnprocessedOrders && (
              <Button
                variant="success"
                size="lg"
                onClick={() => {
                  dispatch(goToNextProcessingOrder())
                  onStartProcessing?.(ProcessingStep.PROCESS_UNPROCESSED_ORDERS)
                }}>
                Process ({unprocessedOrdersCount}) Orders <Icon icon="arrow-right" />
              </Button>
            )}
            or
            <Button variant="light" onClick={onStartNew}>
              Start New
            </Button>
          </div>
        )}
      </div>

      <UploadSummary />

      <OrdersToProcessList />
    </>
  )
}

export default UploadResultsScreen
