import { RefObject, CSSProperties, MouseEvent } from 'react'
import { AsyncThunk } from '@reduxjs/toolkit'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'

import Icon from '~/components/icon'
import { SortableDragHandle } from './sortable'
import { CheckboxTd } from './checkboxes'
import { AppDispatch } from '~/config/store'
import { GridConfig, GridColumn, ModelWithId } from './create-config'
import { useConfirm } from '~/hooks'

const TR = styled.tr`
  &.pointer {
    cursor: pointer;

  &.disabled {
    cursor: default;
    color: #aaaaaa;
    font-style: italic;
  }
`

export const IconBtn = styled.span`
  font-size: 18px;
  margin: 0 10px;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.colors.primary.base};
  }
`

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DataRowProps<Model extends ModelWithId = any, RV = object> = {
  recordType: string
  visibleColumns: GridColumn<Model, RV>[]
  record: Model
  config: GridConfig<Model, RV>
  toggleSelected: (recordId: number) => void
  assertSelected: (recordId: number) => boolean
  className?: string
  destroyRecord?: AsyncThunk<Model, Model, { rejectValue: RV }>
  reference?: RefObject<HTMLTableRowElement>
  style?: CSSProperties
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const DataRow = <Model extends ModelWithId = any, RV = object>(props: DataRowProps<Model, RV>) => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const [confirm] = useConfirm()
  const { className, recordType, visibleColumns, record, config, toggleSelected, assertSelected, destroyRecord } = props
  const { onRowClick, rowClass } = config

  const handleClickEdit = () => {
    if (config.onEdit) {
      config.onEdit(record)
    } else {
      const linkPath = `/${_.kebabCase(recordType)}/${record.id}/edit`
      navigate(linkPath)
    }
  }

  const handleClickDelete = async () => {
    if (
      await confirm({ message: `Are you sure you want to DELETE the ${recordType}? This action CANNOT be undone.` })
    ) {
      if (config.onDelete) {
        config.onDelete(record)
      } else if (destroyRecord != null) {
        return dispatch(destroyRecord(record))
      }
    }
  }

  const renderColumn = (column: GridColumn<Model, RV>) => {
    return (
      <td key={column.attr}>
        {_.isFunction(column.render)
          ? column.render(record, { ...props, dispatch })
          : column.attr && _.get(record, column.attr)}
      </td>
    )
  }

  const ignoreClick = (event: MouseEvent) => {
    event.stopPropagation()
  }

  const renderActionsColumn = () => {
    return (
      config.actions && (
        <td onClick={ignoreClick}>
          {config.actions.edit && (
            <IconBtn className="grid-record-edit-btn" onClick={handleClickEdit}>
              <Icon icon="pencil-alt" />
            </IconBtn>
          )}
          {config.actions.delete && (
            <IconBtn className="grid-record-delete-btn" onClick={handleClickDelete}>
              <Icon icon="trash" />
            </IconBtn>
          )}
          {config.onSortEnd && <SortableDragHandle />}
        </td>
      )
    )
  }

  const renderCheckboxesColumn = () => {
    return (
      config.checkboxesColumn && (
        <CheckboxTd
          checked={assertSelected(record.id)}
          value={record.id}
          onClick={ignoreClick}
          onChange={() => toggleSelected(record.id)}
        />
      )
    )
  }

  const rowClicked = () => {
    if (onRowClick) {
      onRowClick(record)
    }
  }

  return (
    <TR
      className={`${rowClass ? rowClass(record) : ''} ${className} ${onRowClick ? 'pointer' : ''}`}
      ref={props.reference}
      style={props.style}
      onClick={rowClicked}>
      {renderCheckboxesColumn()}
      {_.map(visibleColumns, renderColumn)}
      {renderActionsColumn()}
    </TR>
  )
}

export default DataRow
