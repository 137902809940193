import styled from 'styled-components'
import { useFormik, FormikProvider } from 'formik'

import { BaseErrors, FlexibleInputGroup } from '~/components/forms/formik'
import { ActionBar } from '~/components/forms'
import useSubmitHandler from '~/containers/settings/submit-handler'
import { TwilioPreferences, AccountSettingKeys } from '~/types/account-settings'
import { SettingsProviderState } from '~/contexts/settings'

const Container = styled.div`
  width: 600px;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

interface TwilioPreferencesFormProps {
  redirectPath: string
  initialValues: TwilioPreferences
  settingsKey: AccountSettingKeys
  updateAction: SettingsProviderState['updateAction']
}

const TwilioPreferencesForm = (props: TwilioPreferencesFormProps) => {
  const { redirectPath, initialValues } = props
  const { submitHandler } = useSubmitHandler()
  const formik = useFormik({
    initialValues,
    onSubmit: (values, helpers) => submitHandler<TwilioPreferences, TwilioPreferencesFormProps>(values, helpers, props)
  })

  const { getFieldProps, handleSubmit, isSubmitting } = formik

  return (
    <FormikProvider value={formik}>
      <Wrapper>
        <Container>
          <h3 className="mb-4">Configure Twilio settings</h3>

          <BaseErrors />

          <FlexibleInputGroup label="Your Twilio Number" type="text" {...getFieldProps('fromNumber')} />
          <FlexibleInputGroup label="Account SID" type="text" {...getFieldProps('accountSid')} />
          <FlexibleInputGroup label="Auth Token" type="text" {...getFieldProps('authToken')} />
          <FlexibleInputGroup
            label="Send SMS For Customer Emails Matching"
            type="text"
            {...getFieldProps('sendSmsForCustomerEmailsMatching')}
          />
          <ActionBar isSubmitting={isSubmitting} redirectPath={redirectPath} onSubmit={handleSubmit} />
        </Container>
      </Wrapper>
    </FormikProvider>
  )
}

export default TwilioPreferencesForm
