import { useState, useCallback } from 'react'
import { Alert } from 'react-bootstrap'

import { Button } from '~/components/forms'
import Spinner from '~/components/spinner'
import * as restApi from '~/api/products-csv-imports'
import CsvImport, { CsvImportProgress } from '~/types/csv-import'
import { useConfirm } from '~/hooks'

const btnStyles = { marginBottom: '10px' }

const getProgressText = (p: CsvImportProgress) => {
  let text = ''
  if (p.current > 0) {
    text = `Completed ${p.current - 1} of ${p.total}, ${p.errors.length} errors`
  }
  return text
}

interface ImportInProgressProps {
  lastCsvImport: CsvImport
  cancelPath?: string
}
const ImportInProgress = ({ cancelPath, lastCsvImport }: ImportInProgressProps) => {
  const [cancelled, setCancelled] = useState(false)
  const [confirm] = useConfirm()
  const showProgress = lastCsvImport.pending && lastCsvImport.progress
  const showCancelButton = !cancelled && lastCsvImport.id && !lastCsvImport.cancelledAt && cancelPath

  const handleCancel = useCallback(async () => {
    if (!(await confirm({ message: 'Are you sure?' }))) {
      return
    }
    setCancelled(true)
    return restApi.cancel(lastCsvImport.id).catch(console.log)
  }, [confirm, lastCsvImport.id])
  /* eslint-enable no-restricted-globals */

  if (lastCsvImport.progress) {
    console.log('[DEBUG] lastCsvImport.progress =', lastCsvImport.progress)
  }

  return (
    <>
      {showCancelButton && (
        <Button style={btnStyles} onClick={handleCancel}>
          Cancel Import
        </Button>
      )}
      <Alert variant="warning">
        A csv file is being imported. Please wait. <Spinner />
        <br />
        {showProgress && lastCsvImport?.progress != null && getProgressText(lastCsvImport.progress)}
        <br />
        {lastCsvImport.cancelledAt && <div>Cancelling...</div>}
      </Alert>
    </>
  )
}

export default ImportInProgress
