import styled from 'styled-components'
import { Field, FieldInputProps } from 'formik'

const Wrapper = styled.div`
  display: flex;
`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface RadioButtonProps<V = any> {
  field: FieldInputProps<V>
  dataValue: string
  label: string
  id: string
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RadioButton = <V = any, _FormValues = any>({
  field: { name, value, onChange, onBlur },
  dataValue,
  label,
  id,
  ...otherProps
}: RadioButtonProps<V>) => (
  <div>
    <label htmlFor={id}>
      {label}{' '}
      <input
        id={id}
        name={name}
        type="radio"
        value={dataValue}
        checked={dataValue === value}
        onChange={onChange}
        onBlur={onBlur}
        {...otherProps}
      />
    </label>
  </div>
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface RadioGroupInputProps<V = any> {
  field: FieldInputProps<V>
  options: { value: string; label: string }[]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RadioGroupInput = <V = any, _FormValues = any>({ field, options }: RadioGroupInputProps<V>) => (
  <Wrapper>
    {options.map(option => (
      <Field
        key={option.value}
        component={RadioButton}
        dataValue={option.value}
        field={field}
        id={field.name + '_' + option.value}
        label={option.label}
      />
    ))}
  </Wrapper>
)

export default RadioGroupInput
