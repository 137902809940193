import styled from 'styled-components'

interface WrapperProps {
  size?: number
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    font-size: 20px;
    font-family: ${props => props.theme.fonts.headings};
  }

  & > {
    .mplSpinner {
      transform: scale(${props => props.size || 1});
      color: #000;
      display: inline-block;
      position: relative;
      width: ${props => 64 * (props.size || 1)}px;
      height: ${props => 64 * (props.size || 1)}px;
    }

    .mplSpinner div {
      transform-origin: ${props => 32 * (props.size || 1)}px ${props => 32 * (props.size || 1)}px;
      animation: mplSpinner 1.2s linear infinite;
    }

    .mplSpinner div:after {
      content: ' ';
      display: block;
      position: absolute;
      top: 3px;
      left: 29px;
      width: 5px;
      height: 14px;
      border-radius: 20%;
      background: #888;
    }
    .mplSpinner div:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1.1s;
    }
    .mplSpinner div:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -1s;
    }
    .mplSpinner div:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.9s;
    }
    .mplSpinner div:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.8s;
    }
    .mplSpinner div:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.7s;
    }
    .mplSpinner div:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.6s;
    }
    .mplSpinner div:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.5s;
    }
    .mplSpinner div:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.4s;
    }
    .mplSpinner div:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.3s;
    }
    .mplSpinner div:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.2s;
    }
    .mplSpinner div:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.1s;
    }
    .mplSpinner div:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
    }
    @keyframes mplSpinner {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
`

interface SpinnerProps {
  label?: string
  className?: string
  size?: number // add size prop
}
const Spinner = ({ className, label = 'Please Wait...', size = 1, ...rest }: SpinnerProps) => (
  <Wrapper {...rest} className={className} size={size}>
    <span>{label}</span>
    <div className="mplSpinner">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </Wrapper>
)

export default Spinner
