import { ReactNode } from 'react'
import { FieldInputProps, FormikProps, getIn } from 'formik'
import _ from 'lodash'
import classnames from 'classnames'
import styled from 'styled-components'

const Wrapper = styled.div`
  label {
     {
      /* just so if labelClassName is set, there would still be some padding next to the label */
    }
    padding-right: calc(var(--bs-gutter-x) * 0.5);
  }

  &.input-vertical {
    vertical-align: top;

    label {
      text-align: center;
      display: block;
      @include vertical-align-2-lines;
      min-height: 51px;
      font-size: $font-size-base;

      @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
        min-height: 73px;
      }
    }
  }
`

// NOTE: keep these keys in sync with field-layout-keys.ts
//
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface FieldLayoutProps<V = any, FormValues = any> {
  field: FieldInputProps<V>
  form: FormikProps<FormValues>
  id?: string
  label?: string | null
  labelPlacement?: 'top' | 'left'
  helpBlock?: string | ReactNode
  required?: boolean
  customErrors?: string[]
  children?: (props: Omit<FieldLayoutProps<V, FormValues>, 'children'> & Pick<FieldInputProps<V>, 'value'>) => ReactNode
  formatFn?: (value: V) => V

  wrapperClassName?: string
  labelClassName?: string
  inputWrapperClassName?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FieldLayout = <V = any, FormValues = any>(props: FieldLayoutProps<V, FormValues>) => {
  const {
    field: { value, ...otherFields },
    form,
    id,
    label,
    labelPlacement = 'left',
    helpBlock,
    required,
    customErrors = [],
    children,
    formatFn,
    labelClassName,
    wrapperClassName,
    inputWrapperClassName
  } = props
  const { name } = otherFields
  const theLabel = label === undefined ? _.startCase(name) : label
  const hasLabel = !!theLabel
  const theId = id === undefined ? `field_${name}` : id

  const errorString = getIn(form.errors, name) || ''
  const combinedErrors = [...(errorString ? [errorString] : []), ...customErrors]

  // const touched = getIn(form.touched, name)

  const wrapperClasses = classnames(
    {
      'row mb-3': hasLabel && labelPlacement === 'left' && !wrapperClassName,
      'input-vertical': labelPlacement === 'top' && !wrapperClassName
    },
    wrapperClassName
  )

  const labelClasses = classnames(
    {
      'col-sm-4 col-md-4 col-lg-4': labelPlacement === 'left' && !labelClassName
    },
    labelClassName
  )

  const inputWrapperClasses = classnames(
    {
      'col-sm-8 col-md-8 col-lg-8': hasLabel && labelPlacement === 'left' && !inputWrapperClassName
    },
    inputWrapperClassName
  )

  return (
    <Wrapper className={classnames(wrapperClasses, { 'has-error': combinedErrors.length > 0 })}>
      {hasLabel && (
        <label
          className={classnames(labelClasses, 'col-form-label text-end fw-bold d-flex justify-content-end')}
          htmlFor={theId}>
          {required && <abbr title="required">*</abbr>}
          {required ? ' ' : ''}
          <span>{theLabel}</span>
        </label>
      )}
      <div className={inputWrapperClasses}>
        {children?.({ ...props, id: theId, value: formatFn ? formatFn(value) : value })}
        {combinedErrors?.map((error, index) => (
          <div key={index} className="form-text text-danger">
            {error as string}
          </div>
        ))}
        {helpBlock && <div className="form-text help-block">{helpBlock}</div>}
      </div>
    </Wrapper>
  )
}

export default FieldLayout
