import { useRef, useEffect } from 'react'
import type { XYCoord, Identifier } from 'dnd-core'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useDrag, useDrop } from 'react-dnd'
import { AsyncThunk } from '@reduxjs/toolkit'

import Icon from '~/components/icon'
import DataRow, { IconBtn } from './data-row'
import { GridConfig, ModelWithId, GridColumn } from './create-config'

// TODO: not being used
export const SortableDragHandle = () => (
  <IconBtn>
    <Icon icon="arrows-alt" className="drag-handle" />
  </IconBtn>
)

interface DraggableItemProps {
  index: number
  type: string
}

interface SortableListProps<Model extends ModelWithId, RV> {
  index: number
  onSortEnd?: (dragIndex: number, hoverIndex: number) => void
  className?: string
  recordType: string
  item: Model
  config: GridConfig<Model, RV>
  visibleColumns: GridColumn<Model, RV>[]
  toggleSelected: (recordId: number) => void
  assertSelected: (recordId: number) => boolean
  destroyRecord?: AsyncThunk<Model, Model, { rejectValue: RV }>
}

export const SortableItem = <Model extends ModelWithId, RV>({
  index,
  onSortEnd,
  className,
  recordType,
  item,
  config,
  visibleColumns,
  toggleSelected,
  assertSelected,
  destroyRecord
}: SortableListProps<Model, RV>) => {
  const ref = useRef<HTMLTableRowElement>(null)

  const [{ handlerId }, drop] = useDrop<DraggableItemProps, void, { handlerId: Identifier | null }>({
    accept: 'div',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover(item: DraggableItemProps, monitor) {
      if (!ref.current) return

      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) return

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleX = hoverBoundingRect && (hoverBoundingRect.right - hoverBoundingRect.left) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientX = hoverBoundingRect && (clientOffset as XYCoord).x - hoverBoundingRect.left
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) return
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) return

      onSortEnd?.(dragIndex, hoverIndex)
      config.onSortEnd?.({ oldIndex: dragIndex, newIndex: hoverIndex })
      item.index = hoverIndex
    }
  })

  const [dragStyles, drag, preview] = useDrag({
    type: 'div',
    item: () => {
      return { index }
    },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  })

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview])

  drag(drop(ref))

  //return <SortableItem key={key} index={index} item={Item} disabled={!config.onSortEnd} />

  return (
    <DataRow<Model, RV>
      className={className}
      reference={ref}
      data-handler-id={handlerId}
      style={dragStyles}
      key={`item-${index}`}
      recordType={recordType}
      record={item}
      config={config}
      visibleColumns={visibleColumns}
      toggleSelected={toggleSelected}
      assertSelected={assertSelected}
      destroyRecord={destroyRecord}
    />
  )
}
