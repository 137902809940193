import { ChangeEvent } from 'react'
import styled from 'styled-components'
import ShippingPricingProfile from '~/types/shipping-pricing-profile'
import { RegionalPricingSectionTypeEnum, ProfileValue } from './index'

export const Col = styled.div`
  text-align: right;
  margin-right: 5px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 80px;

  > span {
    color: #aaa;
    font-size: 12px;
  }

  &.empty {
    min-width: 120px;
    max-width: 120px;
    flex-basis: 120px;
  }
`

export const ColLabel = styled(Col)`
  font-weight: 600;
  text-align: right;
  margin-bottom: 0;
  margin-right: 10;
  padding-top: 7px;
  padding-right: 10px;
  min-width: 155px;
  max-width: 155px;
  white-space: nowrap;
`

export const ColDropdown = styled.div`
  margin-right: 10px;
  min-width: 155px;
  max-width: 155px;
  flex-basis: 155px;
`

interface PricingProfileSelectProps {
  type: RegionalPricingSectionTypeEnum
  label: string
  profiles: ShippingPricingProfile[]
  value: ProfileValue
  onChange: (value: ProfileValue) => void
}

const PricingProfileSelect = ({ type, label, profiles, value, onChange }: PricingProfileSelectProps) => {
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault()
    const { value } = e.target
    onChange(value)
  }

  return (
    <>
      <ColLabel>{label}</ColLabel>

      <ColDropdown>
        <div>
          <select className="form-select" value={value || ''} onChange={handleChange}>
            <option value="">Default</option>
            {type === RegionalPricingSectionTypeEnum.REGULAR && (
              <option value="weight_based_regular">Weight-based</option>
            )}
            {type === RegionalPricingSectionTypeEnum.EXPRESS && (
              <option value="weight_based_express">Weight-based</option>
            )}
            {profiles.map(profile => (
              <option key={profile.id} value={profile.id}>
                {profile.name}
              </option>
            ))}
          </select>
        </div>
      </ColDropdown>
    </>
  )
}

export default PricingProfileSelect
