import { InputHTMLAttributes, ReactNode } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export interface SwitchProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value'> {
  id?: string
  tooltip?: string | ReactNode
  label?: string | null
}

const Switch = ({ id, checked, tooltip, label, ...rest }: SwitchProps) => {
  const theTooltip = <Tooltip id="tooltip">{tooltip}</Tooltip>

  return (
    <OverlayTrigger placement="right" overlay={theTooltip}>
      <div className="form-check form-switch d-flex align-items-center gap-2">
        <input className="form-check-input" type="checkbox" checked={checked} id={id} {...rest} />
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>
      </div>
    </OverlayTrigger>
  )
}

export default Switch
