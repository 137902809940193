import ErrorDetails from '~/components/csv-imports/error-details'
import Icon from '~/components/icon'

import CsvImport from '~/types/csv-import'

interface ImportFailedProps {
  lastCsvImport: CsvImport
}

const ImportFailed = ({ lastCsvImport }: ImportFailedProps) => {
  const { failed, errors } = lastCsvImport

  if (!failed && (!errors || errors.length === 0)) {
    return null
  }

  return (
    <div className="import-failed">
      <div className="alert alert-danger">
        {failed && (
          <>
            <h3>
              <Icon icon="times-circle" /> Failure !
            </h3>
            <p>
              Last csv file import failed and no data has been uploaded. Please check the csv file for errors such as
              incorrect formatting and try re-uploading. Contact our support team if problem persists.
            </p>
          </>
        )}
        {!failed && errors && errors.length > 0 && (
          <>
            <h3>
              <Icon icon="times-circle" /> Errors during import
            </h3>
            <p>
              Last csv file import has completed, but also triggered the following errors. Records containing errors
              have been skipped.
            </p>
          </>
        )}
      </div>
      {errors && <ErrorDetails errors={errors} />}
    </div>
  )
}

export default ImportFailed
