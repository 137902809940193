import { MouseEvent } from 'react'
import styled from 'styled-components'
import moment from 'moment'

import useSettings from '~/contexts/useSettings'
import Spinner from '~/components/spinner'
import FileImport, { EbayPackingSlipsImportSummary, EbayPackingSlipsImportStatus } from '~/types/file-import'
import AccountSettings from '~/types/account-settings'

const Td = styled.td`
  color: blue;
  cursor: pointer;
`

const statusLabel = (status: EbayPackingSlipsImportStatus, settings: AccountSettings) => {
  switch (status) {
    case 'skipped__order_not_found':
      return 'Skipped, order not found'
    case 'skipped__not_matched':
      return `Skipped, email not matched with ${settings.twilio.sendSmsForCustomerEmailsMatching}`
    case 'updated':
      return 'Updated'
    default:
      return ''
  }
}

const statusClass = (status: EbayPackingSlipsImportStatus) => {
  switch (status) {
    case 'skipped__order_not_found':
      return 'text-danger'
    case 'skipped__not_matched':
      return 'text-danger'
    case 'updated':
      return 'text-success'
    default:
      return ''
  }
}

interface SuccessProps {
  record: FileImport<EbayPackingSlipsImportSummary>
  setSelectedOrderId: (orderId: number) => void
}
const Success = ({ record, setSelectedOrderId }: SuccessProps) => {
  const { settings, settingsPending } = useSettings()
  const handleOrderClick = (
    e: MouseEvent<HTMLTableCellElement, globalThis.MouseEvent>,
    item: EbayPackingSlipsImportSummary
  ) => {
    console.log('[INFO]', 'orderClicked')
    console.log('[DEBUG] item =', item)
    e.preventDefault()
    if (!item.orderId) {
      return
    }
    setSelectedOrderId(item.orderId)
  }

  return settingsPending ? (
    <Spinner />
  ) : (
    <div className="card">
      <div className="card-body">
        <strong>Summary of last PDF import: </strong>&nbsp;
        <span className="text-danger">Finished {moment(record.completedAt).fromNow()}</span>
        {(record.summary || []).length > 0 && (
          <table className="table table-bordered table-condensed mt-3">
            <thead>
              <tr>
                <th>eBay Order Reference</th>
                <th>Order Reference</th>
                <th>Old Customer Email</th>
                <th>Extracted Customer Email</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {record.summary?.map(item => (
                <tr key={item.ebayId}>
                  <td>{item.ebayId}</td>
                  <Td onClick={e => handleOrderClick(e, item)}>{item.orderReference || '-'}</Td>
                  <td>{item.oldEmail || '-'}</td>
                  <td>{item.extractedEmail}</td>
                  <td>
                    <span className={statusClass(item.status)}>
                      {settings != null && statusLabel(item.status, settings)}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}

export default Success
