import { FocusEvent } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { default as DatePicker, DatePickerProps } from 'react-date-picker'
import moment from 'moment'

import FieldLayout, { FieldLayoutProps } from './field-layout'
import { fieldLayoutKeys } from './field-layout-keys'
import omitProps from '~/lib/omit-props'

import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'

const StyledDatePicker = styled(DatePicker)`
  &.form-control {
    padding: 0.3rem 0.75rem;
    display: flex;
    align-items: center;
  }

  .react-date-picker__wrapper {
    border: none;

    .react-date-picker__inputGroup {
      min-width: 100px;
    }

    .react-date-picker__clear-button {
      display: none;
    }

    .react-date-picker__calendar-button {
      padding: 0;
    }
  }
`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ReactDatePickerProps<V = any, FormValues = any>
  extends DatePickerProps,
    Omit<FieldLayoutProps<V, FormValues>, 'children'> {
  dateInputFormat?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ReactDatePicker = <V = any, FormValues = any>(props: ReactDatePickerProps<V, FormValues>) => {
  const {
    field: { value, onChange: formikOnChange, onBlur: formikOnBlur, ...otherFields },
    dateInputFormat = 'DD/MM/YYYY',
    onChange: customOnChange,
    onBlur: customOnBlur,
    ...allRest
  } = props

  const rest = omitProps<ReactDatePickerProps<V, FormValues>, keyof FieldLayoutProps<V, FormValues>>(
    allRest,
    fieldLayoutKeys
  )

  const { name } = otherFields

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (value: any) => {
    const val = moment(value?.toString()).format(dateInputFormat)
    const syntheticEvent = {
      target: {
        name: name,
        value: val ? val : ''
      }
    } as React.ChangeEvent<HTMLInputElement>
    formikOnChange(syntheticEvent)

    // NOTE: hack because can't seem to get type 'Value' from react-date-picker ?
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customOnChange?.(val as any)
  }

  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    formikOnBlur(e)
    customOnBlur?.(e)
  }

  return (
    <FieldLayout {...props}>
      {({ id, value }) => (
        <StyledDatePicker
          id={id}
          showLeadingZeros
          className="form-control"
          locale="en-AU"
          value={value ? moment(value, dateInputFormat).toDate() : undefined}
          clearIcon={null}
          onChange={onChange}
          onBlur={onBlur}
          {...otherFields} // name, onBlur, onChange, checked, multiple
          {...rest}
        />
      )}
    </FieldLayout>
  )
}

export default ReactDatePicker
