import useSettings from '~/contexts/useSettings'
import useAuth from '~/contexts/useAuth'
import { UserKindEnum } from '~/types/user'
import Spinner from '~/components/spinner'
import Form from './form'

const PaymentsPreferencesPage = () => {
  const { settings, settingsPending, updateAction } = useSettings()
  const { userProfile } = useAuth()

  if (userProfile?.kind !== UserKindEnum.OWNER) return null

  return settingsPending ? (
    <Spinner />
  ) : settings == null ? null : (
    <Form
      initialValues={settings.payments}
      updateAction={updateAction}
      settingsKey="payments"
      redirectPath="/settings"
    />
  )
}

export default PaymentsPreferencesPage
