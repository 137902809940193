import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Spinner from '~/components/spinner-two'
import ImportInProgress from '~/components/csv-imports/import-in-progress'
import ImportContainer from '~/components/csv-imports/import-container'
import { setLastCsvImport } from '~/reducers/products-import-reducer'
import MainView from './main-view'
import ResultView from './result-view'
import { AppState, AppDispatch } from '~/config/store'

const getTitle = (view: string) => {
  let title = 'Products Import'
  if (view === 'result') {
    title = 'Products Import Result'
  }
  return title
}

const ProductsImportPage = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { fileUploading, lastCsvImport } = useSelector((state: AppState) => state.productsImportPage)

  const [view, setView] = useState('main')
  const goBack = () => setView('main')
  const showResult = () => setView('result')
  const inProgress = fileUploading || lastCsvImport?.pending
  const hasLastCsv = lastCsvImport != null && Boolean(lastCsvImport.id)
  const initialized = !lastCsvImport || hasLastCsv

  useEffect(() => {
    if (fileUploading === true) {
      console.log('[INFO]', 'refetching')
      // NOTE: csv progress is fetched from API via ImportContainer component
      dispatch(setLastCsvImport({ id: -1, pending: true }))
    }
  }, [dispatch, fileUploading])

  useEffect(() => {
    if (hasLastCsv && !inProgress) {
      setView('result')
    }
  }, [hasLastCsv, inProgress])

  return (
    <ImportContainer
      lastCsvImport={lastCsvImport}
      onUpdate={async csvImport => {
        dispatch(setLastCsvImport(csvImport))
      }}
      resourcePath="products_csv_imports">
      <h1>{getTitle(view)}</h1>
      {!initialized && <Spinner />}
      {initialized && inProgress && lastCsvImport && (
        <ImportInProgress cancelPath="/api/products_csv_imports/cancel" lastCsvImport={lastCsvImport} />
      )}
      {initialized && !inProgress && view === 'main' && <MainView showResult={showResult} />}
      {lastCsvImport != null && initialized && !inProgress && view === 'result' && (
        <ResultView lastCsvImport={lastCsvImport} goBack={goBack} />
      )}
    </ImportContainer>
  )
}

export default ProductsImportPage
