import { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams, useNavigate } from 'react-router-dom'

import useSettings from '~/contexts/useSettings'
import { newProductPojo, newInventoryPojo } from '~/lib/utils/helpers'
import Spinner from '../../components/spinner'
import { fetchProduct } from '~/async-actions/products-async-actions'
import { AppState, AppDispatch } from '~/config/store'
import ProductForm, { ProductFormTabs } from './form'
import Product from '~/types/product'
import ProductExistsModal from '../products-page/product-exists-modal'

interface MatchParams {
  productId: string
}

const ProductFormPage = () => {
  const { settings } = useSettings()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const [initialValues, setInitialValues] = useState<Product | undefined>(undefined)
  const fetchingProduct = useSelector((state: AppState) => state.productsPage.loading.fetchProducts)
  const [initialTab, setInitialTab] = useState<ProductFormTabs | undefined>(undefined)

  const { productId } = useParams<keyof MatchParams>()

  const onProductExistsModalConfirm = useCallback(
    async (product: Product) => {
      navigate(`/products/${product.id}/edit`)

      // This is a hack to get the new form values to appear in the form. Seems
      // to only work once this input is blured (though not sure why it's even
      // focused to begin with?!)
      setTimeout(() => {
        const el = document.querySelector('#productcustomSku')
        if (el instanceof HTMLElement) {
          el.blur()
        }
      }, 500)
    },
    [navigate]
  )

  useEffect(() => {
    const { hash, pathname } = location
    const shouldPreselectActiveTab =
      hash === '#update-inventory' || pathname.substring(pathname.length - 17) === '#update-inventory'

    if (shouldPreselectActiveTab) {
      setInitialTab(ProductFormTabs.InventoryManagement)
    }
  }, [setInitialTab, location])

  useEffect(() => {
    const loadData = async () => {
      let initialValues = undefined

      if (productId == null) {
        initialValues = newProductPojo({ settings })
      } else {
        const response = await dispatch(fetchProduct(parseInt(productId, 10)))
        initialValues = { ...response.payload.product } // spread props, so object is not 'readonly', and can be mutated below
      }

      const shouldPreselectActiveTab =
        location.hash === '#update-inventory' ||
        location.pathname.substring(location.pathname.length - 17) === '#update-inventory'

      // If user is using the 'shortcut' for updating product inventory, then add a new row to the inventory array
      if (shouldPreselectActiveTab) {
        initialValues.inventories = initialValues?.inventories?.concat([newInventoryPojo()])
      }

      setInitialValues(initialValues)
    }

    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId])

  if (fetchingProduct) {
    return <Spinner label="Loading Please Wait..." />
  }

  if (initialValues) {
    return (
      <>
        <ProductForm initialValues={initialValues} initialTab={initialTab} redirectPath="/products" />

        {/* If the user is creating a new product, and the SKU already exists, then show this modal */}
        {/* If there is a product sku clash when editing a product, that will present as a Yup error in the form itself */}
        {!initialValues.id && (
          <ProductExistsModal onConfirm={onProductExistsModalConfirm}>
            <p>You cannot create a new product with this same SKU.</p>
            <p className="fw-bold">Do you want to edit this product instead?</p>
          </ProductExistsModal>
        )}
      </>
    )
  }

  return null
}
export default ProductFormPage
