import { PersistableDataState } from '~/reducers/persistable-data-reducer'

export const loadState = () => {
  try {
    const serializedState = window.localStorage.getItem('persistableData')
    if (serializedState === null) {
      return undefined
    }
    return { persistableData: JSON.parse(serializedState) }
  } catch (error) {
    return undefined
  }
}

export const saveState = (persistableData: PersistableDataState) => {
  try {
    const serializedState = JSON.stringify(persistableData)
    window.localStorage.setItem('persistableData', serializedState)
  } catch (error) {
    console.log(error)
  }
}
