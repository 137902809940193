import styled from 'styled-components'
import { Field, FormikProvider, useFormik } from 'formik'
import { toast } from 'react-toastify'
import { animateScroll } from 'react-scroll'
import { useDispatch } from 'react-redux'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'

import { handleErrorResponse } from '~/lib/form-utils'
import { ActionBar } from '~/components/forms'
import { BaseErrors, FlexibleInputGroup } from '~/components/forms/formik'
import validations from './validations'
import { addOrUpdateItem } from '~/async-actions/weight-based-shipping-pricing-profiles-async-actions'
import WeightBasedShippingPricingProfile from '~/types/weight-based-shipping-pricing-profile'
import { AppDispatch } from '~/config/store'

const typeOptions = [
  { label: '---', value: '' },
  { label: 'Regular', value: 'regular' },
  { label: 'Express', value: 'express' }
]

const Container = styled.div`
  width: 800px;
`
const Heading = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
`
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`
const Row = styled.div`
  display: flex;
`
const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const Box = styled.div`
  border: 1px solid #ccc;
  padding: 5px;
  padding-right: 15px;
  margin-right: 10px;
  margin-bottom: 10px;
`

interface WeightBasedShippingPricingProfileFormProps {
  item?: WeightBasedShippingPricingProfile
  redirectPath: string
}

const WeightBasedShippingPricingProfileForm = ({ item, redirectPath }: WeightBasedShippingPricingProfileFormProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      id: item?.id || '',
      name: item?.name || '',
      type: item?.type || '',
      minWeight: item?.minWeight || '',
      maxWeight: item?.maxWeight || '',
      piAu: item?.piAu || 0,
      piZone1: item?.piZone1 || 0,
      piZone2: item?.piZone2 || 0,
      piZone3: item?.piZone3 || 0,
      piZone4: item?.piZone4 || 0,
      piZone5: item?.piZone5 || 0,
      piZone6: item?.piZone6 || 0,
      piZone7: item?.piZone7 || 0,
      piZone8: item?.piZone8 || 0,
      piZone9: item?.piZone9 || 0,
      pkAu: item?.pkAu || 0,
      pkZone1: item?.pkZone1 || 0,
      pkZone2: item?.pkZone2 || 0,
      pkZone3: item?.pkZone3 || 0,
      pkZone4: item?.pkZone4 || 0,
      pkZone5: item?.pkZone5 || 0,
      pkZone6: item?.pkZone6 || 0,
      pkZone7: item?.pkZone7 || 0,
      pkZone8: item?.pkZone8 || 0,
      pkZone9: item?.pkZone9 || 0
    } as WeightBasedShippingPricingProfile,
    validationSchema: validations,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      const onSuccess = () => {
        setSubmitting(false)
        navigate(redirectPath)
        toast.success('Success!')
        animateScroll.scrollToTop()
      }

      const onError = (error: AxiosError) => {
        setSubmitting(false)
        toast.error('Sorry, there were errors processing the form. Please review!')
        animateScroll.scrollToTop()

        handleErrorResponse({
          response: error.response,
          setErrors
        })
      }

      try {
        await dispatch(addOrUpdateItem(values))
        onSuccess()
      } catch (error) {
        onError(error as AxiosError)
      }
    }
  })

  return (
    <FormikProvider value={formik}>
      <Wrapper>
        <Container>
          <BaseErrors />

          <Heading>{typeof item?.id === 'number' ? 'Edit Profile' : 'New Profile'}</Heading>

          <Field name="id" type="hidden" />
          <FlexibleInputGroup name="name" />
          <FlexibleInputGroup name="type" type="select" options={typeOptions} />

          <FlexibleInputGroup name="minWeight" label="Min Weight (Kg)" />
          <FlexibleInputGroup name="maxWeight" label="Max Weight (Kg)" />
          <Row>
            <Col>
              <Box>
                <div>AU</div>
                <FlexibleInputGroup name="piAu" label="Per item Plus" />
                <FlexibleInputGroup name="pkAu" label="Per Kg" />
              </Box>
              <Box>
                <div>Zone 1</div>
                <FlexibleInputGroup name="piZone1" label="Per item Plus" />
                <FlexibleInputGroup name="pkZone1" label="Per Kg" />
              </Box>
              <Box>
                <div>Zone 2</div>
                <FlexibleInputGroup name="piZone2" label="Per item Plus" />
                <FlexibleInputGroup name="pkZone2" label="Per Kg" />
              </Box>
              <Box>
                <div>Zone 3</div>
                <FlexibleInputGroup name="piZone3" label="Per item Plus" />
                <FlexibleInputGroup name="pkZone3" label="Per Kg" />
              </Box>
              <Box>
                <div>Zone 4</div>
                <FlexibleInputGroup name="piZone4" label="Per item Plus" />
                <FlexibleInputGroup name="pkZone4" label="Per Kg" />
              </Box>
            </Col>
            <Col>
              <Box>
                <div>Zone 5</div>
                <FlexibleInputGroup name="piZone5" label="Per item Plus" />
                <FlexibleInputGroup name="pkZone5" label="Per Kg" />
              </Box>
              <Box>
                <div>Zone 6</div>
                <FlexibleInputGroup name="piZone6" label="Per item Plus" />
                <FlexibleInputGroup name="pkZone6" label="Per Kg" />
              </Box>
              <Box>
                <div>Zone 7</div>
                <FlexibleInputGroup name="piZone7" label="Per item Plus" />
                <FlexibleInputGroup name="pkZone7" label="Per Kg" />
              </Box>
              <Box>
                <div>Zone 8</div>
                <FlexibleInputGroup name="piZone8" label="Per item Plus" />
                <FlexibleInputGroup name="pkZone8" label="Per Kg" />
              </Box>
              <Box>
                <div>Zone 9</div>
                <FlexibleInputGroup name="piZone9" label="Per item Plus" />
                <FlexibleInputGroup name="pkZone9" label="Per Kg" />
              </Box>
            </Col>
          </Row>

          <ActionBar isSubmitting={formik.isSubmitting} redirectPath={redirectPath} onSubmit={formik.handleSubmit} />
        </Container>
      </Wrapper>
    </FormikProvider>
  )
}

export default WeightBasedShippingPricingProfileForm
