import { useCallback } from 'react'
import googleProductCategoryOptions from '~/lib/google-product-categories'
import AsyncSelectInput, {
  ReactAsyncSelectInputProps
} from '~/components/form-inputs/formik/inputs-decorated/react-async-select'

const GoogleProductCategoryInput = (props: ReactAsyncSelectInputProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filterOptions = useCallback((inputValue: any) => {
    if (!inputValue) {
      return []
    }
    return googleProductCategoryOptions.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase()))
  }, [])

  const loadOptions = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (inputValue: any) =>
      new Promise(resolve => {
        setTimeout(() => {
          resolve(filterOptions(inputValue))
        }, 1000)
      }),
    [filterOptions]
  )

  return <AsyncSelectInput {...props} cacheOptions loadOptions={loadOptions} />
}

export default GoogleProductCategoryInput
