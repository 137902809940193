import CsvImport, { CsvImportSummary } from './csv-import'

export enum GroupEnum {
  NOT_FOUND = 'not_found',
  UPDATED = 'updated',
  SKIPPED = 'skipped',
  ERROR = 'error'
}

export interface InfoTableRow {
  id?: number
  group: GroupEnum
  reference: string
  trackingNum?: string
  errors?: { [key: string]: string[] }
  ebayUpdated?: {
    success: boolean
    error?: string
  }
  inviteSentTo?: string
  inviteError?: string
}

export interface EparcelsTrackingCsvImportSummary extends CsvImportSummary {
  infoTable: InfoTableRow[]
}

interface EparcelsTrackingCsvImport extends Omit<CsvImport, 'summary'> {
  summary?: EparcelsTrackingCsvImportSummary
}

export default EparcelsTrackingCsvImport
