import styled from 'styled-components'
import { useFormik, FormikProvider } from 'formik'
import { Alert } from 'react-bootstrap'

import { ActionBar } from '~/components/forms'
import { BaseErrors, FlexibleInputGroup } from '~/components/forms/formik'
import useSubmitHandler from '~/containers/settings/submit-handler'
import validations from './validations'
import { SettingsProviderState } from '~/contexts/settings'
import { SendGridPreferences, AccountSettingKeys } from '~/types/account-settings'

const Container = styled.div`
  width: 800px;
`
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

interface SendGridPreferencesFormProps {
  redirectPath: string
  initialValues: SendGridPreferences
  settingsKey: AccountSettingKeys
  updateAction: SettingsProviderState['updateAction']
}

const SendGridPreferencesForm = (props: SendGridPreferencesFormProps) => {
  const { submitHandler } = useSubmitHandler()
  const { redirectPath, initialValues } = props

  const formik = useFormik({
    initialValues,
    validationSchema: validations,
    onSubmit: (values, helpers) =>
      submitHandler<SendGridPreferences, SendGridPreferencesFormProps>(values, helpers, props)
  })

  return (
    <FormikProvider value={formik}>
      <Wrapper>
        <Container>
          <Alert variant="info">
            <p>
              We send out email via Sendgrid. Please setup your Sendgrid account separately, and then add your
              credentials here.
            </p>
            <p>In Sendgrid you can setup templates that we will use when sending out emails to customers.</p>
          </Alert>

          <BaseErrors />

          <h4>Sendgrid API Settings</h4>
          <FlexibleInputGroup name="apiKey" label="API Key" labelWidth="25rem" />

          <h4>Sendgrid Templates</h4>
          <FlexibleInputGroup
            name="templates.mobileInvitation"
            labelWidth="25rem"
            label="Mobile / Shopfront Invitation"
          />
          <FlexibleInputGroup
            name="templates.accountConfirmation"
            labelWidth="25rem"
            label="Shopfront Welcome / Confirmation"
          />

          <ActionBar isSubmitting={formik.isSubmitting} redirectPath={redirectPath} onSubmit={formik.handleSubmit} />
        </Container>
      </Wrapper>
    </FormikProvider>
  )
}

export default SendGridPreferencesForm
