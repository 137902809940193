import { ReactNode, useState, useEffect, createContext } from 'react'
import axios from 'axios'

import AuPostcode from '~/types/au-postcode'
import LocalStorage from '~/lib/utils/local-storage'
import useAuth from '~/contexts/useAuth'

interface AuPostcodesContextType {
  auPostcodesPending: boolean
  auPostcodes: AuPostcode[]
  refreshAuPostcodes: () => Promise<void>
  setAuPostcodes: (auPostcodes: AuPostcode[]) => void
}

const defaultState = {
  auPostcodesPending: false,
  auPostcodes: [],
  refreshAuPostcodes: async () => undefined,

  setAuPostcodes: (_auPostcodes: AuPostcode[]) => undefined
}

const AuPostcodesContext = createContext<AuPostcodesContextType>(defaultState)

interface AuPostcodesProviderProps {
  children?: ReactNode
}

const AuPostcodesProvider = ({ children }: AuPostcodesProviderProps) => {
  const [auPostcodesPending, setAuPostcodesPending] = useState<boolean>(false)
  const [auPostcodes, setAuPostcodes] = useState<AuPostcode[]>([])
  const { isLoggedIn } = useAuth()

  useEffect(() => {
    if (isLoggedIn) {
      refreshAuPostcodes()
    }
  }, [isLoggedIn])

  const refreshAuPostcodes = async () => {
    setAuPostcodesPending(true)
    return LocalStorage.get<AuPostcode[]>('auPostcodes')
      .then(data => {
        if (data === null) {
          console.log('fetch_au_postcodes: start')
          return axios
            .get<AuPostcode[]>('/api/au_postcodes')
            .then(response => {
              console.log('fetch_au_postcodes: finish')
              const { data } = response
              if (data.length > 0) {
                LocalStorage.set('auPostcodes', data)
                setAuPostcodes(data)
                setAuPostcodesPending(false)
              }
              return Promise.reject(new Error('AU Postcodes are not setup'))
            })
            .catch(error => {
              console.error('Fetching from API failed:', error)
            })
        }
        setAuPostcodes(data)
        setAuPostcodesPending(false)
      })
      .catch(err => {
        console.error('Fetching from localStorage failed:', err)
        setAuPostcodesPending(false)
        return Promise.reject()
      })
  }

  const contextValue = {
    auPostcodesPending,
    auPostcodes,
    refreshAuPostcodes,
    setAuPostcodes
  }

  return <AuPostcodesContext.Provider value={contextValue}>{children}</AuPostcodesContext.Provider>
}

export default AuPostcodesContext
export { AuPostcodesProvider }
