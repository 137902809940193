import { useState, ChangeEvent } from 'react'
import useSettings from '~/contexts/useSettings'
import Spinner from '~/components/spinner'
import MauMobileSettingsForm from './form'

const MobileSettingsPage = () => {
  const { settings, settingsPending, updateAction } = useSettings()
  const [imageFile, setImageFile] = useState<File>()
  const [newImagePreviewUrl, setNewImagePreviewUrl] = useState<string>()

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    const reader = new FileReader()
    const imageFile = e.target?.files?.[0]

    console.log('[DEBUG] imageFile =', imageFile)

    reader.onloadend = () => {
      setImageFile(imageFile)
      setNewImagePreviewUrl(reader.result as string)
    }

    if (imageFile) reader.readAsDataURL(imageFile)
  }

  return settingsPending ? (
    <Spinner />
  ) : settings == null ? null : (
    <MauMobileSettingsForm
      settingsKey="mobile"
      redirectPath="/settings"
      initialValues={settings.mobile}
      imageFile={imageFile}
      imagePreviewUrl={newImagePreviewUrl || settings.mobile.storeBannerImageUrl}
      updateAction={updateAction}
      onImageChange={handleImageChange}
    />
  )
}

export default MobileSettingsPage
