import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosRequestConfig, CancelTokenSource, AxiosError } from 'axios'
import { default as qs } from 'qs'
import _ from 'lodash'

import { ListMeta } from '~/reducers/products-reducer'

import { transformCustomData } from '~/containers/product-form-page/utils'
import Product, { ProductOriginal, ProductImage } from '~/types/product'
import { ProductInventorySummary, ExistsInAccounts } from '~/types/product-inventory'
import { ProductFormPayload } from '~/containers/product-form-page/form'

// Define types for the additional data returned in some of the async thunks
interface FetchProductResponse {
  product: ProductOriginal
  existsInAccounts?: ExistsInAccounts[]
  inventory: ProductInventorySummary
}

interface ThunkProductPayload {
  product: Product
  existsInAccounts?: ExistsInAccounts[]
  inventory: ProductInventorySummary
}

interface UploadImagesParams {
  newImages: ProductImage[]
  productId: number
}

interface FetchProductsResponse {
  items: Product[]
  meta: ListMeta
}

// Fetch products
export const fetchProducts = createAsyncThunk<
  FetchProductsResponse,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { queryParams?: Record<string, any>; signal?: AbortSignal },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { rejectValue: any }
>('productsPage/fetchProducts', async (options = {}, { rejectWithValue }) => {
  const { signal, queryParams = {} } = options

  if (queryParams.page === '' || queryParams.page === null) {
    delete queryParams.page
  }
  if (queryParams.search === '') {
    delete queryParams.search
  }

  const source: CancelTokenSource = axios.CancelToken.source()

  signal?.addEventListener('abort', () => {
    source.cancel('Request canceled by the user.')
  })

  try {
    const response = await axios.get<FetchProductsResponse>('/api/products?' + qs.stringify(queryParams), {
      cancelToken: source.token
    })

    return response.data
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request cancelled:', error instanceof Error ? error.message : error)
    } else {
      // Handle other errors
      if (error instanceof Error) {
        console.error('Request failed:', error.message)
      } else {
        console.error('An unknown error occurred:', error)
      }
    }
    if (error instanceof AxiosError) {
      return rejectWithValue(error.response?.data)
    }
    return rejectWithValue('An unknown error occurred')
  }
})

// Destroy product
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const destroyProduct = createAsyncThunk<Product, Product, { rejectValue: any }>(
  'productsPage/destroyProduct',
  async (product, { rejectWithValue }) => {
    try {
      await axios.delete(`/api/products/${product.id}`)
      return product
    } catch (error) {
      if (error instanceof Error) {
        console.error('Request failed:', error.message)
      } else {
        console.error('An unknown error occurred:', error)
      }
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchProduct = createAsyncThunk<ThunkProductPayload, number, { rejectValue: any }>(
  'productsPage/fetchProduct',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get<FetchProductResponse>(`/api/products/${id}`, {
        ignoreChildKeys: ['customData']
      } as AxiosRequestConfig)
      const { product, inventory, existsInAccounts } = response.data
      const productTransformed: Product = {
        ...product,
        customData: transformCustomData(product.customData)
      }
      return { product: productTransformed, inventory, existsInAccounts } as ThunkProductPayload
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createProduct = createAsyncThunk<Product, ProductFormPayload, { rejectValue: any }>(
  'productsPage/createProduct',
  async (productParams, { rejectWithValue }) => {
    try {
      const response = await axios.post<ProductOriginal>('/api/products', { product: productParams }, {
        ignoreChildKeys: ['custom_data']
      } as AxiosRequestConfig)
      const product = response.data
      const productTransformed: Product = {
        ...product,
        customData: transformCustomData(product.customData)
      }
      return productTransformed
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateProduct = createAsyncThunk<Product, ProductFormPayload, { rejectValue: any }>(
  'productsPage/updateProduct',
  async (productParams, { rejectWithValue }) => {
    try {
      const response = await axios.put<ProductOriginal>(
        `/api/products/${productParams.id}`,
        { product: productParams },
        {
          ignoreChildKeys: ['custom_data']
        } as AxiosRequestConfig
      )
      const product = response.data
      const productTransformed: Product = {
        ...product,
        customData: transformCustomData(product.customData)
      }
      return productTransformed
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const uploadImages = createAsyncThunk<void, UploadImagesParams, { rejectValue: any }>(
  'productsPage/uploadImages',
  async (params, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }

      const formData = new FormData()

      _.each(params.newImages, image => {
        if (image._file != null) {
          formData.append(`_new_images[]_file`, image._file)
          formData.append(`_new_images[]mobile`, image.mobile ? '1' : '0')
          formData.append(`_new_images[]sort_index`, image.sortIndex.toString())
        }
      })

      await axios.post(`/api/products/${params.productId}/images`, formData, config)
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

interface CheckIfProductExistsParams {
  customSku: string
  productId?: number
}

export const checkIfProductExists = createAsyncThunk<
  Product | undefined,
  CheckIfProductExistsParams,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { rejectValue: any }
>('productsPage/checkIfProductExists', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get<Product | ''>(
      `/api/products/exists?custom_sku=${encodeURIComponent(params.customSku)}${
        params.productId ? '&ignore_id=' + params.productId : ''
      }`
    )

    if (response.data) {
      return response.data
    } else return undefined
  } catch (error) {
    console.error(error)
    if (error instanceof AxiosError) {
      return rejectWithValue(error.response?.data)
    }
    return rejectWithValue('An unknown error occurred')
  }
})

interface CopyProductToAccountParams {
  id: number
  subdomain: string
}

interface CopyProductToAccountResponseOriginal {
  product: ProductOriginal
  subdomain: string
}

interface CopyProductToAccountResponse {
  product: Product
  subdomain: string
}

export const copyProductToAccount = createAsyncThunk<
  CopyProductToAccountResponse,
  CopyProductToAccountParams,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { rejectValue: any }
>('productsPage/copyProductToAccount', async (productParams, { rejectWithValue }) => {
  try {
    const response = await axios.post<CopyProductToAccountResponseOriginal>(
      `/api/products/${productParams.id}/copy_to_account`,
      productParams,
      {
        ignoreChildKeys: ['custom_data']
      } as AxiosRequestConfig
    )
    const product = response.data.product
    const productTransformed: Product = {
      ...product,
      customData: transformCustomData(product.customData)
    }
    return { ...response.data, product: productTransformed }
  } catch (error) {
    console.error(error)
    if (error instanceof AxiosError) {
      return rejectWithValue(error.response?.data)
    }
    return rejectWithValue('An unknown error occurred')
  }
})
