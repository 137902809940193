import { useFormik, FormikProvider } from 'formik'
import styled from 'styled-components'

import { ActionBar } from '~/components/forms'
import { BaseErrors } from '~/components/forms/formik'
import useSubmitHandler from '~/containers/settings/submit-handler'
import validations from './validations'
import BraintreeFields from './braintree-fields'
import StripeFields from './stripe-fields'
import InvoicingFields from './invoicing-fields'
import PreferencesFields from './preferences-fields'
import { SettingsProviderState } from '~/contexts/settings'
import { PaymentsPreferences, AccountSettingKeys } from '~/types/account-settings'

export const Container = styled.div`
  width: 800px;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

interface PaymentsPreferencesFormProps {
  redirectPath: string
  initialValues: PaymentsPreferences
  settingsKey: AccountSettingKeys
  updateAction: SettingsProviderState['updateAction']
}

const PaymentPreferencesForm = (props: PaymentsPreferencesFormProps) => {
  const { redirectPath, initialValues } = props
  const { submitHandler } = useSubmitHandler()
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validations,
    onSubmit: (values, helpers) =>
      submitHandler<PaymentsPreferences, PaymentsPreferencesFormProps>(values, helpers, props)
  })

  const selectedGateway = formik.values.paymentPreferences.paymentGateway

  return (
    <FormikProvider value={formik}>
      <Wrapper>
        <Container>
          <BaseErrors />

          <PreferencesFields selectedGateway={selectedGateway} />
          <InvoicingFields />

          {selectedGateway === 'braintree' && <BraintreeFields />}
          {selectedGateway === 'stripe' && <StripeFields />}

          <ActionBar isSubmitting={formik.isSubmitting} redirectPath={redirectPath} onSubmit={formik.handleSubmit} />
        </Container>
      </Wrapper>
    </FormikProvider>
  )
}

export default PaymentPreferencesForm
