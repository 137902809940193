import { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import _ from 'lodash'
import { Modal } from 'react-bootstrap'
import { AppState, AppDispatch } from '~/config/store'

import { Button } from '~/components/forms'
import { changeVisibleProductsGridColumns } from '~/reducers/persistable-data-reducer'

interface Column {
  id: string
  heading: string
}

interface ProductsGridCustomizerModalProps {
  className?: string
  show: boolean
  onHide: () => void
  allColumns: Column[]
}

const ModalWrapped = styled(Modal)`
  .items {
    -webkit-column-count: 3; /* Chrome, Safari, Opera */
    -moz-column-count: 3; /* Firefox */
    column-count: 3;
  }

  .item {
    padding-left: 20px;
  }
`

const ProductsGridCustomizerModal = ({ className, show, onHide, allColumns }: ProductsGridCustomizerModalProps) => {
  const visibleGridColumnIds = useSelector((state: AppState) => state.persistableData.visibleProductsGridColumnIds)
  const [selectedIds, setSelectedIds] = useState<string[]>(visibleGridColumnIds || [])
  const dispatch = useDispatch<AppDispatch>()

  const toggleSelected = useCallback((checkboxId: string) => {
    setSelectedIds(prevSelectedIds => {
      const newSelectedIds = [...prevSelectedIds]
      const index = newSelectedIds.indexOf(checkboxId)

      if (index === -1) {
        newSelectedIds.push(checkboxId)
      } else {
        newSelectedIds.splice(index, 1)
      }

      return newSelectedIds
    })
  }, [])

  const save = useCallback(() => {
    dispatch(changeVisibleProductsGridColumns(selectedIds))
    onHide()
  }, [dispatch, onHide, selectedIds])

  return (
    <ModalWrapped className={className} show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Customise Products Grid</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h5>Visible columns</h5>
        <div className="items">
          {_.map(allColumns, column => (
            <div key={column.id} className="item">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedIds.includes(column.id)}
                  value={column.id}
                  onChange={() => toggleSelected(column.id)}
                  id={`checkbox_${column.id}`}
                />
                <label className="form-check-label" htmlFor={`checkbox_${column.id}`}>
                  {column.heading}
                </label>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={save}>
          Save Changes
        </Button>
      </Modal.Footer>
    </ModalWrapped>
  )
}

export default ProductsGridCustomizerModal
