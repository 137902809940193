import AuPostShipment from '~/types/au-post-shipment'

export const defaultColumns = [
  {
    attr: 'remoteShipmentId',
    heading: 'Shipment ID',
    locked: true,
    render: (shipment: AuPostShipment) => {
      if (shipment.isCombined === true) {
        return (
          <>
            <p>{shipment.remoteShipmentId}</p>
            <p className="label label-info">combined shipment</p>
          </>
        )
      }
      return shipment.remoteShipmentId
    }
  },
  {
    attr: 'orderReferences',
    heading: 'Order',
    locked: true
  },
  {
    attr: 'orderDate',
    locked: true
  },
  {
    attr: 'orderItemsQuantity',
    heading: 'Quantity',
    locked: true
  },
  {
    attr: 'orderItemsDescription',
    heading: 'Description',
    locked: true
  },
  {
    attr: 'toName',
    heading: 'Name',
    locked: true
  },
  {
    attr: 'orderShippingCountry',
    heading: 'Country',
    locked: true
  },
  {
    attr: 'currentPostageProduct',
    heading: 'Service',
    locked: true
  }
]
