import _ from 'lodash'

import ShippingPricingProfile from '~/types/shipping-pricing-profile'
import AccountSettings, {
  AccountSettingsOriginal,
  CountryNote,
  AuPostInternationalChargeCode
} from '~/types/account-settings'

type ShippingServiceLabelsKey =
  | 'packAndTrack'
  | 'eciDoc'
  | 'internationalAirmail'
  | 'eciMerch'
  | 'regPostInternational'
  | 'intlStdSignature'

const shippingServiceLabels: Record<ShippingServiceLabelsKey, string> = {
  packAndTrack: 'Intl Standard / Pack & Track',
  eciDoc: 'intl Express Docs / ECI Docs',
  internationalAirmail: 'Intl Economy / Airmail Parcels',
  eciMerch: 'Intl Express Merch / ECI Merch',
  regPostInternational: 'Intl Economy w SOD / Regd Post',
  intlStdSignature: 'Intl Standard w/ Signature'
}

const mapShippingPricingProfiles = (settings: AccountSettingsOriginal) => {
  const transformFn = (profiles: ShippingPricingProfile[]) =>
    _.sortBy(profiles, p => {
      p.index
    })
  const regular: ShippingPricingProfile[] = transformFn(settings.regularShippingPricingProfiles)
  const express: ShippingPricingProfile[] = transformFn(settings.expressShippingPricingProfiles)
  const newSettings = {
    ...(_.omit(settings, ['regularShippingPricingProfiles', 'expressShippingPricingProfiles']) as Omit<
      AccountSettingsOriginal,
      'regularShippingPricingProfiles' | 'expressShippingPricingProfiles'
    >),
    shippingPricingProfiles: {
      regular,
      express
    }
  }

  // delete settings.regularShippingPricingProfiles
  // delete settings.expressShippingPricingProfiles

  return newSettings
}

const mapChargeCodes = (settings: AccountSettingsOriginal) => {
  const international: Array<AuPostInternationalChargeCode> = []
  let i = 1
  _.map(settings.auPostChargeCodes.international, (item, key) => {
    const itemCopy = {
      ...item,
      id: i++,
      name: key,
      label: shippingServiceLabels[key as ShippingServiceLabelsKey]
    }
    itemCopy.id = i++
    itemCopy.name = key
    itemCopy.label = shippingServiceLabels[key as ShippingServiceLabelsKey]
    const index = parseInt(itemCopy.order.toString(), 10)
    international[index] = itemCopy
  })

  const newSettings = {
    ...settings,
    auPostChargeCodes: {
      ...settings.auPostChargeCodes,
      international
    }
  }

  return newSettings
}

const mapCountryNotes = (settings: AccountSettingsOriginal) => {
  let i = 1

  const notes = _.map(settings.countryNotes, (v, k) => ({
    id: i++,
    countryCode: k.toUpperCase(),
    body: v
  })) as CountryNote[]

  const newSettings = {
    ...settings,
    countryNotes: { notes }
  }

  return newSettings
}

const transformSettings = async (promise: Promise<AccountSettingsOriginal>) => {
  return promise.then((settings: AccountSettingsOriginal) => {
    const newSettings: AccountSettings = {
      ...(_.omit(settings, [
        'regularShippingPricingProfiles',
        'expressShippingPricingProfiles',
        'auPostChargeCodes',
        'countryNotes'
      ]) as Omit<
        AccountSettingsOriginal,
        'regularShippingPricingProfiles' | 'expressShippingPricingProfiles' | 'auPostChargeCodes' | 'countryNotes'
      >),
      shippingPricingProfiles: mapShippingPricingProfiles(settings).shippingPricingProfiles,
      auPostChargeCodes: mapChargeCodes(settings).auPostChargeCodes,
      countryNotes: mapCountryNotes(settings).countryNotes
    }

    return newSettings
  })
}

export default transformSettings
