import { useRef, useEffect } from 'react'
import type { XYCoord, Identifier } from 'dnd-core'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useDrag, useDrop } from 'react-dnd'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import ShopfrontPage from '~/types/shopfront-page'
import Icon from '~/components/icon'
import { Button } from '~/components/forms'

const ItemRow = styled.div<{ active: boolean; order?: string }>`
  order: ${props => props.order || 'auto'};
  box-sizing: border-box;
  flex-grow: 1;
  width: 100%; // Default to full width
  padding: 0.8em 1.2em;
  overflow: hidden; // Or flex might break

  border: 1px solid ${props => props.theme.colors.gray.lightest};
  background-color: ${props => props.theme.colors.gray.lightest3};
  opacity: ${props => (props.active === true ? 1 : 0.4)};
  border-radius: ${props => props.theme.borderRadius};
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledIcon = styled(Icon)`
  cursor: pointer;
  margin-right: 2rem;
`
const Title = styled.h4`
  flex: 1;
`

interface DraggableItemProps {
  index: number
  type: string
}

interface SortableItemProps {
  index: number
  onSortEnd?: (dragIndex: number, hoverIndex: number) => void
  item: ShopfrontPage
  onDelete: (item: ShopfrontPage) => void
  className?: string
}
const SortableItem = ({ item, onDelete, className, index, onSortEnd }: SortableItemProps) => {
  const ref = useRef<HTMLDivElement>(null)

  const [{ handlerId }, drop] = useDrop<DraggableItemProps, void, { handlerId: Identifier | null }>({
    accept: 'div',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover(item: DraggableItemProps, monitor) {
      if (!ref.current) return

      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) return

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleX = hoverBoundingRect && (hoverBoundingRect.right - hoverBoundingRect.left) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientX = hoverBoundingRect && (clientOffset as XYCoord).x - hoverBoundingRect.left
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) return
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) return

      onSortEnd?.(dragIndex, hoverIndex)
      item.index = hoverIndex
    }
  })

  const [dragStyles, drag, preview] = useDrag({
    type: 'div',
    item: () => {
      return { index }
    },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  })

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview])

  drag(drop(ref))

  return (
    <ItemRow
      key={item.id}
      active={item.active}
      ref={ref}
      data-handler-id={handlerId}
      style={dragStyles}
      className={className}>
      <StyledIcon icon="bars" />
      <Title>{item.title}</Title>
      <div className="action-buttons">
        <Link to={`/settings/shopfront_pages/${item.id}`}>Preview</Link> |&nbsp;
        <Link to={`/settings/shopfront_pages/${item.id}/edit`}>Edit</Link> |&nbsp;
        <Button variant="link" onClick={() => onDelete(item)}>
          Delete
        </Button>
      </div>
    </ItemRow>
  )
}

export default SortableItem
