import { useField, FieldInputProps } from 'formik'
import Select, { PropsValue, OptionsOrGroups, GroupBase, MultiValue, SingleValue } from 'react-select'
import { Col, FormGroup, FormLabel, Form } from 'react-bootstrap'

export type Option = {
  value: string
  label: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getValue = (field: FieldInputProps<any>, options: any, isMulti: boolean) => {
  let result: PropsValue<Option> | undefined = null

  if (options) {
    result = isMulti
      ? options.filter((o: Option) => (field.value || []).indexOf(o.value) >= 0)
      : options.find((option: { value: string }) => option.value === field.value)
  } else {
    result = isMulti ? [] : undefined
  }

  return result
}

export interface SelectInputProps {
  id?: string
  name: string
  label?: string
  hl?: boolean
  options?: OptionsOrGroups<Option, GroupBase<Option>>
  isMulti?: boolean
}
const SelectInput = ({ hl, label, id, options, isMulti = false, ...props }: SelectInputProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [field, meta, helpers] = useField(props as any)
  const hasError = meta.touched && meta.error

  const onChange = (value: SingleValue<Option> | MultiValue<Option>) => helpers.setValue(value)
  const value = getValue(field, options, isMulti)

  if (hl === true) {
    return (
      <FormGroup controlId={id || props.name}>
        <Col as={FormLabel} sm={4}>
          {label || props.name}
        </Col>
        <Col sm={8}>
          <Select isMulti={isMulti} options={options} value={value} onChange={onChange} />
          {hasError && <Form.Text>{meta.error}</Form.Text>}
        </Col>
      </FormGroup>
    )
  }

  return (
    <FormGroup controlId={id || props.name}>
      <FormLabel>{label || props.name}</FormLabel>
      <Select isMulti={isMulti} options={options} value={value} onChange={onChange} />
      {hasError && <Form.Text>{meta.error}</Form.Text>}
    </FormGroup>
  )
}

export default SelectInput
