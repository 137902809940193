import styled from 'styled-components'
import InputGroup, { InputGroupProps } from '../input-group'

interface FlexibleInputGroupProps extends InputGroupProps {
  labelWidth?: string
  textAlign?: string
}
const FlexibleInputGroup = ({ labelWidth, textAlign, ...rest }: FlexibleInputGroupProps) => (
  <InputGroup {...(rest as InputGroupProps)} />
)

const FlexibleInputGroupStyled = styled(FlexibleInputGroup)`
  display: flex;
  align-items: center;

  & label {
    min-width: ${props => props.labelWidth || '16rem'};
    text-align: ${props => props.textAlign || 'right'};
    margin: 0 1rem;
  }

  & input {
    flex: 1;
  }
`

export default FlexibleInputGroupStyled
