import { ChangeEvent } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import { setLastCsvImport, setFileUploading } from '~/reducers/order-processing-reducer'
import { AppDispatch } from '~/config/store'
import CsvImport from '~/types/csv-import'

export enum CsvTypeEnum {
  EBAY_SALES_RECORDS = 'ebay',
  WORDPRESS_ORDERS = 'generic'
}

const Container = styled.div`
  margin-bottom: 20px;
`

const LeadText = styled.div`
  margin-top: 0;
`

const uploadFile = async (file: File, url: string) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }

  const formData = new FormData()
  formData.set(`order_csv_import[csv_file]`, file)

  return axios.post(url, formData, config)
}

interface UploadFormProps {
  title: string
  className?: string
  csvType: CsvTypeEnum
}

interface UploadCsvScreenProps {
  className?: string
  afterUpload?: (csvType: CsvTypeEnum, csvImport: CsvImport) => void
}

const UploadCsvScreen = ({ className, afterUpload }: UploadCsvScreenProps) => {
  const dispatch = useDispatch<AppDispatch>()

  const handleFileInputChange = async (e: ChangeEvent<HTMLInputElement>, csvType: CsvTypeEnum) => {
    const file = e.target?.files?.[0]

    if (file) {
      await startUpload(file, csvType)
    }
  }

  const startUpload = async (file: File, csvType: CsvTypeEnum) => {
    dispatch(setFileUploading(true))
    let response
    try {
      response = await uploadFile(file, `/api/order_csv_imports/${csvType}`)
    } finally {
      dispatch(setFileUploading(false))
    }
    dispatch(setLastCsvImport(response.data))
    afterUpload?.(csvType, response.data)
  }

  const UploadForm = ({ title, csvType }: UploadFormProps) => (
    <div className="card mb-4">
      <div className="card-body">
        <LeadText className="lead">{title}</LeadText>
        <input type="file" onChange={e => handleFileInputChange(e, csvType)} />
      </div>
    </div>
  )

  return (
    <Container className={className}>
      <h1>Import Order Data for Processing</h1>

      <UploadForm title="Import eBay Sales History CSV" csvType={CsvTypeEnum.EBAY_SALES_RECORDS} />
      <UploadForm title="Import Wordpress Orders CSV" csvType={CsvTypeEnum.WORDPRESS_ORDERS} />
    </Container>
  )
}

export default UploadCsvScreen
