import { AxiosError } from 'axios'

import _ from 'lodash'
import ErrorMessageBox from '~/components/error-message-box'

interface ErrorData {
  errorType?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  remoteErrors?: any
}

interface ServerErrorDetails {
  status: number | undefined
  type: string | undefined
  message: string | undefined
  origin: string | undefined
}

interface ServerErrorProps {
  serverError: AxiosError | undefined
  onDismiss: () => void
}
const ServerError = ({ serverError, onDismiss }: ServerErrorProps) => {
  if (!serverError) {
    return null
  }

  let details = {} as ServerErrorDetails
  details.status = serverError?.response?.status
  const resData = serverError?.response?.data as ErrorData

  if (resData) {
    const info = _.pick(resData, ['type', 'message', 'origin'])
    details = { ...details, ...info }
  }

  const isActiveRecordError = resData?.errorType === 'ActiveRecord::RecordInvalid'
  if (isActiveRecordError) {
    return <ErrorMessageBox heading={'The local server returned below validation errors'} onDismiss={onDismiss} />
  }

  const auPostErrors = resData?.remoteErrors || []
  console.log('[DEBUG] auPostErrors =', auPostErrors)
  if (auPostErrors.length > 0) {
    return (
      <ErrorMessageBox
        heading={'The remote server returned the following errors:'}
        onDismiss={onDismiss}
        toYaml={auPostErrors}
      />
    )
  }

  return (
    <ErrorMessageBox
      onDismiss={onDismiss}
      renderBody={() => (
        <>
          <p>Status: {details.status}</p>
          <p>Type: {details.type}</p>
          <p>Message: {details.message}</p>
          <p>Origin: {details.origin}</p>
        </>
      )}
    />
  )
}

export default ServerError
