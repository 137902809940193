import { useState, useCallback } from 'react'
import styled from 'styled-components'
import ReactLoading from 'react-loading'

const ColGroup = styled.div`
  display: flex;
`

const ColA = styled.div`
  align-items: baseline;
  flex-basis: 300px;
  flex-grow: 1;
`

const ColB = styled.div`
  flex-basis: 300px;
  flex-shrink: 1;

  @media (max-width: 799px) {
    margin-top: 20px;
  }
`

const MainDiv = styled.div`
  border-top: 1px solid #ddd;
  padding: 23px 10px;
  text-align: center;

  .loading-images {
    height: 14px !important;
    width: 50px !important;
    margin-top: -10px;
  }
`

const UploadBtn = styled.button`
  float: right;
  background: #4276a4;
  color: #fff;
  border: 1px solid #000;
  padding: 6px 10px;
  font-size: 11px;
  border-radius: 3px;
`

const CancelBtn = styled.a`
  float: right;
  margin-right: 15px;
  margin-top: 5px;
  font-size: 16px;
`

interface UploadImagesButtonProps {
  progressCounter: number
  isUploadInProgress?: boolean
  matchedImageCount: number
  unmatchedImageCount: number
  failedImageCount: number
  startUpload: () => void
  cancelUpload: () => void
}

const UploadImagesButton = ({
  progressCounter,
  isUploadInProgress = false,
  matchedImageCount = 0,
  unmatchedImageCount = 0,
  failedImageCount,
  startUpload,
  cancelUpload
}: UploadImagesButtonProps) => {
  const [totalImageToUpload, setTotalImagesToUpload] = useState(0)

  const buttonPressed = useCallback(() => {
    setTotalImagesToUpload(matchedImageCount)
    startUpload()
  }, [matchedImageCount, startUpload])

  const cancelUploading = useCallback(() => {
    cancelUpload()
  }, [cancelUpload])

  const showStatusText = () => {
    const statusText = (
      <span>
        <span
          style={{
            display: isUploadInProgress && matchedImageCount > 0 ? 'block' : 'none'
          }}>
          <span style={{ marginRight: '9px' }}>Please Wait...... uploading</span>
          {progressCounter} of {totalImageToUpload} images.
          {failedImageCount > 0 && <span> {failedImageCount} failed images</span>}
        </span>
        <span style={{ display: isUploadInProgress ? 'none' : 'block' }}>
          {matchedImageCount} images matched to products.
          {unmatchedImageCount > 0 && <span> {unmatchedImageCount} could not be matched to products</span>}
        </span>
      </span>
    )
    return statusText
  }

  return (
    <MainDiv>
      <ColGroup>
        <ColA>{showStatusText()}</ColA>

        <ColB>
          {!(isUploadInProgress && matchedImageCount > 0) && (
            <UploadBtn onClick={buttonPressed}>Upload Images!</UploadBtn>
          )}
          {isUploadInProgress && matchedImageCount > 0 ? (
            <ReactLoading type="spinningBubbles" color="#444" className="loading-images" />
          ) : (
            ''
          )}
          {isUploadInProgress && matchedImageCount > 0 ? <CancelBtn onClick={cancelUploading}>cancel</CancelBtn> : ''}
        </ColB>
      </ColGroup>
    </MainDiv>
  )
}

export default UploadImagesButton
