import { array, object, string, boolean } from 'yup'

export default object().shape({
  name: string().required('Required'),
  searchTerm: string().nullable(),
  alternateTerms: array().of(
    object().shape({
      key: string().required('Required'),
      value: string().required('Required')
    })
  ),
  featured: boolean(),
  brand: string().nullable(),
  coverImage: string().nullable()
})
