import { useCallback, useState, useEffect, ChangeEvent, ReactNode } from 'react'
import { FieldInputProps, FormikProps, getIn } from 'formik'
import Rte, { EditorValue } from 'react-rte'
import classnames from 'classnames'
import _ from 'lodash'
import { useDebouncedCallback } from 'use-debounce'

import { Button } from '~/components/forms'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface RichTextEditorProps<V = any, FormValues = any> {
  debug: false
  field: FieldInputProps<V>
  form: FormikProps<FormValues>
  label?: string
  helpBlock?: string | ReactNode
  required?: boolean
  onChange?: (text: string) => void
  editorSourceFormat: string
}

// NOTE: 'react-rte' is deprecated, and should be replaced with something else
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RichTextEditor = <V = any, FormValues = any>({
  field: { value, onChange: formikOnChange, ...otherFields },
  form,
  label,
  helpBlock,
  required,
  onChange: customOnChange,
  editorSourceFormat,
  debug
}: RichTextEditorProps<V, FormValues>) => {
  const [editorValue, setEditorValue] = useState<EditorValue>(EditorValue.createEmpty())
  const [showSource, setShowSource] = useState<boolean>(false)
  const [editorFormat, setEditorFormat] = useState<string>(editorSourceFormat)

  const { name } = otherFields
  const formikErrors = getIn(form.errors, name)
  const errors = formikErrors //|| customErrors

  const onChange = useDebouncedCallback(
    useCallback(
      (value: string) => {
        const syntheticEvent = {
          target: {
            name,
            value
          }
        } as React.ChangeEvent<HTMLInputElement>

        formikOnChange(syntheticEvent)
        customOnChange?.(value)
      },
      [customOnChange, formikOnChange, name]
    ),
    500
  )

  useEffect(() => {
    setEditorValue(value ? Rte.createValueFromString(value as string, editorSourceFormat) : Rte.createEmptyValue())
    // NOTE: deliberately not including 'value', as that causes the cursor position in the editor to reset
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorSourceFormat])

  const editorOnChange = (value: EditorValue) => {
    setEditorValue(value)

    const text = value.toString(editorFormat)

    if (text.length === 2 && text.charCodeAt(0) === 8203 && text.charCodeAt(1) === 10) {
      onChange?.('')
    } else {
      onChange?.(text)
    }
  }

  const toggleShowSource = () => setShowSource(!showSource)

  const toggleSourceFormat = (event: ChangeEvent<HTMLInputElement>) => setEditorFormat(event.target.value)

  const renderSourceOption = () => {
    const source = editorValue?.toString(editorFormat)

    return (
      <div>
        <div className="row">
          <Button onClick={toggleShowSource}>Toggle Source</Button>
        </div>

        {showSource && (
          <div className="row">
            <textarea readOnly className="form-control" placeholder="Editor Source" value={source} />
          </div>
        )}
      </div>
    )
  }

  const renderFormatOption = () => (
    <div className="row">
      <label className="radio-item">
        <input
          type="radio"
          name="format"
          value="html"
          checked={editorFormat === 'html'}
          onChange={toggleSourceFormat}
        />
        <span>HTML</span>
      </label>
      <label className="radio-item">
        <input
          type="radio"
          name="format"
          value="markdown"
          checked={editorFormat === 'markdown'}
          onChange={toggleSourceFormat}
        />
        <span>Markdown</span>
      </label>
    </div>
  )

  const renderEditor = () => (
    <>
      {debug && renderFormatOption()}
      <Rte value={editorValue} onChange={editorOnChange} />
    </>
  )

  if (!Rte) {
    return <div />
  }

  return (
    <div className={classnames('row mb-3', { 'has-error': errors })}>
      {debug && renderSourceOption()}
      <label className="col-form-label text-start fw-bold">
        {required && <abbr title="required">*</abbr>}
        {(required ? ' ' : '') + (label || _.startCase(name))}
      </label>

      {renderEditor()}

      {errors &&
        _.map(errors, (error, index) => (
          <span key={index} className="form-text text-danger">
            {error as string}
          </span>
        ))}
      {helpBlock && <span className="form-text help-block">{helpBlock}</span>}
    </div>
  )
}

export default RichTextEditor
