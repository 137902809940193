export enum ProductImportMatcherMarketplaceTypeEnum {
  EBAY = 'ebay',
  WORDPRESS = 'wordpress'
}

interface ProductImportMatcher {
  id?: number
  marketplace: ProductImportMatcherMarketplaceTypeEnum
  scancode: string
}

export default ProductImportMatcher
