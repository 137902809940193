import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import styled from 'styled-components'

import { Table, Thead, Tbody, Tr, Th as Th_, Td } from '~/components/table'
import { Button } from '~/components/forms'
import WeightBasedShippingPricingProfile from '~/types/weight-based-shipping-pricing-profile'

const Th = styled(Th_)`
  text-align: center;
`

const regionNames = ['Au', 'Zone1', 'Zone2', 'Zone3', 'Zone4', 'Zone5', 'Zone6', 'Zone7', 'Zone8', 'Zone9']
type RegionName = (typeof regionNames)[number]

const renderRegionValues = (item: WeightBasedShippingPricingProfile) =>
  _.map(regionNames, (region: RegionName) => (
    <Fragment key={region}>
      <Td>{printPerUnit(item, region)}</Td>
      <Td>{printPerKg(item, region)}</Td>
    </Fragment>
  ))

const printPerUnit = (item: WeightBasedShippingPricingProfile, region: RegionName) => {
  const value = item[`pi${region}` as keyof WeightBasedShippingPricingProfile]
  return value
}

const printPerKg = (item: WeightBasedShippingPricingProfile, region: RegionName) => {
  const value = item[`pk${region}` as keyof WeightBasedShippingPricingProfile]
  return value && value + '/kg'
}

interface ListViewProps {
  items: WeightBasedShippingPricingProfile[]
  onDelete: (item: WeightBasedShippingPricingProfile) => void
}
const ListView = ({ items, onDelete }: ListViewProps) => (
  <div>
    <Table>
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Min Weight (Kg)</Th>
          <Th>Max Weight (Kg)</Th>
          <Th colSpan={2}>Australia</Th>
          <Th colSpan={2}>Zone 1</Th>
          <Th colSpan={2}>Zone 2</Th>
          <Th colSpan={2}>Zone 3</Th>
          <Th colSpan={2}>Zone 4</Th>
          <Th colSpan={2}>Zone 5</Th>
          <Th colSpan={2}>Zone 6</Th>
          <Th colSpan={2}>Zone 7</Th>
          <Th colSpan={2}>Zone 8</Th>
          <Th colSpan={2}>Zone 9</Th>
          <Th className="empty" />
        </Tr>
      </Thead>

      <Tbody>
        {_.map(items, item => (
          <Tr key={item.id}>
            <Td>{item.name}</Td>
            <Td>{item.minWeight}</Td>
            <Td>{item.maxWeight}</Td>
            {renderRegionValues(item)}

            <Td className="action-buttons">
              <Link to={`/settings/weight_based_shipping_pricing_profiles/${item.id}`}>Edit</Link>
              &nbsp;|&nbsp;
              <Button variant="link" onClick={() => onDelete(item)}>
                Delete
              </Button>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  </div>
)

export default ListView
