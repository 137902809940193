import styled from 'styled-components'
import { useFormik, FormikProvider } from 'formik'
import { toast } from 'react-toastify'
import { animateScroll } from 'react-scroll'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'

import { handleErrorResponse } from '~/lib/form-utils'
import { ActionBar } from '~/components/forms'
import { BaseErrors, FlexibleInputGroup } from '~/components/forms/formik'
import validations from './validations'
import { AsyncThunk } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '~/config/store'
import MobilePage from '~/types/mobile-page'

const Container = styled.div`
  width: 800px;
`

const Heading = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

const redirectPath = '/settings/mobile_pages'

interface MobilePagesFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addOrUpdateItem: AsyncThunk<MobilePage, Partial<MobilePage>, any>
  item: MobilePage
}

const MobilePagesForm = ({ addOrUpdateItem, item }: MobilePagesFormProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  const formik = useFormik({
    initialValues: item,
    validationSchema: validations,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      const onSuccess = () => {
        setSubmitting(false)
        navigate(redirectPath)
        toast.success('Success!')
        animateScroll.scrollToTop()
      }

      const onError = (error: AxiosError) => {
        setSubmitting(false)
        toast.error('Sorry, there were errors processing the form. Please review!')
        animateScroll.scrollToTop()

        handleErrorResponse({
          response: error.response,
          setErrors
        })
      }

      console.log('[DEBUG] values =', values)
      dispatch(addOrUpdateItem(values)).then(onSuccess).catch(onError)
    }
  })

  return (
    <FormikProvider value={formik}>
      <Wrapper>
        <Container>
          <BaseErrors />

          <Heading>{item.id > 0 ? 'Edit Mobile Page' : 'New Mobile Page'}</Heading>

          <FlexibleInputGroup {...formik.getFieldProps('title')} />
          <FlexibleInputGroup type="markdown" {...formik.getFieldProps('content')} />

          <ActionBar isSubmitting={formik.isSubmitting} redirectPath={redirectPath} onSubmit={formik.handleSubmit} />
        </Container>
      </Wrapper>
    </FormikProvider>
  )
}

export default MobilePagesForm
