import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useFormikContext } from 'formik'

import { Button } from '~/components/forms'

const Wrapper = styled.div`
  min-height: 50px;
  margin: 0;
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
`

const Group = styled.div`
  display: flex;
  justify-content: space-between;
`

interface FormActionsProps {
  redirectPath: string
  onClickBack: () => void
}
const FormActions = ({ onClickBack, redirectPath }: FormActionsProps) => {
  const navigate = useNavigate()
  const { isSubmitting, handleSubmit } = useFormikContext()

  return (
    <Wrapper>
      <Group>
        {!redirectPath && (
          <Button variant="link" onClick={onClickBack}>
            Back
          </Button>
        )}
        {redirectPath && (
          <div>
            <Button
              variant="primary"
              disabled={isSubmitting}
              onClick={() => handleSubmit()}
              isSubmitting={isSubmitting}>
              Save Changes
            </Button>

            <Button variant="light" onClick={() => navigate(redirectPath)}>
              Cancel
            </Button>
          </div>
        )}
      </Group>
    </Wrapper>
  )
}

export default FormActions
