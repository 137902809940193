import ProductSearchLink from './product-search-link'

export enum MenuItemKindEnum {
  MOBILE = 'mobile',
  SHOPFRONT = 'shopfront'
}

interface MenuItem {
  id: number
  label: string
  priority: number
  parentId: number | null
  searchLink: ProductSearchLink | null
  searchLinkId: number | null
  active: boolean
}

export default MenuItem
