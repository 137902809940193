import styled from 'styled-components'

import Spinner from '~/components/spinner'
import useAuth from '~/contexts/useAuth'

import styles from './index.module.css'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8em;

  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
`

const LoadingView = () => {
  const { authPending } = useAuth()
  return (
    authPending && (
      <Wrapper>
        <Spinner className={styles.spinner} />
      </Wrapper>
    )
  )
}

export default LoadingView
