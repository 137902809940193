import { object, string, array } from 'yup'
import AuPostShipment from '~/types/au-post-shipment'

const getValidationSchema = (model: AuPostShipment) => {
  const isIntl = model?.isIntl
  const schema = object().shape({
    toName: string().required('Required'),
    toLines: array().of(string()).required('Required'),
    toSuburb: string().required('Required'),
    toState: string().required('Required'),
    toPostcode: string().required('Required'),
    toPhone: string().required('Required'),
    toEmail: string().required('Required'),
    packageItemReference: string(),
    packageProductId: string().required('Required'),
    packageLength: string().required('Required'),
    packageWidth: string().required('Required'),
    packageHeight: string().required('Required'),
    packageWeight: string().required('Required'),
    senderReferences: array().of(string().max(50, 'Only ${max} characters are allowed')).required('Required')
  })
  const intlSchema = object().shape({
    intlToCountry: string().required('Required'),
    intlItemContentsCountryOfOrigin: string().required('Required'),
    intlItemContentsDescription: string().required('Required'),
    intlItemContentsQuantity: string().required('Required'),
    intlItemContentsWeight: string().required('Required'),
    intlItemContentsTariffCode: string().required('Required'),
    intlItemContentsValue: string().required('Required'),
    intlPackageItemDescription: string().required('Required'),
    intlPackageCommercialValue: string().required('Required'),
    intlPackageClassificationType: string().required('Required'),
    intlPackageDescriptionOfOther: string().required('Required'),
    intlPackageExportDeclarationNumber: string().required('Required'),
    intlPackageImportReferenceNumber: string().required('Required')
  })

  if (isIntl === true) {
    schema.concat(intlSchema)
  }

  return schema
}

export default getValidationSchema
