import { useField } from 'formik'
import { Col, FormGroup, FormControl, FormLabel, Form } from 'react-bootstrap'
import _ from '~/lib/lodash'

export interface TextInputProps {
  id?: string
  name: string
  label?: string
  readOnly?: boolean
  value?: string | undefined | number | null
  hl?: boolean
}
const TextInput = ({ hl, label, id, ...props }: TextInputProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [field, meta] = useField(props as any)
  const hasError = meta.touched && meta.error
  const _label = label || _.startCase(props.name)

  if (hl === true) {
    return (
      <FormGroup controlId={id || props.name}>
        <Col as={FormLabel} sm={4}>
          {_label}
        </Col>
        <Col sm={8}>
          <FormControl type="text" {...field} {...props} />
          {hasError && <Form.Text>{meta.error}</Form.Text>}
        </Col>
      </FormGroup>
    )
  }

  return (
    <FormGroup controlId={id || props.name}>
      <FormLabel>{label || props.name}</FormLabel>
      <FormControl type="text" {...field} {...props} />
      {hasError && <Form.Text>{meta.error}</Form.Text>}
    </FormGroup>
  )
}

export default TextInput
