import { useCallback, useState, createContext } from 'react'

import Confirm, { ConfirmProps } from './confirm'

type GlobalConfirmProps = Omit<ConfirmProps, 'autoDismiss'> & {
  active?: boolean
}

type ConfirmResolver = (value: boolean) => void
const ConfirmContext = createContext<
  [
    (props: ConfirmProps) => Promise<boolean>, // showConfirm
    () => void // hideConfirm
  ]
>([() => Promise.resolve(false), () => null])

export default ConfirmContext

export const ConfirmContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [confirm, setConfirm] = useState<GlobalConfirmProps>({
    message: '',
    active: false
  })
  const [resolver, setResolver] = useState<ConfirmResolver | null>(null)

  const showConfirm = useCallback(
    ({ ...confirmProps }: Omit<ConfirmProps, 'autoDismiss'>) => {
      return new Promise<boolean>(resolve => {
        setConfirm({
          active: true,
          ...confirmProps
        })
        setResolver(() => resolve)
      })
    },
    [setConfirm]
  )

  const hideConfirm = useCallback(() => {
    setConfirm(prevState => ({
      ...prevState,
      active: false
    }))
  }, [setConfirm])

  const { active, ...rest } = confirm

  return (
    <ConfirmContext.Provider value={[showConfirm, hideConfirm]}>
      {active && (
        <div>
          <Confirm
            {...rest}
            onDismiss={() => {
              resolver?.(false) // Resolve promise with false when dismissed
              setConfirm({ active: false, message: '' })
            }}
            onActualConfirm={() => {
              resolver?.(true) // Resolve promise with true when confirmed
              setConfirm({ active: false, message: '' })
            }}
          />
        </div>
      )}

      {children}
    </ConfirmContext.Provider>
  )
}
