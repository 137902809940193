import _ from 'lodash'
import { FormikErrors } from 'formik'

import Order, { OrderFormPayload, OrderCreatedWithEnum, CustomsClassificationEnum, OrderLineItem } from '~/types/order'
import Customer from '~/types/customer'
import { PosFormValues, OrderShippingData, Discount } from './form'

export function createCustomerRecordFromOrderValues(orderValues: OrderShippingData) {
  const customer = {} as Customer

  if (orderValues) {
    if (orderValues.customerId) customer.id = orderValues.customerId

    customer.name = orderValues.shippingContactName
    customer.phone = orderValues.shippingContactPhone || ''
    customer.email = orderValues.customerEmail
    customer.address1 = orderValues.shippingAddress1
    customer.address2 = orderValues.shippingAddress2 || ''
    customer.address3 = orderValues.shippingAddress3 || ''
    customer.suburb = orderValues.shippingCity
    customer.state = orderValues.shippingState
    customer.country = orderValues.shippingCountry
    customer.countryName = orderValues.shippingCountryName
    customer.postcode = orderValues.shippingPostcode
  }

  // Creating a new customer, default country to australia
  if (!orderValues || _.isEmpty(orderValues)) {
    customer.country = 'AU'
  }

  return customer
}

export function transformForBackend(values: PosFormValues) {
  const orderParams: OrderFormPayload = {
    ...(_.cloneDeep(_.omit(values, 'customer', 'lineItems', 'lineItemsContainGst')) as Omit<
      PosFormValues,
      'customer' | 'lineItems' | 'lineItemsContainGst'
    >),
    lineItemsContainGst: values.lineItemsContainGst === '1',
    createdWith: OrderCreatedWithEnum.POS,
    nettPrice: 0,
    insuranceCost: 0,
    customsClassification: CustomsClassificationEnum.SALE,
    totalPrice: values.total,
    lineItemsAttributes: [...values.lineItems, ...values.deletedLineItems].map(
      l => _.omit(l, 'product') as Omit<OrderLineItem, 'product'>
    )
  }

  console.log('[DEBUG] orderParams =', orderParams)

  return orderParams
}

export function getCustomerErrors(formSyncErrors: FormikErrors<PosFormValues>) {
  return _.omitBy(
    _.mapValues(orderCustomerFieldsMap, (_value, key) => formSyncErrors[key as keyof FormikErrors<PosFormValues>]),
    _.isUndefined
  )
}

export const orderCustomerFieldsMap = {
  customerId: 'id',
  customerEmail: 'email',
  shippingContactName: 'name',
  shippingContactPhone: 'phone',
  shippingAddress1: 'address1',
  shippingAddress2: 'address2',
  shippingAddress3: 'address3',
  shippingCity: 'suburb',
  shippingState: 'state',
  shippingCountry: 'country',
  shippingCountryName: 'countryName',
  shippingPostcode: 'postcode'
}

export const domesticShippingServices = {
  noShipping: 'No Shipping Required',
  regularEparcel: 'Regular eParcel',
  expressEparcel: 'Express eParcel'
} as const

export const intlShippingServices = {
  noShipping: 'No Shipping',
  packAndTrack: 'Intl Standard / Pack & Track',
  eciDoc: 'Intl Express Docs / ECI Docs',
  internationalAirmail: 'Intl Economy / Airmail Parcels',
  eciMerch: 'Intl Express Merch / ECI Merch',
  regPostInternational: 'Intl Economy w SOD / Regd Post',
  intlStdSignature: 'Intl Standard w/ Signature'
} as const

type DomesticShippingServices = typeof domesticShippingServices
type IntlShippingServices = typeof intlShippingServices

export type AllShippingServices = DomesticShippingServices & IntlShippingServices

export const allShippingServices: AllShippingServices = _.merge(domesticShippingServices, intlShippingServices)

export function transformForFrontend(order: Order) {
  const values: PosFormValues = {
    ...order,
    // lineItems: order.lineItems.map(line => {
    //   line.productId = line.product?.id
    // }),
    deletedLineItems: [],
    subtotal: order.nettPrice,
    total: order.totalPrice,
    discount: {
      type: '$',
      value: order.discountAmount || 0
    },
    taxAmount: parseFloat(order.totalPrice.toString()) - parseFloat(order.nettPrice.toString()) || 0,
    lineItemsContainGst: order.lineItemsContainGst === true ? '1' : '0'
  }

  return values
}

export function calculateLineTotal(quantity: number, unitPrice: number | string) {
  let lineTotal = 0
  lineTotal = !quantity || !unitPrice ? 0 : quantity * parseFloat(unitPrice.toString())
  return toDecimal(lineTotal)
}

export function calculateSubtotal(lineItems: { subtotal: number }[]) {
  let subtotal = 0
  _.each(lineItems, line => {
    subtotal += line.subtotal || 0
  })
  return toDecimal(subtotal)
}

export function isDomestic(shippingCountry: string) {
  if (_.toLower(shippingCountry) === 'australia' || shippingCountry === 'AU') {
    return true
  } else {
    return false
  }
}

export function calculateDiscountAmount(subtotal?: number | null, discount?: Discount) {
  let discountAmount = 0

  if (discount?.type === '%') {
    discountAmount = ((subtotal || 0) * discount.value) / 100
  } else if (discount?.type === '$') {
    discountAmount = discount.value
  }

  return toDecimal(discountAmount)
}

export function calculateTaxAmount(subtotal: number | null | undefined, lineItemsContainGst: '1' | '0') {
  return lineItemsContainGst === '1' ? toDecimal(Number(subtotal || 0) / 11) : 0
}

export function calculateTotal(
  subtotal: number | null | undefined,
  discountAmount: number | null | undefined,
  shippingCost: number | null | undefined
) {
  let total = 0
  // After discount
  total = (subtotal || 0) - (discountAmount || 0)
  // After shipping
  total += parseFloat(shippingCost?.toString() || '0')

  return toDecimal(total)
}

export function toDecimal(num: number | null | undefined) {
  return num && _.isFunction(num.toFixed) ? parseFloat(num.toFixed(2)) : num
}

export function printCurrency(value: number | string | null | undefined, prefix = '$') {
  if (value == null) return ''

  const prefixStr = _.toString(prefix)
  const amountStr = Number(value).toFixed(2)
  return value ? `${prefixStr}${amountStr}` : '0'
}
