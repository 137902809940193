import { toast } from 'react-toastify'
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'

import MobilePage from '~/types/mobile-page'

export function fetchAllMobilePages() {
  return axios.get<MobilePage[]>(`/api/settings/mobile_pages`)
}

export function createMobilePage(params: Partial<MobilePage>) {
  return axios.post<MobilePage>(`/api/settings/mobile_pages`, {
    mobilePage: params
  })
}

export function updateMobilePage(params: Partial<MobilePage>) {
  return axios.put<MobilePage>(`/api/settings/mobile_pages/${params.id}`, {
    mobilePage: params
  })
}

export function sortMobilePagesList(orderedIds: number[]) {
  return axios.patch(`/api/settings/mobile_pages/sort_items`, {
    ordered_ids: orderedIds
  })
}

export function destroyMobilePage(id: number) {
  return axios.delete(`/api/settings/mobile_pages/${id}`)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const loadItems = createAsyncThunk<MobilePage[], void, { rejectValue: any }>(
  'mobilePages/loadItems',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchAllMobilePages()
      return response.data
    } catch (error) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addOrUpdateItem = createAsyncThunk<MobilePage, Partial<MobilePage>, { rejectValue: any }>(
  'mobilePages/addOrUpdateItem',
  async (item, { rejectWithValue }) => {
    try {
      const response = item.id ? await updateMobilePage(item) : await createMobilePage(item)
      return response.data
    } catch (error) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reSortItems = createAsyncThunk<number[], number[], { rejectValue: any }>(
  'mobilePages/reSortItems',
  async (orderIds, { rejectWithValue }) => {
    try {
      await sortMobilePagesList(orderIds)
      return orderIds
    } catch (error) {
      toast.error('Error: failed to sort.')
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteItem = createAsyncThunk<MobilePage, MobilePage, { rejectValue: any }>(
  'mobilePages/deleteItem',
  async (mobilePage, { rejectWithValue }) => {
    try {
      await destroyMobilePage(mobilePage.id)
      return mobilePage
    } catch (error) {
      toast.error('Error: failed to delete.')
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)
