import styled from 'styled-components'
import { InputHTMLAttributes, ChangeEvent, FocusEvent } from 'react'
import { FieldInputProps, FormikProps, getIn } from 'formik'
import _ from 'lodash'
import classnames from 'classnames'

const Wrapper = styled.div`
  margin: 0 !important;

  & > input {
    max-width: 80px !important;
    margin: 0;
  }
`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface BsInputProps<V = any, FormValues = any> extends Omit<InputHTMLAttributes<HTMLInputElement>, 'form'> {
  field: FieldInputProps<V>
  form: FormikProps<FormValues>
  helpBlock?: string | null
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BsInput = <V = any, FormValues = any>({
  field: { value, onChange: formikOnChange, onBlur: formikOnBlur, ...otherFields },
  form,
  helpBlock,
  onChange: customOnChange,
  onBlur: customOnBlur,
  ...rest
}: BsInputProps<V, FormValues>) => {
  const { name } = otherFields
  const errors = getIn(form.errors, name)
  // const touched = getIn(form.touched,name)

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    formikOnChange(e)
    customOnChange?.(e)
  }

  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    formikOnBlur(e)
    customOnBlur?.(e)
  }

  return (
    <Wrapper className={classnames('row mb-3', { 'has-error': errors })}>
      <input
        className="form-control"
        id={'product' + name}
        value={(value as string | number | readonly string[] | undefined) ?? ''}
        onChange={onChange}
        onBlur={onBlur}
        {...otherFields} // name, onBlur, onChange, checked, multiple
        {...rest}
      />
      {errors &&
        _.map(errors, (error, index) => (
          <span key={index} className="form-text text-danger">
            {error as string}
          </span>
        ))}
      {helpBlock && <span className="form-text help-block">{helpBlock}</span>}
    </Wrapper>
  )
}

export default BsInput
