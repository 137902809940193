import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { AppDispatch, AppState } from '~/config/store'
import Spinner from '../../components/spinner'
import ProductSearchLinkForm from './form'
import { fetchRecord } from '~/async-actions/product-search-links-async-actions'
// import { loadRecordToEdit } from '~/reducers/product-search-links-reducer'

interface RouteParams {
  id?: string
}

interface ProductSearchLinkFormProps {
  isNew?: boolean
}

const ProductSearchLinkFormPage = ({ isNew = false }: ProductSearchLinkFormProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const { recordToEdit, loading } = useSelector((state: AppState) => state.productSearchLinks)
  const { id } = useParams<keyof RouteParams>()
  const productSearchLinkId = id ? parseInt(id, 10) : undefined

  useEffect(() => {
    if (productSearchLinkId) {
      dispatch(fetchRecord(productSearchLinkId))
    }

    // return () => {
    //   console.log('unmounting')
    //   dispatch(loadRecordToEdit(undefined))
    // }
  }, [dispatch, productSearchLinkId])

  if (isNew) {
    return <ProductSearchLinkForm initialValues={{}} />
  }

  if (loading.fetchRecord) {
    return <Spinner />
  }

  if (recordToEdit) {
    return <ProductSearchLinkForm initialValues={recordToEdit} />
  }

  return null
}

export default ProductSearchLinkFormPage
