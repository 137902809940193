import { InputHTMLAttributes, ChangeEvent, FocusEvent } from 'react'
import _ from 'lodash'

import FieldLayout, { FieldLayoutProps } from './field-layout'
import { fieldLayoutKeys } from './field-layout-keys'
import omitProps from '~/lib/omit-props'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface TextAreaProps<V = any, FormValues = any>
  extends Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'form'>,
    Omit<FieldLayoutProps<V, FormValues>, 'children'> {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BsTextArea = <V = any, FormValues = any>(props: TextAreaProps<V, FormValues>) => {
  const {
    field: { value, onChange: formikOnChange, onBlur: formikOnBlur, ...otherFields },
    onChange: customOnChange,
    onBlur: customOnBlur,
    ...allRest
  } = props
  const rest = omitProps<TextAreaProps<V, FormValues>, keyof FieldLayoutProps<V, FormValues>>(allRest, fieldLayoutKeys)

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    formikOnChange(e)
    customOnChange?.(e)
  }

  const onBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
    formikOnBlur(e)
    customOnBlur?.(e)
  }

  return (
    <FieldLayout {...props}>
      {({ id, value }) => (
        <textarea
          className="form-control"
          id={id}
          onChange={onChange}
          onBlur={onBlur}
          {...otherFields} // name, onBlur, onChange, checked, multiple
          {...rest}
          value={(value as string | number | readonly string[] | undefined) ?? ''}
        />
      )}
    </FieldLayout>
  )
}

export default BsTextArea
