import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { default as qs } from 'qs'

import Spinner from '~/components/spinner-two'
import { fetchOrder } from '~/async-actions/orders-async-actions'
import { changeSelectedOrder } from '~/reducers/orders-reducer'
import OrderDetailsForm from './form'
import { AppDispatch, AppState } from '~/config/store'
import Order from '~/types/order'

interface RouteParams {
  id: string
}

const OrderDetailsFormPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams<keyof RouteParams>()
  const dispatch = useDispatch<AppDispatch>()
  const recordId = parseInt((params && params.id) || '', 10)

  const loading = useSelector((state: AppState) => state.orders.loading.fetchOrder)
  const initialValues = useSelector((state: AppState) => state.orders.selectedOrder) || {}

  useEffect(() => {
    if (recordId) {
      dispatch(fetchOrder(recordId))
    }
  }, [dispatch, recordId])

  // Clean up redux sate when unmounting
  useEffect(() => {
    return () => {
      dispatch(changeSelectedOrder(null))
    }
  }, [dispatch])

  const goBack = useCallback(() => {
    const redirectPath = location?.state?.redirectPath || '/order-history'
    navigate({ pathname: redirectPath, search: qs.stringify({ page: 1 }) })
  }, [navigate, location?.state?.redirectPath])

  const onCancel = useCallback(() => {
    dispatch(changeSelectedOrder(null))
    goBack()
  }, [dispatch, goBack])

  return (
    <>
      {loading && <Spinner />}
      {!loading && initialValues != null && (
        <OrderDetailsForm
          initialValues={initialValues as Partial<Order>}
          onCancel={onCancel}
          afterSave={async () => goBack()}
          saveBtnText="Save and Close"
        />
      )}
    </>
  )
}

export default OrderDetailsFormPage
