import { useEffect } from 'react'
import { Link, Routes, Route, Navigate, useParams } from 'react-router-dom'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { Alert } from 'react-bootstrap'

import { Button } from '~/components/forms'
import Spinner from '~/components/spinner'
import { loadItems, deleteItem } from '~/async-actions/shipping-pricing-profiles-async-actions'
import ShippingPricingProfile from '~/types/shipping-pricing-profile'
import ShippingPricingProfilesForm from './form'
import ListView from './list-view'
import { AppState, AppDispatch } from '~/config/store'
import { useConfirm } from '~/hooks'

import styles from './index.module.css'

interface RouteParams {
  recordId?: string
}

interface RenderFormProps {
  items?: ShippingPricingProfile[]
}
const RenderForm = ({ items }: RenderFormProps) => {
  const findItemById = (id: string) => _.find(items, ['id', parseInt(id, 10)])
  const { recordId } = useParams<keyof RouteParams>()
  const item = recordId ? findItemById(recordId) : undefined

  if (recordId && !item) {
    return <Navigate to="/settings/shipping_pricing_profiles" />
  }

  return <ShippingPricingProfilesForm item={item} redirectPath="/settings/shipping_pricing_profiles" />
}

const ShippingPricingProfiles = () => {
  const [confirm] = useConfirm()
  const { items, loading } = useSelector((state: AppState) => state.shippingPricingProfiles)
  const dispatch = useDispatch<AppDispatch>()

  const getProfilesByType = (type: string) => _.filter(items, ['type', type])

  const onDelete = async (item: ShippingPricingProfile) => {
    if (!(await confirm({ message: 'Are you sure you want to delete this pricing profile?' }))) {
      return
    }
    return dispatch(deleteItem(item))
  }

  const renderListView = () => {
    return (
      <div>
        <Alert variant="light">
          <p>
            Setup your shipping profiles for each of the different zones. When a customer from that zone orders, their
            order will be priced according to that profile (and whether they have selected regular or express shipping).
          </p>
          <p>
            Shipping profiles can be assigned to individual products. Or products with no specific shipping profile,
            will use the default shipping profile as appropriate.
          </p>
        </Alert>

        <Link className={styles.newButton} to="/settings/shipping_pricing_profiles/new">
          <Button variant="primary">New</Button>
        </Link>

        <div>
          <h3>Regular Shipping</h3>
          <ListView items={getProfilesByType('regular')} onDelete={onDelete} />
        </div>
        <div>
          <h3>Express Shipping</h3>
          <ListView items={getProfilesByType('express')} onDelete={onDelete} />
        </div>
        <div>
          <h3>Multi-item Discount</h3>
          <ListView items={getProfilesByType('discount')} onDelete={onDelete} />
        </div>
      </div>
    )
  }

  useEffect(() => {
    dispatch(loadItems())
  }, [dispatch])

  return loading.loadItems ? (
    <Spinner />
  ) : (
    <Routes>
      <Route path="/new" element={<RenderForm />} />
      <Route path="/:recordId" element={<RenderForm items={items} />} />
      <Route path="/" element={renderListView()} />
    </Routes>
  )
}

export default ShippingPricingProfiles
