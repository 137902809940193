import Product from '~/types/product'

export interface ProductsGridColumns {
  id: string
  heading: string
  visible: boolean
  format?: (product: Product) => string
}

export const PRODUCTS_GRID_COLUMNS: ProductsGridColumns[] = [
  // NOTE: Columns will be displayed in the order in the array.
  {
    id: 'customSku',
    heading: 'Custom SKU',
    visible: true
  },
  {
    id: 'sku',
    heading: 'Manufacturer SKU',
    visible: true
  },
  {
    id: 'name',
    heading: 'Description',
    visible: true
  },
  {
    id: 'shelfLocation',
    heading: 'Shelf Location',
    visible: true
  },
  {
    id: 'packageLength',
    heading: 'L',
    visible: true
  },
  {
    id: 'packageWidth',
    heading: 'W',
    visible: true
  },
  {
    id: 'packageHeight',
    heading: 'H',
    visible: true
  },
  {
    id: 'packageWeight',
    heading: 'Kg',
    visible: true
  },
  {
    id: 'packageWeightIntl',
    heading: 'Int. Wt',
    visible: true
  },
  {
    id: 'onlineQuantityAvailable',
    heading: 'Mobile Qty Avail',
    visible: false

    // TODO: need a way to hide this column, if the account does not have the mobile feature turned on
  },
  {
    id: 'otherQuantityAvailable',
    heading: 'Other Qty Avail',
    visible: false

    // TODO: need a way to hide this column, if the account does not have the mobile feature turned on
  },
  {
    id: 'quantityAvailable',
    heading: 'Qty Avail',
    visible: true
  },
  {
    id: 'featured',
    heading: 'Featured?',
    visible: false,
    format(product: Product) {
      return product.featured ? 'Yes' : ''
    }
  },
  {
    id: 'mobile',
    heading: 'Mobile?',
    visible: false,
    format(product: Product) {
      return product.mobile ? 'Yes' : ''
    }
  },
  {
    id: 'checkoutEnabled',
    heading: 'Checkout Enabled?',
    visible: false,
    format(product: Product) {
      return product.mobileCheckoutEnabled ? 'Yes' : ''
    }
  }
]
