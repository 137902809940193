import { useState, useEffect, useCallback } from 'react'
import { PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'

import Spinner from '~/components/spinner'
import { UnmatchedLineItem } from '~/types/ebay-sales-record'
import { resetProcessingOrders, clearProcessingOrder } from '~/reducers/order-processing-reducer'
import { fetchUnmatchedSalesRecords } from '~/async-actions/unmatched-sales-records-async-actions'
import { fetchUnprocessedOrders, FetchUnprocessedOrdersResponse } from '~/async-actions/order-processing-async-actions'
import UploadCsvScreen, { CsvTypeEnum } from './upload-csv-screen'
import UploadInProgressScreen from './upload-in-progress-screen'
import UploadResultsScreen from './upload-results-screen'
import IncompleteRecordsScreen from './incomplete-records-screen'
import ProcessOrdersScreen from './process-orders-screen'
import ReviewProcessedDataScreen from './review-processed-data-screen'
import { AppState, AppDispatch } from '~/config/store'

export enum ProcessingStep {
  UPLOAD_CSV = 'upload_csv',
  WAIT_FOR_CSV_IMPORT = 'wait_for_csv_import',
  VIEW_UPLOAD_RESULTS = 'view_upload_results',
  PROCESS_UNMATCHED_RECORDS = 'process_unmatched_records',
  PROCESS_UNPROCESSED_ORDERS = 'process_unprocessed_orders',
  REVIEW_PROCESSED_DATA = 'review_processed_data'
}

const OrderProcessingPage = () => {
  const [currentStep, setCurrentStep] = useState<ProcessingStep | undefined>(undefined)
  const [csvType, setCsvType] = useState<CsvTypeEnum | undefined>(undefined)
  const dispatch = useDispatch<AppDispatch>()
  const unmatchedLoading = useSelector(
    (state: AppState) => state.unmatchedSalesRecordsPage.loading.fetchUnmatchedSalesRecords
  )
  const ordersLoading = useSelector((state: AppState) => state.orderProcessing.loading.fetchUnprocessedOrders)
  const ordersList = useSelector((state: AppState) => state.orderProcessing.list)
  const unprocessedOrdersCount = _.filter(ordersList, ['status', 'unprocessed']).length
  const hasNextOrder = unprocessedOrdersCount > 1

  // Load initial data and determine the initial step
  const loadData = useCallback(async () => {
    const unmatchedRecordsResponse = (await dispatch(fetchUnmatchedSalesRecords())) as PayloadAction<
      UnmatchedLineItem[]
    >
    const unprocessOrdersResponse = (await dispatch(
      fetchUnprocessedOrders()
    )) as PayloadAction<FetchUnprocessedOrdersResponse>
    const hasUnmatchedRecords = unmatchedRecordsResponse.payload.length > 0
    const hasUnprocessedOrders = unprocessOrdersResponse.payload.items.length > 0

    if (hasUnmatchedRecords) {
      setCurrentStep(ProcessingStep.PROCESS_UNMATCHED_RECORDS)
    } else if (hasUnprocessedOrders) {
      setCurrentStep(ProcessingStep.PROCESS_UNPROCESSED_ORDERS)
    } else {
      setCurrentStep(ProcessingStep.UPLOAD_CSV)
    }
  }, [dispatch])

  // Load initial data
  useEffect(() => {
    loadData()
  }, [dispatch, loadData])

  useEffect(() => {
    // on unmount, reset the redux data
    return () => {
      dispatch(resetProcessingOrders())
      dispatch(clearProcessingOrder())
    }
  }, [dispatch])

  const afterUpload = useCallback(async (csvType: CsvTypeEnum) => {
    setCsvType(csvType)
    setCurrentStep(ProcessingStep.WAIT_FOR_CSV_IMPORT)
  }, [])

  const onCsvImportComplete = useCallback(async () => {
    await loadData()
    setCurrentStep(ProcessingStep.VIEW_UPLOAD_RESULTS)
  }, [loadData])

  const onStartProcessing = useCallback((nextStep: ProcessingStep) => {
    setCurrentStep(nextStep)
  }, [])

  const onCompletedUnmatchedRecords = useCallback(async () => {
    // Refresh unprocessed orders after completing unmatched products, as
    // processing unrecognised scancodes mutates order state on the server
    const unprocessOrdersResponse = (await dispatch(
      fetchUnprocessedOrders()
    )) as PayloadAction<FetchUnprocessedOrdersResponse>

    const hasUnprocessedOrders = unprocessOrdersResponse.payload.items.length > 0

    if (hasUnprocessedOrders) {
      setCurrentStep(ProcessingStep.PROCESS_UNPROCESSED_ORDERS)
    } else {
      setCurrentStep(ProcessingStep.REVIEW_PROCESSED_DATA)
    }
  }, [dispatch])

  const onCompletedProcessingOrders = useCallback(async () => {
    if (!hasNextOrder) {
      setCurrentStep(ProcessingStep.REVIEW_PROCESSED_DATA)
    }
  }, [hasNextOrder])

  const onStartNew = useCallback(() => {
    setCurrentStep(ProcessingStep.UPLOAD_CSV)
    setCsvType(undefined)
  }, [])

  // Check if data is loading
  if (!currentStep || ordersLoading || unmatchedLoading) {
    return (
      <div>
        <h1>Order Processing</h1>
        <Spinner />
      </div>
    )
  }

  return (
    <div>
      {currentStep == ProcessingStep.UPLOAD_CSV && <UploadCsvScreen afterUpload={afterUpload} />}

      {currentStep == ProcessingStep.WAIT_FOR_CSV_IMPORT && csvType != null && (
        <UploadInProgressScreen csvType={csvType} onComplete={onCsvImportComplete} />
      )}

      {currentStep == ProcessingStep.VIEW_UPLOAD_RESULTS && (
        <UploadResultsScreen onStartProcessing={onStartProcessing} onStartNew={onStartNew} />
      )}

      {currentStep == ProcessingStep.PROCESS_UNMATCHED_RECORDS && (
        <IncompleteRecordsScreen onComplete={onCompletedUnmatchedRecords} onStartNew={onStartNew} />
      )}

      {currentStep == ProcessingStep.PROCESS_UNPROCESSED_ORDERS && (
        <ProcessOrdersScreen onComplete={onCompletedProcessingOrders} onStartNew={onStartNew} />
      )}

      {currentStep == ProcessingStep.REVIEW_PROCESSED_DATA && <ReviewProcessedDataScreen onStartNew={onStartNew} />}
    </div>
  )
}

export default OrderProcessingPage
