import _ from 'lodash'
import { useSelector } from 'react-redux'

import { AppState } from '~/config/store'

import Grid from '~/components/grid'
import { gridColumns } from './config'

const OrdersToProcessList = () => {
  const ordersList = useSelector((state: AppState) => state.orderProcessing.list)
  const listMeta = useSelector((state: AppState) => state.orderProcessing.listMeta)

  const ordersCount = ordersList.length
  return (
    <>
      {ordersCount > 0 && (
        <Grid
          recordType="orderProcessing"
          config={{
            columns: gridColumns,
            actions: {
              edit: false,
              delete: false
            },
            checkboxesColumn: false,
            pagination: false
          }}
          list={ordersList}
          listMeta={listMeta}
        />
      )}
    </>
  )
}

export default OrdersToProcessList
