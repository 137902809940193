import { MouseEvent } from 'react'
import moment from 'moment'
import L from 'lodash'

import { Button } from '~/components/forms'
import Icon from '~/components/icon'
import { inviteCustomerError } from '~/reducers/customers-reducer'
import { inviteCustomer } from '~/async-actions/customers-async-actions'
import { useConfirm } from '~/hooks'
import { AppDispatch } from '~/config/store'
import Customer from '~/types/customer'
import { GridColumnRenderProps } from '~/components/grid/create-config'

const LoginColumn = (customer: Customer, props: GridColumnRenderProps) => {
  const [confirm] = useConfirm()
  const onInviteClick = async (event: MouseEvent, customer: Customer, dispatch: AppDispatch) => {
    event.preventDefault()

    if (
      await confirm({
        message:
          'Are you sure you want to INVITE this customer? This will send them an invite for the mobile application.'
      })
    ) {
      dispatch(inviteCustomer(customer)).catch(error => {
        console.error(error)
        const errorMessage = L.get(error, 'response.data.message', null)
        dispatch(inviteCustomerError(errorMessage))
      })
    }
  }

  if (customer.email && customer.customerLogin) {
    if (customer.customerLogin.lastSignInAt)
      return `Signed in at ${moment(customer.customerLogin.lastSignInAt).format('DD/MM/YYYY HH:mm')}`

    if (customer.customerLogin.invitationAcceptedAt)
      return `Accepted at ${moment(customer.customerLogin.invitationAcceptedAt).format('DD/MM/YYYY HH:mm')}`

    if (customer.customerLogin.invitationSentAt)
      return (
        <div>
          <div>Sent at {moment(customer.customerLogin.invitationSentAt).format('DD/MM/YYYY HH:mm')}</div>
          <Button variant="warning" size="sm" onClick={e => onInviteClick(e, customer, props.dispatch)}>
            <Icon icon="envelope" /> Resend Invitation
          </Button>
        </div>
      )

    if (customer.customerLogin.invitationCreatedAt)
      return `Created at ${moment(customer.customerLogin.invitationCreatedAt).format('DD/MM/YYYY HH:mm')}`
  } else if (customer.email) {
    return (
      <Button size="sm" onClick={e => onInviteClick(e, customer, props.dispatch)}>
        <Icon icon="envelope" /> Send Invitation
      </Button>
    )
  } else {
    if (customer.guestEmail) return 'Guest'
    return `Email missing`
  }
}

export default LoginColumn
