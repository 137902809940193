import { ChangeEvent, useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'

interface Option {
  label: string
  value: string
}

interface ImportIdPickerProps {
  onChange: (query: { createdAt: string }) => void
}

const ImportIdPicker = ({ onChange }: ImportIdPickerProps) => {
  const [selected, setSelected] = useState<string>('')
  const [options, setOptions] = useState<Option[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const dateFormat = 'DD/MM/YYYY'

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setSelected(e.target.value)
      if (e.target.value !== '') {
        onChange({ createdAt: `importId:${e.target.value}` })
      }
    },
    [onChange]
  )

  const loadRecentImports = useCallback(async () => {
    setLoading(true)
    let response
    try {
      response = await axios.get('/api/csv_imports')
    } catch (e) {
      return
    } finally {
      setLoading(false)
    }
    const newOptions = _.map(response.data, record => ({
      label: '#' + record.id + ' - ' + moment(record.completed_at).format(dateFormat),
      value: record.id
    }))

    setOptions(newOptions)

    const e = _.set({}, 'target.value', newOptions[0].value) as ChangeEvent<HTMLSelectElement>
    handleChange(e)
  }, [handleChange])

  useEffect(() => {
    loadRecentImports()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      {loading && (
        <div className="text-muted ms-2">
          <small>Loading recent imports...</small>
        </div>
      )}

      {!loading && options.length > 0 && (
        <select value={selected} onChange={handleChange} className="form-control">
          {_.map(options, opt => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </select>
      )}

      {!loading && options.length === 0 && (
        <div className="text-muted ms-2">
          <small>No recent imports</small>
        </div>
      )}
    </div>
  )
}

export default ImportIdPicker
