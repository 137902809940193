import useSettings from '~/contexts/useSettings'
import Spinner from '~/components/spinner'
import Form from './form'

const CustomsPage = () => {
  const { settings, settingsPending, updateAction } = useSettings()

  return settingsPending ? (
    <Spinner />
  ) : settings == null ? null : (
    <Form
      initialValues={settings.customsDefaults}
      updateAction={updateAction}
      settingsKey="customsDefaults"
      redirectPath="/settings"
    />
  )
}

export default CustomsPage
