import styled from 'styled-components'
import { useFormik, FormikProvider } from 'formik'

import { ActionBar } from '~/components/forms'
import { BaseErrors, FlexibleInputGroup } from '~/components/forms/formik'
import useSubmitHandler from '~/containers/settings/submit-handler'
import validations from './validations'
import { SocialLinks, AccountSettingKeys } from '~/types/account-settings'
import { SettingsProviderState } from '~/contexts/settings'

const Container = styled.div`
  width: 600px;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

interface SocialLinksSettingsFormProps {
  redirectPath: string
  initialValues?: SocialLinks
  settingsKey: AccountSettingKeys
  updateAction: SettingsProviderState['updateAction']
}

const SocialLinksSettingsForm = (props: SocialLinksSettingsFormProps) => {
  const { redirectPath, initialValues } = props
  const { submitHandler } = useSubmitHandler()

  const formik = useFormik({
    initialValues: {
      facebook: initialValues?.facebook || '',
      twitter: initialValues?.twitter || '',
      instagram: initialValues?.instagram || ''
    } as SocialLinks,
    validationSchema: validations,
    onSubmit: (values, helpers) => submitHandler<SocialLinks, SocialLinksSettingsFormProps>(values, helpers, props)
  })

  const { handleSubmit, getFieldProps, isSubmitting } = formik

  return (
    <FormikProvider value={formik}>
      <Wrapper>
        <Container>
          <h3 className="mb-4">Configure links to your social media pages</h3>

          <BaseErrors />

          <FlexibleInputGroup type="text" {...getFieldProps('facebook')} />
          <FlexibleInputGroup type="text" {...getFieldProps('twitter')} />
          <FlexibleInputGroup type="text" {...getFieldProps('instagram')} />

          <ActionBar {...{ isSubmitting: isSubmitting, redirectPath }} onSubmit={handleSubmit} />
        </Container>
      </Wrapper>
    </FormikProvider>
  )
}

export default SocialLinksSettingsForm
