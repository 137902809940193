import { Fieldset } from '~/components/forms'
import { FlexibleInputGroup } from '~/components/forms/formik'

const btEnvOptions = [
  {
    value: 'sandbox',
    label: 'Sandbox'
  },
  {
    value: 'production',
    label: 'Production'
  }
]

const BraintreeFields = () => (
  <Fieldset>
    <h3>Braintree Payments</h3>

    <FlexibleInputGroup
      name="braintree.environment"
      type="select"
      options={btEnvOptions}
      hint="Select either sandbox or live"
    />
    <FlexibleInputGroup name="braintree.merchantId" label="Braintree Merchant ID" />
    <FlexibleInputGroup name="braintree.publicKey" label="Braintree Public Key" />
    <FlexibleInputGroup name="braintree.privateKey" label="Braintree Private Key" />
    <p>For security reasons we do not show the current private key, but you can update it if required.</p>
  </Fieldset>
)

export default BraintreeFields
