import Icon from '~/components/icon'
import FileImport, { EbayPackingSlipsImportSummary } from '~/types/file-import'

interface FailedProps {
  record: FileImport<EbayPackingSlipsImportSummary>
}
const Failed = ({ record }: FailedProps) => (
  <div className="import-failed">
    <div className="alert alert-danger">
      <h3>
        <Icon icon="times-circle" /> Failure !
      </h3>
      <p>
        Last PDF file import failed and no data has been uploaded. Please contact our support team if problem persists.
        Import ID: #00
        {record.id}
      </p>
    </div>
  </div>
)

export default Failed
