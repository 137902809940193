import { MouseEvent, useCallback } from 'react'

import useAuth from '~/contexts/useAuth'
import { Icon, MenuItem } from '~/components/side-menu'
import { useConfirm } from '~/hooks'

const LogoutMenuItem = () => {
  const { logoutAction } = useAuth()
  const [confirm] = useConfirm()

  const handleLogout = useCallback(
    async (e: MouseEvent, logoutAction: () => Promise<void>) => {
      e.preventDefault()
      if (!(await confirm({ message: 'Are you sure you want to Logout?' }))) {
        return
      }

      return logoutAction().then(() => {
        // Reboot the app to ensure nothing from the previous session is present
        document.location.href = '/'
      })
    },
    [confirm]
  )

  return (
    <MenuItem to="/logout" onClick={e => handleLogout(e, logoutAction)}>
      <Icon icon="sign-out-alt" />
      Logout
    </MenuItem>
  )
}

export default LogoutMenuItem
