import { ChangeEvent, MouseEvent } from 'react'

interface CheckboxThProps {
  checked: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}
const CheckboxTh = ({ checked, onChange }: CheckboxThProps) => (
  <th>
    <input type="checkbox" checked={checked} onChange={onChange} />
  </th>
)

interface CheckboxTdProps {
  checked: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
  onClick: (event: MouseEvent<HTMLTableCellElement>) => void
}
const CheckboxTd = ({ checked, onChange, value, onClick }: CheckboxTdProps) => (
  <td onClick={onClick}>
    <input type="checkbox" value={value} checked={checked} onChange={onChange} />
  </td>
)

export { CheckboxTh, CheckboxTd }
