import useSettings from '~/contexts/useSettings'
import Spinner from '~/components/spinner'
import Form from './form'
import { ShopfrontConfig } from '~/types/account-settings'

const ShopfrontPreferencesPage = () => {
  const { settings, settingsPending, updateAction } = useSettings()

  return settingsPending ? (
    <Spinner />
  ) : settings == null ? null : (
    <Form
      initialValues={(settings?.shopfront || {}) as ShopfrontConfig}
      updateAction={updateAction}
      settingsKey="shopfront"
      redirectPath="/settings"
    />
  )
}

export default ShopfrontPreferencesPage
