import ManifestJob from '~/containers/manifest-job'
import moment from 'moment'
import _ from 'lodash'
import ManifestItem from '~/types/au-post-manifest'

export const gridColumns = [
  {
    attr: 'id',
    heading: 'ID',
    locked: true
  },
  {
    attr: 'remoteManifestId',
    heading: 'Manifest ID',
    locked: true
  },
  {
    attr: 'status',
    render: (manifest: ManifestItem) => {
      if (manifest.status === 'pending') {
        return <ManifestJob as="status_column_spinner" manifest={manifest} />
      }

      if (manifest.status === 'despatched') {
        return <span>Despatched at {moment(manifest.despatchedAt).format('DD/MM/YYYY')}</span>
      }

      return <span>{_.startCase(manifest.status)}</span>
    }
  },
  {
    attr: 'ordersCount',
    locked: true,
    render: (manifest: ManifestItem) => manifest.ordersCount
  },
  {
    attr: 'shipmentsCount',
    locked: true,
    render: (manifest: ManifestItem) => manifest.shipmentsCount
  },
  {
    attr: 'created',
    render: (manifest: ManifestItem) => moment(manifest.createdAt).format('DD/MM/YYYY')
  }
]
