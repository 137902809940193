import { object, mixed, array } from 'yup'

import { ShopfrontShippingCountriesOptions } from '~/types/account-settings'

export default object().shape({
  mobilePreference: object().shape({
    showSortBtn: mixed().oneOf(['true', 'false']),
    showSearchBar: mixed().oneOf(['true', 'false'])
  }),
  shopfrontPreferences: object().shape({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    shippingCountriesOptionData: array().when('shippingCountriesOption', (val: any) => {
      if (val === ShopfrontShippingCountriesOptions.ONLY || val === ShopfrontShippingCountriesOptions.EXCLUDE)
        return array().required('Required')
      else return array()
    })
  })
})
