import { combineReducers } from '@reduxjs/toolkit'

import grid from './grid-reducer'
import orderProcessing from './order-processing-reducer'
import trackingPage from './eparcels-tracking-reducer'
import mobilePages from './mobile-pages-reducer'
import shopfrontPages from './shopfront-pages-reducer'
import shippingPricingProfiles from './shipping-pricing-profiles-reducer'
import weightBasedShippingPricingProfiles from './weight-based-shipping-pricing-profiles-reducer'
import users from './users-reducer'
import customersPage from './customers-reducer'
import posPage from './pos-reducer'
import productsPage from './products-reducer'
import productsImportPage from './products-import-reducer'
import productSearchLinks from './product-search-links-reducer'
import priorityItems from './priority-items-reducer'
import menuItems from './menu-items-reducer'
import unmatchedSalesRecordsPage from '~/reducers/unmatched-sales-records-page-reducer'
import orders from '~/reducers/orders-reducer'
import transientFormData from '~/reducers/transient-form-data-reducer'
import importedOrders from '~/reducers/imported-orders-reducer'
import persistableData from '~/reducers/persistable-data-reducer'
import router from '~/reducers/router-reducer'
import manifests from '~/reducers/manifests-reducer'

const rootReducer = combineReducers({
  // User / Session
  users,

  // Common / utility data
  grid,
  router,
  transientFormData,
  persistableData,

  // Customers
  customersPage,

  // Products
  productsImportPage,
  productsPage,

  // Orders
  unmatchedSalesRecordsPage,
  orderProcessing,
  importedOrders,
  orders,
  posPage,
  trackingPage,

  // Aus Post shipping info
  manifests,

  // Shipping profiles / pricing
  shippingPricingProfiles,
  weightBasedShippingPricingProfiles,

  // Mau / Mobile
  mobilePages,
  priorityItems,
  productSearchLinks,

  // Shopfront
  menuItems,
  shopfrontPages
})

export default rootReducer
