import _ from 'lodash'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { useFormikContext } from 'formik'

import { Button } from '~/components/forms'
import { PosFormValues } from './form'
import { allShippingServices, printCurrency } from './utils'
import CustomerDetailsBox from './customer-details-box'

const OrderContainer = styled.div``

const FlexParent = styled.div`
  display: flex;
  flex-direction: row;
`

const Header = styled(FlexParent)`
  font-weight: 600;
  margin-top: 20px;
`

const Lines = styled.div``

const Line = styled(FlexParent)``
interface ColProps {
  textAlign?: 'left' | 'right' | 'center' | 'justify'
}

const Col = styled.div<ColProps>`
  text-align: ${props => (props.textAlign ? props.textAlign : 'right')};
  padding: 4px 8px !important;

  flex-basis: 100px;
  margin-right: 10px;
  flex-shrink: 0;
  min-width: 0; // needed to make truncated text work

  // truncate text with ellipsis, so it doesn't spread to multiple lines
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: auto;
  }
`

const Sku = styled(Col)`
  flex-basis: 130px;
`

const ColGrow = styled(Col)`
  flex-grow: 1;
`

const Totals = styled.div``

const FooterRow = styled(FlexParent)``

const SubTotalRow = styled(FooterRow)`
  border-top: 2px solid #efefef;
`

const TotalRow = styled(FooterRow)`
  font-weight: 600;
`

const Msg = styled.span`
  margin-right: 20px;
  font-weight: 600;
`

interface ConfirmDialogProps {
  show: boolean
  onHide: () => void
}
const ConfirmDialog = ({ show, onHide }: ConfirmDialogProps) => {
  const { values: formValues, submitForm } = useFormikContext<PosFormValues>()

  return (
    show && (
      <Modal size="lg" backdrop="static" show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Confirm Order Details</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OrderContainer>
            <div className="d-flex">
              <CustomerDetailsBox orderShippingData={formValues} />
              <FlexParent>
                <Col>
                  <strong>Order No</strong>
                </Col>
                <Col textAlign="left">{formValues.reference}</Col>
              </FlexParent>
              <FlexParent>
                <Col>
                  <strong>Order Date</strong>
                </Col>
                <Col textAlign="left">{formValues.orderDate}</Col>
              </FlexParent>
            </div>
            <Header>
              <Sku textAlign="left">Custom SKU</Sku>
              <ColGrow textAlign="left">Product</ColGrow>
              <Col>Qty</Col>
              <Col>Unit Price</Col>
              <Col>Line Total</Col>
            </Header>
            <Lines>
              {_.map(formValues.lineItems, (line, index) => (
                <Line key={index}>
                  <Sku textAlign="left">
                    <span>{line.product?.customSku}</span>
                  </Sku>
                  <ColGrow textAlign="left">
                    <span>{line.product?.name}</span>
                  </ColGrow>
                  <Col>{line.quantity}</Col>
                  <Col>{printCurrency(line.unitPrice)}</Col>
                  <Col>{printCurrency(line.subtotal)}</Col>
                </Line>
              ))}
            </Lines>
            <Totals>
              <SubTotalRow>
                <ColGrow>Subtotal</ColGrow>
                <Col>{printCurrency(formValues.subtotal)}</Col>
              </SubTotalRow>
              <FooterRow>
                <ColGrow>Discount</ColGrow>
                <Col>{printCurrency(formValues.discountAmount, '-')}</Col>
              </FooterRow>
              <FooterRow>
                <ColGrow>{allShippingServices[_.camelCase(formValues.shippingService)]}</ColGrow>
                <Col>{printCurrency(formValues.shippingCost)}</Col>
              </FooterRow>
              <FooterRow>
                <ColGrow>Tax</ColGrow>
                <Col>{printCurrency(formValues.taxAmount)}</Col>
              </FooterRow>
              <TotalRow>
                <ColGrow>Total</ColGrow>
                <Col>{printCurrency(formValues.total, '$')}</Col>
              </TotalRow>
            </Totals>
          </OrderContainer>
        </Modal.Body>
        <Modal.Footer>
          <Msg>Are order details correct?</Msg>
          <Button variant="light" onClick={onHide}>
            No
          </Button>
          <Button variant="success" onClick={() => submitForm()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    )
  )
}

export default ConfirmDialog
