import countriesList from '~/lib/countries-list'
import ShippingPricingProfile from '~/types/shipping-pricing-profile'
import Account from '~/types/account'

export type CountryCode = (typeof countriesList)[number]['value']

export interface CountryNote {
  id: number
  countryCode: string
  body: string
}

export interface IntlShippingServiceOption {
  id: string
  label: string
  value?: string | number
  insuranceCostMax: number
}

export interface CustomsDefaults {
  origin?: string
  classification?: string
  classificationExplanation?: string
}

export interface SellerProfile {
  firstName?: string
  lastName?: string
  businessName?: string
  address1?: string
  address2?: string
  city?: string
  state?: string
  postcode?: string
  countryCode?: string
  phone?: string
  email?: string
}

export enum SortingDirectionEnum {
  ASC = 'asc',
  DESC = 'desc'
}

export enum MobileSortingColumnEnum {
  ID = 'id',
  ONLINE_QUANTITY_AVAILABLE = 'online_quantity_available',
  SALE_PRICE = 'sale_price',
  QUANTITY_SOLD = 'quantity_sold'
}

export interface MobileDefaultSorting {
  sortBy?: MobileSortingColumnEnum
  sortDirection?: SortingDirectionEnum
}

export interface SocialLinks {
  facebook: string | null
  twitter: string | null
  instagram: string | null
}

export enum ShopfrontShippingCountriesOptions {
  ALL = 'all',
  EXCLUDE = 'exclude',
  ONLY = 'only'
}

export interface ShopfrontPreferences {
  googleAnalyticsId: string | null
  googleTagManagerId: string | null
  googleSiteVerification: string | null
  defaultPageTitle: string | null
  defaultMetaDescription: string | null
  shippingCountriesOption: ShopfrontShippingCountriesOptions
  shippingCountriesOptionData: CountryCode[]
  gmapsApiKey: string | null
}

export interface ShopfrontConfig {
  shopfrontDomain: string
  shopfrontPreferences: ShopfrontPreferences
  bannerImageSmUrl: string
  bannerImageLgUrl: string
  bannerImageSm?: File // Only used when saving the form
  bannerImageLg?: File // Only used when saving the form
}

export interface MobilePreferences {
  showSortBtn: boolean
  showSearchBar: boolean
}

export interface MobileConfig {
  storeTitle: string
  supporEmail: string
  mobilePreferences: MobilePreferences
  mobileDefaultSorting: MobileDefaultSorting
  storeBannerImageUrl: string
  storeBannerImage?: File // only used when posting
}

export interface SendGridPreferences {
  apiKey: string | null
  templates: {
    mobileInvitation: string | null
    accountConfirmation: string | null
  }
}

export interface InvoicePreferences {
  shopfrontPrefix: string
  mobilePrefix: string
  posPrefix: string
}

export interface AuPostInternationalChargeCodeOriginal {
  id: number
  val: string
  order: number | string
}

export interface AuPostChargeCodesOriginal {
  domestic: {
    regularEparcel: string
    expressEparcel: string
  }
  international: {
    internationalAirmail: AuPostInternationalChargeCodeOriginal
    eciDoc: AuPostInternationalChargeCodeOriginal
    eciMerch: AuPostInternationalChargeCodeOriginal
    packAndTrack: AuPostInternationalChargeCodeOriginal
    regPostInternational: AuPostInternationalChargeCodeOriginal
    intlStdSignature: AuPostInternationalChargeCodeOriginal
  }
}

export interface AuPostInternationalChargeCode {
  id: number
  val: string
  name: string
  label: string
}

export interface AuPostChargeCodes {
  domestic: {
    regularEparcel: string
    expressEparcel: string
  }
  international: Array<AuPostInternationalChargeCode>
}

// Access the `international` property type
type InternationalType = AuPostChargeCodesOriginal['international']

// Generic type to get the type of a specific child property under `international`
export type InternationalChildType<Key extends keyof InternationalType> = InternationalType[Key]

export interface EparcelsDefaults {
  isCommercialValue?: boolean
  deliveryInstructions?: string
  ref1?: string
  ref2?: string
}

export enum EparcelsTrackingSendInvitesEnum {
  NO = 'no',
  UNINVITED_ONLY = 'uninvited_only',
  ALWAYS = 'always'
}

export enum EparcelsTrackingUpdateTrackingEnum {
  NO = 'no',
  ALWAYS = 'always'
}

export interface EparcelsTrackingPreferences {
  sendInvites: EparcelsTrackingSendInvitesEnum
  updateTracking: EparcelsTrackingUpdateTrackingEnum
}

export type CountryNotesOriginal = {
  [key in CountryCode]?: string
}

export type CountryNotes = {
  notes: CountryNote[]
}

export interface IntercomPreferences {
  appId?: string
  androidApiKey?: string
  iOSApiKey?: string
}

export interface TwilioPreferences {
  authToken?: string
  accountSid?: string
  fromNumber?: string
  sendSmsForCustomerEmailsMatching?: string
}

export interface ParcelSendPreferences {
  mode?: string
  accountNum?: string
  apiKey?: string
  apiPassword?: string
  afourOffsetTop?: string
  afourOffsetLeft?: string
  asixOffsetTop?: string
  asixOffsetLeft?: string
  branded?: boolean
  labelsSignature?: string
}

export enum BrainTreeEnvironmentEnum {
  SANDBOX = 'sandbox',
  PRODUCTION = 'production'
}

export enum PaymentGatewayEnum {
  STRIPE = 'stripe',
  BRAIN_TREE = 'braintree'
}

export interface PaymentsPreferences {
  stripeApiPublishableKey: string
  paymentDescriptor: string
  currency: string
  invoicePreferences: InvoicePreferences
  braintree: {
    environment: BrainTreeEnvironmentEnum
    merchantId: string
    publicKey: string
    privateKey: string
  }
  paymentPreferences: {
    paymentGateway: PaymentGatewayEnum
  }
}

export interface EbayUserAccessToken {
  refreshToken: string
  accessToken: string
  expiresAt: string
  refreshTokenExpiresAt: string
}

export interface ShopfrontSmsTemplates {
  invitationInstructions?: string | null
  resetPasswordInstructions?: string | null
  confirmationInstructions?: string | null
}

export interface ProductDefaults {
  scancodesEnabled: boolean
  defaultScancodeMarketplace?: string | null
}

export interface AccountSettingsOriginal {
  account: Account
  regularShippingPricingProfiles: ShippingPricingProfile[]
  expressShippingPricingProfiles: ShippingPricingProfile[]
  customsDefaults: CustomsDefaults
  sellerProfile: SellerProfile
  socialLinks: SocialLinks
  sendgrid: SendGridPreferences
  auPostChargeCodes: AuPostChargeCodesOriginal
  productDefaults: ProductDefaults
  eparcelsDefaults: EparcelsDefaults
  eparcels: EparcelsTrackingPreferences
  countryNotes: CountryNotesOriginal
  mobileEnabled: boolean
  intercom: IntercomPreferences
  twilio: TwilioPreferences
  parcelSend: ParcelSendPreferences
  ebayConnectUrl: string
  superDomain: string
  payments: PaymentsPreferences
  mobile: MobileConfig
  shopfront: ShopfrontConfig
  shopfrontSmsTemplates: ShopfrontSmsTemplates
  ebayUserAccessToken?: EbayUserAccessToken
}

export type AccountSettingKeysOriginal = keyof AccountSettingsOriginal

export type AccountSettingValuesOriginal = {
  [K in AccountSettingKeysOriginal]: AccountSettingsOriginal[K]
}[AccountSettingKeysOriginal]

interface AccountSettings {
  account: Account
  shippingPricingProfiles: {
    express: ShippingPricingProfile[]
    regular: ShippingPricingProfile[]
  }
  customsDefaults: CustomsDefaults
  sellerProfile: SellerProfile
  socialLinks: SocialLinks
  sendgrid: SendGridPreferences
  auPostChargeCodes: AuPostChargeCodes
  productDefaults: ProductDefaults
  eparcelsDefaults: EparcelsDefaults
  eparcels: EparcelsTrackingPreferences
  countryNotes: CountryNotes
  mobileEnabled: boolean
  intercom: IntercomPreferences
  twilio: TwilioPreferences
  parcelSend: ParcelSendPreferences
  ebayConnectUrl: string
  superDomain: string
  payments: PaymentsPreferences
  mobile: MobileConfig
  shopfront: ShopfrontConfig
  shopfrontSmsTemplates: ShopfrontSmsTemplates
  ebayUserAccessToken?: EbayUserAccessToken
}

export type AccountSettingKeys = keyof AccountSettings

export type AccountSettingValues = {
  [K in AccountSettingKeys]: AccountSettings[K]
}[AccountSettingKeys]

export default AccountSettings
