import { object, string } from 'yup'

export default object().shape({
  Alert: object().shape({
    info: object().shape({
      text: string().required(),
      background: string().required()
    }),
    warning: object().shape({
      text: string().required(),
      background: string().required()
    }),
    danger: object().shape({
      text: string().required(),
      background: string().required()
    }),
    success: object().shape({
      text: string().required(),
      background: string().required()
    })
  })
})
