import axios from 'axios'
import _ from 'lodash'

import { UserCredentials } from '~/types/user'
import snakeToCamelCase from '~/lib/snake-to-camel-case'
import camelToSnakeCase from '~/lib/camel-to-snake-case'
import { BASE_URL } from './constants'

// Make it easier for hot reloading to work in development, so persist the token
export const canPersistToken = false
// Don't persist tokens in localStorage anymore - insecure
// import.meta.env.VITE_ENV !== 'production' && import.meta.env.VITE_ENV !== 'staging'

const getPersistedToken = () => {
  if (canPersistToken) return localStorage.getItem('token') || undefined
}

const saveToken = (token: string) => {
  if (canPersistToken) return localStorage.setItem('token', token)
}

// NOTE: JWT is not really used anymore. It's still being captured, and sent
// back with requests, but it's pretty much superceeded by the _my_post_labels
// session cookie
const JWT = {
  token: getPersistedToken()
}

export async function fetchUserProfileRequest() {
  return axios.get(`${BASE_URL}/api/user_profiles`).then(response => snakeToCamelCase(response.data))
}

export async function loginRequest(params: UserCredentials) {
  return axios.post(`${BASE_URL}/api/user_sessions`, camelToSnakeCase(params)).then(response => {
    const authHeader = response.headers.authorization || ''
    const parts = authHeader.split(' ') || []
    JWT.token = _.last(parts)
    saveToken(JWT.token || '')
    return snakeToCamelCase(response.data)
  })
}

export async function logoutRequest() {
  return axios.delete(`${BASE_URL}/api/user_sessions`).then(() => {
    JWT.token = undefined
    if (canPersistToken) return localStorage.removeItem('token')
  })
}

export { JWT }

export async function forgotPasswordRequest(params: { email: string }) {
  return axios.post(
    `${BASE_URL}/api/forgot_password`,
    camelToSnakeCase({
      email: params.email
    })
  )
}

export async function resetPasswordRequest(params: { token: string; password?: string; dryRun?: boolean }) {
  return axios.post(
    `${BASE_URL}/api/reset_password`,
    camelToSnakeCase({
      token: params.token,
      password: params.password,
      dryRun: params.dryRun
    })
  )
}
