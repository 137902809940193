import { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import * as restApi from '~/api/au-post-shipment-jobs'
import JobInProgress from '~/components/job-in-progress'
import { updateList } from '~/reducers/manifests-reducer'
import _ from 'lodash'
import ManifestItem from '~/types/au-post-manifest'
import { AppDispatch } from '~/config/store'

interface ManifestJobProps {
  as?: string
  manifest: ManifestItem
  setManifest?: (manifest: ManifestItem) => void
}
const ManifestJob = ({ as = 'page_spinner', manifest, setManifest }: ManifestJobProps) => {
  const [job, setJob] = useState(manifest.job)

  const dispatch = useDispatch<AppDispatch>()

  const updateManifestList = useCallback(
    (manifest: ManifestItem) => {
      dispatch(updateList(manifest))
    },
    [dispatch]
  )

  const pollingTimer = useRef<ReturnType<typeof setInterval> | null>(null)

  useEffect(() => {
    console.log('[INFO]', 'ManifestJob useEffect')
    console.log('[DEBUG] job =', job)
    if (!job) {
      console.log('[INFO]', 'job is empty')
      return
    }
    if (job.finished) {
      console.log('[INFO]', 'job finished')
      updateManifestList(job.manifest)
      setManifest?.(job.manifest)
      return
    }
    if (pollingTimer.current && job.finished) {
      clearInterval(pollingTimer.current)
    }
    if (!job.finished) {
      console.log('[INFO]', 'job not finished', pollingTimer.current)
    }
    if (!job.finished && pollingTimer.current === null) {
      pollingTimer.current = setInterval(() => {
        console.log('[INFO]', 'polling')
        restApi.get(job.id).then(setJob)
      }, 5000)
    }
    // Cleanup function
    return function () {
      if (pollingTimer.current) {
        console.log('[INFO] clear polling timer', pollingTimer.current)
        clearInterval(pollingTimer.current)
        pollingTimer.current = null
      }
    }
  }, [job, setManifest, updateManifestList])

  const running = !job?.finished

  if (as === 'status_column_spinner' && job != null) {
    return <span>{_.startCase(job.manifestStatus)}</span>
  }

  if (as === 'page_spinner') {
    return <div>{running && <JobInProgress />}</div>
  }

  return null
}

export default ManifestJob
