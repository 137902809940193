import { useFormikContext } from 'formik'

import { OrderFormValues } from './use-order-details'

const PackageContents = () => {
  const { values: formValues } = useFormikContext<OrderFormValues>()
  const { lineItems } = formValues

  return (
    <fieldset>
      <legend>Package Contents</legend>
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Qty</th>
            <th>Item</th>
            <th>SKU</th>
            <th>Shelf Location</th>
          </tr>
        </thead>
        <tbody>
          {lineItems &&
            lineItems.map(lineItem => (
              <tr key={lineItem.id}>
                <td>{lineItem.quantity}</td>
                <td className={lineItem.product ? '' : 'text-danger'}>
                  {lineItem.product
                    ? lineItem.product.name
                    : lineItem?.importedScancode ?? lineItem.salesRecord?.itemTitle}
                </td>
                <td>{lineItem.product ? lineItem.product.customSku : '-'}</td>
                <td>{lineItem.product ? lineItem.product.shelfLocation : '-'}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </fieldset>
  )
}

export default PackageContents
