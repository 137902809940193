import styled from 'styled-components'
import { Link } from 'react-router-dom'

import Spinner from '~/components/spinner'
import { Button } from '~/components/forms'
import _SortableItem from './sortable-item'
import ShopfrontPage from '~/types/shopfront-page'

const ItemList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 0 3em 0;
  padding: 0;
`
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`
const Container = styled.div`
  width: 600px;
`
const SortableItem = styled(_SortableItem)`
  margin-top: 2rem;
`

interface ListViewProps {
  items: ShopfrontPage[]
  pending: boolean
  onDelete: (item: ShopfrontPage) => void
  onSortEnd: (oldIndex: number, newIndex: number) => void
}
const ListView = ({ pending, items, onDelete, onSortEnd }: ListViewProps) =>
  pending ? (
    <Spinner />
  ) : (
    <Wrapper>
      <Container>
        <Link className="new-record-btn" to="/settings/shopfront_pages/new">
          <Button>New</Button>
        </Link>

        <ItemList>
          {items.map((item, index) => (
            <SortableItem key={item.id} index={index} item={item} onDelete={onDelete} onSortEnd={onSortEnd} />
          ))}
        </ItemList>
      </Container>
    </Wrapper>
  )

export default ListView
