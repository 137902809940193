import { Link } from 'react-router-dom'
import { AxiosError } from 'axios'

import Icon from '~/components/icon'
import Customer from '~/types/customer'
import { GridColumn } from '~/components/grid/create-config'
import LoginColumn from './login-column'

export const gridColumns = [
  {
    attr: 'login',
    locked: false,
    render: LoginColumn
  },
  {
    attr: 'name',
    locked: true
  },
  {
    attr: 'address1',
    visible: false
  },
  {
    attr: 'address2',
    visible: false
  },
  {
    attr: 'address3',
    visible: false
  },
  {
    attr: 'email',
    render: (customer: Customer) => {
      return customer.guestEmail || customer.email
    }
  },
  {
    attr: 'phone',
    visible: false
  },
  {
    attr: 'suburb',
    visible: false
  },
  {
    attr: 'state',
    visible: false
  },
  {
    attr: 'postcode',
    visible: false
  },
  {
    attr: 'country',
    visible: false
  },
  {
    attr: 'groups',
    visible: false
  },
  {
    attr: 'ebayUserId',
    visible: false
  },
  {
    attr: 'orders',
    render: (customer: Customer) => {
      const customerSearch = customer.email ? encodeURIComponent(customer.email) : customer.id
      const linkPath = `/order-history?customer=${customerSearch}&createdAt=any`
      return (
        <Link to={linkPath}>
          <Icon icon="external-link-alt" />
        </Link>
      )
    }
  }
] as GridColumn<Customer, AxiosError>[]
