import styled from 'styled-components'
import { useFormik, FormikProvider } from 'formik'
import { toast } from 'react-toastify'
import { animateScroll } from 'react-scroll'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'

import { handleErrorResponse } from '~/lib/form-utils'
import { ActionBar } from '~/components/forms'
import { BaseErrors, FlexibleInputGroup } from '~/components/forms/formik'
import useAuth from '~/contexts/useAuth'
import timeZones from '~/lib/time-zones'
import validations from './validations'
import User, { UserKindEnum, UserFormParams } from '~/types/user'

interface MyFormProps {
  item?: User
  onUpdateOrCreate: (values: UserFormParams) => Promise<void>
}

const timeZoneOptions = [{ label: '---', value: '' }, ...timeZones]

const Container = styled.div`
  width: 800px;
`

const Heading = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

const redirectPath = '/users'

const UserForm = ({ item, onUpdateOrCreate }: MyFormProps) => {
  const { userProfile } = useAuth()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
      id: item?.id || '',
      fullName: item?.fullName || '',
      email: item?.email || '',
      kind: item?.kind || '',
      timeZone: item?.timeZone || ''
    } as UserFormParams,
    validationSchema: validations,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      const onSuccess = () => {
        setSubmitting(false)
        navigate(redirectPath)
        toast.success('Success!')
        animateScroll.scrollToTop()
      }

      const onError = (error: AxiosError) => {
        setSubmitting(false)
        toast.error('Sorry, there were errors processing the form. Please review!')
        animateScroll.scrollToTop()

        handleErrorResponse({
          response: error.response,
          setErrors
        })
      }

      onUpdateOrCreate(values).then(onSuccess).catch(onError)
    }
  })

  const { getFieldProps, handleSubmit, isSubmitting, values } = formik

  const kindOptions = [
    { label: '---', value: '' },
    { label: 'Staff Full', value: UserKindEnum.STAFF_FULL },
    { label: 'Staff Limited', value: UserKindEnum.STAFF_LIMITED },
    ...(userProfile?.kind === UserKindEnum.OWNER ? [{ label: 'Owner', value: UserKindEnum.OWNER }] : [])
  ]

  return (
    <FormikProvider value={formik}>
      <Wrapper>
        <Container>
          <BaseErrors />

          <Heading>{item?.id && item?.id > 0 ? 'Edit User' : 'New User'}</Heading>

          <input type="hidden" name="id" value={values.id} />
          <FlexibleInputGroup {...getFieldProps('fullName')} />
          <FlexibleInputGroup {...getFieldProps('email')} />

          {(values.kind !== UserKindEnum.OWNER ||
            (values.kind === UserKindEnum.OWNER && userProfile?.kind === UserKindEnum.OWNER)) && (
            <FlexibleInputGroup type="select" options={kindOptions} {...getFieldProps('kind')} />
          )}

          {values.id && (
            <p className="form-text help-block text-end">
              {"Leave password fields blank if you don't wish to change them"}
            </p>
          )}

          <FlexibleInputGroup type="password" {...getFieldProps('password')} />
          <FlexibleInputGroup type="password" {...getFieldProps('passwordConfirmation')} />

          <FlexibleInputGroup type="select" options={timeZoneOptions} {...getFieldProps('timeZone')} />

          <ActionBar isSubmitting={isSubmitting} redirectPath={redirectPath} onSubmit={handleSubmit} />
        </Container>
      </Wrapper>
    </FormikProvider>
  )
}

export default UserForm
