import styled from 'styled-components'
import { Field, FormikProvider, useFormik } from 'formik'
import { toast } from 'react-toastify'
import { animateScroll } from 'react-scroll'
import { useDispatch } from 'react-redux'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'

import { addOrUpdateItem } from '~/async-actions/shipping-pricing-profiles-async-actions'
import { handleErrorResponse } from '~/lib/form-utils'
import { BaseErrors, FlexibleInputGroup } from '~/components/forms/formik'
import { ActionBar } from '~/components/forms'
import validations from './validations'
import { AppDispatch } from '~/config/store'
import ShippingPricingProfile from '~/types/shipping-pricing-profile'

const typeOptions = [
  { label: '---', value: '' },
  { label: 'Regular', value: 'regular' },
  { label: 'Express', value: 'express' },
  { label: 'Multi-item Discount', value: 'discount' }
]

const Container = styled.div`
  width: 800px;
`
const Heading = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
`
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`
const Row = styled.div`
  display: flex;
`
const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

interface ShippingPricingProfilesFormProps {
  item?: ShippingPricingProfile
  redirectPath: string
}

const ShippingPricingProfilesForm = ({ item, redirectPath }: ShippingPricingProfilesFormProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const formik = useFormik({
    initialValues: {
      id: item?.id || '',
      type: item?.type || '',
      name: item?.name || '',
      default: item?.default || false,
      au: item?.au || 0,
      zone1: item?.zone1 || 0,
      zone2: item?.zone2 || 0,
      zone3: item?.zone3 || 0,
      zone4: item?.zone4 || 0,
      zone5: item?.zone5 || 0,
      zone6: item?.zone6 || 0,
      zone7: item?.zone7 || 0,
      zone8: item?.zone8 || 0,
      zone9: item?.zone9 || 0
    } as ShippingPricingProfile,
    validationSchema: validations,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      const onSuccess = () => {
        setSubmitting(false)
        navigate(redirectPath)
        toast.success('Success!')
        animateScroll.scrollToTop()
      }

      const onError = (error: AxiosError) => {
        setSubmitting(false)
        toast.error('Sorry, there were errors processing the form. Please review!')
        animateScroll.scrollToTop()

        handleErrorResponse({
          response: error.response,
          setErrors
        })
      }

      dispatch(addOrUpdateItem(values)).then(onSuccess).catch(onError)
    }
  })

  const { getFieldProps, values, isSubmitting, handleSubmit } = formik

  return (
    <FormikProvider value={formik}>
      <Wrapper>
        <Container>
          <BaseErrors />

          <Heading>{typeof values.id === 'number' ? 'Edit Profile' : 'New Profile'}</Heading>

          <Field name="id" type="hidden" />
          <FlexibleInputGroup {...getFieldProps('name')} />
          <FlexibleInputGroup type="select" options={typeOptions} {...getFieldProps('type')} />
          <FlexibleInputGroup type="checkbox" checked={values.default === true} {...getFieldProps('default')} />

          <Row>
            <Col>
              <FlexibleInputGroup {...getFieldProps('au')} />
              <FlexibleInputGroup {...getFieldProps('zone1')} />
              <FlexibleInputGroup {...getFieldProps('zone2')} />
              <FlexibleInputGroup {...getFieldProps('zone3')} />
              <FlexibleInputGroup {...getFieldProps('zone4')} />
            </Col>
            <Col>
              <FlexibleInputGroup {...getFieldProps('zone5')} />
              <FlexibleInputGroup {...getFieldProps('zone6')} />
              <FlexibleInputGroup {...getFieldProps('zone7')} />
              <FlexibleInputGroup {...getFieldProps('zone8')} />
              <FlexibleInputGroup {...getFieldProps('zone9')} />
            </Col>
          </Row>

          <ActionBar isSubmitting={isSubmitting} redirectPath={redirectPath} onSubmit={handleSubmit} />
        </Container>
      </Wrapper>
    </FormikProvider>
  )
}

export default ShippingPricingProfilesForm
