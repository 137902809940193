import { ReactNode } from 'react'
import styled from 'styled-components'
import { Alert } from 'react-bootstrap'
import { stringify } from 'yaml'

import _ from '~/lib/lodash'

const Preformatted = styled.div`
  font-family: monospace;
  white-space: pre;
  margin-bottom: 10px;
`

const jsonToYaml = (json: object) => {
  let output = ''
  try {
    output = stringify(json)
    return output
  } catch (error) {
    return output
  }
}

interface ErrorMessageBoxProps {
  onDismiss: () => void
  heading?: string
  renderBody?: () => ReactNode
  toYaml?: object[]
}
const ErrorMessageBox = ({
  heading = 'There was an error',
  onDismiss,
  renderBody = () => null,
  toYaml = []
}: ErrorMessageBoxProps) => (
  <Alert variant="danger" onClose={onDismiss}>
    <h4>{heading}</h4>
    <hr />
    {renderBody()}
    {toYaml.length > 0 && toYaml.map((e: object) => <Preformatted key={_.uniqueId()}>{jsonToYaml(e)}</Preformatted>)}
  </Alert>
)

export default ErrorMessageBox
