import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { AxiosError } from 'axios'
import { Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import Icon from '~/components/icon'
import { Button } from '~/components/forms'
import Grid from '~/components/grid'
import Filters from './filters'
import { fetchCustomers } from '~/async-actions/customers-async-actions'
import { gridColumns } from './config'
import ErrorMessages from './error-messages'
import { AppState } from '~/config/store'
import Customer from '~/types/customer'
import { ListMeta } from '~/reducers/customers-reducer'

const FilterRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CustomersPage = () => {
  const navigate = useNavigate()
  const {
    list,
    listMeta,
    loading: { fetchCustomers: loading }
  } = useSelector((state: AppState) => state.customersPage)

  return (
    <div>
      <div className="clearfix">
        <div className="row">
          <div className="col-sm-2">
            <h1>
              <>Customers</>
              {loading && <Spinner className="ms-4" />}
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <ErrorMessages />
          </div>
        </div>
      </div>

      <Grid<Customer, ListMeta, AxiosError>
        recordType="customers"
        fetchFn={fetchCustomers}
        config={{
          columns: gridColumns
        }}
        filtersComponent={({ onChange, query }) => (
          <FilterRow>
            <Filters query={query as { loginStatus: string; search: string }} onChange={onChange} />
            <Button className="new-customer-btn" onClick={() => navigate('/customers/new')}>
              <Icon icon="plus" /> New Customer
            </Button>
          </FilterRow>
        )}
        list={list}
        listMeta={listMeta}
      />
    </div>
  )
}

export default CustomersPage
