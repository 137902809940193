import moment from 'moment'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { transformForFrontend } from '~/containers/pos-page/utils'
import { getOrderDefaults, fetchOrder } from '~/async-actions/pos-async-actions'
import { PosFormValues } from '~/containers/pos-page/form'

import Order, { ShippingServiceEnum, OrderLineItem } from '~/types/order'

// NOTE: I think this is always returned as '{}' (empty object) from the backend for some reason?!
//
// defaults when creating a new order via POS
export interface OrderDefaults {
  orderDate: string
  subtotal: number
  discount: {
    value: number
    type: '%'
  }
  discountAmount: number
  lineItems: OrderLineItem[]
  deletedLineItems: OrderLineItem[]
  shippingCost: number
  lineItemsContainGst: boolean
  taxAmount: number
  total: number
  shippingService: ShippingServiceEnum
}

export interface PosPageState {
  orderDefaults: OrderDefaults
  recordToEdit: PosFormValues | null
  loading: {
    getOrderDefaults: boolean
    fetchOrder: boolean
    createOrder: boolean
    updateOrder: boolean
  }
  error: {
    getOrderDefaults: string | null
    fetchOrder: string | null
    createOrder: string | null
    updateOrder: string | null
  }
}

const initialState: PosPageState = {
  orderDefaults: {
    orderDate: moment().format('DD/MM/YYYY'),
    subtotal: 0,
    discount: {
      value: 0,
      type: '%'
    },
    discountAmount: 0,
    lineItems: [],
    deletedLineItems: [],
    shippingCost: 0,
    lineItemsContainGst: true,
    taxAmount: 0,
    total: 0,
    shippingService: ShippingServiceEnum.NO_SHIPPING
  },
  recordToEdit: null,
  loading: {
    getOrderDefaults: false,
    fetchOrder: false,
    createOrder: false,
    updateOrder: false
  },
  error: {
    getOrderDefaults: null,
    fetchOrder: null,
    createOrder: null,
    updateOrder: null
  }
}

const posPageSlice = createSlice({
  name: 'posPage',
  initialState,
  reducers: {
    setOrderDefaults: (state, action: PayloadAction<OrderDefaults>) => {
      state.orderDefaults = action.payload
    },
    setPosOrderToEdit: (state, action: PayloadAction<Order | null>) => {
      if (action.payload != null) {
        state.recordToEdit = transformForFrontend(action.payload)
      } else {
        state.recordToEdit = null
      }
    }
  },
  extraReducers: builder => {
    builder
      // fetchOrderDefaults async action
      .addCase(getOrderDefaults.pending, state => {
        state.loading.getOrderDefaults = true
        state.error.getOrderDefaults = null
      })
      .addCase(getOrderDefaults.fulfilled, (state, action) => {
        state.orderDefaults = action.payload
        state.loading.getOrderDefaults = false
      })
      .addCase(getOrderDefaults.rejected, (state, action) => {
        state.loading.getOrderDefaults = false
        state.error.getOrderDefaults = action.payload?.message || 'Error fetching order defaults'
      })

      // fetchOrder async action
      .addCase(fetchOrder.pending, state => {
        state.loading.fetchOrder = true
        state.error.fetchOrder = null
      })
      .addCase(fetchOrder.fulfilled, (state, action) => {
        state.recordToEdit = action.payload
        state.loading.fetchOrder = false
      })
      .addCase(fetchOrder.rejected, (state, action) => {
        state.loading.fetchOrder = false
        state.error.fetchOrder = action.payload?.message || 'Error fetching order'
      })
  }
})

export const { setOrderDefaults, setPosOrderToEdit } = posPageSlice.actions

export default posPageSlice.reducer
