import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { AppState } from '~/config/store'

const Wrapper = styled.div``

const ErrorMessages = () => {
  // Use useSelector to get the error from the Redux store
  const error = useSelector((state: AppState) => state.customersPage.inviteCustomerError)

  return <>{error && <Wrapper className="alert alert-danger">{error}</Wrapper>}</>
}

export default ErrorMessages
