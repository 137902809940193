import styled from 'styled-components'
import { Field } from 'formik'
import _ from 'lodash'

import ReactDatePicker from '~/components/form-inputs/formik/inputs-decorated/react-date-picker'
import { Button } from '~/components/forms'
import Icon from '~/components/icon'
import { BsInput, BsSelectInput, CounterInputField } from '~/components/form-inputs/formik/inputs-decorated'
import { ProductFormValues } from './render-stock-history'
import { ProductInventoryStatusEnum } from '~/types/product-inventory'

const BaseRow = styled.div`
  display: flex;
  flex-direction: row;
`
const BaseCol = styled.div<{ width?: number | string; textAlign?: string }>`
  flex-basis: ${props => props.width || 150}px;
  ${props => (props.width ? 'min-width: ' + props.width + ';' : '')}
  ${props => (props.textAlign ? 'text-align: ' + props.textAlign + ';' : '')}
  margin-right: 5px;
`
const Row = styled(BaseRow)`
  margin-bottom: 10px;
`

const Col = styled(BaseCol)``

interface RenderStockHistoryRowProps {
  index: number
  formValues: ProductFormValues
  showTotals: boolean
  readOnly: boolean
  existingRecord: boolean
  onRemove: (index: number) => void
}
const RenderStockHistoryRow = ({
  index,
  onRemove,
  formValues,
  showTotals,
  readOnly,
  existingRecord
}: RenderStockHistoryRowProps) => {
  const inventoryStatusOptions = [
    { value: '', label: 'Please select' },
    { value: ProductInventoryStatusEnum.ON_ORDER, label: 'On Order' },
    { value: ProductInventoryStatusEnum.RECEIVED, label: 'Received' }
  ]

  const isPaidOptions = [
    { value: '', label: 'Please select' },
    { value: true, label: 'Paid' },
    { value: false, label: 'Unpaid' }
  ]

  const { quantityIn, onlineQuantity } = formValues?.inventories?.[index] || {}

  const quantitySoldOther = (formValues?.inventories?.[index] || {}).quantitySoldOther || 0
  const mobileQuantityMax = _.max([(formValues?.inventories?.[index]?.quantityIn || 0) - quantitySoldOther, 0])
  const inventoryField = `inventories[${index}]`

  return (
    <Row>
      <Col>
        <Field
          name={`${inventoryField}.purchaseDate`}
          component={ReactDatePicker}
          label={null}
          readOnly={readOnly && existingRecord}
          dateInputFormat="YYYY-MM-DD"
        />
      </Col>
      <Col>
        <Field
          name={`${inventoryField}.supplierName`}
          component={BsInput}
          label={null}
          readOnly={readOnly && existingRecord}
        />
      </Col>
      <Col>
        <Field
          name={`${inventoryField}.invoiceNo`}
          component={BsInput}
          label={null}
          readOnly={readOnly && existingRecord}
        />
      </Col>
      <Col>
        <Field
          name={`${inventoryField}.quantityIn`}
          component={BsInput}
          label={null}
          readOnly={readOnly && existingRecord}
        />
      </Col>
      {formValues.mobile && (
        <Col>
          <Field
            name={`${inventoryField}.onlineQuantity`}
            min={0}
            max={mobileQuantityMax}
            component={CounterInputField}
            label={null}
            readOnly={readOnly && existingRecord}
          />
        </Col>
      )}
      {formValues.mobile && (
        <Col>
          <input
            readOnly
            disabled
            className="form-control"
            name={`${inventoryField}.quantityOther`}
            value={quantityIn != null && onlineQuantity != null ? quantityIn - onlineQuantity : ''}
          />
        </Col>
      )}
      <Col>
        <Field
          name={`${inventoryField}.costPricePerUnit`}
          component={BsInput}
          label={null}
          readOnly={readOnly && existingRecord}
        />
      </Col>
      <Col width="100">
        <Field
          name={`${inventoryField}.isPaid`}
          label={null}
          component={BsSelectInput}
          options={isPaidOptions}
          readOnly={readOnly && existingRecord}
        />
      </Col>
      <Col width="100">
        <Field
          name={`${inventoryField}.status`}
          label={null}
          component={BsSelectInput}
          options={inventoryStatusOptions}
          readOnly={readOnly && existingRecord}
        />
      </Col>
      {showTotals && (
        <Col width="40" textAlign="center">
          {(formValues?.inventories?.[index] || {}).quantitySold}
        </Col>
      )}
      {showTotals && formValues.mobile && (
        <Col width="40" textAlign="center">
          {(formValues?.inventories?.[index] || {}).quantitySoldMobile}
        </Col>
      )}
      {showTotals && formValues.mobile && (
        <Col width="40" textAlign="center">
          {quantitySoldOther}
        </Col>
      )}
      {index >= 1 && !(readOnly && existingRecord) && (
        <Col width="40">
          <Button variant="light" onClick={() => onRemove(index)}>
            <Icon icon="trash" className="text-danger" />
          </Button>
        </Col>
      )}
      {!(index >= 1 && !(readOnly && existingRecord)) && <Col width="40" />}
    </Row>
  )
}

export default RenderStockHistoryRow
