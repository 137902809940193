import { Navigate } from 'react-router-dom'

import useAuth from '~/contexts/useAuth'

const UserProfilePage = () => {
  const { userProfile, authPending } = useAuth()

  if (!userProfile && !authPending) {
    return <Navigate to="/signin" />
  }

  return (
    <div>
      <h1>User Profile</h1>

      <h4>Your Details</h4>
      <div>
        <p>
          <strong>Full Name: </strong> {userProfile?.fullName || '-'}
        </p>
        <p>
          <strong>Email: </strong> {userProfile?.email || '-'}
        </p>
      </div>
    </div>
  )
}

export default UserProfilePage
