import styled from 'styled-components'
import { FieldArray, Field } from 'formik'

import Icon from '~/components/icon'
import { Button } from '~/components/forms'

const Heading = styled.div`
  margin-bottom: 5px;
`

const Box = styled.div`
  @media (max-width: 768px) {
    margin-top: 20px;
  }

  @media (min-width: 769px) {
    h4 {
      margin-top: 8px;
      margin-bottom: 0;
    }
  }

  margin-bottom: 20px;
  display: flex;

  button.add {
    margin-top: 5px;
    margin-left: 10px;
  }
`

const FieldRow = styled.div`
  display: flex;
  margin-bottom: 5px;

  input {
    flex-grow: 1;

    & + input {
      margin-left: 20px;
    }
  }

  button {
    height: 34px;
    margin-left: 10px;
  }
`
interface CustomData {
  key: string
  value: string
}

interface RenderCompProps {
  onRemove: (index: number) => void
}

const renderComp = ({ onRemove }: RenderCompProps) => (
  <FieldArray name="customData">
    {({ form }) => (
      <div>
        {form.values.customData?.map((_: CustomData, index: number) => (
          <FieldRow key={index}>
            <Field
              name={`customData[${index}].key`}
              className="form-control"
              placeholder={'Enter a key, i.e. "Size"'}
            />
            <Field
              name={`customData[${index}].value`}
              className="form-control"
              placeholder={'Enter a value, i.e. "Small"'}
            />
            <Button variant="light" onClick={() => onRemove(index)}>
              <Icon icon="trash" className="text-danger" />
            </Button>
          </FieldRow>
        ))}
      </div>
    )}
  </FieldArray>
)

// Supports below format for customData
//
// [
//   { key: 'some key', value: 'some value' },
//   { key: 'some key', value: 'some value' },
// ]
interface CustomDataSectionProps {
  onAdd: () => void
  onRemove: (index: number) => void
}

const CustomDataSection = ({ onAdd, onRemove }: CustomDataSectionProps) => {
  return (
    <Box>
      <fieldset>
        <Heading className="d-flex align-items-center">
          <h5>
            Custom Data <small>(will appear in mobile app)</small>
          </h5>
          <Button variant="light" className="add" onClick={onAdd}>
            <Icon icon="plus" />
          </Button>
        </Heading>
        {renderComp({ onRemove })}
      </fieldset>
    </Box>
  )
}

export default CustomDataSection
