import { ProductImportMatcherMarketplaceTypeEnum } from '~/types/product-import-matcher'

interface DropdownOption {
  label: string
  value: string
}

const marketplaceOptions: DropdownOption[] = [
  { value: '', label: 'Please select' },
  { value: ProductImportMatcherMarketplaceTypeEnum.EBAY, label: 'eBay' },
  { value: ProductImportMatcherMarketplaceTypeEnum.WORDPRESS, label: 'Wordpress' }
]

export default marketplaceOptions
