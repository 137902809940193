import { useState } from 'react'
import styled from 'styled-components'
import { Field, FieldProps, getIn, useFormikContext } from 'formik'

import TripleClickHandler from '~/components/triple-click-handler'

const StyledFormError = styled.div`
  padding: 0.75rem 1rem 0.75rem 1rem;
  margin-bottom: 1.25rem;
  color: #ff3333;
  background-color: #fff5f5;
  border-radius: 3px;
  font-size: 0.875rem;

  & p:last-child {
    margin-bottom: 0;
  }
`

type BaseErrorsProps = FieldProps

const BaseErrors = ({ name = 'baseErrors', ...rest }) => {
  const [showErrors, setShowErrors] = useState(false)
  const { submitCount } = useFormikContext()

  if (submitCount === 0) return null

  return (
    <Field
      name={name}
      component={({ field, form: { isValid, errors, values } }: BaseErrorsProps) => {
        const allErrors = []

        // Function to check if the error should be displayed, i.e. if this error
        // is for a field not present in the form
        const shouldDisplayError = (fieldName: string) => {
          const fieldError = getIn(errors, fieldName)
          const fieldValue = getIn(values, fieldName)
          return fieldError && fieldValue === undefined
        }

        // Add error for the specified field name
        const fieldError = getIn(errors, field.name)
        if (fieldError) {
          allErrors.push(fieldError.toString())
        }

        // Just in case there is something inside of 'base' too
        const baseError = getIn(errors, 'base')
        if (baseError) {
          allErrors.push(baseError.toString())
        }

        // Add errors for fields not present in values
        const additionalErrors = Object.keys(errors).filter(shouldDisplayError)
        additionalErrors.forEach(errorFieldName => {
          const error = getIn(errors, errorFieldName)
          if (error) {
            allErrors.push(error.toString())
          }
        })

        // In this case one of the fields has an error, and hopefully it's
        // printed somewhere on the page, so just leave a generic error to make
        // the user aware
        if (allErrors.length === 0 && !isValid) {
          allErrors.push('Please check the form for errors and try again.')
          // useful for debugging:
          // allErrors.push(JSON.stringify(errors))
        }

        if (allErrors.length === 0) return null

        // Render all errors inside a single StyledFormError
        return (
          <TripleClickHandler onTripleClick={() => setShowErrors(!showErrors)}>
            <StyledFormError className="d-flex">
              {allErrors.map((error, index) => (
                <p key={index} className="mt-1 mb-1">
                  {error}
                </p>
              ))}

              {showErrors && (
                <div className="ms-auto">
                  <pre className="mb-0">{JSON.stringify(errors, null, 2)}</pre>
                </div>
              )}
            </StyledFormError>
          </TripleClickHandler>
        )
      }}
      {...rest}
    />
  )
}

export default BaseErrors
