import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IntlShippingServiceOption } from '~/types/account-settings'

export interface OrderCountryNote {
  countryCode: string
  note: string
  countryName: string
}

interface OrderDetailsForm {
  intlShippingServiceOptions: IntlShippingServiceOption[]
  countryNote: OrderCountryNote | null
}

export interface TransientFormDataState {
  orderDetailsForm: OrderDetailsForm
}

const initialState: TransientFormDataState = {
  orderDetailsForm: {
    intlShippingServiceOptions: [],
    countryNote: null
  }
}

const transientFormDataSlice = createSlice({
  name: 'transientFormData',
  initialState,
  reducers: {
    setCountryNote: (state, action: PayloadAction<OrderCountryNote | null>) => {
      state.orderDetailsForm.countryNote = action.payload
    },
    setIntlShippingServiceOptions: (state, action: PayloadAction<IntlShippingServiceOption[]>) => {
      state.orderDetailsForm.intlShippingServiceOptions = action.payload
    }
  }
})

export const { setCountryNote, setIntlShippingServiceOptions } = transientFormDataSlice.actions

export default transientFormDataSlice.reducer
