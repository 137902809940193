import axios from 'axios'
import snakeToCamelCase from '~/lib/snake-to-camel-case'
import camelToSnakeCase from '~/lib/camel-to-snake-case'
import { BASE_URL } from './constants'
import User from '~/types/user'

export async function fetchAllUsers() {
  return axios.get<User[]>(`${BASE_URL}/api/users`).then(res => snakeToCamelCase(res.data) as User[])
}

export async function createUser(params: User) {
  return axios
    .post<User>(
      `${BASE_URL}/api/users`,
      camelToSnakeCase({
        userProfile: params
      })
    )
    .then(res => snakeToCamelCase(res.data) as User)
}

export async function updateUser(params: User) {
  return axios
    .put<User>(
      `${BASE_URL}/api/users/${params.id}`,
      camelToSnakeCase({
        userProfile: params
      })
    )
    .then(res => snakeToCamelCase(res.data) as User)
}

export async function destroyUser(id: number) {
  return axios.delete(`${BASE_URL}/api/users/${id}`)
}
