import { Table } from 'react-bootstrap'
import AuPostShipment from '~/types/au-post-shipment'

interface PacakgeContentsProps {
  model: AuPostShipment
}
const PackageContents = ({ model }: PacakgeContentsProps) => {
  return (
    <div>
      <h4>Item Contents (ID: {model.remoteShipmentItemId})</h4>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Order #</th>
            <th>Item</th>
            <th>SKU</th>
            <th>Quantity</th>
            <th>Weight</th>
          </tr>
        </thead>
        <tbody>
          {model.items.map(i => (
            <tr key={i.orderId}>
              <td>{i.orderId}</td>
              <td>{i.description}</td>
              <td>{i.sku}</td>
              <td>{i.quantity}</td>
              <td>{i.weight}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default PackageContents
