import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import ManifestItem from '~/types/au-post-manifest'
import Grid from '~/components/grid'
import { gridColumns } from './config'
import { AppState } from '~/config/store'

const ManifestsGrid = () => {
  const navigate = useNavigate()
  const list = useSelector((state: AppState) => state.manifests.list)

  const onRowClick = (record: ManifestItem) => {
    navigate(`/manifests/${record.id}`)
  }

  return (
    <Grid
      recordType="manifests"
      config={{
        columns: gridColumns,
        actions: { edit: false, delete: false },
        checkboxesColumn: true,
        customizerModal: false,
        onItemSelected: () => undefined,
        onRowClick
      }}
      list={list}
      listMeta={undefined}
    />
  )
}

export default ManifestsGrid
