import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'

import { UnmatchedLineItem } from '~/types/ebay-sales-record'

// TODO: type this properly
export interface QueryParams {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export const fetchUnmatchedSalesRecords = createAsyncThunk<
  UnmatchedLineItem[],
  QueryParams | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { rejectValue: any }
>('unmatchedSalesRecordsPage/fetchUnmatchedSalesRecords', async (queryParams, { rejectWithValue }) => {
  try {
    const response = await axios.get<UnmatchedLineItem[]>('/api/unmatched_sales_records', {
      params: queryParams
    })
    return response.data
  } catch (error) {
    console.error(error)
    if (error instanceof AxiosError) {
      return rejectWithValue(error.response?.data)
    }
    return rejectWithValue('An unknown error occurred')
  }
})
