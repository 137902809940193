import { ReactNode, MouseEvent, useCallback } from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import useSettings from '~/contexts/useSettings'
import useAuth from '~/contexts/useAuth'
import Icon from '~/components/icon'
import { destroyProduct } from '~/async-actions/products-async-actions'
import { useConfirm } from '~/hooks'
import { AppDispatch } from '~/config/store'
import Product from '~/types/product'

interface Column {
  id: string
  format?: (product: Product) => string | ReactNode
}

interface ProductsTableRowProps {
  product: Product
  columns: Column[]
}

const ProductsTableRow = ({ product, columns }: ProductsTableRowProps) => {
  const { settings: accountSettings } = useSettings()
  const dispatch = useDispatch<AppDispatch>()
  const [confirm] = useConfirm()
  const { userProfile } = useAuth()

  const handleDeleteClick = useCallback(
    async (event: MouseEvent) => {
      event.preventDefault()

      if (await confirm({ message: 'Are you sure you want to DELETE this product? This action CANNOT be undone.' })) {
        const productToDelete = product
        return dispatch(destroyProduct(productToDelete))
      }
    },
    [dispatch, confirm, product]
  )

  return (
    <tr className={'product-row' + (product.status === 'active' ? '' : ' ' + product.status)}>
      {_.map(columns, column => (
        <td key={column.id}>
          {column.format ? column.format(product) : product[column.id as keyof Product]?.toString()}
        </td>
      ))}
      <td className="text-nowrap actions">
        <div className="d-flex gap-2">
          <Link className="edit-product-btn" to={{ pathname: `/products/${product.id}/edit` }}>
            <Icon icon="pencil-alt" />
          </Link>
          <Link
            className="update-inventory-btn"
            to={{
              pathname: `/products/${product.id}/edit`,
              hash: '#update-inventory'
            }}>
            <Icon icon="plus" />
          </Link>
          {userProfile && userProfile.kind === 'owner' && (
            <span className="delete-product-btn" onClick={handleDeleteClick}>
              <Icon icon="trash" />
            </span>
          )}
          {product.mobile && accountSettings?.account?.shopfrontDomain && (
            <a
              className="view-product-on-shopfront"
              href={`https://${accountSettings.account.shopfrontDomain}/product/${product.slug}`}
              target="_blank"
              rel="noopener noreferrer"
              title="View on Shopfront">
              <Icon icon="external-link-alt" />
            </a>
          )}
        </div>
      </td>
    </tr>
  )
}

export default ProductsTableRow
