import { FC, Ref, forwardRef } from 'react'
import styled from 'styled-components'
import Icon_ from '~/components/icon'
import classnames from 'classnames'

const Link = styled.a`
  white-space: nowrap;
  text-decoration: none;
  margin-top: -5px;
  margin-left: 0;
`
const Icon = styled(Icon_)`
  margin-left: 1rem;
`

interface EbaySearchProps {
  label?: string
  value: string
  ref?: Ref<HTMLAnchorElement>
  className?: string
}

const EbaySearch: FC<EbaySearchProps> = forwardRef(({ label = 'Search eBay', value, className, ...rest }, ref) => {
  const ebayUrl = `https://www.ebay.com.au/sh/lst/active?action=search&editMode=false&format=ALL_FORMATS&status=ACTIVE&q_field1=title&q_op1=CONTAINS&q_value1=${encodeURIComponent(
    value
  )}`

  return (
    <>
      <Link
        ref={ref}
        className={classnames('btn btn-link', { disabled: !value }, className)}
        href={ebayUrl}
        rel="noopener noreferrer"
        target="_blank"
        {...rest}>
        {label}
        <Icon icon="external-link-alt" />
      </Link>
    </>
  )
})

EbaySearch.displayName = 'EbaySearch'

export { EbaySearch }
