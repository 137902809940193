import { useState, useRef } from 'react'
import styled from 'styled-components'
import axios, { AxiosError } from 'axios'

import { Button as _Button } from '~/components/forms'
import FileImport, { EbayPackingSlipsImportSummary } from '~/types/file-import'

const Feedback = styled.div`
  color: red;
`
const Button = styled(_Button)`
  margin-top: 20px;
`

interface FormProps {
  onUploadStart: () => void
  onUploadSuccess: (record: FileImport<EbayPackingSlipsImportSummary>) => void
  onUploadError: (error: AxiosError) => void
}
const Form = ({ onUploadStart, onUploadSuccess, onUploadError }: FormProps) => {
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState<string>()
  const fileInput = useRef<HTMLInputElement>(null)

  const handleSubmit = () => {
    setSubmitting(true)
    const file = fileInput?.current?.files?.[0]
    console.log('[DEBUG] file =', file)
    if (!file) {
      setError('A input pdf file is required')
      setSubmitting(false)
      return
    }
    uploadFile(file)
  }

  const uploadFile = (file: File) => {
    onUploadStart()
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    const data = new FormData()
    data.append('ebay_packing_slips_import[file]', file)
    axios
      .post('/api/ebay_packing_slips_imports', data, config)
      .then(res => res.data)
      .then(record => onUploadSuccess(record))
      .catch(error => {
        console.log('[DEBUG] upload error =', error)
        onUploadError(error)
      })
  }

  return (
    <div className="card mt-5">
      <div className="card-body">
        <h4>Upload PDF</h4>
        <input ref={fileInput} name="file" type="file" />
        {error && <Feedback>{error}</Feedback>}
        <Button disabled={submitting} onClick={() => handleSubmit()} isSubmitting={submitting}>
          Import
        </Button>
      </div>
    </div>
  )
}

export default Form
