import _ from '~/lib/lodash'
import { AxiosError } from 'axios'

const createErrorMsg = (error: AxiosError) => {
  let msg: string | undefined = undefined
  if (error && error.response && error.response.data) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const all = _.map(error.response.data, (value: any, key: string) => {
      return String(key).toUpperCase() + ': ' + value.join(', ')
    })
    msg = all.join('\n')
  } else {
    msg = 'An error occurred when uploading the file. Please upload a valid PDF file'
  }
  return msg
}

export default createErrorMsg
