import _ from 'lodash'

// similar to lodash snakeCase, but doesn't remove square brackets
// i.e. without this function, lodash would convert
//
// productImportMatcher[0].scanCode to product_import_matcher_0_scan_code
//
// whereas it needs to be converted to product_import_matcher[0].scan_code
function customSnakeCase(str: string) {
  // Placeholder characters
  const placeholderOpenBracket = '\uFFF0' // Placeholder for '['
  const placeholderCloseBracket = '\uFFF1' // Placeholder for ']'
  const placeholderDot = '\uE000' // Placeholder for '.'

  // Replace '[', ']', and '.' with placeholders
  let processedStr = str
    .replace(/\[/g, placeholderOpenBracket)
    .replace(/\]/g, placeholderCloseBracket)
    .replace(/\./g, placeholderDot)

  // Apply lodash snakeCase
  processedStr = _.snakeCase(processedStr)

  // Replace placeholders back with '[', ']', and '.'
  processedStr = processedStr
    .replace(new RegExp(`_?${placeholderOpenBracket}_?`, 'g'), '[')
    .replace(new RegExp(`_?${placeholderCloseBracket}_?`, 'g'), ']')
    .replace(new RegExp(placeholderDot, 'g'), '.')

  return processedStr
}

/**
 * @example
 *   import keysToSnakeCase from './camel-to-snake-case'
 *   keysToSnakeCase({badKey: 1})   => {bad_key: 1}
 *   keysToSnakeCase([{badKey: 1}]) => [{bad_key: 1}]
 */

function keysToSnakeCase(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any,
  options: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  } = { ignoreChildKeys: [] }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  let snakeCaseObject = _.cloneDeep(object)

  if (_.isString(object) || _.isNumber(object) || _.isBoolean(object)) return object

  if (_.isArray(snakeCaseObject)) {
    return _.map(snakeCaseObject, item => keysToSnakeCase(item, options))
  }

  snakeCaseObject = _.mapKeys(snakeCaseObject, (_value, key) => {
    const newKey = customSnakeCase(key)
    // Don't remove leading underscores, as rails needs these
    if (key.substring(0, 1) === '_') return `_${newKey}`
    return newKey
  })

  // Recursively apply throughout object
  return _.mapValues(snakeCaseObject, (value, key) => {
    if (_.isPlainObject(value) && (!options.ignoreChildKeys || options.ignoreChildKeys.indexOf(key) === -1)) {
      return keysToSnakeCase(value, options)
    }

    if (_.isArray(value)) {
      return _.map(value, item => keysToSnakeCase(item, options))
    }

    return value
  })
}

export default keysToSnakeCase
