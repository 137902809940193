import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'

import { ListMeta } from '~/reducers/order-processing-reducer'
import Order from '~/types/order'

// TODO: type this properly
export interface QueryParams {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export interface FetchUnprocessedOrdersResponse {
  items: Order[]
  meta: ListMeta
}

export const fetchUnprocessedOrders = createAsyncThunk<
  FetchUnprocessedOrdersResponse,
  QueryParams | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { rejectValue: any }
>('orderProcessing/fetchUnprocessedOrders', async (queryParams, { rejectWithValue }) => {
  try {
    const response = await axios.get('/api/orders?status=unprocessed&page_size=10000', { params: queryParams })
    return response.data
  } catch (error) {
    console.error(error)
    if (error instanceof AxiosError) {
      return rejectWithValue(error.response?.data)
    }
    return rejectWithValue('An unknown error occurred')
  }
})
