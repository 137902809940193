import size from 'lodash-es/size'
import mixin from 'lodash-es/mixin'
import clone from 'lodash-es/clone'
import merge from 'lodash-es/merge'
import find from 'lodash-es/find'
import findIndex from 'lodash-es/findIndex'
import first from 'lodash-es/first'
import filter from 'lodash-es/filter'
import sortBy from 'lodash-es/sortBy'
import startCase from 'lodash-es/startCase'
import pick from 'lodash-es/pick'
import keys from 'lodash-es/keys'
import map from 'lodash-es/map'
import debounce from 'lodash-es/debounce'
import trimStart from 'lodash-es/trimStart'
import cloneDeep from 'lodash-es/cloneDeep'
import isString from 'lodash-es/isString'
import isArray from 'lodash-es/isArray'
import mapKeys from 'lodash-es/mapKeys'
import snakeCase from 'lodash-es/snakeCase'
import camelCase from 'lodash-es/camelCase'
import mapValues from 'lodash-es/mapValues'
import isPlainObject from 'lodash-es/isPlainObject'
import uniqueId from 'lodash-es/uniqueId'

const _ = {
  clone,
  size,
  merge,
  find,
  findIndex,
  first,
  filter,
  sortBy,
  startCase,
  pick,
  keys,
  map,
  debounce,
  trimStart,
  cloneDeep,
  isString,
  isArray,
  mapKeys,
  snakeCase,
  camelCase,
  mapValues,
  isPlainObject,
  mixin,
  uniqueId
}

export default _
