import { useState } from 'react'
import { Modal, ModalProps } from 'react-bootstrap'
import Select, { OptionsOrGroups, GroupBase } from 'react-select'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { Button } from '~/components/forms'
import Product from '~/types/product'
import { copyProductToAccount } from '~/async-actions/products-async-actions'
import { AppDispatch } from '~/config/store'

export type Option = {
  value: string
  label: string
}

interface ProductExistsModalProps extends ModalProps {
  onSuccess?: (product: Product) => Promise<void>
  children?: React.ReactNode
  productId: number
}

const CopyToAccountModal = ({ productId, onSuccess, children, ...rest }: ProductExistsModalProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const [subdomain, setSubdomain] = useState<string | undefined>('cca')
  const options = [{ label: 'CCA', value: 'cca' }] as OptionsOrGroups<Option, GroupBase<Option>>
  const [isSubmitting, setIsSubmitting] = useState(false)

  const copy = async () => {
    if (subdomain == null) return
    setIsSubmitting(true)

    try {
      const result = await dispatch(copyProductToAccount({ id: productId, subdomain }))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (!(result as any).error) {
        toast.success(`Successfully copied product to the ${subdomain} account`)
      }
      if (onSuccess) await onSuccess(result.payload)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Modal {...rest}>
      <Modal.Header closeButton={!isSubmitting}>
        <Modal.Title>Copy to Account</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {children}
        <Select
          isMulti={false}
          options={options}
          defaultValue={{ label: 'CCA', value: 'cca' }}
          onChange={e => setSubdomain(e?.value)}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={copy} isSubmitting={isSubmitting}>
          Yes
        </Button>
        <Button variant="light" onClick={rest.onHide} disabled={isSubmitting}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CopyToAccountModal
