import { default as React } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import { ModalProvider } from 'styled-react-modal'

import { FallbackError } from '~/components/error-messages'
import bugsnagClient from '~/config/bugsnag'

import { AuthProvider } from '~/contexts/auth'
import { CountryCodesProvider } from '~/contexts/country-codes'
import { AuPostcodesProvider } from '~/contexts/au-postcodes'
import { SettingsProvider } from '~/contexts/settings'
import { configureAxios } from '~/config/axios'
import createStore from '~/config/store'
import currentTheme from '~/styles/themes/current'
import App from './app'

import './lib/utils/run-migrations'

configureAxios()

const ErrorBoundary = bugsnagClient.getPlugin('react')?.createErrorBoundary(React) || (() => null)

const store = createStore()

const WrappedApp = () => (
  <BrowserRouter>
    <ThemeProvider theme={currentTheme}>
      <ModalProvider>
        <AuthProvider>
          <CountryCodesProvider>
            <AuPostcodesProvider>
              <SettingsProvider>
                <App />
              </SettingsProvider>
            </AuPostcodesProvider>
          </CountryCodesProvider>
        </AuthProvider>
      </ModalProvider>
    </ThemeProvider>
  </BrowserRouter>
)

// Provider cannot handle the store being hot loaded directly, so this must be
// extracted from WrappedApp
const Root = () => (
  <ErrorBoundary FallbackComponent={FallbackError}>
    <Provider store={store}>
      <WrappedApp />
    </Provider>
  </ErrorBoundary>
)

export default Root
