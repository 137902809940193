import { ChangeEvent } from 'react'
import styled from 'styled-components'
import _ from 'lodash'

const Wrapper = styled.div`
  align-self: center;
  margin-left: auto;
  margin-right: 10px;
`

interface PageSizeWidgetProps {
  onChange: (options: { page_size: number }) => void
  value: number
  className?: string
}

const PageSizeWidget = ({ onChange, value, className = '' }: PageSizeWidgetProps) => {
  const sizes = [30, 50, 100]

  const handleOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const pageSize = parseInt(e.target.value, 10)
    onChange({ page_size: pageSize })
  }

  return (
    <Wrapper className={className} aria-label="grid page-size dropdown">
      <select value={value} className="form-select" onChange={handleOnChange}>
        {_.map(sizes, size => (
          <option key={size} value={size}>
            {size}
          </option>
        ))}
      </select>
    </Wrapper>
  )
}

export default PageSizeWidget
