import styled from 'styled-components'

import { Button } from '~/components/forms'

const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;

  & > button {
    margin-right: 10px;
  }
`

interface ShipmentActionsProps {
  onDelete: () => void
  onCombine: () => void
  disableDelete: boolean
  disableCombine: boolean
}
const ShipmentActions = ({ onDelete, onCombine, disableDelete, disableCombine }: ShipmentActionsProps) => (
  <Wrapper>
    <Button variant="primary" disabled={disableCombine} onClick={onCombine}>
      Combine Shipments
    </Button>

    <Button variant="danger" disabled={disableDelete} onClick={onDelete}>
      Delete Shipments
    </Button>
  </Wrapper>
)

export default ShipmentActions
