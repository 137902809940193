import { FieldInputProps, FormikProps } from 'formik'
import styled from 'styled-components'

const Textarea = styled.textarea`
  width: 100%;
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #555555;
  line-height: 1.42857143;
  font-size: inherit;
  padding: 0.5rem 1rem;

  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

  &::placeholder {
    color: #ccc;
  }

  &:focus {
    border-color: ${props => props.theme.colors.primary.base};
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  }

  &.hasError {
    border-color: ${props => props.theme.colors.danger.base};
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

    &:focus {
      border-color: ${props => props.theme.colors.danger.dark};
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
    }
  }

  &:disabled {
    background-color: #eee;
  }
`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface TextareaInputProps<V = any, FormValues = any> {
  field: FieldInputProps<V>
  form: FormikProps<FormValues>
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TextareaInput = <V = any, FormValues = any>({
  field: { value, ...otherFields },
  form,
  ...otherProps
}: TextareaInputProps<V, FormValues>) => (
  <Textarea {...otherFields} {...otherProps} value={(value as string | number | readonly string[] | undefined) ?? ''} />
)

export default TextareaInput
