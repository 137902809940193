import { InputHTMLAttributes, ChangeEvent, FocusEvent } from 'react'
import _ from 'lodash'
import classnames from 'classnames'

import FieldLayout, { FieldLayoutProps } from './field-layout'
import { fieldLayoutKeys } from './field-layout-keys'
import omitProps from '~/lib/omit-props'

import styles from './bs-input.module.css'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface BsInputProps<V = any, FormValues = any>
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'form'>,
    Omit<FieldLayoutProps<V, FormValues>, 'children'> {
  displayMode?: 'input' | 'plain'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BsInput = <V = any, FormValues = any>(props: BsInputProps<V, FormValues>) => {
  const {
    field: { value, onChange: formikOnChange, onBlur: formikOnBlur, ...otherFields },
    onChange: customOnChange,
    onBlur: customOnBlur,
    displayMode = 'input',
    className,
    ...allRest
  } = props
  const rest = omitProps<BsInputProps<V, FormValues>, keyof FieldLayoutProps<V, FormValues>>(allRest, fieldLayoutKeys)

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    formikOnChange(e)
    customOnChange?.(e)
  }

  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    formikOnBlur(e)
    customOnBlur?.(e)
  }

  return (
    <FieldLayout {...props}>
      {({ id, value }) => (
        <input
          className={classnames(
            {
              [styles.formControlPlainText]: displayMode === 'plain'
            },
            className ? className : 'form-control'
          )}
          id={id}
          value={(value as string | number | readonly string[] | undefined) ?? ''}
          onChange={onChange}
          onBlur={onBlur}
          {...otherFields} // name, onBlur, onChange, checked, multiple
          {...rest}
        />
      )}
    </FieldLayout>
  )
}

export default BsInput
