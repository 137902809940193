import { ReactNode, createContext, useState, useEffect } from 'react'
import _ from 'lodash'
import { AxiosError } from 'axios'

import { fetchSettingsRequest, updateRequest } from '~/api/settings'
import useAuth from '~/contexts/useAuth'
import AccountSettings, {
  CountryNotes,
  CountryNotesOriginal,
  AuPostChargeCodes,
  AuPostInternationalChargeCode,
  AuPostChargeCodesOriginal,
  AccountSettingKeys,
  AccountSettingValues,
  AccountSettingValuesOriginal
} from '~/types/account-settings'

const fallbackDefaultSettings = {
  sellerProfile: {},
  eparcelsDefaults: {}
} as AccountSettings

export interface SettingsProviderState {
  settingsPending: boolean
  settings?: AccountSettings
  setSettings: (settings?: AccountSettings) => void
  updateAction: (
    key: AccountSettingKeys,
    values: AccountSettingValues,
    onSuccess: () => void,
    onError: (error: AxiosError) => void
  ) => void
}

const defaultState: SettingsProviderState = {
  settingsPending: true,
  settings: fallbackDefaultSettings,
  setSettings: () => console.log('[INFO]', 'default'),
  updateAction: () => console.log('[INFO]', 'default')
}

const SettingsContext = createContext<SettingsProviderState>(defaultState)

interface SettingsProviderProps {
  children?: ReactNode
}

// TODO: store the settings in redux, and use async-actions for fetching & updating

const SettingsProvider = ({ children }: SettingsProviderProps) => {
  const [settingsPending, setSettingsPending] = useState<boolean>(true)
  const [settings, setSettings] = useState<AccountSettings | undefined>(fallbackDefaultSettings)
  const { isLoggedIn } = useAuth()

  useEffect(() => {
    if (isLoggedIn) {
      refreshSettings()
    }
  }, [isLoggedIn])

  const refreshSettings = async () => {
    setSettingsPending(true)
    return fetchSettingsRequest()
      .then(fetchedSettings => {
        setSettings(fetchedSettings)
        setSettingsPending(false)
      })
      .catch(error => {
        console.log('[DEBUG] Error =', error)
        setSettings(undefined)
        setSettingsPending(false)
      })
  }

  const mapChargeCodesValuesToParams = (values: AuPostChargeCodes) => {
    const params = _.clone(values) as AuPostChargeCodes
    const international = {} as AuPostChargeCodesOriginal['international']

    _.each(params.international, (entry: AuPostInternationalChargeCode, index) => {
      const { id, val, name } = entry
      international[name as keyof AuPostChargeCodesOriginal['international']] = {
        id,
        val,
        order: index
      }
      return entry
    })

    const returnParams = {
      ...params,
      international
    } as AuPostChargeCodesOriginal

    return returnParams
  }

  const mapCountryNotesToParams = (values: CountryNotes) => {
    const params = {} as CountryNotesOriginal
    const notes = _.clone(values.notes)
    _.each(notes, note => {
      params[note.countryCode] = note.body
    })
    return params
  }

  const updateAction = (
    key: AccountSettingKeys,
    values: AccountSettingValues,
    onSuccess: () => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => void
  ) => {
    let params = values as AccountSettingValuesOriginal
    if (key === 'auPostChargeCodes') {
      params = mapChargeCodesValuesToParams(values as AuPostChargeCodes)
    }
    if (key === 'countryNotes') {
      params = mapCountryNotesToParams(values as CountryNotes)
    }
    updateRequest(key, params)
      .then(updatedSettings => {
        setSettings(updatedSettings)
        onSuccess()
      })
      .catch(onError)
  }

  const value: SettingsProviderState = {
    settingsPending,
    settings,
    setSettings,
    updateAction
  }

  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>
}

export { SettingsProvider }
export default SettingsContext
