// Application wide config
// We should map all env variables to a config value.
// Because we shouldn't use env vars outside of the config section.

const defaultApiHost = document.location.protocol + '//' + document.location.hostname.replace('.frontend.', '.api.')

const appConfig = {
  apiBaseUrl: import.meta.env.VITE_APP_API_HOST || defaultApiHost,
  environment: import.meta.env.NODE_ENV
}

export default appConfig
