import { ReactNode, useState, useEffect, useCallback, useMemo } from 'react'
import { AsyncThunk } from '@reduxjs/toolkit'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import _ from 'lodash'
import { default as qs } from 'qs'

import { Button } from '~/components/forms'
import { PersistableDataState } from '~/reducers/persistable-data-reducer'
import CustomizerModal from './customizer-modal'
import SummaryText from './summary-text'
import PageControls from './page-controls'
import PageSizeWidget from './page-size-widget'
import HeadingRow from './heading-row'
import { SortableItem } from './sortable'
import createConfig, { GridConfig, ModelWithId, GridColumn } from './create-config'
import { changeSelectedIds } from '~/reducers/grid-reducer'
import { AppDispatch, AppState } from '~/config/store'

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`

const CustomiseGridBtn = styled(Button)`
  align-self: center;
`

interface MetaBase {
  currentPage: number
  totalPages: number
  totalCount: number
  pageSize: number
}

// Add the new type alias GridComponentType before the Grid class definition:
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GridProps<Model extends ModelWithId = any, Meta extends MetaBase = any, RV = object> = {
  recordType: keyof PersistableDataState['grid']

  fetchFn?: AsyncThunk<
    {
      items: Model[]
      meta: Meta
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Record<string, any>,
    { rejectValue: RV }
  >
  filtersComponent?: (options: { onChange: (newParams: qs.ParsedQs) => void; query: qs.ParsedQs }) => string | ReactNode
  config?: GridConfig<Model, RV>
  list: Model[]
  listMeta?: {
    pageSize: number
    currentPage: number
    totalPages: number
    totalCount: number
  }
  destroyRecord?: AsyncThunk<Model, Model, { rejectValue: RV }>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Grid = <Model extends ModelWithId = any, Meta extends MetaBase = any, RV = object>({
  recordType,
  config: defaultConfig,
  fetchFn,
  filtersComponent,
  list,
  listMeta,
  destroyRecord
}: GridProps<Model, Meta, RV>) => {
  const defaultPageSize = 30
  const defaultPageNum = 1
  const config = createConfig<Model, RV>({ config: defaultConfig })
  const gridPreferences = useSelector((state: AppState) => state.persistableData.grid)
  const visibleColumnIds = useMemo(
    () => gridPreferences[recordType as keyof PersistableDataState['grid']] || [],
    [gridPreferences, recordType]
  )
  const selectedIdsOf = useSelector((state: AppState) => state.grid.selectedIdsOf)
  const selectedIds = useMemo(() => (selectedIdsOf?.[recordType] || []) as number[], [recordType, selectedIdsOf])
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const location = useLocation()
  const [showCustomizerModal, setShowCustomizerModal] = useState(false)

  const toggleSelected = useCallback(
    (recordId: number) => {
      const ids = [...selectedIds]
      const index = ids.indexOf(recordId)

      if (index === -1) {
        ids.push(recordId)
      } else {
        ids.splice(index, 1)
      }

      if (config && config.onItemSelected) {
        config.onItemSelected({ selectedIds: ids })
      }
      dispatch(changeSelectedIds({ recordType, selectedIds: ids }))
    },
    [config, dispatch, recordType, selectedIds]
  )

  const assertSelected = (recordId: number) => selectedIds.includes(recordId)

  const assertAllSelected = useCallback(() => selectedIds.length === list.length, [list.length, selectedIds.length])

  const toggleSelectAll = useCallback(() => {
    let selectedIds: number[] = []

    if (!assertAllSelected()) {
      selectedIds = _.map(list, 'id')
    }

    if (config && config.onItemSelected) {
      config.onItemSelected({ selectedIds })
    }
    dispatch(changeSelectedIds({ recordType, selectedIds }))
  }, [assertAllSelected, config, dispatch, list, recordType])

  const getQueryParams = useCallback(
    () => qs.parse(location.search, { ignoreQueryPrefix: true }) || {},
    [location.search]
  )

  const handleQueryParamsChange = useCallback(
    (newParams: { search?: string; page?: number | string; page_size?: number | string }) => {
      const origParams = getQueryParams()

      // If the search has changed, clear the page number
      if (newParams.search && newParams.search !== origParams.search && origParams.page) {
        delete origParams.page
      }

      // If the page_size has changed, clear the page number
      if (newParams.page_size && newParams.page_size !== origParams.page_size && origParams.page) {
        delete origParams.page
      }

      const query = Object.assign({}, origParams, newParams)
      const path = Object.assign(location, { search: qs.stringify(query) })
      navigate(path)
      if (fetchFn) {
        dispatch(fetchFn(query))
      }
    },
    [dispatch, fetchFn, getQueryParams, location, navigate]
  )

  const getVisibleColumns = useCallback(() => {
    const minimumColumns =
      _.map(
        _.filter(config.columns, col => {
          return col.locked === true
        }),
        'attr'
      ) || []

    const m = minimumColumns.filter(c => c != null) as string[]
    const columns = (visibleColumnIds || []).concat(m)

    return _.filter(config.columns, column => {
      return column.attr != null && columns.includes(column.attr)
    }) as GridColumn<Model, RV>[]
  }, [config.columns, visibleColumnIds])

  const renderSummaryText = useCallback(() => {
    const shouldRender = config.pagination && listMeta?.totalPages

    return shouldRender && <SummaryText recordType={recordType} listMeta={listMeta} />
  }, [config.pagination, listMeta, recordType])

  const renderPageSizeWidget = useCallback(() => {
    const getCurrentPageSize = () =>
      parseInt(qs.parse(location.search, { ignoreQueryPrefix: true }).page_size?.toString() || '', 10) ||
      defaultPageSize

    const shouldRender = config.pagination && listMeta?.totalPages

    return shouldRender && <PageSizeWidget value={getCurrentPageSize()} onChange={handleQueryParamsChange} />
  }, [config.pagination, location.search, handleQueryParamsChange, listMeta])

  const renderPageControls = useCallback(() => {
    const getCurrentPageNum = () =>
      parseInt(qs.parse(location.search, { ignoreQueryPrefix: true }).page?.toString() || '', 10) || defaultPageNum

    const handlePageChange = (page: number) => {
      handleQueryParamsChange({ page })
    }

    return (
      <>
        {config.pagination && listMeta?.totalPages != null && (
          <PageControls
            currentPage={getCurrentPageNum()}
            totalPages={listMeta.totalPages}
            onChange={handlePageChange}
          />
        )}
      </>
    )
  }, [config.pagination, location.search, handleQueryParamsChange, listMeta])

  const renderCustomizerBtn = useCallback(() => {
    return (
      config.customizerModal && (
        <CustomiseGridBtn size="sm" variant="light" onClick={() => setShowCustomizerModal(true)}>
          Customise Grid
        </CustomiseGridBtn>
      )
    )
  }, [config.customizerModal])

  const renderFilters = () => {
    if (!filtersComponent) return null
    return filtersComponent({
      onChange: handleQueryParamsChange,
      query: getQueryParams()
    })
  }

  useEffect(() => {
    const resetSelectedIds = () => {
      const selectedIds = config.checkboxesColumnChecked === true ? _.map(list, 'id') : []
      if (config && config.onItemSelected) {
        config.onItemSelected({ selectedIds })
      }
      dispatch(changeSelectedIds({ recordType, selectedIds }))
    }

    if (fetchFn) {
      dispatch(fetchFn(getQueryParams()))
    }
    resetSelectedIds()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const visibleColumns = getVisibleColumns()

  return (
    <div>
      {renderFilters()}

      <Flex>
        {renderPageControls()}
        {renderPageSizeWidget()}
        {renderCustomizerBtn()}
      </Flex>

      <table className="table table-bordered table-striped table-hover">
        <thead>
          <HeadingRow<Model, RV>
            config={config}
            visibleColumns={visibleColumns}
            isAllSelected={assertAllSelected()}
            toggleSelectAll={toggleSelectAll}
          />
        </thead>

        <tbody>
          {list.map((record: Model, index: number) => (
            <SortableItem<Model, RV>
              key={record.id}
              index={index}
              item={record}
              recordType={recordType}
              config={config}
              visibleColumns={visibleColumns}
              toggleSelected={toggleSelected}
              assertSelected={assertSelected}
              destroyRecord={destroyRecord}
            />
          ))}
        </tbody>
      </table>

      <Flex>
        {renderSummaryText()}
        {renderPageSizeWidget()}
        {renderPageControls()}
      </Flex>

      {showCustomizerModal && (
        <CustomizerModal<Model, RV>
          recordType={recordType}
          show={showCustomizerModal}
          columns={config.columns}
          visibleColumnIds={visibleColumnIds}
          onHide={() => setShowCustomizerModal(false)}
        />
      )}
    </div>
  )
}

export default Grid
