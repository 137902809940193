import _ from 'lodash'
import { configureStore } from '@reduxjs/toolkit'

import { loadState, saveState } from '~/lib/utils/persistance'
import rootReducer from '~/reducers'

const createStore = () => {
  const initialState = {}
  const persistedState = loadState()
  const preloadedState = Object.assign({}, persistedState, initialState)

  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware(),
    devTools: import.meta.env.VITE_ENV !== 'production',
    preloadedState
  })

  // TOOD: how to enable this with Vite and/or react-refresh?
  //
  // if (!import.meta.env.VITE_ENV && module.hot) {
  //   module.hot.accept('reducers', () => {
  //     const newRootReducer = require('reducers').default
  //     store.replaceReducer(newRootReducer)
  //   })
  // }

  store.subscribe(
    _.throttle(() => {
      saveState({
        ...store.getState().persistableData
      })
    }, 1000)
  )

  return store
}

export type AppState = ReturnType<ReturnType<typeof createStore>['getState']>
export type AppDispatch = ReturnType<typeof createStore>['dispatch']

export default createStore
