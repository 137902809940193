import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Location } from 'history'

export interface RouterState {
  prevLocation: Location | null
  pageError: string | number | null
}

export const initialState: RouterState = {
  prevLocation: null,
  pageError: null
}

const routerSlice = createSlice({
  name: 'router',
  initialState,
  reducers: {
    setPrevLocation: (state, action: PayloadAction<Location | null>) => {
      state.prevLocation = action.payload
    },
    setPageError: (state, action: PayloadAction<string | number | null>) => {
      state.pageError = action.payload
    }
  }
})

export const { setPrevLocation, setPageError } = routerSlice.actions

export default routerSlice.reducer
