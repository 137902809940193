import _ from 'lodash'

import { ProductSearchLinkPayload } from '~/async-actions/product-search-links-async-actions'

export const encodeAsFormData = (values: ProductSearchLinkPayload) => {
  const formData = new FormData()

  _.each(values, (value, key) => {
    if (key === 'alternateTerms') {
      formData.append(`mobile_product_search_link[${_.snakeCase(key)}]`, JSON.stringify(value))
    } else if (value instanceof File) {
      formData.append(`mobile_product_search_link[${_.snakeCase(key)}]`, value)
    } else if (value !== null) {
      formData.append(`mobile_product_search_link[${_.snakeCase(key)}]`, value?.toString() || '')
    }
  })

  return formData
}
