import styled from 'styled-components'
import moment from 'moment'
import _ from 'lodash'

import ManifestItem, { ManifestStatusEnum } from '~/types/au-post-manifest'
import ManifestItemResponseBody from '~/types/au-post-manifest-response-body'

const Div = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 400px;
`

const P = styled.p`
  margin: 0;
  margin-bottom: 8px;

  & > strong > span {
    margin-right: 5px;
  }
`

const getLabelClass = (status: ManifestStatusEnum) => {
  switch (status) {
    case 'despatched':
      return 'label label-success'
    case 'not_despatched':
      return 'label label-warning'
    default:
      return 'label label-warning'
  }
}

const getShippingCost = (status: ManifestStatusEnum, remoteResponseBody: ManifestItemResponseBody) => {
  if (status === 'despatched' && typeof remoteResponseBody.order.orderSummary.totalCostExGst !== undefined) {
    return remoteResponseBody.order.orderSummary.totalCostExGst
  } else {
    return ''
  }
}

interface InfoProps {
  manifest: ManifestItem
}
const Info = ({ manifest }: InfoProps) => {
  const { status, despatchedAt, remoteResponseBody } = manifest
  const statusStr = _.startCase(status)
  const despatchedAtStr = despatchedAt ? moment(manifest.despatchedAt).format('DD/MM/YYYY') : '-'

  const shippingCost = getShippingCost(status, remoteResponseBody)
  return (
    <Div className="card">
      <div className="card-body">
        <P>
          <strong>
            Status<span>:</span>
          </strong>{' '}
          <span className={getLabelClass(status)}>{statusStr}</span>
        </P>
        {despatchedAt && (
          <P>
            <strong>
              Despatched At<span>:</span>
            </strong>{' '}
            <span>{despatchedAtStr}</span>
          </P>
        )}
        {shippingCost && (
          <P>
            <strong>
              Shipping Cost<span>:</span>
            </strong>{' '}
            <span>{shippingCost}</span>
          </P>
        )}
      </div>
    </Div>
  )
}

export default Info
