import { useEffect, useCallback, ReactNode } from 'react'
import axios from 'axios'

import CsvImport from '~/types/csv-import'

interface ImportContainerProps<T extends CsvImport = CsvImport> {
  resourcePath: string
  lastCsvImport?: CsvImport
  children: ReactNode
  onUpdate?: (csvImport: T | undefined) => Promise<void>
  onCompleted?: (csvImport: T) => Promise<void>
  onFailed?: (csvImport: T) => Promise<void>
  onCancelled?: (csvImport: T) => Promise<void>
  onPending?: (csvImport: T) => Promise<void>
}

const ImportContainer = <T extends CsvImport = CsvImport>({
  resourcePath,
  lastCsvImport,
  children,
  onUpdate,
  onCompleted,
  onFailed,
  onCancelled,
  onPending
}: ImportContainerProps<T>) => {
  const reFetchLastCsvImport = useCallback(
    async (id: number) => {
      const response = await axios.get<T>(`/api/${resourcePath}/${id}`)
      const csvImport = response.data ? response.data : undefined

      if (csvImport == null) return

      await onUpdate?.(csvImport)

      if (csvImport?.completed) {
        await onCompleted?.(csvImport)
      } else if (csvImport?.failed) {
        await onFailed?.(csvImport)
      } else if (csvImport.cancelledAt) {
        await onCancelled?.(csvImport)
      } else if (csvImport?.pending) {
        await onPending?.(csvImport)
        setTimeout(() => {
          reFetchLastCsvImport(csvImport.id)
        }, 1000)
      }
    },
    [resourcePath, onCompleted, onFailed, onCancelled, onPending, onUpdate]
  )

  useEffect(() => {
    if (lastCsvImport?.id && lastCsvImport?.pending) {
      reFetchLastCsvImport(lastCsvImport.id)
    } else if (!lastCsvImport?.id) {
      reFetchLastCsvImport(-1)
    }
  }, [lastCsvImport?.id, lastCsvImport?.pending, reFetchLastCsvImport])

  return <>{children}</>
}

export default ImportContainer
