import styled from 'styled-components'
import { Field, useFormikContext } from 'formik'
import _ from 'lodash'

import BsSelectInput from '~/components/form-inputs/formik/inputs-decorated/bs-select-input'
import BsInput from '~/components/form-inputs/formik/inputs-decorated/bs-input'
import Icon from '~/components/icon'
import { Button } from '~/components/forms'
import ProductImportMatcher from '~/types/product-import-matcher'
import { ProductFormValues } from '../use-product-form'
import marketplaceOptions from './marketplace-options'

const ScancodeInputsWrapper = styled.div`
  .add-btn {
    margin-top: 1rem;
  }

  fieldset + fieldset {
    margin-top: 1rem;
  }
`

export interface ScancodesInputsProps {
  // Disable the first matcher in the table.
  // Used when setting up a new product, from an imported sales record.
  //
  // In that case, we want to allow new scancodes to be added, but not to touch
  // the first scancode which was used to setup the product in the first place
  disableFirstMatcher?: boolean
}

const ScancodesInputs = ({ disableFirstMatcher }: ScancodesInputsProps) => {
  const { values, setFieldValue } = useFormikContext<ProductFormValues>()

  const name = 'productImportMatchers'
  const fields = values.productImportMatchers

  const push = (matcher: ProductImportMatcher) => {
    setFieldValue(name, [...(fields || []), matcher])
  }

  const remove = (index: number) => {
    setFieldValue(
      name,
      _.filter(fields, (_: ProductImportMatcher, i: number) => i !== index)
    )
  }

  return (
    <fieldset>
      <legend>Scan Codes</legend>
      <ScancodeInputsWrapper>
        <div className="row">
          <div className="col-sm-4 col-md-3">
            <strong>
              <abbr title="required">*</abbr> Marketplace
            </strong>
          </div>
          <div className="col-sm-8 col-md-9">
            <strong>
              <abbr title="required">*</abbr> Scan Code
            </strong>
          </div>
        </div>
        {fields?.map((_importMatcher: ProductImportMatcher, index: number) => {
          const disabled = disableFirstMatcher === true && index === 0
          return (
            <div key={index}>
              <div className="row mb-1">
                <div className="col-sm-4 col-md-3">
                  <Field
                    name={`${name}[${index}].marketplace`}
                    component={BsSelectInput}
                    label={null}
                    disabled={disabled}
                    options={marketplaceOptions}
                  />
                </div>
                <div className="col-sm-7 col-md-8">
                  <Field name={`${name}[${index}].scancode`} component={BsInput} label={null} disabled={disabled} />
                </div>
                <div className="col-sm-1">
                  {!disabled && (
                    <Button variant="light" onClick={() => remove(index)}>
                      <Icon icon="trash" className="text-danger" />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          )
        })}
        <div className="add-btn">
          <Button variant="light" onClick={() => push({} as ProductImportMatcher)}>
            Add&nbsp;&nbsp;
            <Icon icon="plus" />
          </Button>
        </div>
      </ScancodeInputsWrapper>
    </fieldset>
  )
}

export default ScancodesInputs
