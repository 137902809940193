import { useState, useRef, useCallback } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'

import { fetchCustomers } from '~/async-actions/customers-async-actions'
import { AppDispatch } from '~/config/store'
import Customer from '~/types/customer'

type CustomerSearchInputProps = {
  onSelect: (customer: Customer) => void
  onHide: () => void
}

const CustomerSearchInput = ({ onSelect, onHide }: CustomerSearchInputProps) => {
  const [searchText, setSearchText] = useState<string>('')
  const [selectedCustomer, setSelectedCustomer] = useState<number | undefined>()
  const [showSelectBox, setShowSelectBox] = useState<boolean>(false)
  const [options, setOptions] = useState<Customer[]>([])

  const customerSelectInstance = useRef<HTMLSelectElement>(null)

  const dispatch = useDispatch<AppDispatch>()

  const handleCustomerSelectChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const customerId = parseInt(e.target.value, 10)

      setSelectedCustomer(customerId)
      const customer = _.find(options, item => item.id === customerId)
      if (customer) {
        onSelect(customer)
        onHide()
        setSearchText('')
        setSelectedCustomer(undefined)
        setShowSelectBox(false)
      }
    },
    [onHide, onSelect, options]
  )

  const handleSearch = useDebouncedCallback(
    useCallback(
      async (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchText = e.target.value
        // TODO: it would be good if this didn't clobber the redux state!
        const response = await dispatch(fetchCustomers({ search: searchText }))
        const { items: searchResults } = response.payload

        const newOptions = searchResults
        const newShowSelectBox = newOptions.length > 0

        setOptions(newOptions)
        setShowSelectBox(newShowSelectBox)
        if (customerSelectInstance.current) {
          customerSelectInstance.current.size = newOptions.length > 16 ? 16 : newOptions.length + 1
        }
      },
      [dispatch]
    ),
    700
  )

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value)
      handleSearch(e)
    },
    [handleSearch]
  )

  return (
    <div>
      <input
        className="form-control"
        placeholder="Type to search a customer by name, address, email etc"
        value={searchText}
        onChange={handleInputChange}
      />
      <select
        ref={customerSelectInstance}
        className="form-select"
        value={selectedCustomer}
        style={{ display: showSelectBox ? 'block' : 'none' }}
        onChange={handleCustomerSelectChange}>
        <option value="">Please select</option>
        {options.map(c => (
          <option key={c.id} value={c.id}>
            {c.name}
          </option>
        ))}
      </select>
    </div>
  )
}

export default CustomerSearchInput
