import { useCallback } from 'react'
import _ from 'lodash'
import { Pagination } from 'react-bootstrap'

interface PageControlsProps {
  currentPage: number
  totalPages: number
  onChange: (page: number) => void
  visiblePages?: number
  className?: string
}

const PageControls = ({ currentPage, totalPages, onChange, visiblePages = 7, className = '' }: PageControlsProps) => {
  const getVisiblePages = useCallback(() => {
    return totalPages < visiblePages ? totalPages : visiblePages
  }, [totalPages, visiblePages])

  const getPages = useCallback(() => {
    const visiblePages = getVisiblePages()

    const pages: number[] = []
    const half = Math.floor(visiblePages / 2)
    let start = currentPage - half + 1 - (visiblePages % 2)
    let end = currentPage + half

    if (start <= 0) {
      start = 1
      end = visiblePages
    }
    if (end > totalPages) {
      start = totalPages - visiblePages + 1
      end = totalPages
    }

    let itPage = start
    while (itPage <= end) {
      pages.push(itPage)
      itPage++
    }

    return pages
  }, [currentPage, totalPages, getVisiblePages])

  const goToPage = useCallback(
    (page: number) => {
      onChange(page)
    },
    [onChange]
  )

  const goBack = useCallback(() => {
    const prevPageNum = currentPage - 1
    if (currentPage > 1) goToPage(prevPageNum)
  }, [currentPage, goToPage])

  const goForward = useCallback(() => {
    const nextPageNum = currentPage + 1
    if (currentPage < totalPages) goToPage(nextPageNum)
  }, [currentPage, totalPages, goToPage])

  const pages = getPages()

  if (pages.length <= 1) return null

  return (
    <div className={className}>
      <nav aria-label="grid pages">
        <Pagination size="sm">
          <Pagination.Prev onClick={goBack} />

          {pages.map(pageNum => (
            <Pagination.Item key={pageNum} active={currentPage === pageNum} onClick={() => goToPage(pageNum)}>
              {pageNum}
            </Pagination.Item>
          ))}

          <Pagination.Next onClick={goForward} />
        </Pagination>
      </nav>
    </div>
  )
}

export default PageControls
