import axios, { AxiosRequestConfig } from 'axios'
import snakeToCamelCase from '~/lib/snake-to-camel-case'
import camelToSnakeCase from '~/lib/camel-to-snake-case'
import _ from 'lodash'
import { BASE_URL } from './constants'
import transformSettings from './transform-settings'
import { AccountSettingKeys, AccountSettingsOriginal, AccountSettingValuesOriginal } from '~/types/account-settings'

import ShippingPricingProfile from '~/types/shipping-pricing-profile'

// TODO: remove any types

const encodeAsFormData = (parentKey: AccountSettingKeys, values: AccountSettingValuesOriginal) => {
  const formData = new FormData()
  _.each(values as object, (value, key) => {
    if (value === null) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (typeof value === 'object' && (value as any).constructor === Object) {
      formData.append(`${_.snakeCase(parentKey)}[${_.snakeCase(key)}]`, JSON.stringify(camelToSnakeCase(value)))
    } else {
      formData.append(`${_.snakeCase(parentKey)}[${_.snakeCase(key)}]`, value)
    }
  })

  return formData
}

// I think this is the new format for fetching settings. i.e. calls the
// 'settings' controller and maps the settings into a more convienient nested
// format
export async function fetchSettingsRequest() {
  return transformSettings(
    axios.get<AccountSettingsOriginal>(`${BASE_URL}/api/settings`).then(res => snakeToCamelCase(res.data))
  )
}

export async function updateRequest(key: AccountSettingKeys, params: AccountSettingValuesOriginal) {
  if (key === 'shippingPricingProfiles') {
    return updateShippingPricingProfilesRequest(params as UpdateShippingPricingProfilesParams)
  }

  if (key === 'mobile' || key === 'shopfront') {
    return updateMobileOrShopfront(key, params)
  }

  return transformSettings(
    axios
      .put<AccountSettingsOriginal>(
        `${BASE_URL}/api/settings/${_.snakeCase(key)}`,
        camelToSnakeCase({
          [key]: params
        })
      )
      .then(res => snakeToCamelCase(res.data))
  )
}

async function updateMobileOrShopfront(key: 'mobile' | 'shopfront', params: AccountSettingValuesOriginal) {
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    },
    snake: false
  }
  const formData = encodeAsFormData(key, params)

  return transformSettings(
    axios
      .put<AccountSettingsOriginal>(`${BASE_URL}/api/settings/${key}`, formData, config)
      .then(res => snakeToCamelCase(res.data))
  )
}

interface UpdateShippingPricingProfilesParams {
  regular: ShippingPricingProfile
  express: ShippingPricingProfile
}
async function updateShippingPricingProfilesRequest(params: UpdateShippingPricingProfilesParams) {
  return transformSettings(
    axios
      .put(
        `${BASE_URL}/api/settings/shipping_pricing_profiles`,
        {
          shipping_pricing_profiles: {
            regular_shipping_pricing_profiles_attributes: params.regular,
            express_shipping_pricing_profiles_attributes: params.express
          }
        },
        { snake: false } as AxiosRequestConfig
      )
      .then(res => snakeToCamelCase(res.data))
  )
}

export async function clearEbayAccessToken() {
  return transformSettings(axios.delete(`${BASE_URL}/api/settings/ebay/reset`).then(res => snakeToCamelCase(res.data)))
}

export async function acceptEbayAccessToken(token: string) {
  // URL encode the token
  const encodedToken = encodeURIComponent(token)
  return transformSettings(
    axios.post(`${BASE_URL}/api/settings/ebay/accepted?token=${encodedToken}`).then(res => snakeToCamelCase(res.data))
  )
}

export async function refreshEbayAccessToken() {
  return transformSettings(axios.post(`${BASE_URL}/api/settings/ebay/refresh`).then(res => snakeToCamelCase(res.data)))
}
