import { useDispatch, useSelector } from 'react-redux'

import { UnmatchedLineItem } from '~/types/ebay-sales-record'
import { AppState, AppDispatch } from '~/config/store'
import { Button } from '~/components/forms'
import IncompleteRecordFixItForm from './incomplete-record-fix-it-form'
import IncompleteRecordsTable from './incomplete-records-table'
import { changeSelectedUnmatchedRecord } from '~/reducers/unmatched-sales-records-page-reducer'

interface IncompleteRecordsScreenProps {
  onComplete?: () => Promise<void>
  onStartNew: () => void
}

const IncompleteRecordsScreen = ({ onComplete, onStartNew }: IncompleteRecordsScreenProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const list = useSelector((state: AppState) => state.unmatchedSalesRecordsPage.list)
  const recordToProcess = useSelector((state: AppState) => state.unmatchedSalesRecordsPage.selectedRecord)

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <h1>Order Processing - Fix incomplete records</h1>

        <Button variant="light" onClick={onStartNew}>
          Start New
        </Button>
      </div>
      <div>
        {recordToProcess != null && (
          <IncompleteRecordFixItForm
            selectedRecord={recordToProcess}
            afterRecordProcessed={async (_unmatchedRecord, wasLastRecord) => {
              // if this is the last record, call the onComplete callback
              if (wasLastRecord) {
                await onComplete?.()
              }

              // Scroll to the top of the page so we can process the next record (which will have already been loaded)
              window.scrollTo({ top: 0 })
            }}
            onCancel={() => {
              dispatch(changeSelectedUnmatchedRecord(null))
            }}
          />
        )}

        {recordToProcess == null && list.length > 0 && (
          <IncompleteRecordsTable
            list={list}
            onRecordClick={(record: UnmatchedLineItem) => {
              dispatch(changeSelectedUnmatchedRecord(record))
            }}
          />
        )}

        {list.length === 0 && (
          <div>
            <p className="lead">Nothing to do here.</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default IncompleteRecordsScreen
