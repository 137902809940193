import { Field, useFormikContext } from 'formik'

import { BsInput } from '~/components/form-inputs/formik/inputs-decorated'
import { OrderFormValues } from './use-order-details'
import Icon from '~/components/icon'

interface EparcelsTrackingProps {
  initialValues: OrderFormValues
}

const EparcelsTracking = ({ initialValues }: EparcelsTrackingProps) => {
  const { values: formValues } = useFormikContext<OrderFormValues>()
  const { trackingNum } = formValues
  const { shippingVirtualTrackingNum } = initialValues

  return (
    <fieldset>
      <legend>eParcels Tracking</legend>

      <div className="mb-3 d-flex justify-content-between">
        <span>
          {shippingVirtualTrackingNum && (
            <>
              (<em>eBay Virtual Tracking Number:</em> <code>{shippingVirtualTrackingNum}</code>)
            </>
          )}
        </span>

        {trackingNum && (
          <a
            href={`https://auspost.com.au/mypost/track/#/details/${trackingNum}`}
            target="_blank"
            rel="noopener noreferrer">
            View on Aust Post <Icon icon="external-link-alt" />
          </a>
        )}
      </div>

      <div>
        <Field name="trackingNum" label="Tracking Number" component={BsInput} />
        <Field name="trackingService" label="Service" component={BsInput} />
      </div>
    </fieldset>
  )
}

export default EparcelsTracking
