import styled from 'styled-components'
import { FieldArray, FormikProvider, useFormik } from 'formik'
import { arrayMove } from '@dnd-kit/sortable'
import { Alert } from 'react-bootstrap'

import { ActionBar } from '~/components/forms'
import { BaseErrors, FlexibleInputGroup } from '~/components/forms/formik'
import useSubmitHandler from '~/containers/settings/submit-handler'
import validations from './validations'
import SortableItem from './sortable-item'
import { SettingsProviderState } from '~/contexts/settings'
import { AuPostChargeCodes, AccountSettingKeys } from '~/types/account-settings'

const Container = styled.div`
  width: 800px;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

const ItemList = styled.div``

interface AuPostChargeCodesFormProps {
  initialValues: AuPostChargeCodes
  redirectPath: string
  settingsKey: AccountSettingKeys
  updateAction: SettingsProviderState['updateAction']
}

const AuPostChargeCodesForm = (props: AuPostChargeCodesFormProps) => {
  const { submitHandler } = useSubmitHandler()
  const { initialValues, redirectPath } = props
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validations,
    onSubmit: (values, helpers) => submitHandler<AuPostChargeCodes, AuPostChargeCodesFormProps>(values, helpers, props)
  })

  const { values, setFieldValue, handleSubmit, isSubmitting } = formik

  const createOnSortEnd = (setFieldValue: typeof formik.setFieldValue, values: typeof formik.values) => {
    return function (oldIndex: number, newIndex: number) {
      console.log('[DEBUG] oldIndex, newIndex =', oldIndex, newIndex)
      const sortedList = arrayMove(values.international, oldIndex, newIndex)
      setFieldValue('international', sortedList)
    }
  }

  return (
    <FormikProvider value={formik}>
      <Wrapper>
        <Container>
          <Alert variant="light">
            <p>These charge codes are assigned to orders as they are imported from eBay.</p>
            <p>
              The system will automatically assign them to an order based on what shipping service was used on the
              order.
            </p>
          </Alert>

          <BaseErrors />

          <FlexibleInputGroup name="domestic.regularEparcel" label="Regular eParcel" />
          <FlexibleInputGroup name="domestic.expressEparcel" label="Express eParcel" />

          <Alert variant="warning">
            <p>
              NOTE: Please press <strong>Save Changes</strong> after sorting the list.
            </p>
            <p>You can order the international charge codes in order of preference.</p>
            <p>
              Post Label Plus will be use the highest ranked charge code (if applicable to the relevant country
              destination) by default.
            </p>
          </Alert>

          <FieldArray
            name="international"
            render={() => (
              <ItemList>
                {values.international.map((item, index) => (
                  <SortableItem
                    key={item.id}
                    index={index}
                    chargeCode={item}
                    onSortEnd={createOnSortEnd(setFieldValue, values)}
                  />
                ))}
              </ItemList>
            )}
          />

          <ActionBar isSubmitting={isSubmitting} redirectPath={redirectPath} onSubmit={handleSubmit} />
        </Container>
      </Wrapper>
    </FormikProvider>
  )
}

export default AuPostChargeCodesForm
