import { useState, useCallback } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import _ from 'lodash'

import useAuth from '~/contexts/useAuth'
import Icon from '~/components/icon'
import Grid from '~/components/grid'
import ItemSearchBox from '../priority-items-page/item-search-box'
import { sortList, loadSearchResults } from '~/reducers/menu-items-reducer'
import {
  fetchList,
  destroyRecord,
  createRecord,
  sortListAsync,
  searchMenuEntries,
  QueryParams
} from '~/async-actions/menu-items-async-actions'
import { AppDispatch, AppState } from '~/config/store'
import MenuItem, { MenuItemKindEnum } from '~/types/menu-item'
import { getColumnsConfig } from './config'

import styles from './index.module.css'

type Option = { value: number; label: string }

const Flex = styled.div`
  display: flex;
`
const SelectWrapper = styled.div`
  min-width: 200px;
  max-height: 34px;
`
const Nav = styled.div`
  margin-bottom: 2rem;
  display: flex;
`
const NavItem = styled.div`
  margin-right: 2rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
const AddButton = styled.button`
  margin-left: 1rem;
  max-height: 34px;
`

interface MenuItemsPageProps {
  kind: MenuItemKindEnum
}
const MenuItemsPage = ({ kind }: MenuItemsPageProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const authContext = useAuth()

  const userProfile = authContext?.userProfile
  const list = useSelector((state: AppState) => state.menuItems.list)
  const listMeta = useSelector((state: AppState) => state.menuItems.listMeta)
  const searchResults = useSelector((state: AppState) => state.menuItems.searchResults)

  const [selectedItem, setSelectedItem] = useState<MenuItem>()
  const [selectedParent, setSelectedParent] = useState<Option>()

  const calcNextItem = (list: MenuItem[]) => {
    return 1000 - list.length * 10
  }

  const addMenuItem = () => {
    if (!selectedItem) return

    const priority = calcNextItem(list)

    dispatch(
      createRecord({
        params: {
          searchLinkId: selectedItem.id,
          priority,
          parentId: selectedParent?.value
        } as MenuItem,
        kind
      })
    )

    setSelectedItem(undefined)
    setSelectedParent(undefined)
  }

  const handleSelect = (item: MenuItem) => {
    setSelectedItem(item)
  }

  const orderItems = (items: MenuItem[], parentId?: number, depth = 0) => {
    depth = depth || 0
    const itemsForParent = _.filter(items, item => {
      return item.parentId === (parentId || null)
    })

    const sorted = _.map(itemsForParent, item => {
      let depthIndicator = _.pad('', depth * 2, '-')
      if (depthIndicator) depthIndicator = `${depthIndicator}`
      const label = `${depthIndicator} ${item.label || item.searchLink?.name}`
      return [Object.assign({}, item, { label })].concat(orderItems(items, item.id, depth + 1))
    })

    const flattened = _.flattenDeep(sorted) as MenuItem[]

    return flattened
  }

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    dispatch(sortList({ oldIndex, newIndex }))

    const orderedIds = _.map(list, item => item.id)
    dispatch(sortListAsync({ orderedIds, kind }))
  }

  const _parentOptions = (list: MenuItem[]) => {
    return _.map(list, item => {
      return { value: item.id, label: `${item.label || item.searchLink?.name}` }
    })
  }

  const onEdit = (record: MenuItem) => {
    const path = `/${_.kebabCase(kind)}/product-search-links/${(record.searchLink || {}).id}/edit`
    navigate(path)
  }

  const onDelete = (record: MenuItem) => {
    return dispatch(destroyRecord({ record, kind }))
  }

  const checkIfDisabled = (record: MenuItem): boolean => {
    if (!record.active) {
      return true
    }

    if (record.parentId) {
      const parent = _.find(list, { id: record.parentId })
      if (parent) {
        return checkIfDisabled(parent)
      }
    }

    return false
  }

  const itemsOrdered = orderItems(list)

  const onSelectChange = (option: Option | null): void => {
    setSelectedParent(option != null ? option : undefined)
  }

  // Grid expects fetchFn to be a thunk with a particular shape. For now, all we can do is return as 'any' to make it work
  //
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fetchFn: any = useCallback(
    (queryParams: QueryParams) => {
      return fetchList({ kind, queryParams })
    },
    [kind]
  )

  if (!userProfile || !['owner', 'staff_full'].includes(userProfile.kind)) return null

  return (
    <div>
      <h1>{_.capitalize(kind)} Menu Items</h1>
      <p>Items that will appear in the {_.capitalize(kind)} menu bar.</p>

      {userProfile && (userProfile.kind === 'owner' || userProfile.kind === 'staff_full') && (
        <div>
          <Nav>
            <NavItem onClick={() => navigate(`/${_.kebabCase(kind)}/product-search-links?page=1`)}>
              <Icon icon="external-link-alt" />
              &nbsp; Search Links Page
            </NavItem>

            {kind === 'shopfront' && (
              <NavItem onClick={() => navigate(`/${_.kebabCase(kind)}?page=1`)}>
                <Icon icon="external-link-alt" />
                &nbsp; Priority Items Page
              </NavItem>
            )}
          </Nav>

          <Flex>
            <ItemSearchBox<MenuItem>
              className={styles.itemSearchBox}
              searchResults={searchResults}
              searchMenuEntries={searchMenuEntries}
              selectedItem={selectedItem}
              onSelect={handleSelect}
              onHide={() => null}
              onReset={() => dispatch(loadSearchResults([]))}
            />

            <SelectWrapper>
              <Select<Option, false>
                value={selectedParent}
                placeholder="Select Parent Item"
                options={_parentOptions(itemsOrdered)}
                onChange={onSelectChange}
              />
            </SelectWrapper>

            <AddButton className="btn btn-primary btn-sm" onClick={addMenuItem}>
              Add
            </AddButton>
          </Flex>

          <Grid<MenuItem>
            config={{
              pagination: false,
              customizerModal: false,
              columns: getColumnsConfig(kind),
              onSortEnd: onSortEnd,
              onEdit: onEdit,
              onDelete: onDelete,
              rowClass: record => (checkIfDisabled(record) ? 'disabled' : '')
            }}
            recordType={`${kind}MenuItems`}
            list={itemsOrdered}
            listMeta={listMeta}
            fetchFn={fetchFn}
          />
        </div>
      )}
    </div>
  )
}

export default MenuItemsPage
