import styled from 'styled-components'
import { useSelector } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

import { AppState } from '~/config/store'

const Container = styled.div`
  margin-bottom: 20px;
`

interface ImportsSectionProps {
  className?: string
}

const UploadSummary = ({ className }: ImportsSectionProps) => {
  const lastCsvImport = useSelector((state: AppState) => state.orderProcessing.lastCsvImport)

  if (lastCsvImport == null) return null

  return (
    <Container className={className}>
      {lastCsvImport.completed && (
        <div className="alert alert-success mt-4">
          <p className="text-notice mb-0">
            <strong>Summary of last csv import: </strong>
            <span className="text-danger">{moment(lastCsvImport.completedAt).fromNow()}</span>
            ,&nbsp;
            <span>Records created: </span>
            <strong>{lastCsvImport.summary?.createdRecords}</strong>,&nbsp; Records updated:{' '}
            <strong>{lastCsvImport.summary?.updatedRecords}</strong>,&nbsp; Records not modified:{' '}
            <strong>{lastCsvImport.summary?.skippedRecords}</strong>
          </p>
        </div>
      )}

      <div>
        {lastCsvImport.failed && (
          <div className="alert alert-danger">
            <h3>The CSV failed to import! 😱</h3>
            <p className="mb-0">
              Last csv file import failed and no data has been uploaded. Please check the csv file for errors such as
              incorrect formatting and try re-uploading. Contact our support team if problem persists.
            </p>
          </div>
        )}

        {(lastCsvImport.errors?.length ?? 0) > 0 && (
          <div className="card">
            <div className="card-body">
              <p className="text-danger">
                <strong>Warning:</strong> The following errors occurred processing the file
              </p>
              <table className="table table-nonfluid mb-0">
                <thead>
                  <tr>
                    <th>Line</th>
                    <th>Error Message</th>
                  </tr>
                </thead>
                <tbody>
                  {_.map(lastCsvImport.errors || [], error => (
                    <tr key={error.errors}>
                      <td className="line-number">{error.lineNum}</td>
                      <td className="error-messages">{error.errors}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}

export default UploadSummary
