import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import classnames from 'classnames'

import Icon from '~/components/icon'
import { Button } from '~/components/forms'
import OrderDetailsForm from '~/containers/order-details-form-page/form'
import {
  goToNextProcessingOrder,
  clearProcessingOrder,
  updateProcessingOrder
} from '~/reducers/order-processing-reducer'
import { AppState, AppDispatch } from '~/config/store'
import OrdersToProcessList from './orders-to-process-list'

import styles from './process-orders-screen.module.css'

interface ProcessOrderScreenProps {
  onComplete?: () => Promise<void>
  onStartNew: () => void
}
const ProcessOrdersScreen = ({ onComplete, onStartNew }: ProcessOrderScreenProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const list = useSelector((state: AppState) => state.orderProcessing.list)
  const currentOrderToProcess = useSelector((state: AppState) => state.orderProcessing.currentOrderToProcess)
  const unprocessedOrdersCount = _.filter(list, ['status', 'unprocessed']).length

  const hasNextOrder = unprocessedOrdersCount > 1

  return (
    <>
      <div className="d-flex justify-content-between align-items-start">
        <h1>
          Order Processing{' '}
          <small className="text-secondary">
            {currentOrderToProcess && <strong>({unprocessedOrdersCount - 1} left after this)</strong>}
            {!currentOrderToProcess && <strong>({unprocessedOrdersCount} total)</strong>}
          </small>
        </h1>

        <div className={classnames('d-flex justify-content-between align-items-center', styles.buttonContainer)}>
          {!currentOrderToProcess && (
            <>
              <Button
                variant="success"
                size="lg"
                onClick={() => {
                  dispatch(goToNextProcessingOrder())
                }}>
                Process ({unprocessedOrdersCount}) Orders <Icon icon="arrow-right" />
              </Button>
              or
            </>
          )}

          <Button variant="light" onClick={onStartNew}>
            Start New
          </Button>
        </div>
      </div>

      {currentOrderToProcess && (
        <OrderDetailsForm
          initialValues={currentOrderToProcess}
          key={currentOrderToProcess.id} // this is important to force the form to re-render, so no values from the previous record will still be visible
          onCancel={() => {
            dispatch(clearProcessingOrder())
          }}
          afterSave={async order => {
            dispatch(updateProcessingOrder(order))

            if (hasNextOrder) {
              dispatch(goToNextProcessingOrder())
            } else {
              await onComplete?.()
            }
          }}
          saveBtnText={hasNextOrder ? `Save and Next (${unprocessedOrdersCount - 1})` : 'Save and Close'}
        />
      )}

      {!currentOrderToProcess && <OrdersToProcessList />}
    </>
  )
}

export default ProcessOrdersScreen
