import { ReactNode } from 'react'
import styled from 'styled-components'
import { Outlet } from 'react-router-dom'

import Spinner from '~/components/spinner'
import Header from './header'
import Footer from './footer'
import SideMenu from './side-menu'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  h1,
  h2.heading {
    margin-top: 0;
  }
`
const InnerContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`
const SideBar = styled.div`
  display: flex;
  flex-direction: row;
`
const MainPanel = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  padding: 2rem;

  @media (max-width: ${props => props.theme.breakpoint.sm}) {
    padding: 1rem;
  }
`

interface LayoutProps {
  authRefreshPending?: boolean
  noHeader?: boolean
  noFooter?: boolean
  children?: ReactNode
}

const Layout = ({ authRefreshPending = false, noHeader = false, noFooter = false, children = null }: LayoutProps) => (
  <Wrapper>
    {!noHeader && <Header />}

    <InnerContainer>
      {authRefreshPending ? (
        <Spinner />
      ) : (
        <SideBar>
          <SideMenu />
          <MainPanel>
            {children}
            <Outlet />
          </MainPanel>
        </SideBar>
      )}
      {!noFooter && <Footer />}
    </InnerContainer>
  </Wrapper>
)

export default Layout
