import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { FormikHelpers } from 'formik'

import { createCustomer, updateCustomer, fetchCustomer } from '~/async-actions/customers-async-actions'
import { setErrorsFromResponse } from '~/lib/formik-utils'

import { AppDispatch, AppState } from '~/config/store'
import Customer from '~/types/customer'

export interface CustomerFormValues extends Omit<Partial<Customer>, 'id'> {
  id?: number
}

interface UseCustomerFormProps {
  beforeSave?: (values: CustomerFormValues) => Promise<boolean> // return false if the save should not go ahead
  afterSave?: (values: CustomerFormValues) => Promise<void>
}

const useCustomerForm = ({ beforeSave, afterSave }: UseCustomerFormProps = {}) => {
  const [saving, setSaving] = useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const loading = useSelector((state: AppState) => state.customersPage.loading.fetchCustomer)

  const fetch = async (recordId: number) => {
    return dispatch(fetchCustomer(recordId))
  }

  const save = useCallback(
    async (values: CustomerFormValues, { setFieldError }: FormikHelpers<CustomerFormValues>) => {
      // If callback returns false, don't save
      const shouldSave = await beforeSave?.(values)
      if (shouldSave === false) {
        return
      }

      setSaving(true)

      const record = await (values.id ? dispatch(updateCustomer(values)) : dispatch(createCustomer(values)))

      if (record.type.match(/rejected/)) {
        toast.error('An error occurred when saving the customer. Please review the form for any errors.')
        setErrorsFromResponse(record.payload, setFieldError)
      } else {
        await afterSave?.(record.payload)
      }

      setSaving(false)
    },
    [dispatch, beforeSave, afterSave]
  )

  return {
    loading,
    saving,
    fetch,
    save
  }
}

export default useCustomerForm
