import Spinner from '~/components/spinner'
import AuPostChargeCodesForm from './form'
import useSettings from '~/contexts/useSettings'

const AuPostChargeCodes = () => {
  const { settings, settingsPending, updateAction } = useSettings()
  return settingsPending ? (
    <Spinner />
  ) : settings == null ? null : (
    <AuPostChargeCodesForm
      initialValues={settings.auPostChargeCodes}
      updateAction={updateAction}
      settingsKey="auPostChargeCodes"
      redirectPath="/settings"
    />
  )
}

export default AuPostChargeCodes
