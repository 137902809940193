import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

import ManifestItem from '~/types/au-post-manifest'

export interface ManifestsState {
  list: ManifestItem[]
}

const initialState: ManifestsState = {
  list: []
}

const manifestsSlice = createSlice({
  name: 'au-post-manifests',
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<ManifestItem[]>) => {
      state.list = action.payload
    },
    updateList: (state, action: PayloadAction<ManifestItem>) => {
      const index = _.findIndex(state.list, ['id', action.payload.id])
      if (index !== -1) {
        state.list.splice(index, 1, action.payload)
      }
    }
  }
})

export const { setList, updateList } = manifestsSlice.actions

export default manifestsSlice.reducer
