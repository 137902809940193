import useSettings from '~/contexts/useSettings'

import Spinner from '~/components/spinner'
import Form from './form'

const ShopfrontSmsTemplatesPage = () => {
  const { settings, settingsPending, updateAction } = useSettings()
  return settingsPending ? (
    <Spinner />
  ) : settings == null ? null : (
    <Form
      initialValues={settings.shopfrontSmsTemplates}
      updateAction={updateAction}
      settingsKey="shopfrontSmsTemplates"
      redirectPath="/settings"
    />
  )
}

export default ShopfrontSmsTemplatesPage
