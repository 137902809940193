import styled from 'styled-components'

const Text = styled.div``

interface SummaryTextProps {
  recordType: string
  listMeta: {
    pageSize: number
    currentPage: number
    totalPages: number
    totalCount: number
  }
}
const SummaryText = ({ recordType, listMeta }: SummaryTextProps) => {
  const { pageSize, currentPage, totalPages, totalCount } = listMeta
  const lastPageNum = totalPages

  let text = ''

  if (totalCount === 0) {
    text = `No ${recordType} found`
  } else if (currentPage > lastPageNum) {
    text = `Page not found`
  } else if (totalCount < pageSize) {
    text = `Showing 1 to ${totalCount} of ${totalCount} ${recordType}`
  } else {
    const start = pageSize * (currentPage - 1) + 1
    const end = currentPage === lastPageNum ? totalCount : pageSize * currentPage

    text = start > totalCount ? `Page is empty` : `Showing ${start} to ${end} of ${totalCount} ${recordType}`
  }

  return totalCount === undefined ? null : <Text>{text}</Text>
}

export default SummaryText
