import { useState } from 'react'
import styled from 'styled-components'
import classnames from 'classnames'

import { ProductImage } from '~/types/product'

import { BASE_URL } from '~/api/constants'

const Div = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .checkbox {
    margin-left: 20px;
  }
`

const ImageContainer = styled.div`
  height: 170px;
  width: 170px;
  background-color: #f1f4f7;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-height: 100%;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 20% 20% 20% 20%;
  grid-template-rows: auto;
  grid-gap: 1em;
`

const ItemWrapper = styled.div`
  width: 170px;
  vertical-align: top;
  position: relative;
`

interface ImageListHash {
  [imageId: string]: boolean
}

interface DownloadPhotoGridProps {
  images: ProductImage[]
  productSku: string
}
const DownloadPhotoGrid = ({ images, productSku }: DownloadPhotoGridProps) => {
  const [list, setList] = useState<ImageListHash>({})

  const toggleCheckbox = (imgId: number) => {
    let newList = {}
    if (list[imgId] === true) {
      newList = {
        ...list,
        [imgId]: false
      }
    } else {
      newList = {
        ...list,
        [imgId]: true
      }
    }
    setList(newList)
  }
  const selected = Object.keys(list).filter(id => list[id] === true)
  const disabled = selected.length === 0
  const isAllSelected = images.length > 0 && selected.length === images.length
  const getDownloadPhotosUrl = () => {
    const imageIdsStr = selected.join(',')
    return `${BASE_URL}/api/products/download_photos?product_sku=${productSku}&image_ids=${imageIdsStr}`
  }
  const toggleSelectAll = () => {
    if (isAllSelected === true) {
      setList({})
    } else {
      const selected = images.reduce((acc, img) => {
        acc[img.id] = true
        return acc
      }, {} as ImageListHash)
      setList(selected)
    }
  }

  return (
    <div>
      <Div>
        <a
          className={classnames('btn btn-primary me-2', { disabled: disabled })}
          href={getDownloadPhotosUrl()}
          role="button">
          Zip and Download Photos
        </a>

        <div className="form-check">
          <input
            type="checkbox"
            onChange={toggleSelectAll}
            checked={isAllSelected}
            className="form-check-input"
            id="toggle-select-all-for-download"
          />
          <label className="form-check-label" htmlFor="toggle-select-all-for-download">
            Select All
          </label>
        </div>
      </Div>

      <Grid>
        {images.map(img => (
          <ItemWrapper key={img.id}>
            <ImageContainer>
              <img className="img-fluid" src={img.photo.grid.url} alt="product" />
            </ImageContainer>

            <input
              type="checkbox"
              checked={list[img.id] === true}
              onChange={() => toggleCheckbox(img.id)}
              className="form-check-input"
            />
          </ItemWrapper>
        ))}
      </Grid>
    </div>
  )
}

export default DownloadPhotoGrid
