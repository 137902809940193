import { Spinner, Button as BootstrapButton, ButtonProps as BootstrapButtonProps } from 'react-bootstrap'

interface ButtonProps extends BootstrapButtonProps {
  block?: boolean
  isSubmitting?: boolean
  isSubmittingLabel?: string
}

const Button = ({ block, isSubmitting, isSubmittingLabel = 'Saving...', children, disabled, ...rest }: ButtonProps) => {
  const button = () => (
    <BootstrapButton disabled={disabled || isSubmitting} {...(rest as ButtonProps)}>
      {isSubmitting && (
        <>
          <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> {isSubmittingLabel}
        </>
      )}
      {!isSubmitting && children}
    </BootstrapButton>
  )

  if (block) {
    return <div className="d-flex">{button()}</div>
  } else {
    return button()
  }
}

export default Button
