import { useState, useCallback, useEffect, useMemo, useRef } from 'react'
import { useFormik, FormikProvider } from 'formik'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import useAuPostcodes from '~/contexts/useAuPostcodes'
import useCountryCodes from '~/contexts/useCountryCodes'
import createOrderSchema from './validation'
import useOrderDetails, { OrderFormValues } from './use-order-details'
import Order from '~/types/order'
import { AppState } from '~/config/store'
import BaseErrors from '~/components/forms/formik/base-errors'
import OrderShippingAddress from './order-shipping-address'
import OrderShippingService from './order-shipping-service'
import PackageDetailsDomestic from './package-details-domestic'
import PackageDetailsInternational, { PackageDetailsInternationalRef } from './package-details-international'
import SaveAndNext from './save-and-next'
import PackageContents from './package-contents'
import EparcelsTracking from './eparcels-tracking'
import OrderDetails from './order-details'
import OrderWarnings from './order-warnings'

export type OrderDetailsFormProps = {
  onCancel?: () => void
  afterSave?: (order: Order) => Promise<void>
  initialValues: Partial<Order>
  saveBtnText?: string
}

export type Option = {
  value: string
  label: string
}

const OrderDetailsForm = ({
  initialValues,
  onCancel,
  afterSave: parentAfterSave,
  saveBtnText
}: OrderDetailsFormProps) => {
  const packageDetailsInternationalRef = useRef<PackageDetailsInternationalRef>()
  const [countryOptions, setCountryOptions] = useState<Option[]>([{ value: '', label: 'Please select' }])
  const { countryCodes } = useCountryCodes()
  const { auPostcodes } = useAuPostcodes()
  const transientFormData = useSelector((state: AppState) => state.transientFormData)
  const { intlShippingServiceOptions } = transientFormData.orderDetailsForm
  const validationSchema = useMemo(
    () => createOrderSchema({ intlShippingServiceOptions, auPostcodes }),
    [auPostcodes, intlShippingServiceOptions]
  )

  const afterSave = useCallback(
    async (order: Order) => {
      // Update 'standing data' cache for customs descriptions
      if (order.customsDescription) {
        const val = order.customsDescription

        if (packageDetailsInternationalRef.current) {
          packageDetailsInternationalRef.current.addCustomsDescription([{ value: val, label: val }])
        }
      }

      await parentAfterSave?.(order)
    },
    [parentAfterSave]
  )

  const { save } = useOrderDetails({ afterSave })

  const formik = useFormik<OrderFormValues>({
    initialValues,
    onSubmit: save,
    enableReinitialize: true,
    validationSchema
  })

  const { values: formValues } = formik
  const { isDomestic } = formValues

  const loadCountryData = useCallback(() => {
    if (isDomestic) {
      return
    }

    let countries = _.map(countryCodes, entry => ({
      label: entry.countryName,
      value: entry.countryCode
    }))

    countries = _.sortBy(countries, 'label')
    countries.unshift(
      { value: '', label: 'Please select' },
      {
        value: 'AU',
        label: 'AUSTRALIA'
      }
    )

    setCountryOptions(countries)
  }, [isDomestic, countryCodes])

  useEffect(() => {
    loadCountryData()
  }, [loadCountryData])

  return (
    <FormikProvider value={formik}>
      <OrderWarnings />
      <BaseErrors />

      <div className="row">
        <div className="col-sm-6 col-md-6 col-lg-5">
          <OrderShippingAddress countryOptions={countryOptions} />
        </div>

        <div className="visible-lg-block col-lg-1" />

        <div className="col-sm-6 col-md-6 col-lg-5">
          <OrderShippingService />

          <PackageDetailsDomestic />
          <PackageDetailsInternational countryOptions={countryOptions} ref={packageDetailsInternationalRef} />

          <div className="row mb-3">
            <div className="col-sm-12 d-flex justify-content-end">
              <SaveAndNext onCancel={onCancel} saveBtnText={saveBtnText} />
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-sm-12 col-lg-11">
          <PackageContents />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-sm-4 col-lg-5">
          <EparcelsTracking initialValues={initialValues} />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6 col-md-6 col-lg-5">
          <OrderDetails />
        </div>

        <div className="visible-lg-block col-lg-1" />

        <div className="col-sm-6 col-md-6 col-lg-5">
          <fieldset>
            <legend>Order History</legend>
          </fieldset>
        </div>
      </div>
    </FormikProvider>
  )
}

export default OrderDetailsForm
