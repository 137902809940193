import { ReactNode, InputHTMLAttributes, ChangeEvent, FocusEvent } from 'react'
import _ from 'lodash'

import FieldLayout, { FieldLayoutProps } from './field-layout'
import { fieldLayoutKeys } from './field-layout-keys'
import omitProps from '~/lib/omit-props'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface BsCheckboxProps<V = any, FormValues = any>
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'form' | 'title'>,
    Omit<FieldLayoutProps<V, FormValues>, 'children'> {
  title?: string | ReactNode
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BsCheckbox = <V = any, FormValues = any>(props: BsCheckboxProps<V, FormValues>) => {
  const {
    field: { value, onChange: formikOnChange, onBlur: formikOnBlur, ...otherFields },
    label = null,
    title,
    onChange: customOnChange,
    onBlur: customOnBlur,
    ...allRest
  } = props
  const rest = omitProps<Omit<BsCheckboxProps<V, FormValues>, 'title'>, keyof FieldLayoutProps<V, FormValues>>(
    allRest,
    fieldLayoutKeys
  )
  const { name } = otherFields

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    formikOnChange(e)
    customOnChange?.(e)
  }

  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    formikOnBlur(e)
    customOnBlur?.(e)
  }

  return (
    <FieldLayout {...props} label={label}>
      {({ id, value }) => (
        <div className="form-check">
          <input name={name} value="0" type="hidden" />
          <input
            id={id}
            type="checkbox"
            className="form-check-input"
            value={(value as string | number | readonly string[] | undefined) ?? ''}
            checked={!!value}
            onChange={onChange}
            onBlur={onBlur}
            {...otherFields} // name, onBlur, onChange, checked, multiple
            {...rest}
          />
          <label className="form-check-label" htmlFor={id}>
            {title || _.startCase(name)}
          </label>
        </div>
      )}
    </FieldLayout>
  )
}

export default BsCheckbox
