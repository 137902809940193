import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import PageHeading from '~/components/page-heading'
import Spinner from '~/components/spinner-two'
import ServerError from '~/components/server-error'
import ManifestsGrid from '~/containers/manifests-grid'
import { AppDispatch } from '~/config/store'
import { fetchList } from '~/api/manifests'
import { setList } from '~/reducers/manifests-reducer'

const ManifestsPage = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [loading, setLoading] = useState(true)
  const [serverError, setServerError] = useState(null)
  const clearServerError = () => setServerError(null)

  useEffect(() => {
    fetchList()
      .then(data => {
        if (data) {
          dispatch(setList(data))
        }
      })
      .catch(setServerError)
      .finally(() => setLoading(false))
  }, [dispatch])

  return (
    <div>
      <PageHeading title="Manifests" />
      {loading && <Spinner />}
      {!loading && serverError && <ServerError clearServerError={clearServerError} serverError={serverError} />}
      <ManifestsGrid />
    </div>
  )
}

export default ManifestsPage
