import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import { transformForBackend, transformForFrontend } from '~/containers/pos-page/utils'

import Order from '~/types/order'
import { PosFormValues } from '~/containers/pos-page/form'
import { OrderDefaults } from '~/reducers/pos-reducer'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getOrderDefaults = createAsyncThunk<OrderDefaults, void, { rejectValue: any }>(
  'posPage/getOrderDefaults',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<OrderDefaults>('/api/orders/defaults')
      return response.data
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchOrder = createAsyncThunk<PosFormValues, number, { rejectValue: any }>(
  'posPage/fetchOrder',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get<Order>(`/api/orders/${id}`)
      return transformForFrontend(response.data)
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createOrder = createAsyncThunk<Order, PosFormValues, { rejectValue: any }>(
  'posPage/createOrder',
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.post<Order>('/api/orders', {
        order: transformForBackend(values)
      })
      return response.data
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateOrder = createAsyncThunk<Order, PosFormValues, { rejectValue: any }>(
  'posPage/updateOrder',
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.patch<Order>(`/api/orders/${values.id}`, {
        order: transformForBackend(values)
      })
      return response.data
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)
