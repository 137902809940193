import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import { Field, useFormikContext } from 'formik'
import _ from 'lodash'
import { isRejectedWithValue } from '@reduxjs/toolkit'

import { BsInput } from '~/components/form-inputs/formik/inputs-decorated'
import { ProductFormValues } from '../use-product-form'
import { AppDispatch } from '~/config/store'
import { checkIfProductExists } from '~/async-actions/products-async-actions'
import useAuth from '~/contexts/useAuth'
import { addCustomError, removeCustomError } from '~/lib/formik-utils'

const ProductDetails = () => {
  const { values: formValues, setFieldValue, setErrors, errors } = useFormikContext<ProductFormValues>()
  const { userProfile } = useAuth()
  const dispatch = useDispatch<AppDispatch>()
  const [originalCustomSku, setOriginalCustomSku] = useState<string | undefined>(undefined)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const saveOriginalCustomSku = (e: any) => {
    setOriginalCustomSku(e.target.value)
  }

  const copyCustomSkuToName = () => {
    if (!formValues?.name || formValues?.name === originalCustomSku) {
      setFieldValue('name', formValues?.customSku)
    }
  }

  const onCustomSkuChange = useDebouncedCallback(
    useCallback(
      (customSku: string, id?: number) => {
        // NOTE: this will also update `productExistsModal' in redux state.
        // But we don't need to use it here, as we just want to add a Yup error
        dispatch(checkIfProductExists({ customSku, productId: id })).then(data => {
          if (isRejectedWithValue(data)) {
            // Handle the error case here
          } else if (data.payload) {
            setOriginalCustomSku(customSku)
            addCustomError(errors, setErrors, 'customSku', 'already exists')
          } else {
            removeCustomError(errors, setErrors, 'customSku', 'already exists')
          }
        })
      },
      [dispatch, setErrors, errors]
    ),
    700,
    { trailing: true }
  )

  const customSkuReadOnly = formValues.id && userProfile && userProfile.kind !== 'owner'

  return (
    <fieldset>
      <legend>Product Details</legend>

      <Field
        required
        name="customSku"
        label="SKU"
        component={BsInput}
        onFocus={saveOriginalCustomSku}
        onBlur={copyCustomSkuToName}
        readOnly={customSkuReadOnly}
        disabled={customSkuReadOnly}
        displayMode={customSkuReadOnly ? 'plain' : undefined}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={(e: any) => onCustomSkuChange(e.target.value, formValues.id)}
      />

      <Field required name="name" label="POS Name" component={BsInput} />
      <Field required name="shelfLocation" component={BsInput} />
    </fieldset>
  )
}

export default ProductDetails
