import { Route, Routes } from 'react-router-dom'
import { useLocation } from 'react-router'

import useAuth from '~/contexts/useAuth'
import useSettings from '~/contexts/useSettings'
import sections from './sections'
import TitleBar from './title-bar'
import IconsView from './icons-view'
import LoadError from './load-error'

export const SettingsIndexPage = () => {
  const location = useLocation()
  const { settings, settingsPending } = useSettings()
  const { userProfile } = useAuth()
  const showLoadError = !settingsPending && !settings

  return showLoadError ? (
    <LoadError />
  ) : (
    <div>
      <TitleBar pagePath={location.pathname} />
      <Routes>
        {userProfile &&
          sections
            .filter(s => s.userKind === undefined || s.userKind.includes(userProfile.kind))
            .map(s => {
              const Section = s.component
              return <Route key={s.path} path={`${s.path}/*`} element={<Section />} />
            })}
        <Route path="/" element={<IconsView />} />
      </Routes>
    </div>
  )
}

export default SettingsIndexPage
