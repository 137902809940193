import PriorityItem, { PriorityItemTypeEnum } from '~/types/priority-item'
import { fetchRecord } from '~/async-actions/priority-items-async-actions'

export const formConfig = {
  fetchRecord,
  recordPath: 'priorityItems.recordToEdit'
}

export const columnsConfig = [
  {
    attr: 'item.coverImage',
    heading: 'Image',
    locked: true,
    render: (record: PriorityItem) =>
      record.item && record.item.coverImageUrl ? (
        <img src={record.item.coverImageUrl} alt={`item${record.id}CoverImage`} width="100" />
      ) : (
        '--'
      )
  },
  {
    attr: 'item.name',
    heading: 'Name',
    locked: true,
    render: (record: PriorityItem) => {
      if (!record.item) return ''
      if (record.itemType === PriorityItemTypeEnum.PRODUCT && 'mobileName' in record.item)
        return record.item.mobileName ? record.item.mobileName : record.item.name
      return record.item.name
    }
  },
  {
    attr: 'itemType',
    heading: 'Type',
    locked: true
  },
  {
    attr: 'item.featured',
    heading: 'Featured?',
    locked: true,
    render: (record: PriorityItem) => (record.item && record.item.featured ? 'yes' : 'no')
  },
  {
    attr: 'item.mobile',
    heading: 'mobile product?',
    locked: true,
    render: (record: PriorityItem) =>
      record.item && record.itemType === PriorityItemTypeEnum.PRODUCT && 'mobile' in record.item
        ? record.item.mobile
          ? 'yes'
          : 'no'
        : ''
  },
  {
    attr: 'active',
    locked: true,
    render: (record: PriorityItem) => (record.active ? 'Yes' : 'No')
  }
]
