import styled from 'styled-components'
import { useFormik, FormikProvider } from 'formik'
import { Alert } from 'react-bootstrap'

import useSubmitHandler from '~/containers/settings/submit-handler'
import { ActionBar } from '~/components/forms'
import { BaseErrors, FlexibleInputGroup } from '~/components/forms/formik'
import validations from './validations'
import useSettings from '~/contexts/useSettings'
import {
  EparcelsTrackingPreferences,
  AccountSettingKeys,
  EparcelsTrackingSendInvitesEnum,
  EparcelsTrackingUpdateTrackingEnum
} from '~/types/account-settings'
import { SettingsProviderState } from '~/contexts/settings'

const sendInvitesOptions = [
  { label: 'No', value: EparcelsTrackingSendInvitesEnum.NO },
  { label: 'Only if not already invited', value: EparcelsTrackingSendInvitesEnum.UNINVITED_ONLY },
  { label: 'Always', value: EparcelsTrackingSendInvitesEnum.ALWAYS }
]

const updateTrackingOptions = [
  { label: 'No', value: EparcelsTrackingUpdateTrackingEnum.NO },
  { label: 'Always', value: EparcelsTrackingUpdateTrackingEnum.ALWAYS }
]

const Container = styled.div`
  width: 600px;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

interface EparcelsTrackingSettingsFormProps {
  redirectPath: string
  initialValues: EparcelsTrackingPreferences
  settingsKey: AccountSettingKeys
  updateAction: SettingsProviderState['updateAction']
}

const EparcelsTrackingSettingsForm = (props: EparcelsTrackingSettingsFormProps) => {
  const { redirectPath, initialValues } = props
  const { settings } = useSettings()
  const { submitHandler } = useSubmitHandler()
  const formik = useFormik({
    initialValues,
    validationSchema: validations,
    onSubmit: (values, helpers) =>
      submitHandler<EparcelsTrackingPreferences, EparcelsTrackingSettingsFormProps>(values, helpers, props)
  })

  const { getFieldProps, isSubmitting, handleSubmit } = formik

  return (
    <FormikProvider value={formik}>
      <Wrapper>
        <Container>
          <Alert variant="light">
            <p>
              When importing eParcels CSV tracking information, Postlabels can send this data to eBay or send to
              customers via email notifications.
            </p>
            <p>You can control how that works here:</p>
          </Alert>

          {settings && !settings.ebayUserAccessToken?.accessToken && (
            <Alert variant="warning">
              <p>
                Warning! eBay is not connected. We cannot add tracking numbers to eBay. You may correct this from the
                eBay settings page.
              </p>
            </Alert>
          )}

          <BaseErrors />

          <FlexibleInputGroup
            type="select"
            options={sendInvitesOptions}
            label="Send mobile app invites?"
            hint="Send invites (with tracking info) for the mobile app and/or shopfront, when importing eParcels tracking info"
            {...getFieldProps('sendInvites')}
          />
          <FlexibleInputGroup
            type="select"
            options={updateTrackingOptions}
            label="Update eBay with tracking?"
            hint="Update eBay with tracking information from eParcels"
            {...getFieldProps('updateTracking')}
          />

          <ActionBar isSubmitting={isSubmitting} redirectPath={redirectPath} onSubmit={handleSubmit} />
        </Container>
      </Wrapper>
    </FormikProvider>
  )
}

export default EparcelsTrackingSettingsForm
