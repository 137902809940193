import { object, string, number } from 'yup'
import '~/lib/yup'
import _ from 'lodash'

import { IntlShippingServiceOption } from '~/types/account-settings'
import AuPostcode from '~/types/au-postcode'

export interface CreateOrderSchemaProps {
  intlShippingServiceOptions: IntlShippingServiceOption[]
  auPostcodes: AuPostcode[]
}

const createOrderSchema = ({ intlShippingServiceOptions, auPostcodes }: CreateOrderSchemaProps) => {
  const OrderSchema = object().shape({
    packageLength: number().integer().requiredIfDependenciesPresent(['isDomestic']).nullable().min(0),
    packageWidth: number().integer().requiredIfDependenciesPresent(['isDomestic']).nullable().min(0),
    packageHeight: number().integer().requiredIfDependenciesPresent(['isDomestic']).nullable().min(0),
    packageWeight: number().required().requiredIfDependenciesPresent(['isDomestic']).nullable().min(0).max(5),
    shippingAddress1: string().required().max(40).notMatches(/[?]+/, {
      excludeEmptyString: true,
      message: 'contains invalid characters'
    }),
    shippingAddress2: string().nullable().notRequired().max(40).notMatches(/[?]+/, {
      excludeEmptyString: true,
      message: 'contains invalid characters'
    }),
    shippingAddress3: string().nullable().notRequired().max(40).notMatches(/[?]+/, {
      excludeEmptyString: true,
      message: 'contains invalid characters'
    }),
    shippingAddress4: string().nullable().notRequired().max(40).notMatches(/[?]+/, {
      excludeEmptyString: true,
      message: 'contains invalid characters'
    }),
    shippingCity: string().required().max(50).notMatches(/[?]+/, {
      excludeEmptyString: true,
      message: 'contains invalid characters'
    }),
    shippingContactName: string().required().max(35).notMatches(/[?]+/, {
      excludeEmptyString: true,
      message: 'contains invalid characters'
    }),
    shippingContactPhone: string().nullable().notRequired().max(20),
    shippingCountry: string().required(),
    shippingPostcode: string()
      .required()
      .isInCollection(auPostcodes, 'Invalid postcode', { path: 'postcode', ifDependenciesPresent: ['isDomestic'] }),
    shippingService: string().required(),
    shippingState: string().nullable().max(10).requiredIfDependenciesPresent(['isDomestic']),
    customsDescription: string().nullable().max(50).requiredIfDependenciesBlank(['isDomestic']),
    customsTariffNumber: string().nullable().notRequired().max(12),
    customsClassification: string().nullable().requiredIfDependenciesBlank(['isDomestic']),
    customsOriginCountryCode: string().nullable().requiredIfDependenciesBlank(['isDomestic']),
    customsDeclaredValue: string().nullable().requiredIfDependenciesBlank(['isDomestic']),
    customsClassificationExplanation: string()
      .nullable()
      .notRequired()
      .test('customsClassificationExplanation', 'Required', function (value) {
        const order = this.from?.[0]?.value
        if (order.isDomestic && order.customsClassification === 'other') {
          return !!value
        }
        return true
      }),
    insuranceCost: number()
      .nullable()
      .notRequired()
      .test('insuranceCost', 'Must be less than ${insuranceCostMax}', function (value) {
        let insuranceCostMax = 0
        const shippingService = this.from?.[0]?.value?.shippingService

        if (intlShippingServiceOptions) {
          const selectedOption = _.find(intlShippingServiceOptions, ['id', shippingService])
          insuranceCostMax = selectedOption ? selectedOption.insuranceCostMax : 0
        }

        if (insuranceCostMax > 0) {
          // Check if the value is a number and greater than 100
          if (value != null && !isNaN(value) && value > insuranceCostMax) {
            return this.createError({
              path: this.path,
              message: `Must be less than ${insuranceCostMax}`,
              params: { insuranceCostMax: insuranceCostMax }
            })
          }
        }

        return true
      })
  })

  return OrderSchema
}

export default createOrderSchema
