import styledNormalize from 'styled-normalize'
import theme from '~/styles/themes/current'

import './main.scss'

const globalStyles = `
  ${styledNormalize}

  @import url('https://fonts.googleapis.com/css?family=Roboto');
  @import url('https://fonts.googleapis.com/css?family=Montserrat');
  @import url('https://fonts.googleapis.com/css?family=Allura');
  @import url('https://fonts.googleapis.com/css?family=Homenaje');

  html {
  }

  html,
  body,
  html {
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.6;
  }
  *, *:before, *:after {
    box-sizing: border-box;
  }
  body {
    background-color: ${theme.colors.white};
    color: ${theme.colors.gray.darker};
    font-family: ${theme.fonts.body};
    -webkit-font-smoothing: antialiased;
    font-feature-settings: "ss01";
  }

  #root {
  }

  /* Typography */
  h1, h2, h3, h4, h5, h6 {
    font-family: ${theme.fonts.headings};
    margin-top: 0;
  }

  p, ul, ol, li, div {
    font-family: ${theme.fonts.body};
    margin-top: 0;
  }

  small {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  a {
    color: ${theme.colors.primary.base};
    transition: color 150ms ease;
    text-decoration: none;

    :hover {
      color: ${theme.colors.primary.light};
    }
  }

  code, pre {
    font-family: ${theme.fonts.monospace};
  }
`

export default globalStyles
