import _ from 'lodash'

import { CheckboxTh } from './checkboxes'
import { GridConfig, ModelWithId, GridColumn } from './create-config'

const getHeading = (column: { heading?: string; attr?: string }) => column.heading || _.startCase(column.attr)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface HeadingRowProps<Model extends ModelWithId = any, RV = object> {
  visibleColumns: GridColumn<Model, RV>[]
  config?: GridConfig<Model, RV>
  className?: string
  toggleSelectAll: () => void
  isAllSelected: boolean
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const HeadingRow = <Model extends ModelWithId = any, RV = object>({
  className,
  config,
  visibleColumns,
  toggleSelectAll,
  isAllSelected
}: HeadingRowProps<Model, RV>) => (
  <tr className={className}>
    {config?.checkboxesColumn && <CheckboxTh checked={isAllSelected} onChange={toggleSelectAll} />}
    {_.map(visibleColumns, column => (
      <th key={column.attr}>{getHeading(column)}</th>
    ))}
    {config?.actions && <th>&nbsp;</th>}
  </tr>
)

export default HeadingRow
