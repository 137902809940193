import { useState, useEffect } from 'react'
import { FormikHelpers } from 'formik'
import { useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { animateScroll } from 'react-scroll'
import { AxiosError } from 'axios'

import SubHeader from './sub-header'
import * as shipmentsApi from '~/api/shipments'
import Spinner from '~/components/spinner-two'
import { handleFormErrorResponse } from '~/lib/formik-utils'
import ServerError from './server-error'
import Form from './form'
import AuPostShipment from '~/types/au-post-shipment'

interface RouteParams {
  shipmentId: string
}

const ShipmentFormPage = () => {
  const navigate = useNavigate()
  const params = useParams<keyof RouteParams>()
  const shipmentId = parseInt(params?.shipmentId || '', 10)
  const [shipment, setShipment] = useState<AuPostShipment>()
  const [loading, setLoading] = useState(true)
  const [serverError, setServerError] = useState<AxiosError>()

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    shipmentsApi
      .fetchRecord(shipmentId)
      .then(data => {
        if (data) {
          setShipment(data)
        }
      })
      .catch(setServerError)
      .finally(() => setLoading(false))
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  const onSubmit = (values: AuPostShipment, formikBag: FormikHelpers<AuPostShipment>) => {
    const { setSubmitting, setErrors } = formikBag
    setSubmitting(true)

    const onSuccess = () => {
      if (shipment == null) return
      setSubmitting(false)
      const redirectPath = `/manifests/${shipment.manifestId}`
      navigate(redirectPath)
      toast.success('Success!')
      animateScroll.scrollToTop()
    }

    const onError = (e: AxiosError) => {
      setSubmitting(false)
      setServerError(e)
      handleFormErrorResponse(e.response, setErrors)
      toast.error('Sorry, there were errors processing the form. Please review!')
      animateScroll.scrollToTop()
    }

    shipmentsApi.updateRecord(shipmentId, values).then(onSuccess).catch(onError)
  }

  const onClickBack = () => {
    console.log('[INFO]', 'onClickBack')
    navigate(-1)
  }

  const redirectPath = `/manifests/${shipment?.manifestId}`

  if (!shipment) {
    return <h2>Shipment Not Found</h2>
  }

  return (
    <div>
      <SubHeader
        title="Edit Shipment"
        renderTags={() => (
          <div>{shipment?.isCombined === true && <span className="label label-info">Combined shipment</span>}</div>
        )}
      />
      {loading && <Spinner />}
      {!loading && serverError && <ServerError serverError={serverError} onDismiss={() => setServerError(undefined)} />}
      {!loading && !serverError && (
        <Form redirectPath={redirectPath} onClickSubmit={onSubmit} onClickBack={onClickBack} model={shipment} />
      )}
    </div>
  )
}

export default ShipmentFormPage
