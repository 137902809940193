import styled from 'styled-components'

import Spinner from '~/components/spinner-two'

const Wrapper = styled.div``

const Div1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const Div2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
`

const JobInProgress = () => (
  <Wrapper>
    <Div1>
      <Spinner />
    </Div1>

    <Div2>
      <span>A job is in progress. Please wait until it is finished</span>
    </Div2>
  </Wrapper>
)

export default JobInProgress
