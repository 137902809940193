import { useState, useEffect, useCallback } from 'react'
import moment from 'moment'
import _ from 'lodash'
import { default as Calendar } from 'react-calendar'
import { Value, Range } from 'react-calendar/dist/cjs/shared/types'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { Button } from '~/components/forms'
import Icon from '~/components/icon'

interface DateRangePickerProps {
  id?: string
  onChange: (dateRange: { createdAt: string }) => void
}

const DateRangePicker = ({ id, onChange }: DateRangePickerProps) => {
  const dateFormat = 'DD/MM/YYYY'

  const [dateRange, setDateRange] = useState<Range<Date | null>>([moment().toDate(), moment().add(1, 'd').toDate()])
  const [menuIcon, setMenuIcon] = useState('chevron-down')
  const [menu, setMenu] = useState(false)

  const showCurrentDates = useCallback(() => {
    if (dateRange.length !== 2) return ''
    return `${moment(dateRange[0]).format(dateFormat)} to ${moment(dateRange[1]).format(dateFormat)}`
  }, [dateRange])

  const toggleMenu = () => {
    if (menu === false) {
      setMenu(true)
      setMenuIcon('chevron-up')
    } else if (menu === true) {
      setMenu(false)
      setMenuIcon('chevron-down')
    }
  }

  const handleDateRangeChange = useCallback(() => {
    if (dateRange.length !== 2) return

    const normalizedDateRange = _.map(dateRange, date => encodeURIComponent(moment(date).format()))

    onChange({ createdAt: `dates:${normalizedDateRange}` })
  }, [dateRange, onChange])

  const handleDatePickerChange = useCallback(
    (dateRange: Value) => {
      if (Array.isArray(dateRange)) {
        setDateRange(dateRange)
      }
      handleDateRangeChange()
    },
    [handleDateRangeChange]
  )

  useEffect(() => {
    handleDateRangeChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Button id={id} variant="light" onClick={() => toggleMenu()}>
        Dates {showCurrentDates()}&nbsp;&nbsp;
        <Icon icon={menuIcon as IconProp} />
      </Button>

      {menu && (
        <Calendar
          selectRange
          locale="en-AU"
          value={dateRange}
          onChange={dateRange => handleDatePickerChange(dateRange)}
        />
      )}
    </>
  )
}

export default DateRangePicker
