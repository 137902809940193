import _ from 'lodash'
import { useFormikContext, Field } from 'formik'
import { useSelector } from 'react-redux'

import { OrderFormValues } from './use-order-details'
import { BsInput, BsSelectInput } from '~/components/form-inputs/formik/inputs-decorated'
import { AppState } from '~/config/store'

const domesticShippingServiceOptions = [
  { value: '', label: 'Please select' },
  { value: 'regular_eparcel', label: 'Regular eParcel' },
  { value: 'express_eparcel', label: 'Express eParcel' }
]

const OrderShippingService = () => {
  const { values: formValues } = useFormikContext<OrderFormValues>()
  const transientFormData = useSelector((state: AppState) => state.transientFormData)

  const { mainSalesRecord, shippingService, isDomestic } = formValues
  const isInternational = !isDomestic

  const { intlShippingServiceOptions } = transientFormData.orderDetailsForm
  return (
    <fieldset>
      {formValues.mainSalesRecord && (
        <Field
          label="Buyer Requested"
          component={BsInput}
          value={mainSalesRecord?.shippingService}
          name={
            'buyer-shipping-service' +
            (mainSalesRecord?.shippingService != null &&
            mainSalesRecord?.shippingService.match(/express/i) &&
            !shippingService?.match(/express/i) &&
            !shippingService?.match(/eci/i)
              ? '-highlighted'
              : '')
          }
          readOnly
          disabled
          displayMode="plain"
        />
      )}

      {isDomestic && (
        <Field
          required
          name="shippingService"
          label="Shipping Service"
          component={BsSelectInput}
          options={domesticShippingServiceOptions}
        />
      )}

      {isInternational && (
        <Field
          required
          name="shippingService"
          label="Shipping Service"
          component={BsSelectInput}
          options={intlShippingServiceOptions}
        />
      )}
    </fieldset>
  )
}

export default OrderShippingService
