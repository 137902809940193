import { useSelector, useDispatch } from 'react-redux'

import ImportContainer from '~/components/csv-imports/import-container'
import ImportFailed from '~/components/csv-imports/import-failed'
import ImportCompleted from '~/components/csv-imports/import-completed'
import ImportForm from '~/components/csv-imports/import-form'
import ImportInProgress from '~/components/csv-imports/import-in-progress'
import InfoTable from './info-table'
import EbayWarning from './ebay-warning'
import { setLastCsvImport, setFileUploading } from '~/reducers/eparcels-tracking-reducer'
import { AppState, AppDispatch } from '~/config/store'
import EparcelsTrackingCsvImport from '~/types/eparcels-tracking'
import CsvImport from '~/types/csv-import'

const TrackingPage = () => {
  const fileUploading = useSelector((state: AppState) => state.trackingPage.fileUploading)
  const lastCsvImport = useSelector((state: AppState) => state.trackingPage.lastCsvImport)
  const dispatch = useDispatch<AppDispatch>()
  const inProgress = fileUploading || lastCsvImport?.pending

  return (
    <ImportContainer<EparcelsTrackingCsvImport>
      lastCsvImport={lastCsvImport}
      onUpdate={async csvImport => {
        dispatch(setLastCsvImport(csvImport))
      }}
      resourcePath="eparcels_csv_imports">
      <h1>Track Orders</h1>

      {inProgress && <ImportInProgress lastCsvImport={lastCsvImport as CsvImport} />}

      {!inProgress && (
        <>
          {lastCsvImport != null && (
            <>
              <ImportCompleted lastCsvImport={lastCsvImport} />

              {lastCsvImport.summary && (
                <InfoTable lastCsvImport={lastCsvImport} infoTable={lastCsvImport.summary.infoTable} />
              )}

              <ImportFailed lastCsvImport={lastCsvImport} />
            </>
          )}

          <EbayWarning />

          <ImportForm<EparcelsTrackingCsvImport>
            title="Import eParcels CSV"
            resourcePath="eparcels_csv_imports"
            paramName="eparcels_csv_import"
            setLastCsvImport={data => (data ? dispatch(setLastCsvImport(data)) : undefined)}
            setFileUploading={uploading => dispatch(setFileUploading(uploading))}
          />
        </>
      )}
    </ImportContainer>
  )
}

export default TrackingPage
