import styled from 'styled-components'
import Spinner from '~/components/spinner'
import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'

import ShopfrontPage from '~/types/shopfront-page'
import { Button } from '~/components/forms'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`
const Container = styled.div`
  width: 600px;
`
interface PreviewProps {
  item: ShopfrontPage
  pending: boolean
}
const Preview = ({ pending, item }: PreviewProps) =>
  pending ? (
    <Spinner />
  ) : (
    <Wrapper>
      <Container>
        <h3>{item.title}</h3>
        <ReactMarkdown>{item.content}</ReactMarkdown>

        <hr />
        <Link to="/settings/shopfront_pages">
          <Button>Back</Button>
        </Link>
      </Container>
    </Wrapper>
  )

export default Preview
