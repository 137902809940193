import { useState, useEffect } from 'react'
import axios from 'axios'
import _ from 'lodash'
import { FlexibleInputGroup } from '~/components/forms/formik'
import { Option } from '~/components/forms/formik/input-group'

interface CategoriesFieldProps {
  name?: string
  className?: boolean
}

const CategoriesField = ({ name = 'categories', className = false }: CategoriesFieldProps) => {
  const [categoriesLoading, setCategoriesLoading] = useState(false)
  const [categoriesOptions, setCategoriesOptions] = useState<Option[]>([])

  useEffect(() => {
    loadCategoriesOptions()
  }, [])

  const loadCategoriesOptions = async () => {
    setCategoriesLoading(true)

    try {
      const response = await axios.get<string[]>('/api/products/categories')
      const options: Option[] = _.map(response.data, (entry: string) => ({
        label: entry,
        value: entry
      }))

      setCategoriesOptions(options)
    } catch (error) {
      console.error('Error loading categories:', error)
    } finally {
      setCategoriesLoading(false)
    }
  }

  return (
    <FlexibleInputGroup
      isMulti
      type="select"
      name={name}
      className={className}
      options={categoriesOptions}
      isLoading={categoriesLoading}
    />
  )
}

export default CategoriesField
