import { useState, ChangeEvent } from 'react'
import styled from 'styled-components'
import TagUploadedImages from './tag-uploaded-images'

const ColGroup = styled.div`
  @media (min-width: 800px) {
    display: flex;
  }
`

const ColA = styled.div`
  align-items: baseline;
  flex-basis: 300px;
  flex-grow: 1;
`
const ColB = styled.div`
  flex-basis: 300px;
  flex-shrink: 1;

  @media (max-width: 799px) {
    margin-top: 20px;
  }
`

const H3 = styled.h3`
  margin-bottom: 10px;
  margin-top: 0;
`
const RadioBtnDiv = styled.div`
  padding-left: 7px;
  vertical-align: top;
`
interface UploadImagesInstructionProps {
  uploadedTags: (tags: string) => void
  optionsChanged: (options: { addExistingImage: boolean }) => void
}
const UploadImagesInstruction = ({ uploadedTags, optionsChanged }: UploadImagesInstructionProps) => {
  const [selectedOption, setSelectedOption] = useState('option1')

  const handleOptionChange = (changeEvent: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(changeEvent.target.value)
    optionsChanged({
      addExistingImage: changeEvent.target.value === 'option1'
    })
  }

  return (
    <div>
      <H3>Add Photos to Products in Bulk</H3>
      <p>
        To upload, name your photos in <code>%SKU%.png</code> or <code>%SKU%__%something%.png</code> format, we will
        then associate those photos with the matching products.
      </p>

      <ColGroup>
        <ColA>
          <RadioBtnDiv className="radio">
            <label>
              <input
                type="radio"
                value="option1"
                checked={selectedOption === 'option1'}
                onChange={handleOptionChange}
              />
              Add images to the products, those photos with the matching
            </label>
          </RadioBtnDiv>
          <RadioBtnDiv className="radio">
            <label>
              <input
                type="radio"
                value="option2"
                checked={selectedOption === 'option2'}
                onChange={handleOptionChange}
              />
              Delete any existing images and replace them with new images
            </label>
          </RadioBtnDiv>
        </ColA>

        <ColB>
          <TagUploadedImages uploadedTags={uploadedTags} />
        </ColB>
      </ColGroup>
    </div>
  )
}

export default UploadImagesInstruction
