import { Alert } from 'react-bootstrap'
import Spinner from '~/components/spinner'

const Pending = () => (
  <Alert variant="warning">
    <div>A pdf file is being imported.</div>
    <Spinner />
  </Alert>
)

export default Pending
