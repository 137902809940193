import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface GridState {
  selectedIdsOf: { [recordType: string]: number[] }
}

const initialState: GridState = {
  selectedIdsOf: {}
}

export interface ChangeSelectedIdsOfPayload {
  recordType: string
  selectedIds: number[]
}

const gridSlice = createSlice({
  name: 'grid',
  initialState,
  reducers: {
    changeSelectedIds: (state, action: PayloadAction<ChangeSelectedIdsOfPayload>) => {
      const { recordType, selectedIds } = action.payload
      state.selectedIdsOf[recordType] = selectedIds
    }
  }
})

export const { changeSelectedIds } = gridSlice.actions

export default gridSlice.reducer
