import { useRef, useEffect } from 'react'
import type { XYCoord, Identifier } from 'dnd-core'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useDrag, useDrop } from 'react-dnd'
import styled from 'styled-components'

import { ProductImage } from '~/types/product'
import User from '~/types/user'
import GridItem from './grid-item'

const ItemWrapper = styled.div``

interface DraggableItemProps {
  index: number
  type: string
}

interface SortableListProps {
  index: number
  onSortEnd?: (dragIndex: number, hoverIndex: number) => void
  image: ProductImage
  onRemoveImage: (image: ProductImage, imageField: string) => void
  isAccountMobileEnabled: boolean
  userProfile?: User
}
const SortableList: React.FC<SortableListProps> = ({
  index,
  onSortEnd,
  image,
  onRemoveImage,
  isAccountMobileEnabled,
  userProfile
}) => {
  const ref = useRef<HTMLDivElement>(null)

  const [{ handlerId }, drop] = useDrop<DraggableItemProps, void, { handlerId: Identifier | null }>({
    accept: 'div',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover(item: DraggableItemProps, monitor) {
      if (!ref.current) return

      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) return

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleX = hoverBoundingRect && (hoverBoundingRect.right - hoverBoundingRect.left) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientX = hoverBoundingRect && (clientOffset as XYCoord).x - hoverBoundingRect.left
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) return
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) return

      onSortEnd?.(dragIndex, hoverIndex)
      item.index = hoverIndex
    }
  })

  const [dragStyles, drag, preview] = useDrag({
    type: 'div',
    item: () => {
      return { index }
    },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  })

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview])

  drag(drop(ref))

  if (image._destroy) {
    return null
  }

  const imageField = `images[${index}]`
  return (
    <ItemWrapper ref={ref} data-handler-id={handlerId} style={dragStyles}>
      <GridItem
        key={`item-${index}`}
        imageField={imageField}
        image={image}
        isAccountMobileEnabled={isAccountMobileEnabled}
        userProfile={userProfile}
        onRemove={onRemoveImage}
      />
    </ItemWrapper>
  )
}

export default SortableList
