import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import Root from './root'

/*
 * NOTE: <StrictMode> deliberately causes double-rendering of pages in development, to help highlight potential issues with sideeffects.
 */

createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <Root />
  </StrictMode>
)
