import { Field } from 'formik'
import styled from 'styled-components'

import { BsInput, ReactDatePicker } from '~/components/form-inputs/formik/inputs-decorated'

const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

const Label = styled.label`
  margin-right: 10px;
  font-weight: 600;
  flex-basis: flex-start;
  white-space: nowrap;
  min-width: 80px;
  text-align: right;
`

const OrderHeaderFields = () => (
  <div className="d-flex flex-column gap-2">
    <Box>
      <Label htmlFor="orderReference">Order No</Label>
      <Field id="orderReference" name="reference" component={BsInput} label={null} readOnly disabled />
    </Box>
    <Box>
      <Label htmlFor="orderDate">Order Date</Label>
      <Field id="orderDate" name="orderDate" component={ReactDatePicker} label={null} />
    </Box>
  </div>
)

export default OrderHeaderFields
