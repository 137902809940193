import styled from 'styled-components'

import { Button } from '~/components/forms'

export const NotFound = () => <h1>404 Not Found</h1>

export const ServerError = () => <h1>500 Internal Server Error</h1>

const Wrapper = styled.div`
  padding: 0 2rem;
`

export const FallbackError = () => (
  <Wrapper>
    <h1>System Error</h1>
    <p>
      <strong>Uh oh, looks like something went wrong!</strong>
    </p>
    <p>Please contact support if the problem persists.</p>

    <div>
      <Button
        variant="light"
        onClick={() => {
          window.location.href = '/'
        }}>
        Restart Application
      </Button>
    </div>
  </Wrapper>
)
