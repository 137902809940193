import migrate, { Migration } from '~/lib/utils/migrate'

const clearData = {
  version: new Date('2017-05-31'),
  up: () => {
    localStorage.removeItem('dbVersion')
    localStorage.removeItem('state')
    localStorage.removeItem('persistableData')
  }
} as Migration

migrate([clearData])
