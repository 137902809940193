import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Navigate, useLocation, useParams } from 'react-router-dom'

import LinkButton from '~/components/forms/link-button'
import Spinner from '~/components/spinner'
import { resetPasswordRequest } from '~/api/auth'
import useAuth from '~/contexts/useAuth'
import Form from './form'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #5e6673;
`

const InfoBox = styled.div`
  background-color: #fff;
  border: 1px solid #888;
  padding: 20px 40px;
  margin-top: 40px;
  height: fit-content;
`

interface MatchParams {
  token: string
}

const ResetPasswordPage = () => {
  const { userProfile } = useAuth()
  const [pending, setPending] = useState(true)
  const [tokenValid, setTokenValid] = useState(false)
  const location = useLocation()
  const { token } = useParams<keyof MatchParams>()

  useEffect(() => {
    if (token) {
      resetPasswordRequest({ token, dryRun: true })
        .then(() => {
          setPending(false)
          setTokenValid(true)
        })
        .catch(() => {
          setPending(false)
          setTokenValid(false)
        })
    }
  }, [token])

  const { from } = (location.state || { from: { pathname: '/' } }) as { from: { pathname: string } }

  if (userProfile) {
    return <Navigate to={from} />
  }

  return (
    <Wrapper>
      {pending && <Spinner />}
      {!pending && tokenValid && token && <Form token={token} />}
      {!pending && !tokenValid && (
        <InfoBox>
          <p>Token is Expired or Invalid.</p>
          <LinkButton to="/signin">Signin</LinkButton>
        </InfoBox>
      )}
    </Wrapper>
  )
}

export default ResetPasswordPage
