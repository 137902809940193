import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Not sure this is even used?

export interface ImportedOrdersState {
  recordTypeFilter: string
}

const initialState: ImportedOrdersState = {
  recordTypeFilter: 'all'
}

const importedOrdersSlice = createSlice({
  name: 'importedOrders',
  initialState,
  reducers: {
    changeRecordTypeFilter: (state, action: PayloadAction<string>) => {
      state.recordTypeFilter = action.payload
    }
  }
})

export const { changeRecordTypeFilter } = importedOrdersSlice.actions

export default importedOrdersSlice.reducer
