import { useState, useEffect } from 'react'
import { Row, Col, Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { useField } from 'formik'

import * as shipmentsApi from '~/api/shipments'
import { Button as Button_ } from '../../components/forms'
import ShipmentError, { ShipmentErrorDetails } from './shipment-error'
import AuPostShipmentPrice from '~/types/au-post-shipment-price'
import AuPostShipment from '~/types/au-post-shipment'
import { AuPostCreateShipmentError, AuPostSystemErrorDetails } from '~/types/au-post-api'

const Flex = styled.div`
  display: flex;
  justify-content: space-around;
  & > * {
    margin-right: 40px;
  }
  margin: 15px 20px 20px 20px;
  font-size: 15px;
`
const ShipmentService = styled.div`
  margin: 15px 20px;
  text: center;
  font-weight: bold;
`
const ShipmentNames = styled.div`
  margin: 15px 0 15px 0;
`
const Button = styled(Button_)`
  outline: none;
`

interface ShipmentPricesProps {
  show: boolean
  onHide: () => void
  name: string
  model: AuPostShipment
}

const ShipmentPrices = ({ show, onHide, name, model }: ShipmentPricesProps) => {
  const shipmentId = model.id
  const [shipmentPrice, setshipmentPrice] = useState<AuPostShipmentPrice>()
  const [shipmentErrors, setShipmentErrors] = useState<ShipmentErrorDetails>()
  const [serverError, setServerError] = useState<AuPostSystemErrorDetails[]>()

  const shipmentServices = model.postageProducts.map(p => ({
    label: p.type,
    value: p.productId
  }))

  // Update shipment service
  const props = { name: name }

  const [_field, _meta, helpers] = useField(props)
  const { setValue } = helpers

  useEffect(() => {
    shipmentsApi
      .fetchShipmentPrice(shipmentId)
      .then(data => {
        if (data.responseBody) {
          console.log('Shipment price data fetched')
          if (data.responseBody.shipments && data.responseStatus === 200) {
            setShipmentErrors(undefined)
            setshipmentPrice(data.responseBody)
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((data.responseBody as any).errors || (data.responseBody as any).code) {
            console.log('Shipment price missing/wrong required fields')
            setShipmentErrors(data.responseBody as unknown as ShipmentErrorDetails)
            setshipmentPrice(undefined)
          }
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!data.responseBody && (data as any).errors) {
          console.log('Shipment price get server error')
          setServerError((data as unknown as AuPostCreateShipmentError).errors)
        }
      })
      .catch(errors => {
        console.log(errors)
      })
  }, [shipmentId])

  let flag = false
  if (shipmentErrors != null || serverError != null) {
    flag = true
  }

  return (
    <>
      {show && (
        <Modal className="shipments-prices-dialog" size="lg" show={show} onHide={onHide}>
          <Modal.Header closeButton>
            <Row className="text-center">
              <Col>
                <h1>Select Shipment Service</h1>
              </Col>
            </Row>
          </Modal.Header>
          <Modal.Body>
            {flag === false &&
              shipmentPrice?.shipments &&
              shipmentPrice?.shipments.map((shipment, i) => (
                <Row key={i}>
                  {shipmentServices.map(
                    (service, i) =>
                      service.value === shipment.items[0].productId && (
                        <Flex key={i}>
                          <Col>
                            <ShipmentService>{service.label}</ShipmentService>
                          </Col>
                          <Col>
                            <ShipmentNames>{shipment.shipmentSummary.totalCostExGst}</ShipmentNames>
                          </Col>
                          <Col>
                            <Button variant="link" onClick={() => setValue(service.value)}>
                              Select
                            </Button>
                          </Col>
                        </Flex>
                      )
                  )}
                </Row>
              ))}
            {flag === true && <ShipmentError shipmentErrors={shipmentErrors} serverError={serverError} />}
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export default ShipmentPrices
