import ProductSearchLink from '~/types/product-search-link'

const columnsConfig = [
  {
    attr: 'name',
    locked: true
  },
  {
    attr: 'searchTerm'
  },
  {
    attr: 'categories',
    heading: 'Categories',
    locked: true,
    render: (record: ProductSearchLink) => (record.categories || []).join(', ')
  },
  {
    attr: 'brand',
    heading: 'Brand',
    locked: true,
    render: (record: ProductSearchLink) => record.brand
  }
]

export default columnsConfig
