import { Fieldset } from '~/components/forms'
import { FlexibleInputGroup } from '~/components/forms/formik'
import { PaymentGatewayEnum } from '~/types/account-settings'

const gatewayOptions = [
  {
    value: 'braintree',
    label: 'Braintree'
  },
  {
    value: 'stripe',
    label: 'Stripe'
  }
]

const currencyOptions = [
  {
    value: 'AUD',
    label: 'Australian Dollar - AUD'
  },
  {
    value: 'USD',
    label: 'United States Dollar - USD'
  },
  {
    value: 'GBP',
    label: 'British Pound - GBP'
  },
  {
    value: 'EUR',
    label: 'Euro - EUR'
  },
  {
    value: 'THB',
    label: 'Thai Baht - THB'
  },
  {
    value: 'IDR',
    label: 'Indonesian Rupiah - IDR'
  }
]

const getPaymentDescriptorHint = (gateway: PaymentGatewayEnum) =>
  gateway === PaymentGatewayEnum.BRAIN_TREE
    ? `Company name/DBA section must be either 3, 7 or 12 characters and the product descriptor can be up to 18, 14, or 9 characters respectively (with an * in between
  for a total descriptor name of 22 characters)`
    : `Max 22 chars, cannot contain <> " ' characters`

interface PreferencesFieldsProps {
  selectedGateway: PaymentGatewayEnum
}

const PreferencesFields = ({ selectedGateway }: PreferencesFieldsProps) => (
  <Fieldset>
    <h3>Preferences</h3>

    <FlexibleInputGroup
      name="paymentPreferences.paymentGateway"
      type="select"
      options={gatewayOptions}
      label="Payment Gateway"
      hint="Select a Payment Gateway"
    />

    <FlexibleInputGroup
      name="currency"
      type="select"
      options={currencyOptions}
      hint="Select the currency of your account. We will format money accorrding to this format in the app."
    />

    <FlexibleInputGroup
      name="paymentDescriptor"
      label="Statement Descriptor"
      hint={getPaymentDescriptorHint(selectedGateway)}
    />
  </Fieldset>
)

export default PreferencesFields
