import { ReactNode } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  min-height: 50px;
  margin: 0;
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
`

const Heading = styled.h2`
  margin-top: 5px;

  font-family: 'Gill Sans', sans-serif;
  font-size: 18px;
  font-weight: 500;

  color: #444;
`

const Group = styled.div`
  display: flex;
  justify-content: space-between;
`

interface SubHeaderProps {
  title: string
  renderTags?: () => ReactNode
}
const SubHeader = ({ title, renderTags }: SubHeaderProps) => {
  return (
    <Wrapper>
      <Group>
        <Heading>{title}</Heading>
      </Group>
      {renderTags && renderTags()}
    </Wrapper>
  )
}

export default SubHeader
