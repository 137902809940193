import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { toast } from 'react-toastify'

import _ from 'lodash'
import PageHeading from '~/components/page-heading'
import Spinner from '~/components/spinner-two'
import ServerError from '~/components/server-error'
import SystemError from '~/components/system-error'
import ShipmentsGrid from '~/containers/shipments-grid'
import * as shipmentsApi from '~/api/shipments'
import * as manifestsApi from '~/api/manifests'
import * as jobsApi from '~/api/au-post-shipment-jobs'
import ManifestJob from '~/containers/manifest-job'
import { openUrlInNewTab } from '~/lib/misc-utils'
import FailedOrdersTable from './failed-orders-table'
import ActionButtons from './action-buttons'
import Info from './info'
import ShipmentActions from './shipment-actions'
import ManifestItem from '~/types/au-post-manifest'
import { AuPostSystemError } from '~/types/au-post-api'
import { useConfirm } from '~/hooks'

const Top = styled.div`
  display: flex;
  justify-content: space-between;
`

interface RouteParams {
  manifestId: string
}

const ShipmentsPage = () => {
  const navigate = useNavigate()
  const [confirm] = useConfirm()
  const params = useParams<keyof RouteParams>()
  const [manifest, setManifest] = useState<ManifestItem | undefined>()
  const [loading, setLoading] = useState(true)
  const [serverError, setServerError] = useState()
  const [systemError, setSystemError] = useState<AuPostSystemError>()
  const [disableLabels, setDisableLabels] = useState(false)
  const [selectedIds, setSelectedIds] = useState<number[]>([])

  const manifestId = parseInt(params?.manifestId || '', 10)
  const pageTitle = `Manifest #${manifestId}`
  const clearServerError = () => setServerError(undefined)
  const clearSystemError = () => setSystemError(undefined)
  const isManifestDespatched = manifest?.status === 'despatched'

  const getFailed = (manifest?: ManifestItem) =>
    Object.values(manifest?.results || {}).filter(
      result => !['remote_server_success', 'already_processed'].includes(result.type)
    )

  const reloadManifest = async () => {
    if (manifest == null) {
      return
    }
    setLoading(true)
    return manifestsApi
      .fetchRecord(manifest.id)
      .then(data => {
        if (data) {
          setManifest(data)
        }
      })
      .catch(setServerError)
      .finally(() => setLoading(false))
  }

  const onRetry = async () => {
    if (manifest == null || manifest.job == null) {
      return
    }

    if (!(await confirm({ message: 'Are you sure you want to retry creating shipments?' }))) {
      return
    }

    const params = {
      manifestId: manifest.id,
      orderIds: manifest.job.orderIds,
      params: manifest.job.params
    }
    return jobsApi
      .create(params)
      .then(data => {
        setManifest(data.manifest)
      })
      .catch(setServerError)
  }

  const onViewOrder = (orderId: number) => navigate(`/orders/${orderId}/edit`)

  const onRemoveOrder = async (orderId: number) => {
    if (manifest == null) {
      return
    }

    if (!(await confirm({ message: 'Are you sure you want to remove this order from this manifest?' }))) {
      return
    }

    console.log('[INFO]', 'removeOrder')
    console.log('[DEBUG] orderId =', orderId)

    return manifestsApi
      .removeOrder(manifest.id, orderId)
      .then(data => {
        setManifest(data)
      })
      .catch(setServerError)
  }

  const onDespatch = async () => {
    if (manifest == null) {
      return
    }
    if (!(await confirm({ message: 'Are you sure you want to despatch this manifest?' }))) {
      return
    }
    return manifestsApi
      .despatch(manifest.id)
      .then(data => {
        setManifest(data)
      })
      .catch(setServerError)
  }

  const onDeleteShipments = async () => {
    const msg = `This will DELETE the selected shipments from AusPost. This action CANNOT be undone. Are you sure?`
    if (!(await confirm({ message: msg }))) {
      return
    }
    return shipmentsApi.destroyList(selectedIds).then(reloadManifest).catch(setServerError)
  }

  const getSelectedShipments = () => manifest?.shipments.filter(s => selectedIds.includes(s.id))

  const checkHasSameShippingService = () => {
    const shippingServices = getSelectedShipments()?.map(s => s.packageProductId)
    if (_.uniq(shippingServices).length > 1) {
      return false
    }
    return true
  }

  const checkHasSameCustomerInfo = () => {
    const data = getSelectedShipments()?.map(s => s.orderCustomerInfo.id)
    if (_.uniq(data).length > 1) {
      return false
    }
    return true
  }

  const onCombineShipments = async () => {
    const msg = `This will DELETE the selected shipments from AusPost and replace them with a new shipment. This action CANNOT be undone. Are you sure?`
    if (!(await confirm({ message: msg }))) {
      return
    }

    const warning1 = `You are trying to combine shipments with different shipping services, please set them to use the same shipping service first`
    if (!checkHasSameShippingService()) {
      toast.error(warning1)
      return
    }

    const warning2 = `You are trying to combine shipments with different customer information, Are you sure?`
    if (!checkHasSameCustomerInfo() && !(await confirm({ message: warning2 }))) {
      return
    }

    return shipmentsApi
      .combineRecords(selectedIds)
      .then(data => {
        setManifest(data)
      })
      .catch(setServerError)
  }

  const onDelete = async () => {
    if (manifest == null) {
      return
    }
    if (
      !(await confirm({ message: 'Are you sure you want to DELETE this manifest? This action CAN NOT be undone.' }))
    ) {
      return
    }
    return manifestsApi
      .destroyRecord(manifest.id)
      .then(() => {
        navigate('/manifests')
      })
      .catch(setServerError)
  }

  const onDownloadLabels = () => {
    if (manifest == null) {
      return
    }
    setDisableLabels(true)
    if (manifest.labelsValid === true) {
      console.log('[INFO]', 'labels valid')
      setDisableLabels(false)
      openUrlInNewTab(manifest.labelsUrl)
      return
    }
    return manifestsApi
      .fetchLabels(manifest.id)
      .then(data => {
        setDisableLabels(false)
        openUrlInNewTab(data.labelsUrl)
        setManifest(data)
      })
      .catch(setServerError)
  }

  const onDownload = () => {
    if (manifest == null) {
      return
    }
    const url = manifestsApi.getDownloadPdfUrl(manifest.id)
    window.open(url)
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    manifestsApi
      .fetchRecord(manifestId)
      .then(data => {
        if (data) {
          setManifest(data)
        }
      })
      .catch(setServerError)
      .finally(() => setLoading(false))
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (manifest == null) {
      return
    }
    if (manifest.job && manifest.job.hasSystemError === true) {
      setSystemError(manifest.job.systemError)
    }
    if (manifest.status === 'empty' && disableLabels === false) {
      setDisableLabels(true)
    } else {
      setDisableLabels(false)
    }
  }, [manifest])
  /* eslint-enable react-hooks/exhaustive-deps */

  const failed = getFailed(manifest)
  const hasFailed = failed.length > 0
  const disableDespatch = hasFailed || !manifest?.labelsValid || ['despatched', 'empty'].includes(manifest.status)
  const disableDelete = manifest?.status === 'despatched'
  const disableManifestButton = !manifest?.remoteManifestId?.length
  const disableCombineShipments = selectedIds.length < 2 || ['despatched', 'empty'].includes(manifest?.status || '')
  const disableDeleteShipments = selectedIds.length < 1 || ['despatched', 'empty'].includes(manifest?.status || '')

  const pending = manifest?.status === 'pending'

  return (
    <div>
      <PageHeading title={pageTitle} />
      {!loading && !pending && (
        <Top>
          {manifest != null && <Info manifest={manifest} />}
          <ActionButtons
            onDespatch={onDespatch}
            onDelete={onDelete}
            onDownloadLabels={onDownloadLabels}
            onDownload={onDownload}
            disableDelete={disableDelete}
            disableDespatch={disableDespatch}
            disableLabels={disableLabels}
            disableManifestButton={disableManifestButton}
          />
        </Top>
      )}

      <br />
      {loading && <Spinner />}
      {!loading && serverError && <ServerError clearServerError={clearServerError} serverError={serverError} />}
      {!loading && systemError && <SystemError systemError={systemError} onDismiss={clearSystemError} />}
      {!loading && pending && <ManifestJob manifest={manifest} setManifest={reloadManifest} />}
      {!loading && !pending && hasFailed && (
        <FailedOrdersTable onViewOrder={onViewOrder} onRemoveOrder={onRemoveOrder} onRetry={onRetry} failed={failed} />
      )}
      {!loading && !pending && manifest?.shipments != null && (
        <ShipmentsGrid
          isManifestDespatched={isManifestDespatched}
          list={manifest?.shipments}
          onItemSelected={({ selectedIds }) => setSelectedIds(selectedIds)}
        />
      )}
      {!loading && !pending && (
        <ShipmentActions
          onCombine={onCombineShipments}
          onDelete={onDeleteShipments}
          disableDelete={disableDeleteShipments}
          disableCombine={disableCombineShipments}
        />
      )}
    </div>
  )
}
/* eslint-enable no-restricted-globals */

export default ShipmentsPage
