import { useFormikContext } from 'formik'

import { OrderFormValues } from './use-order-details'

const OrderHistory = () => {
  const { values: formValues } = useFormikContext<OrderFormValues>()
  const { mainSalesRecord, salesRecordNumber, reference, orderDate, customerReference, customerEmail } = formValues

  return (
    <fieldset>
      <legend>Order Details</legend>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-sm-4 text-end">Order #</div>
            <div className="col-sm-8">
              {mainSalesRecord && (
                <a
                  href={
                    'http://k2b-bulk.ebay.com.au/ws/eBayISAPI.dll?MyEbaySellingSoldListings&searchvalue=' +
                    salesRecordNumber +
                    '&Search=Search&searchparam=12'
                  }
                  target="_blank"
                  rel="noopener noreferrer">
                  {salesRecordNumber}
                </a>
              )}
              {!mainSalesRecord && <span>{reference}</span>}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 text-end">Date</div>
            <div className="col-sm-8">{orderDate}</div>
          </div>
          <div className="row">
            <div className="col-sm-4 text-end">UserID</div>
            <div className="col-sm-8">
              <a
                href={'http://mail.google.com/mail/?shva=1#search/' + customerReference + '+OR+' + customerEmail}
                target="_blank"
                rel="noopener noreferrer">
                {customerReference}
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 text-end">Email</div>
            <div className="col-sm-8">
              <a href={'mailto:' + customerEmail} target="_blank" rel="noopener noreferrer">
                {customerEmail}
              </a>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  )
}

export default OrderHistory
