import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import { default as qs } from 'qs'

import Customer from '~/types/customer'
import { ListMeta } from '~/reducers/customers-reducer'

interface FetchCustomersResponse {
  items: Customer[]
  meta: ListMeta
}

// Fetch customers
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchCustomers = createAsyncThunk<FetchCustomersResponse, Record<string, any>, { rejectValue: any }>(
  'customers/fetchCustomers',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (queryParams: Record<string, any> = {}, { rejectWithValue }) => {
    try {
      if (queryParams.page === '' || queryParams.page === null) {
        delete queryParams.page
      }
      if (queryParams.search === '') {
        delete queryParams.search
      }

      const response = await axios.get<FetchCustomersResponse>('/api/customers?' + qs.stringify(queryParams))
      return response.data
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

// Destroy customer
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const destroyCustomer = createAsyncThunk<{ id: number }, { id: number }, { rejectValue: any }>(
  'customers/destroyCustomer',
  async (customer: { id: number }, { rejectWithValue }) => {
    try {
      await axios.delete(`/api/customers/${customer.id}`)
      return customer
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

// Invite customer
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const inviteCustomer = createAsyncThunk<Customer, { id: number }, { rejectValue: any }>(
  'customers/inviteCustomer',
  async (customer: { id: number }, { rejectWithValue }) => {
    try {
      const response = await axios.post<Customer>(`/api/customers/${customer.id}/invite`)
      return response.data
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchCustomer = createAsyncThunk<Customer, number, { rejectValue: any }>(
  'customers/fetchCustomer',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/customers/${id}`)
      return response.data.customer
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createCustomer = createAsyncThunk<Customer, Omit<Partial<Customer>, 'id'>, { rejectValue: any }>(
  'customers/createCustomer',
  async (customerParams, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/customers', {
        customer: customerParams
      })
      return response.data
    } catch (error) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateCustomer = createAsyncThunk<Customer, Partial<Customer>, { rejectValue: any }>(
  'customers/updateCustomer',
  async (customerParams, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`/api/customers/${customerParams.id}`, {
        customer: customerParams
      })
      return response.data
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)
