import { useSelector } from 'react-redux'

import Icon from '~/components/icon'
import { Button } from '~/components/forms'
import Grid from '~/components/grid'
import OrderActions from '~/components/order-actions'
import { gridColumns } from './config'
import { AppState } from '~/config/store'
import Order from '~/types/order'

interface ReviewProcessedDataScreenProps {
  onStartNew: () => void
}

const ReviewProcessedDataScreen = ({ onStartNew }: ReviewProcessedDataScreenProps) => {
  const selectedIds = useSelector((state: AppState) => state.grid.selectedIdsOf.orderProcessing) || []
  const list = useSelector((state: AppState) => state.orderProcessing.list)
  const listMeta = useSelector((state: AppState) => state.orderProcessing.listMeta)
  const ordersLoading = useSelector((state: AppState) => state.orderProcessing.loading.fetchUnprocessedOrders)
  const unmatchedSalesRecordsLoading = useSelector(
    (state: AppState) => state.unmatchedSalesRecordsPage.loading.fetchUnmatchedSalesRecords
  )

  const isSubmitting = ordersLoading && unmatchedSalesRecordsLoading

  return (
    <div>
      <h1>
        Order Processing{' '}
        <small>
          <span>
            {' '}
            - Complete <Icon icon="check" className="text-success" />
          </span>
        </small>
      </h1>

      <Button style={{ marginBottom: '10px' }} variant="warning" onClick={onStartNew} isSubmitting={isSubmitting}>
        Start New
      </Button>

      <OrderActions selectedIds={selectedIds} />

      <Grid<Order>
        recordType="orderProcessing"
        config={{
          columns: gridColumns,
          // NOTE: disabling these action buttons, because the links don't work properly, i.e. linking to /order-processing/3/edit instead of /order-history/3/edit
          //
          // User can always navigate directly to Order History and filter by 'lastid' if they need to
          actions: {
            edit: false,
            delete: false
          },
          checkboxesColumn: true,
          checkboxesColumnChecked: true,
          pagination: false
        }}
        list={list}
        listMeta={listMeta}
      />
    </div>
  )
}

export default ReviewProcessedDataScreen
