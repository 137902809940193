import { Alert } from 'react-bootstrap'

import { AuPostSystemError } from '~/types/au-post-api'

interface SystemErrorProps {
  systemError: AuPostSystemError
  onDismiss: () => void
}
const SystemError = ({ systemError, onDismiss }: SystemErrorProps) => {
  const context = systemError.type
  const { details } = systemError

  if (!systemError) {
    return null
  }

  return (
    <Alert variant="danger" onClose={onDismiss}>
      <h4>There was an system error</h4>
      <hr />

      <p>Context: {context}</p>
      <p>Type: {details.type}</p>
      <p>Message: {details.message}</p>
      <p>Origin: {details.origin}</p>
    </Alert>
  )
}

export default SystemError
