import { FieldInputProps, FormikProps } from 'formik'
import styled from 'styled-components'

const Input = styled.input``

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface CheckboxInputProps<V = any, FormValues = any> {
  field: FieldInputProps<V>
  form: FormikProps<FormValues>
  type?: string
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CheckboxInput = <V = any, FormValues = any>({
  field: { value, ...otherFields },
  form,
  type = 'text',
  ...otherProps
}: CheckboxInputProps<V, FormValues>) => (
  <div>
    <Input
      type="checkbox"
      value={(value as string | number | readonly string[] | undefined) ?? ''}
      {...otherFields}
      {...otherProps}
    />
  </div>
)

export default CheckboxInput
