import Grid from '~/components/grid'
import { useNavigate } from 'react-router-dom'

import AuPostShipment from '~/types/au-post-shipment'
import { defaultColumns } from './config'

interface ShipmentsGripProps {
  list: AuPostShipment[]
  onItemSelected: (options: { selectedIds: number[] }) => void
  isManifestDespatched: boolean
}
const ShipmentsGrid = ({ list, onItemSelected, isManifestDespatched }: ShipmentsGripProps) => {
  const navigate = useNavigate()
  const goToEditPath = (record: AuPostShipment) => {
    navigate(`/shipments/${record.id}`)
  }

  let onRowClick = goToEditPath

  if (isManifestDespatched === true) {
    onRowClick = () => null
  }

  return (
    <Grid
      recordType="shipments"
      config={{
        actions: undefined,
        columns: defaultColumns,
        checkboxesColumn: true,
        customizerModal: false,
        onItemSelected,
        onRowClick
      }}
      list={list}
    />
  )
}

export default ShipmentsGrid
