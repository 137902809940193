import { object, array, string } from 'yup'

export default object().shape({
  notes: array().of(
    object().shape({
      countryCode: string().required('Required'),
      body: string().required('Required')
    })
  )
})
