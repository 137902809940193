export interface UserFormParams {
  id: number
  email: string
  fullName: string
  kind: UserKindEnum
  timeZone: string
  password?: string
  passwordConfirmation?: string
}

export interface UserCredentials {
  email: string
  password: string
}

export enum UserKindEnum {
  OWNER = 'owner',
  STAFF_FULL = 'staff_full',
  STAFF_LIMITED = 'staff_limited'
}

interface User {
  id: number
  email: string
  fullName: string
  kind: UserKindEnum
  createdAt: string
  updatedAt: string
  timeZone: string
  auPostSignature?: string
  accountSettings: {
    name: string
    mobileEnabled: boolean
    shopfrontEnabled: boolean
    ebayEnabled: boolean
    shopfrontOnlyMode: boolean
  }
}

export default User
