import Product from '~/types/product'
import ProductSearchLink from '~/types/product-search-link'

export enum PriorityItemKindEnum {
  MOBILE = 'mobile',
  SHOPFRONT = 'shopfront'
}

export enum PriorityItemTypeEnum {
  PRODUCT = 'Product',
  MOBILE_PRODUCT_SEARCH_LINK = 'MobileProductSearchLink'
}

interface PriorityItem {
  id: number
  itemType: PriorityItemTypeEnum
  itemId: number
  item: Product | ProductSearchLink
  priority: number
  active: boolean
}

export default PriorityItem
