import { useState } from 'react'
import styled from 'styled-components'

import { Button } from '~/components/forms'
import ExportButton_ from '~/components/export-button'
import ImportForm from '~/components/csv-imports/import-form'
import { setLastCsvImport, setFileUploading } from '~/reducers/products-import-reducer'

import styles from './main-view.module.css'

const btnStyles = {
  marginTop: '20px',
  marginBottom: '20px',
  marginRight: '10px'
}

const ExportButton = styled(ExportButton_)`
  &:not([disabled]),
  &[disabled] {
    &,
    &:hover,
    &:active,
    &.active,
    &:focus,
    &:active:focus {
      background-color: transparent;
      color: ${props => props.theme.colors.primary.base};
      border: none;
      box-shadow: none;
      padding: 0;
      line-height: 17px;
      outline: none;
      display: inline;
    }

    &[disabled] {
      color: ${props => props.theme.colors.primary.lightest};
    }
  }

  &:hover {
    text-decoration: underline;
  }
`
const Notes = styled.div`
  display: ${props => (props.hidden ? 'none' : 'block')};
  padding: 1rem;
  margin-top: 1rem;
  background-color: ${props => props.theme.colors.gray.lightest2};
  border-radius: ${props => props.theme.borderRadius};

  dl {
    margin-bottom: 0;
    margin-top: 1rem;
  }
`
interface MainViewProps {
  showResult: () => void
}
const MainView = ({ showResult }: MainViewProps) => {
  const [notesHidden, setNotesHidden] = useState(true)
  const toggleNotes = () => setNotesHidden(!notesHidden)

  return (
    <>
      <p>Note: file must be formatted properly for uploading to work.</p>
      <p>
        You can download a <a href="/sample-csvs/product-import-format.csv">sample CSV file here</a>, or you can{' '}
        <ExportButton endpoint="/api/products/export" icon="file-excel">
          export your existing products
        </ExportButton>
        .
      </p>

      <Button variant="primary" style={btnStyles} onClick={toggleNotes}>
        Show Notes
      </Button>
      <Button style={btnStyles} variant="light" onClick={showResult}>
        Previous Result
      </Button>
      <Notes hidden={notesHidden}>
        Some fields in the CSV must have particular values. Please only use the following values for these fields:
        <dl>
          <dt>Condition</dt>
          <dd>new_with_tags</dd>
          <dd>new_without_tags</dd>
          <dd>new_with_defects</dd>
          <dd>used</dd>
        </dl>
        <dl>
          <dt>Status</dt>
          <dd>active</dd>
          <dd>inactive</dd>
        </dl>
      </Notes>

      <ImportForm
        className={styles.wrapper}
        title="Import Products from CSV"
        resourcePath="products_csv_imports"
        paramName="products_csv_import"
        setLastCsvImport={setLastCsvImport}
        setFileUploading={setFileUploading}
      />
    </>
  )
}

export default MainView
