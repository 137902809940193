import _ from 'lodash'
import snakeToCamelCase from './snake-to-camel-case'
import { FormikErrors } from 'formik'

export const mapErrors = (serverErrors: { message: string }) => {
  const { message, ...rest } = serverErrors
  const errors = {
    baseErrors: message,
    ...rest
  }
  return _.mapKeys(errors, (_value, key) => snakeToCamelCase(key))
}

interface Errors {
  [key: string]: string
}

interface HandleErrorResponseParams<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: any
  setErrors: (errors: Errors & FormikErrors<T>) => void
  baseMessage?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleErrorResponse = <T = any>({ response, setErrors, baseMessage }: HandleErrorResponseParams<T>) => {
  let isJsonError = false

  // Need to check if json error message, or potentially html 500 error
  // (development)
  if (response && response.data) {
    isJsonError = !_.isError(_.attempt(JSON.parse, response.data))
  }

  if (isJsonError) {
    const errors = mapErrors(response.data)
    setErrors(errors as Errors & FormikErrors<T>)
  } else {
    const errors = {
      baseErrors: baseMessage || 'An error occurred. Please contact support if the problem persists.'
    }
    setErrors(errors as Errors & FormikErrors<T>)
  }
}

export function normalizeSelectField(value: { value: string }) {
  return _.has(value, 'value') ? value.value : value
}

export function normalizeMultiSelectField(value: Array<{ value: string }>) {
  return _.map(value, optionObj => optionObj.value)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function denormalizeSelectField(value: any): undefined | Array<{ value: string; label: string }> {
  if (!value) {
    return
  }

  let valueMapped: undefined | Array<{ value: string; label: string }> = undefined

  if (_.isArray(value)) {
    valueMapped = _.map(value, v => (v.value === undefined ? { value: v, label: v } : v))
  } else {
    valueMapped = _.has(value, 'value') ? value : { value, label: value }
  }

  return valueMapped
}
