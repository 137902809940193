import { useImperativeHandle, forwardRef, useState, useEffect, useCallback } from 'react'
import { Field, useFormikContext } from 'formik'
import _ from 'lodash'

import standingDataService from '~/services/standing-data-service'
import useCountryCodes from '~/contexts/useCountryCodes'
import { BsInput, ReactSelectInputCreatable, BsSelectInput } from '~/components/form-inputs/formik/inputs-decorated'
import { ProductFormValues } from '../use-product-form'
import { calculateCubicWeight } from '~/lib/cubic-weight'
import InternationalCountryCode from '~/types/international-country-code'

interface DropdownOption {
  label: string
  value: string
}

export interface PackageDimensionsAndCustomsInfoRef {
  addCustomsDescription: (customsDescriptionOptions: DropdownOption[]) => void
}

const PackageDimensionsAndCustomsInfo = forwardRef((_props, ref) => {
  const { values: formValues, setFieldValue } = useFormikContext<ProductFormValues>()
  const { countryCodes } = useCountryCodes()
  const [originalDomesticWeight, setOriginalDomesticWeight] = useState<number | undefined>(undefined)
  const [customsDescriptionOptions, setCustomsDescriptionOptions] = useState<DropdownOption[]>([])
  const [customsDescriptionOptionsLoaded, setCustomsDescriptionOptionsLoaded] = useState(false)
  const [countryOptions, setCountryOptions] = useState<DropdownOption[]>([])

  useImperativeHandle(ref, () => ({
    addCustomsDescription: (customsDescriptionOptions: DropdownOption[]) => {
      setCustomsDescriptionOptions(prevCustomsDescriptions =>
        _.uniqBy([...prevCustomsDescriptions, ...customsDescriptionOptions], 'value')
      )
      standingDataService.addCustomsDescriptionOptions(customsDescriptionOptions)
    }
  }))

  const defaultCountryOptions = useCallback(
    (countryCodes: InternationalCountryCode[]) => {
      if (countryOptions.length > 0) return
      const options = _.map(countryCodes, entry => ({
        label: entry.countryName,
        value: entry.countryCode
      }))
      options.unshift(
        { value: '', label: 'Please select' },
        {
          value: 'AU',
          label: 'AUSTRALIA'
        }
      )
      setCountryOptions(options)
    },
    [countryOptions.length]
  )

  // Disabling this for now, don't think it actually works in production anyway
  // const defaultCustomsOrigin = useCallback(() => {
  //   // Update customs defaults
  //   if (!productId && settings) {
  //     const defaults = settings?.customsDefaults
  //     if (_.has(defaults, 'origin') && !formValues.customsOriginCountryCode) {
  //       setFieldValue('customsOriginCountryCode', defaults?.origin)
  //     }
  //   }
  // }, [setFieldValue, productId, settings, formValues.customsOriginCountryCode])

  const fetchCustomsDescriptionOptions = useCallback(async () => {
    if (customsDescriptionOptionsLoaded) return

    const result = await standingDataService.fetchCustomsDescriptionOptions()
    setCustomsDescriptionOptions(result)
    setCustomsDescriptionOptionsLoaded(true)
  }, [customsDescriptionOptionsLoaded])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _saveOriginalDomesticWeight = (e: any) => {
    setOriginalDomesticWeight(e.target.value)
  }

  const _copyWeightToInternational = () => {
    if (!formValues?.packageWeightIntl || formValues?.packageWeightIntl === originalDomesticWeight) {
      setFieldValue('packageWeightIntl', formValues?.packageWeight)
    }
  }

  useEffect(() => {
    // defaultCustomsOrigin()
    defaultCountryOptions(countryCodes)
    fetchCustomsDescriptionOptions()
  }, [countryCodes, defaultCountryOptions, /* defaultCustomsOrigin,*/ fetchCustomsDescriptionOptions])

  useEffect(() => {
    setFieldValue(
      'cubicWeight',
      calculateCubicWeight(formValues.packageLength, formValues.packageWidth, formValues.packageHeight)
    )
  }, [formValues.packageLength, formValues.packageWidth, formValues.packageHeight, setFieldValue])

  return (
    <fieldset>
      <legend>Package Dimensions &amp; Customs Information</legend>
      <div className="d-flex justify-space-between gap-3 mb-3">
        <Field name="packageLength" label="Length (cm)" labelPlacement="top" component={BsInput} />
        <Field name="packageWidth" label="Width (cm)" labelPlacement="top" component={BsInput} />
        <Field name="packageHeight" label="Height (cm)" labelPlacement="top" component={BsInput} />

        <Field name="cubicWeight" label="Cubic Weight" labelPlacement="top" component={BsInput} displayMode="plain" />

        <Field
          name="packageWeight"
          label="Dom Weight (kg)"
          labelPlacement="top"
          component={BsInput}
          onFocus={_saveOriginalDomesticWeight}
          onBlur={_copyWeightToInternational}
        />

        <Field name="packageWeightIntl" label="Int'l Weight (kg)" labelPlacement="top" component={BsInput} />
      </div>

      <Field
        name="customsDescription"
        label="Description of Goods"
        component={ReactSelectInputCreatable}
        options={customsDescriptionOptions}
      />
      <Field name="customsTariffNumber" label="Tariff No" component={BsInput} />
      <Field name="customsOriginCountryCode" label="Origin" component={BsSelectInput} options={countryOptions} />
    </fieldset>
  )
})

PackageDimensionsAndCustomsInfo.displayName = 'PackageDimensionsAndCustomsInfo'

export default PackageDimensionsAndCustomsInfo
