import { useState, ChangeEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import ProductExistsModal from '~/containers/products-page/product-exists-modal'
import ProductForm from '~/containers/product-form-page/form'
import AddUnrecognisedScancodeToProduct from './add-unrecognised-scancode-to-product'
import { AppState, AppDispatch } from '~/config/store'
import { newProductPojo } from '~/lib/utils/helpers'
import {
  newProductAddedAndChangeSelected,
  scancodeLinkedAndChangeSelected
} from '~/reducers/unmatched-sales-records-page-reducer'
import useSettings from '~/contexts/useSettings'

import Product from '~/types/product'
import { UnmatchedLineItem } from '~/types/ebay-sales-record'

interface IncompleteRecordFixItFormProps {
  selectedRecord: UnmatchedLineItem
  afterRecordProcessed?: (unmatchedRecord: UnmatchedLineItem, wasLastRecord: boolean) => Promise<void>
  onCancel?: () => void
}

/*
 * This component will either:
 *
 * 1. create a new produc to represent the unrecognised scancode
 * 2. link the unrecognised scancode to an existing product
 *
 * In both cases, on the server, the new scancode will be automatically
 * associated to any orders that may have it.
 *
 * The 'orders' redux state should therefor be refreshed in `afterRecordProcessed`r
 * callback by the parent component
 */
const IncompleteRecordFixItForm = ({
  selectedRecord,
  afterRecordProcessed,
  onCancel
}: IncompleteRecordFixItFormProps) => {
  const { settings } = useSettings()
  const dispatch = useDispatch<AppDispatch>()
  const [formType, setFormType] = useState<'newProduct' | 'linkToProduct'>('newProduct')
  const [existingProduct, setExistingProduct] = useState<Product | undefined>(undefined)
  const unmatchedList = useSelector((state: AppState) => state.unmatchedSalesRecordsPage.list)

  const linkToExistingProduct = async (product: Product) => {
    setFormType('linkToProduct')
    setExistingProduct(product)
  }

  const onChangeFormType = (e: ChangeEvent<HTMLInputElement>) => {
    const newFormType = e.target.value as 'newProduct' | 'linkToProduct'
    setFormType(newFormType)
  }

  return (
    <div>
      <div className="card mb-4">
        <div className="card-body">
          <div className="form-check form-check-inline">
            <label className="form-check-label" htmlFor="mode-newProduct">
              Add new product
            </label>
            <input
              id="mode-newProduct"
              type="radio"
              value="newProduct"
              checked={formType === 'newProduct'}
              onChange={onChangeFormType}
              className="form-check-input"
            />
          </div>

          <div className="form-check form-check-inline">
            <label className="form-check-label" htmlFor="mode-linkToProduct">
              Link to existing product
            </label>
            <input
              id="mode-linkToProduct"
              type="radio"
              className="form-check-input"
              value="linkToProduct"
              checked={formType === 'linkToProduct'}
              onChange={onChangeFormType}
            />
          </div>
        </div>
      </div>

      {/* OPTION 1: Create a new product to represent the unrecognised scancode */}
      {formType === 'newProduct' && (
        <>
          <ProductForm
            initialValues={newProductPojo({ unmatchedRecord: selectedRecord, settings })}
            key={selectedRecord.id} // Force re-render when the selected record changes
            fixFirstMatcher
            saveBtnText="Save and Next"
            customHeading={formValues => (
              <div className="mb-4">
                <h2>
                  Add new product <small className="text-secondary">({unmatchedList.length - 1} left after this)</small>
                </h2>
                <h5>{formValues?.productImportMatchers?.[0].scancode}</h5>
              </div>
            )}
            afterSave={async (product: Product) => {
              const wasLastRecord = unmatchedList.length === 1
              // Will update `selectedRecord` to the next record in the list to process
              dispatch(newProductAddedAndChangeSelected(product))
              await afterRecordProcessed?.(selectedRecord, wasLastRecord)
            }}
            onCancel={onCancel}
          />

          <ProductExistsModal onConfirm={async product => await linkToExistingProduct(product)}>
            <p>You cannot create a new product with this same SKU.</p>
            <p className="fw-bold">Do you want to link to this product instead?</p>
          </ProductExistsModal>
        </>
      )}

      {/* OPTION 2: Link the unrecognised scancode to an existing product */}
      {formType === 'linkToProduct' && (
        <AddUnrecognisedScancodeToProduct
          existingProduct={existingProduct}
          onScancodeAdded={async productImportMatcher => {
            const wasLastRecord = unmatchedList.length === 1
            dispatch(scancodeLinkedAndChangeSelected(productImportMatcher))
            setExistingProduct(undefined)
            await afterRecordProcessed?.(selectedRecord, wasLastRecord)
          }}
          onCancel={() => {
            onCancel?.()
            setExistingProduct(undefined)
          }}
        />
      )}
    </div>
  )
}

export default IncompleteRecordFixItForm
