import { Modal, ModalProps } from 'react-bootstrap'
import styled from 'styled-components'

import { PosFormLineItemValue } from './form'

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 150px;
`

const Box = styled.div`
  padding: 10px;
  border: 1px solid #ccc;
  margin: 10px;
`

const A = styled(Box)`
  flex-basis: 25%;
`

const B = styled(Box)`
  flex-basis: 75%;
`

const C = styled(Box)`
  flex-grow: 1;
`

const Pp = styled.div`
  margin-bottom: 20px;
`

const H2 = styled.div`
  font-size: 16;
  font-weight: 600;
  margin-bottom: 20px;
`

const H3 = styled.div`
  font-size: 14;
`

const H4 = styled.div`
  font-size: 14;
`

const P = styled.div`
  margin-bottom: 5px;
`

const List = styled.div`
  display: flex;
  flex-direction: row;
`

const ListItem = styled.div`
  text-align: center;
  margin-right: 20px;
`

interface ProductDetailsDialogProps extends ModalProps {
  line?: PosFormLineItemValue
}
const ProductDetailsDialog = ({ line, ...rest }: ProductDetailsDialogProps) => {
  if (line == null || line.product == null) return null

  return (
    <Modal className="pos-product-details-dialog" size="lg" {...rest}>
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>
            {line.product.customSku} &nbsp; {line.product.description}
          </strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <A>
            <H2>Product Details</H2>
            <Pp>SKU: {line.product.customSku}</Pp>
            <Pp>Shelf Location: {line.product.shelfLocation}</Pp>
            <Pp>Quantity Available: {line.product.quantityAvailable}</Pp>
            {line.product.mobile && <Pp>Mobile Available: {line.product.onlineQuantityAvailable}</Pp>}
          </A>

          <B>
            <H2>Packge Details &amp; Customs Information</H2>
            <Pp>
              <List>
                <ListItem>
                  <H3>Length (cm)</H3>
                  <H4>{line.product.packageLength}</H4>
                </ListItem>
                <ListItem>
                  <H3>Width (cm)</H3>
                  <H4>{line.product.packageWidth}</H4>
                </ListItem>
                <ListItem>
                  <H3>Height (cm)</H3>
                  <H4>{line.product.packageHeight}</H4>
                </ListItem>
                <ListItem>
                  <H3>Domestic Weight (kg)</H3>
                  <H4>{line.product.packageWeight}</H4>
                </ListItem>
                <ListItem>
                  <H3>Int&apos;l Weight (kg)</H3>
                  <H4>{line.product.packageWeightIntl}</H4>
                </ListItem>
              </List>
            </Pp>
            <Pp>
              <P>Description of Goods: {line.product.description}</P>
              <P>Tarrif No: {line.product.customsTariffNumber}</P>
              <P>Origin: {line.product.customsOriginCountryCode}</P>
              <P>Classification: {line.product.customsDescription}</P>
              <P>Classification Explanation: ??</P>
            </Pp>
          </B>
        </Row>

        <Row>
          <C>
            <H2>Product Specifications</H2>
            <Row>
              <Column>
                <Pp>Condition: {line.product.condition}</Pp>
                <Pp>Brand: {line.product.brand}</Pp>
                <Pp>Model: {line.product.model}</Pp>
                <Pp>Manufacturers SKU: {line.product.sku}</Pp>
                <Pp>Barcode / UPC: {line.product.barcode}</Pp>
              </Column>
              <Column>
                <Pp>RRP: {line.product.rrp}</Pp>
                <Pp>Categories: {(line.product.categories || []).join(', ')}</Pp>
                <Pp>Description / Keywords: {line.product.description}</Pp>
                <Pp>Status: {line.product.status}</Pp>
              </Column>
            </Row>
          </C>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default ProductDetailsDialog
