import _ from 'lodash'

import ShippingPricingProfile, { ZoneKeys } from '~/types/shipping-pricing-profile'
import WeightBasedShippingPricingProfile from '~/types/weight-based-shipping-pricing-profile'
import { RegionalPricingSectionTypeEnum } from './regional-pricing-section'
import { CustomDataOriginal, CustomDataTransformed } from '~/types/product'

export function findWeightBasedShippingProfile(
  profiles: WeightBasedShippingPricingProfile[],
  type: RegionalPricingSectionTypeEnum,
  weightStr: string | number | undefined | null
) {
  const predicate = (p: WeightBasedShippingPricingProfile) => {
    const weight = parseFloat(weightStr?.toString() || '')
    const minWeight = parseFloat(p.minWeight.toString())
    const maxWeight = parseFloat(p.maxWeight.toString())
    return weight >= minWeight && weight <= maxWeight
  }
  return _(profiles).filter(['type', type]).find(predicate)
}

export function transformWeightBasedProfile(
  weightStr: string | number | undefined | null,
  p?: WeightBasedShippingPricingProfile
) {
  const fieldNames = ['Au', 'Zone1', 'Zone2', 'Zone3', 'Zone4', 'Zone5', 'Zone6', 'Zone7', 'Zone8', 'Zone9']
  const result = {} as ShippingPricingProfile
  const weight = parseFloat(weightStr?.toString() || '')
  _.each(fieldNames, f => {
    const f1 = `pi${f}`
    const f2 = `pk${f}`
    const v1 = p != null ? parseFloat(p[f1 as keyof WeightBasedShippingPricingProfile].toString()) : 0
    const v2 = p != null ? parseFloat(p[f2 as keyof WeightBasedShippingPricingProfile].toString()) : 0
    const r = _.floor(v1 + weight * v2, 2)
    const key = f.toLowerCase() as ZoneKeys
    result[key] = r || 0
  })
  return result as ShippingPricingProfile
}

export function transformCustomData(serverCustomData: CustomDataOriginal | undefined) {
  return _.map(serverCustomData, (value, key) => ({ key, value })) as CustomDataTransformed
}

export function reverseTransformCustomData(appCustomData?: CustomDataTransformed | null) {
  const result: CustomDataOriginal = {}

  _.each(appCustomData, entry => {
    const { key, value } = entry
    result[key] = value
  })

  return result
}

export function printCurrency(value: string | number) {
  return Number(value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  })
}
