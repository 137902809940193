import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import EparcelsTrackingCsvImport from '~/types/eparcels-tracking'

export interface TrackingPageState {
  loading: boolean
  lastCsvImport?: EparcelsTrackingCsvImport
  fileUploading: boolean
}

const initialState: TrackingPageState = {
  loading: false,
  lastCsvImport: undefined,
  fileUploading: false
}

const trackingPageSlice = createSlice({
  name: 'order-tracking',
  initialState,
  reducers: {
    setLastCsvImport: (state, action: PayloadAction<EparcelsTrackingCsvImport | undefined>) => {
      state.lastCsvImport = action.payload
    },
    setFileUploading: (state, action: PayloadAction<boolean>) => {
      state.fileUploading = action.payload
    }
  }
})

export const { setLastCsvImport, setFileUploading } = trackingPageSlice.actions

export default trackingPageSlice.reducer
