import _ from 'lodash'
import axios from 'axios'

interface DropdownOption {
  label: string
  value: string
}

// TODO: reimplement this using redux

class StandingDataService {
  private brandOptionsCache?: DropdownOption[]
  private colorOptionsCache?: DropdownOption[]
  private variantGroupOptionsCache?: DropdownOption[]
  private customsDescriptionOptionsCache?: DropdownOption[]
  private productCategoriesCache?: DropdownOption[]

  async fetchBrandOptions(): Promise<DropdownOption[]> {
    if (this.brandOptionsCache) {
      return this.brandOptionsCache
    }

    const response = await axios.get('/api/brands')
    this.brandOptionsCache = _.map(response.data, entry => ({
      label: entry,
      value: entry
    }))

    return this.brandOptionsCache
  }

  async fetchColorOptions(): Promise<DropdownOption[]> {
    if (this.colorOptionsCache) {
      return this.colorOptionsCache
    }

    const response = await axios.get('/api/colors')
    this.colorOptionsCache = _.map(response.data, entry => ({
      label: entry,
      value: entry
    }))

    return this.colorOptionsCache
  }

  async fetchVariantGroupOptions(): Promise<DropdownOption[]> {
    if (this.variantGroupOptionsCache) {
      return this.variantGroupOptionsCache
    }

    const response = await axios.get('/api/variant_groups')
    this.variantGroupOptionsCache = _.map(response.data, entry => ({
      label: entry,
      value: entry
    }))

    return this.variantGroupOptionsCache
  }

  async fetchCustomsDescriptionOptions(): Promise<DropdownOption[]> {
    if (this.customsDescriptionOptionsCache) {
      return this.customsDescriptionOptionsCache
    }

    const response = await axios.get('/api/customs_descriptions')
    this.customsDescriptionOptionsCache = _.map(response.data, entry => ({
      label: entry,
      value: entry
    }))

    return this.customsDescriptionOptionsCache
  }

  async fetchProductCategoriesOptions(): Promise<DropdownOption[]> {
    if (this.productCategoriesCache) {
      return this.productCategoriesCache
    }

    const response = await axios.get('/api/products/categories')
    this.productCategoriesCache = _.map(response.data, entry => ({
      label: entry,
      value: entry
    }))

    return this.productCategoriesCache
  }

  private addUniqueOptionsToCache(cache: DropdownOption[], newOptions: DropdownOption[]): DropdownOption[] {
    // Filter out options that already exist in the cache
    const uniqueOptions = newOptions.filter(newOption => !cache.some(option => option.value === newOption.value))
    return [...cache, ...uniqueOptions]
  }

  addBrandOptions(newOptions: DropdownOption[]): void {
    this.brandOptionsCache = this.addUniqueOptionsToCache(this.brandOptionsCache || [], newOptions)
  }

  addColorOptions(newOptions: DropdownOption[]): void {
    this.colorOptionsCache = this.addUniqueOptionsToCache(this.colorOptionsCache || [], newOptions)
  }

  addVariantGroupOptions(newOptions: DropdownOption[]): void {
    this.variantGroupOptionsCache = this.addUniqueOptionsToCache(this.variantGroupOptionsCache || [], newOptions)
  }

  addCustomsDescriptionOptions(newOptions: DropdownOption[]): void {
    this.customsDescriptionOptionsCache = this.addUniqueOptionsToCache(
      this.customsDescriptionOptionsCache || [],
      newOptions
    )
  }

  addProductCategoriesOptions(newOptions: DropdownOption[]): void {
    this.productCategoriesCache = this.addUniqueOptionsToCache(this.productCategoriesCache || [], newOptions)
  }
}

export default new StandingDataService()
