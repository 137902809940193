import { ReactNode } from 'react'
import { Form, FormProps } from 'react-bootstrap'

interface FieldsetProps extends FormProps {
  children: ReactNode
  hl?: boolean
}
const Fieldset = ({ children, hl = false, ...props }: FieldsetProps) => (
  <Form.Group as="fieldset" horizontal={hl} {...props}>
    {children}
  </Form.Group>
)

export default Fieldset
