import styled from 'styled-components'
import { FieldInputProps, FormikProps } from 'formik'
import { DatePicker } from 'react-date-picker'
import moment from 'moment'
import classnames from 'classnames'

const StyledDatePicker = styled(DatePicker)`
  &.form-control {
    padding: 0 0 0 0.5rem;
    display: flex;
    align-items: center;
  }

  .react-date-picker__wrapper {
    border: none;
  }
`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface DateInputProps<V = any, FormValues = any> {
  id?: string
  field: FieldInputProps<V>
  form: FormikProps<FormValues>
  readOnly?: boolean
  className?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DateInput = <V = any, FormValues = any>({
  id,
  className,
  field,
  form,
  readOnly = false,
  ...otherProps
}: DateInputProps<V, FormValues>) => (
  <StyledDatePicker
    showLeadingZeros
    className={classnames('form-control', className)}
    locale="en-AU"
    value={field.value ? moment(field.value, 'DD/MM/YYYY').toDate() : undefined}
    clearIcon={null}
    disabled={readOnly}
    onChange={option => form.setFieldValue(field.name, option)}
    {...otherProps}
  />
)

export default DateInput
