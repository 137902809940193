import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 0.5rem 2rem;
  font-size: 0.9rem;
  background-color: ${props => props.theme.Footer.backgroundColor};
  color: ${props => props.theme.Footer.textColor};
`

const Footer = () => <Wrapper>2016 © MyPostLabels</Wrapper>

export default Footer
