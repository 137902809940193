import styled from 'styled-components'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import ShippingPricingProfile from '~/types/shipping-pricing-profile'
import { Table, Thead, Tbody, Tr, Th, Td } from '~/components/table'
import { Button } from '~/components/forms'

const Star = styled.span`
  &::before {
    content: '*';
    color: red;
  }
`

interface ListViewProps {
  items: ShippingPricingProfile[]
  onDelete: (item: ShippingPricingProfile) => void
}
const ListView = ({ items, onDelete }: ListViewProps) => (
  <div>
    <Table>
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Australia</Th>
          <Th>Zone 1</Th>
          <Th>Zone 2</Th>
          <Th>Zone 3</Th>
          <Th>Zone 4</Th>
          <Th>Zone 5</Th>
          <Th>Zone 6</Th>
          <Th>Zone 7</Th>
          <Th>Zone 8</Th>
          <Th>Zone 9</Th>
          <Th className="empty" />
        </Tr>
      </Thead>

      <Tbody>
        {_.map(items, item => (
          <Tr key={item.id}>
            <Td>
              {item.name} {item.default === true && <Star />}
            </Td>
            <Td>{item.au}</Td>
            <Td>{item.zone1}</Td>
            <Td>{item.zone2}</Td>
            <Td>{item.zone3}</Td>
            <Td>{item.zone4}</Td>
            <Td>{item.zone5}</Td>
            <Td>{item.zone6}</Td>
            <Td>{item.zone7}</Td>
            <Td>{item.zone8}</Td>
            <Td>{item.zone9}</Td>
            <Td className="action-buttons">
              <Link to={`/settings/shipping_pricing_profiles/${item.id}`}>Edit</Link>
              &nbsp;|&nbsp;
              <Button variant="link" onClick={() => onDelete(item)}>
                Delete
              </Button>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  </div>
)

export default ListView
