import { FC } from 'react'

import SelectInput, { SelectInputProps } from './select-input'
import TextInput, { TextInputProps } from './text-input'

type InputMappingType = {
  select: FC<SelectInputProps>
  text: FC<TextInputProps>
}

const mapping: InputMappingType = {
  select: SelectInput,
  text: TextInput
}

interface InputProps extends SelectInputProps, TextInputProps {
  as?: string
}
const Input = ({ as = 'text', ...props }: InputProps) => {
  const InputComponent = mapping[as as keyof InputMappingType]
  return <InputComponent {...props} />
}

export default Input
