import { Fieldset } from '~/components/forms'
import { FlexibleInputGroup } from '~/components/forms/formik'

const InvoicingFields = () => (
  <Fieldset>
    <h3>Invoicing</h3>
    <h4>Order reference prefixes</h4>
    <h5 className="mb-4">These prefixes help distinguish different order sources from each other</h5>

    <FlexibleInputGroup name="invoicePreferences.posPrefix" label="POS Invoice Prefix" />
    <FlexibleInputGroup name="invoicePreferences.mobilePrefix" label="Mobile Invoice Prefix" />
    <FlexibleInputGroup name="invoicePreferences.shopfrontPrefix" label="Shopfront Invoice Prefix" />
  </Fieldset>
)

export default InvoicingFields
