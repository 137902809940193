import styled from 'styled-components'
import { useFormik, FormikProvider } from 'formik'

import { ActionBar, Fieldset } from '~/components/forms'
import { BaseErrors, FlexibleInputGroup } from '~/components/forms/formik'
import useSubmitHandler from '~/containers/settings/submit-handler'
import { SettingsProviderState } from '~/contexts/settings'
import { ParcelSendPreferences, AccountSettingKeys } from '~/types/account-settings'
import useAuth from '~/contexts/useAuth'
import validations from './validations'
import User from '~/types/user'

const Container = styled.div`
  width: 600px;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

interface ParcelSendPreferencesFormProps {
  redirectPath: string
  initialValues?: ParcelSendPreferences
  settingsKey: AccountSettingKeys
  updateAction: SettingsProviderState['updateAction']
}

const modeOptions = [
  {
    value: 'testbed',
    label: 'Testing'
  },
  {
    value: 'production',
    label: 'Production'
  }
]

const ParcelSendPreferencesForm = (props: ParcelSendPreferencesFormProps) => {
  const { redirectPath, initialValues = {} } = props
  const { submitHandler } = useSubmitHandler()
  const { userProfile, setUserProfile } = useAuth()
  const formik = useFormik({
    initialValues: {
      mode: 'testbed',
      accountNum: '',
      apiKey: '',
      apiPassword: '',
      afourOffsetTop: '',
      afourOffsetLeft: '',
      asixOffsetTop: '',
      asixOffsetLeft: '',
      branded: false,
      ...initialValues,
      labelsSignature: userProfile?.auPostSignature || ''
    } as ParcelSendPreferences,
    validationSchema: validations,
    onSubmit: (values, helpers) => {
      const profile = {
        ...userProfile,
        auPostSignature: values.labelsSignature
      } as User

      setUserProfile(profile)

      return submitHandler<ParcelSendPreferences, ParcelSendPreferencesFormProps>(values, helpers, props)
    }
  })

  return (
    <FormikProvider value={formik}>
      <Wrapper>
        <Container>
          <h3 className="mb-4">Configure Parcel Send settings</h3>

          <BaseErrors />

          <FlexibleInputGroup name="mode" label="Mode" type="select" options={modeOptions} />
          <FlexibleInputGroup name="accountNum" label="Account No (10 digits)" type="text" />
          <FlexibleInputGroup name="apiKey" label="API Key" type="text" />
          <FlexibleInputGroup name="apiPassword" label="API Password" type="text" />
          <FlexibleInputGroup name="labelsSignature" label="Signature for Labels" type="text" />

          <Fieldset>
            <h4>Labels Preferences</h4>

            <FlexibleInputGroup name="afourOffsetTop" label="A4 Top Offset" type="text" />
            <FlexibleInputGroup name="afourOffsetLeft" label="A4 Left Offset" type="text" />
            <FlexibleInputGroup name="asixOffsetTop" label="A6 Top Offset" type="text" />
            <FlexibleInputGroup name="asixOffsetLeft" label="A6 Left Offset" type="text" />
            <FlexibleInputGroup name="branded" label="Branded" type="checkbox" checked={formik.values.branded} />
          </Fieldset>

          <ActionBar isSubmitting={formik.isSubmitting} redirectPath={redirectPath} onSubmit={formik.handleSubmit} />
        </Container>
      </Wrapper>
    </FormikProvider>
  )
}

export default ParcelSendPreferencesForm
