import styled from 'styled-components'
import { Field } from 'formik'

import BsInput from './input'

const Wrapper = styled.div`
  margin-top: 20px;
`

interface DropshipPricingSectionProps {
  shopfrontOnlyMode: boolean
}
const DropshipPricingSection = ({ shopfrontOnlyMode }: DropshipPricingSectionProps) => {
  if (!shopfrontOnlyMode) {
    return null
  }

  return (
    <Wrapper>
      <h4>Dropship Shipping Fees</h4>

      <table className="table table-bordered table-striped">
        <tbody>
          <tr>
            <th>Region</th>
            <td>ACT</td>
            <td>NSW_M</td>
            <td>NT_M</td>
            <td>QLD_M</td>
            <td>SA_M</td>
            <td>TAS_M</td>
            <td>VIC_M</td>
            <td>WA_M</td>
          </tr>
          <tr>
            <th>Price</th>
            <td>
              <Field name="dropshipFees.act" component={BsInput} />
            </td>
            <td>
              <Field name="dropshipFees.nswm" component={BsInput} />
            </td>
            <td>
              <Field name="dropshipFees.ntm" component={BsInput} />
            </td>
            <td>
              <Field name="dropshipFees.qldm" component={BsInput} />
            </td>
            <td>
              <Field name="dropshipFees.sam" component={BsInput} />
            </td>
            <td>
              <Field name="dropshipFees.tasm" component={BsInput} />
            </td>
            <td>
              <Field name="dropshipFees.vicm" component={BsInput} />
            </td>
            <td>
              <Field name="dropshipFees.wam" component={BsInput} />
            </td>
          </tr>
          <tr>
            <th>Region</th>
            <td>REMOTE</td>
            <td>NSW_R</td>
            <td>NT_R</td>
            <td>QLD_R</td>
            <td>SA_R</td>
            <td>TAS_R</td>
            <td>VIC_R</td>
            <td>WA_R</td>
          </tr>
          <tr>
            <th>Price</th>
            <td>
              <Field name="dropshipFees.remote" component={BsInput} />
            </td>
            <td>
              <Field name="dropshipFees.nswr" component={BsInput} />
            </td>
            <td>
              <Field name="dropshipFees.ntr" component={BsInput} />
            </td>
            <td>
              <Field name="dropshipFees.qldr" component={BsInput} />
            </td>
            <td>
              <Field name="dropshipFees.sar" component={BsInput} />
            </td>
            <td>
              <Field name="dropshipFees.tasr" component={BsInput} />
            </td>
            <td>
              <Field name="dropshipFees.vicr" component={BsInput} />
            </td>
            <td>
              <Field name="dropshipFees.war" component={BsInput} />
            </td>
          </tr>
        </tbody>
      </table>
    </Wrapper>
  )
}

export default DropshipPricingSection
