import styled from 'styled-components'
import { useFormik, FormikProvider } from 'formik'

import { ActionBar } from '~/components/forms'
import { FlexibleInputGroup, BaseErrors } from '~/components/forms/formik'
import countriesList from '~/lib/countries-list'
import useSubmitHandler from '~/containers/settings/submit-handler'
import validations from './validations'
import { SettingsProviderState } from '~/contexts/settings'
import { SellerProfile, AccountSettingKeys } from '~/types/account-settings'

const Container = styled.div`
  width: 600px;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

interface SellerProfileFormProps {
  redirectPath: string
  initialValues: SellerProfile
  settingsKey: AccountSettingKeys
  updateAction: SettingsProviderState['updateAction']
}

const SellerProfileForm = (props: SellerProfileFormProps) => {
  const { redirectPath, initialValues } = props
  const { submitHandler } = useSubmitHandler()
  const formik = useFormik({
    initialValues: {
      firstName: initialValues.firstName || '',
      lastName: initialValues.lastName || '',
      businessName: initialValues.businessName || '',
      address1: initialValues.address1 || '',
      address2: initialValues.address2 || '',
      city: initialValues.city || '',
      state: initialValues.state || '',
      postcode: initialValues.postcode || '',
      countryCode: initialValues.countryCode || 'AU',
      phone: initialValues.phone || '',
      email: initialValues.email || ''
    } as SellerProfile,
    validationSchema: validations,
    onSubmit: (values, helpers) => submitHandler<SellerProfile, SellerProfileFormProps>(values, helpers, props)
  })

  return (
    <FormikProvider value={formik}>
      <Wrapper>
        <Container>
          <h3 className="mb-4">These details will appear on invoices and orders</h3>

          <BaseErrors />

          <FlexibleInputGroup name="firstName" />
          <FlexibleInputGroup name="lastName" />
          <FlexibleInputGroup name="businessName" />
          <FlexibleInputGroup name="address1" />
          <FlexibleInputGroup name="address2" />
          <FlexibleInputGroup name="city" />
          <FlexibleInputGroup name="state" />
          <FlexibleInputGroup name="postcode" />
          <FlexibleInputGroup name="countryCode" type="select" options={countriesList} />
          <FlexibleInputGroup name="phone" />
          <FlexibleInputGroup name="email" />

          <ActionBar isSubmitting={formik.isSubmitting} redirectPath={redirectPath} onSubmit={formik.handleSubmit} />
        </Container>
      </Wrapper>
    </FormikProvider>
  )
}

export default SellerProfileForm
