import { ChangeEvent } from 'react'
import styled from 'styled-components'
import { useFormik, FormikProvider } from 'formik'
import _ from 'lodash'
import { Alert } from 'react-bootstrap'

import { ActionBar, Fieldset } from '~/components/forms'
import { BaseErrors, FlexibleInputGroup } from '~/components/forms/formik'
import useSettings from '~/contexts/useSettings'
import useCountryCodes from '~/contexts/useCountryCodes'
import useSubmitHandler from '~/containers/settings/submit-handler'
import { BASE_URL } from '~/api/constants'
import validations from './validations'
import { ShopfrontConfig, ShopfrontShippingCountriesOptions, AccountSettingKeys } from '~/types/account-settings'
import { SettingsProviderState } from '~/contexts/settings'

export const Container = styled.div`
  width: 800px;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

const ImagePreviewBox = styled.div`
  margin-left: 100px;
  margin-top: 20px;
  border: 1px solid #ccc;
  padding: 20px;
  text-align: center;
`

const shippingCountriesOptions = [
  { label: 'Worldwide', value: ShopfrontShippingCountriesOptions.ALL },
  { label: 'Worldwide, excluding selected countries', value: ShopfrontShippingCountriesOptions.EXCLUDE },
  { label: 'Only selected countries', value: ShopfrontShippingCountriesOptions.ONLY }
]

const getShippingCountriesLabel = (option: ShopfrontShippingCountriesOptions) => {
  let label = 'Select Countries'
  if (option === ShopfrontShippingCountriesOptions.EXCLUDE) {
    label = 'Exclude Countries'
  }
  return label
}

const stripPort = (url?: string) => {
  return (url || '').toString().split(':')[0]
}

const stripScheme = (url?: string) => {
  return (url || '').toString().replace(/^https?:\/\//, '')
}

interface FormProps extends ShopfrontConfig {
  imagePreviewUrlSM?: string
  imagePreviewUrlLG?: string
}

interface ShopfrontPreferencesFormProps {
  redirectPath: string
  initialValues: FormProps
  settingsKey: AccountSettingKeys
  updateAction: SettingsProviderState['updateAction']
}

const ShopfrontPreferencesForm = (props: ShopfrontPreferencesFormProps) => {
  const { getCountryOptions } = useCountryCodes()
  const { settings } = useSettings()
  const { initialValues, redirectPath } = props
  const { submitHandler } = useSubmitHandler()
  const formik = useFormik({
    initialValues,
    validationSchema: validations,
    onSubmit: (values, helpers) => submitHandler<FormProps, ShopfrontPreferencesFormProps>(values, helpers, props)
  })

  const { values, isSubmitting, setFieldValue, handleSubmit, getFieldProps } = formik

  const onImageChange = (e: ChangeEvent<HTMLInputElement>, size: 'sm' | 'lg') => {
    const imageFile = e.target.files && e.target.files[0]
    if (imageFile) {
      const reader = new FileReader()
      reader.onload = () => {
        setFieldValue(`imagePreviewUrl${size.toUpperCase()}`, reader.result)
        setFieldValue(`bannerImage${_.capitalize(size)}`, imageFile)
      }
      if (imageFile) reader.readAsDataURL(imageFile)
    }
  }

  const imagePreviewUrlLG = values.imagePreviewUrlLG || settings?.shopfront.bannerImageLgUrl
  const imagePreviewUrlSM = values.imagePreviewUrlSM || settings?.shopfront.bannerImageSmUrl

  return (
    <FormikProvider value={formik}>
      <Wrapper>
        <Container>
          <Alert variant="info">
            <p>
              <strong>NOTE:</strong> make sure you create the following matching DNS entries which point to our server.
              E.g.
            </p>

            <p>
              CNAME {stripPort(values.shopfrontDomain)} {'==>'} {`shopfront.${settings?.superDomain}`}
            </p>

            <p>
              CNAME {`shopfront-api.${stripPort(values.shopfrontDomain)}`} {'==>'} {stripScheme(BASE_URL)}
            </p>
          </Alert>

          <BaseErrors />

          <Fieldset>
            <h3>Select Shipping Countries</h3>
            <FlexibleInputGroup
              type="radioGroup"
              options={shippingCountriesOptions}
              label=""
              {...getFieldProps('shopfrontPreferences.shippingCountriesOption')}
            />
            {['exclude', 'only'].includes(values.shopfrontPreferences.shippingCountriesOption) && (
              <FlexibleInputGroup
                isMulti
                type="select"
                label={getShippingCountriesLabel(values.shopfrontPreferences.shippingCountriesOption)}
                options={getCountryOptions()}
                {...getFieldProps('shopfrontPreferences.shippingCountriesOptionData')}
              />
            )}
          </Fieldset>

          <Fieldset>
            <h3>Store Settings for Shopfront</h3>
            <FlexibleInputGroup label="Shopfront Domain" {...getFieldProps('shopfrontDomain')} />
            <FlexibleInputGroup
              label="Default Page Title"
              {...getFieldProps('shopfrontPreferences.defaultPageTitle')}
            />
            <FlexibleInputGroup
              label="Default Meta Description"
              {...getFieldProps('shopfrontPreferences.defaultMetaDescription')}
            />
            <FlexibleInputGroup
              label="Google Analytics ID"
              {...getFieldProps('shopfrontPreferences.googleAnalyticsId')}
            />
            <FlexibleInputGroup
              label="Google Tag Manager ID"
              {...getFieldProps('shopfrontPreferences.googleTagManagerId')}
            />
            <FlexibleInputGroup
              label="Google Site Verification ID"
              {...getFieldProps('shopfrontPreferences.googleSiteVerification')}
            />
            <FlexibleInputGroup label="Google Maps API Key" {...getFieldProps('shopfrontPreferences.gmapsApiKey')} />
          </Fieldset>

          <Fieldset>
            <h3>Shopfront Banner Images</h3>
            <div>
              <h4>Mobile</h4>
              <FlexibleInputGroup
                label=""
                name="tempBannerImageSm"
                type="file"
                accept="image/*"
                onChange={(e: ChangeEvent<HTMLInputElement>) => onImageChange(e, 'sm')}
              />

              <ImagePreviewBox>
                {imagePreviewUrlSM ? (
                  <img
                    src={imagePreviewUrlSM}
                    width="100"
                    style={{ visibility: 'visible' }}
                    alt="shopfront banner for mobile"
                  />
                ) : (
                  <span>Please select an image to preview</span>
                )}
              </ImagePreviewBox>
            </div>
            <div>
              <h4>Desktop</h4>
              <FlexibleInputGroup
                label=""
                name="tempBannerImageLg"
                type="file"
                accept="image/*"
                onChange={(e: ChangeEvent<HTMLInputElement>) => onImageChange(e, 'lg')}
              />

              <ImagePreviewBox>
                {imagePreviewUrlLG ? (
                  <img
                    src={imagePreviewUrlLG}
                    width="200"
                    style={{ visibility: 'visible' }}
                    alt="shopfront banner for desktop"
                  />
                ) : (
                  <span>Please select an image to preview</span>
                )}
              </ImagePreviewBox>
            </div>
          </Fieldset>

          <ActionBar isSubmitting={isSubmitting} redirectPath={redirectPath} onSubmit={handleSubmit} />
        </Container>
      </Wrapper>
    </FormikProvider>
  )
}

export default ShopfrontPreferencesForm
