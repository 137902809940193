import { toast } from 'react-toastify'
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'

import ShopfrontPage from '~/types/shopfront-page'

export function fetchAllShopfrontPages() {
  return axios.get<ShopfrontPage[]>(`/api/settings/shopfront_pages`)
}

export function createShopfrontPage(params: Partial<ShopfrontPage>) {
  return axios.post<ShopfrontPage>(`/api/settings/shopfront_pages`, {
    shopfrontPage: params
  })
}

export function updateShopfrontPage(params: Partial<ShopfrontPage>) {
  return axios.put<ShopfrontPage>(`/api/settings/shopfront_pages/${params.id}`, {
    shopfrontPage: params
  })
}

export function sortShopfrontPagesList(params: number[]) {
  return axios.patch(`/api/settings/shopfront_pages/sort_items`, {
    ordered_ids: params
  })
}

export function destroyShopfrontPage(id: number) {
  return axios.delete(`/api/settings/shopfront_pages/${id}`)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const loadItems = createAsyncThunk<ShopfrontPage[], void, { rejectValue: any }>(
  'shopfrontPages/loadItems',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchAllShopfrontPages()
      return response.data
    } catch (error) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addOrUpdateItem = createAsyncThunk<ShopfrontPage, Partial<ShopfrontPage>, { rejectValue: any }>(
  'shopfrontPages/addOrUpdateItem',
  async (item, { rejectWithValue }) => {
    try {
      const response = item.id ? await updateShopfrontPage(item) : await createShopfrontPage(item)
      return response.data
    } catch (error) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reSortItems = createAsyncThunk<number[], number[], { rejectValue: any }>(
  'shopfrontPages/reSortItems',
  async (orderIds, { rejectWithValue }) => {
    try {
      await sortShopfrontPagesList(orderIds)
      return orderIds
    } catch (error) {
      toast.error('Error: failed to sort.')
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteItem = createAsyncThunk<ShopfrontPage, ShopfrontPage, { rejectValue: any }>(
  'shopfrontPages/deleteItem',
  async (shopfrontPage, { rejectWithValue }) => {
    try {
      await destroyShopfrontPage(shopfrontPage.id)
      return shopfrontPage
    } catch (error) {
      toast.error('Error: failed to delete.')
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)
