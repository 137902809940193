import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import CsvImport from '~/types/csv-import'

export interface ProductsImportPageState {
  loading: boolean
  lastCsvImport?: CsvImport
  fileUploading: boolean
}

const initialState: ProductsImportPageState = {
  loading: false,
  lastCsvImport: undefined,
  fileUploading: false
}

const productsImportPageSlice = createSlice({
  name: 'productsImportPage',
  initialState,
  reducers: {
    setLastCsvImport: (state, action: PayloadAction<CsvImport | undefined>) => {
      state.lastCsvImport = action.payload
    },
    setFileUploading: (state, action: PayloadAction<boolean>) => {
      state.fileUploading = action.payload
    }
  }
})

export const { setLastCsvImport, setFileUploading } = productsImportPageSlice.actions

export default productsImportPageSlice.reducer
