import { toast } from 'react-toastify'
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'

import ShippingPricingProfile from '~/types/shipping-pricing-profile'

export const fetchShippingPricingProfiles = () =>
  axios.get<ShippingPricingProfile[]>(`/api/settings/shipping_pricing_profiles`)

export function createShippingPricingProfile(params: Partial<ShippingPricingProfile>) {
  return axios.post<ShippingPricingProfile>(`/api/settings/shipping_pricing_profiles`, {
    shippingPricingProfile: params
  })
}

export function updateShippingPricingProfile(params: Partial<ShippingPricingProfile>) {
  return axios.put<ShippingPricingProfile>(`/api/settings/shipping_pricing_profiles/${params.id}`, {
    shippingPricingProfile: params
  })
}

export function destroyShippingPricingProfile(id: number) {
  return axios.delete(`/api/settings/shipping_pricing_profiles/${id}`)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const loadItems = createAsyncThunk<ShippingPricingProfile[], void, { rejectValue: any }>(
  'shippingProfiles/loadItems',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchShippingPricingProfiles()
      return response.data
    } catch (error) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

export const addOrUpdateItem = createAsyncThunk<
  ShippingPricingProfile,
  Partial<ShippingPricingProfile>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { rejectValue: any }
>('shippingProfiles/addOrUpdateItem', async (item, { rejectWithValue }) => {
  try {
    const response = item.id ? await updateShippingPricingProfile(item) : await createShippingPricingProfile(item)

    return response.data
  } catch (error) {
    if (error instanceof AxiosError) {
      return rejectWithValue(error.response?.data)
    }
    return rejectWithValue('An unknown error occurred')
  }
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteItem = createAsyncThunk<ShippingPricingProfile, ShippingPricingProfile, { rejectValue: any }>(
  'shippingProfiles/deleteItem',
  async (shippingPricingProfile: ShippingPricingProfile, { rejectWithValue }) => {
    try {
      await destroyShippingPricingProfile(shippingPricingProfile.id)
      return shippingPricingProfile
    } catch (error) {
      toast.error('Error: failed to delete.')
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)
