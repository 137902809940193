import { useState, useEffect, useCallback } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import _ from 'lodash'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Modal, ModalProps } from 'react-bootstrap'

import { Button } from '~/components/forms'
import Icon from '~/components/icon'

export type ConfirmProps = {
  className?: string
  message?: string | React.ReactNode
  kind?: 'primary' | 'info' | 'success' | 'error' | 'warning'
  title?: string
  autoDismiss?: boolean
  dismissable?: boolean
  onActualConfirm?: () => Promise<void> | void
  onConfirm?: () => Promise<void> | void
  onDismiss?: () => void
} & ModalProps

const TIMEOUT_DELAY = 10000
const CLOSE_DELAY = 250

const kindToIcon: Record<string, IconProp> = {
  primary: 'circle-exclamation',
  info: 'circle-exclamation',
  error: 'circle-exclamation',
  success: 'circle-check',
  warning: 'circle-exclamation'
}

const Confirm = ({
  className,
  title,
  dismissable = true,
  kind = 'primary',
  onDismiss,
  onConfirm,
  onActualConfirm,
  autoDismiss = false,
  message,
  ...rest
}: ConfirmProps) => {
  const [isClosed, setIsClosed] = useState(false)
  const [confirming, setConfirming] = useState(false)

  const onDismissClick = useCallback(() => {
    setIsClosed(true)
    onDismiss?.()
  }, [onDismiss])

  const dismissWithDelay = useDebouncedCallback(() => {
    setIsClosed(true)
    if (onDismiss) {
      setTimeout(onDismiss, CLOSE_DELAY)
    }
  }, TIMEOUT_DELAY)

  useEffect(() => {
    if (autoDismiss) {
      dismissWithDelay()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isClosed) return <></>

  return (
    <Modal centered backdrop="static" show={!isClosed} onHide={() => onDismissClick()} {...rest}>
      {title && (
        <Modal.Header closeButton>
          <Modal.Title>
            <Icon icon={kindToIcon[kind]} />
            {title}
          </Modal.Title>
        </Modal.Header>
      )}

      {message && <Modal.Body>{message}</Modal.Body>}

      <Modal.Footer>
        <Button variant="secondary" onClick={() => onDismissClick()}>
          Cancel
        </Button>
        <Button
          variant={kind}
          isSubmitting={confirming}
          onClick={async () => {
            setConfirming(true)
            await onConfirm?.()
            setConfirming(false)
            onActualConfirm?.()
          }}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default Confirm
