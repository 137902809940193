import { MouseEvent, useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import _ from 'lodash'
import moment from 'moment'
import { AxiosError } from 'axios'
import { Alert } from 'react-bootstrap'

import { Button } from '~/components/forms'

import { clearEbayAccessToken, acceptEbayAccessToken, refreshEbayAccessToken } from '~/api/settings'

const isDevelopment = import.meta.env.VITE_ENV === 'development'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Container = styled.div`
  width: 600px;
`

const ErrorMsg = styled.div`
  color: red;
  border: 1px solid red;
  padding: 20px;
  margin-bottom: 20px;
`

interface EbaySettingsDetailViewProps {
  accessToken?: string
  expiresAt?: string
  refreshTokenExpiresAt?: string
  ebayConnectUrl: string
  superDomain: string
  setSettings: () => void
}

const EbaySettingsDetailView = ({
  accessToken,
  expiresAt,
  refreshTokenExpiresAt,
  ebayConnectUrl,
  superDomain,
  setSettings
}: EbaySettingsDetailViewProps) => {
  const [connectBtnActive, setConnectBtnActive] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const handleClickClear = useCallback(
    (e: MouseEvent) => {
      e.preventDefault()
      console.log('[INFO]', 'clearing ebay accessToken')
      const onSuccess = () => toast.success('Success!')
      const onError = (e: AxiosError) => {
        const errorMessage = _.get(e, 'response.data.message')
        console.log('[DEBUG] e =', e)
        toast.error('Error occurred!')
        if (errorMessage) {
          setErrorMessage(errorMessage)
        }
      }
      clearEbayAccessToken().then(setSettings).then(onSuccess).catch(onError)
    },
    [setSettings]
  )

  const handleClickRefresh = useCallback(
    (e: MouseEvent) => {
      e.preventDefault()
      console.log('[INFO]', 'refreshing ebay accessToken')
      const onSuccess = () => toast.success('Success!')
      const onError = (e: AxiosError) => {
        const errorMessage = _.get(e, 'response.data.message')
        console.log('[DEBUG] e =', e)
        toast.error('Error occurred!')
        if (errorMessage) {
          setErrorMessage(errorMessage)
        }
      }
      refreshEbayAccessToken().then(setSettings).then(onSuccess).catch(onError)
    },
    [setSettings]
  )

  const handleClickConnect = useCallback(
    (e: MouseEvent) => {
      e.preventDefault()
      setConnectBtnActive(false)

      window.open(
        ebayConnectUrl,
        'Connect to eBay',
        'toolbars=0,width=400,height=640,left=200,top=200,scrollbars=1,resizable=1'
      )
    },
    [ebayConnectUrl]
  )

  const renderConnected = () => {
    const accessTokenExpiryDate = moment(expiresAt)
    const refreshTokenExpiryDate = moment(refreshTokenExpiresAt)
    const currentDate = moment()

    return (
      <div>
        <h4>You are connected to eBay</h4>
        <Button onClick={handleClickClear}>Clear eBay token</Button>
        {(expiresAt || accessTokenExpiryDate < currentDate) && (
          <Button onClick={handleClickRefresh}>Refresh eBay token</Button>
        )}

        <br />
        <br />
        <p>
          <strong>User Access Token</strong>
        </p>
        {expiresAt && <p>Expires at {accessTokenExpiryDate.toString()}</p>}
        {refreshTokenExpiresAt && <p>Refresh expires at {refreshTokenExpiryDate.toString()}</p>}
        <pre>{accessToken}</pre>
      </div>
    )
  }

  const renderNotConnected = () => {
    return (
      <div>
        <h4>You are not connected to eBay</h4>
        <Button disabled={!connectBtnActive} onClick={handleClickConnect}>
          {connectBtnActive ? 'Connect to eBay' : 'Waiting...'}
        </Button>
      </div>
    )
  }

  useEffect(() => {
    console.log('[DEBUG] useEffect')

    const onSuccess = () => {
      toast.success('Success!')
      setConnectBtnActive(true)
    }

    const onError = (e: AxiosError) => {
      const errorMessage = _.get(e, 'response.data.message')
      console.log('[DEBUG] e =', e)
      toast.error('Error occurred!')
      setConnectBtnActive(true)
      if (errorMessage) {
        setErrorMessage(errorMessage)
      }
    }

    const listener = (event: MessageEvent) => {
      console.log('[DEBUG] event.origin =', event.origin, event.data)

      //hardcoding preparcel.com, because the eBay sandbox will always use this, and there's nothing else we cna use for staging
      if (!(event.origin.includes(superDomain) || (isDevelopment && event.origin.includes('preparcel.com')))) {
        console.log('[DEBUG] ignoring message from', event.origin)
        return
      }

      const { token } = event.data

      if (token) {
        acceptEbayAccessToken(token).then(setSettings).then(onSuccess).catch(onError)
      }
      // May be blank if cross-origin request to the frame is blocked
      else {
        toast.error('Failed to fetch access token from popup')
      }
    }

    window.addEventListener('message', listener)

    return () => {
      window.removeEventListener('message', listener)
    }
  }, [setSettings, superDomain])

  const refreshTokenExpiryDate = refreshTokenExpiresAt != null ? new Date(refreshTokenExpiresAt) : ''
  const currentDate = new Date()

  return (
    <Wrapper>
      <Container>
        <Alert variant="info">
          You can connect your Postlabels store with eBay, to allow automatic sending of tracking information to eBay
        </Alert>
        {errorMessage && <ErrorMsg>{errorMessage}</ErrorMsg>}
        {accessToken && (!refreshTokenExpiresAt || refreshTokenExpiryDate > currentDate)
          ? renderConnected()
          : renderNotConnected()}
      </Container>
    </Wrapper>
  )
}

export default EbaySettingsDetailView
