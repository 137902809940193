import styled from 'styled-components'
import { useFormik, FormikProvider } from 'formik'
import { object, string } from 'yup'

import { ActionBar } from '~/components/forms'
import { BaseErrors, FlexibleInputGroup } from '~/components/forms/formik'
import countriesList from '~/lib/countries-list'
import useSubmitHandler from '~/containers/settings/submit-handler'
import { SettingsProviderState } from '~/contexts/settings'
import { CustomsDefaults, AccountSettingKeys } from '~/types/account-settings'

const originOptions = [{ label: '---', value: '' }, ...countriesList]

const classificationOptions = [
  { label: '---', value: '' },
  { label: 'Sale of Goods', value: 'sale' },
  { label: 'Gift', value: 'gift' },
  { label: 'Document', value: 'document' },
  { label: 'Commercial Sample', value: 'commercial_sample' },
  { label: 'Returned Goods', value: 'returned_goods' },
  { label: 'Other', value: 'other' }
]

const Container = styled.div`
  width: 600px;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

interface CustomsFormProps {
  redirectPath: string
  initialValues: CustomsDefaults
  settingsKey: AccountSettingKeys
  updateAction: SettingsProviderState['updateAction']
}

const validationSchema = object().shape({
  origin: string().required('Origin is required'),
  classification: string().required('Classification is required'),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  classificationExplanation: string().when('classification', (v: any) => {
    if (v === 'other') return string().required('Classification explanation is required')
    else return string().nullable()
  })
})

const CustomsForm = (props: CustomsFormProps) => {
  const { submitHandler } = useSubmitHandler()
  const { redirectPath, initialValues } = props

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, helpers) => submitHandler<CustomsDefaults, CustomsFormProps>(values, helpers, props)
  })

  const { handleSubmit, isSubmitting } = formik

  return (
    <FormikProvider value={formik}>
      <Wrapper>
        <Container>
          <h3 className="mb-4">These defaults will be used when importing orders from eBay</h3>

          <BaseErrors />

          <FlexibleInputGroup type="select" options={originOptions} {...formik.getFieldProps('origin')} />
          <FlexibleInputGroup
            type="select"
            options={classificationOptions}
            {...formik.getFieldProps('classification')}
          />
          <FlexibleInputGroup {...formik.getFieldProps('classificationExplanation')} />

          <ActionBar isSubmitting={isSubmitting} redirectPath={redirectPath} onSubmit={handleSubmit} />
        </Container>
      </Wrapper>
    </FormikProvider>
  )
}

export default CustomsForm
