import _ from 'lodash'
import styled from 'styled-components'
import classnames from 'classnames'

import { OrderShippingData } from './form'
import Icon from '~/components/icon'

const Content = styled.div`
  display: flex;
`
const Box = styled.div`
  margin-left: -10px;
  margin-top: -10px;
  padding: 10px 60px 10px 10px;
  font-size: 14px;
  min-width: 400px;
  position: relative;

  &.has-buttons:hover {
    background: #eee;
  }

  &.has-error {
    border: 1px solid red;
    border-radius: 4px;
  }
`
const Name = styled.div`
  margin-bottom: 5px;
`
const L = styled.div`
  margin-bottom: 5px;
`
const A = styled.div`
  justify-content: flex-start;
`
const B = styled.div`
  margin-left: 40px;
  justify-content: flex-start;
`
const C = styled.div`
  margin-left: 20px;
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;

  // target parent's hover
  ${Box}:hover & {
    display: block;
  }
`

interface ICustomerDetailsBoxProps {
  orderShippingData: OrderShippingData
  onEdit?: () => void
  onRemove?: () => void
  errors?: Record<string, unknown>
}

const CustomerDetailsBox = ({ orderShippingData, onEdit, onRemove, errors = {} }: ICustomerDetailsBoxProps) => {
  const hasError = !_.isEmpty(errors)
  const className = classnames({
    'has-error': hasError,
    'has-buttons': onEdit || onRemove
  })

  return (
    <Box className={className}>
      <Content>
        <A>
          <Name>{orderShippingData.shippingContactName}</Name>
          <L>{orderShippingData.shippingAddress1 || ''}</L>
          <L>{orderShippingData.shippingAddress2 || ''}</L>
          <L>{orderShippingData.shippingAddress3 || ''}</L>
          <L>
            {orderShippingData.shippingCity || ''},{' '}
            {(orderShippingData.shippingState || '') + (orderShippingData.shippingState ? ',' : '')}{' '}
            {orderShippingData.shippingPostcode}
          </L>
          <L>{orderShippingData.shippingCountryName}</L>
        </A>
        <B>
          <L>{orderShippingData.customerEmail}</L>
          <L>{orderShippingData.shippingContactPhone}</L>
        </B>
        {(onEdit || onRemove) && (
          <C className="d-flex gap-2">
            <Icon icon="pencil-alt" className="text-primary cursor-pointer" title="Edit" onClick={onEdit} />
            <Icon icon="times" className="text-danger cursor-pointer" title="Remove" onClick={onRemove} />
          </C>
        )}
      </Content>
      {hasError && <span className="text-danger">Please edit customer and fix errors</span>}
    </Box>
  )
}

export default CustomerDetailsBox
