import { FieldLayoutProps } from './field-layout'

// NOTE: keep these keys in sync with field-layout.ts
export const fieldLayoutKeys: Array<keyof FieldLayoutProps> = [
  'field',
  'form',
  'id',
  'label',
  'labelPlacement',
  'helpBlock',
  'required',
  'customErrors',
  'children',
  'formatFn',
  'wrapperClassName',
  'labelClassName',
  'inputWrapperClassName'
]
