import { useState, useEffect } from 'react'

interface TripleClickHandlerProps {
  onTripleClick: () => void
  children: React.ReactNode
}

const TripleClickHandler = ({ onTripleClick, children }: TripleClickHandlerProps) => {
  const [clicks, setClicks] = useState(0)

  useEffect(() => {
    if (clicks === 3) {
      onTripleClick()
      setClicks(0) // Reset the click count
    }
  }, [clicks, onTripleClick])

  const handleClick = () => {
    setClicks(prevClicks => prevClicks + 1)
  }

  return <div onClick={handleClick}>{children}</div>
}

export default TripleClickHandler
