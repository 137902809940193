import { ReactNode, useState, useEffect, createContext } from 'react'
import axios from 'axios'
import _ from 'lodash'

import InternationalCountryCode from '~/types/international-country-code'
import LocalStorage from '~/lib/utils/local-storage'
import useAuth from '~/contexts/useAuth'
import intlCountryList from '~/lib/intl-countries-list'

interface CountryCodesContextType {
  countryCodesPending: boolean
  countryCodes: InternationalCountryCode[]
  getCountryOptions: () => { label: string; value: string }[]
}

const defaultState = {
  countryCodesPending: false,
  countryCodes: [],
  getCountryOptions: () => []
}

const CountryCodesContext = createContext<CountryCodesContextType>(defaultState)

interface CountryCodesProviderProps {
  children?: ReactNode
}

const CountryCodesProvider = ({ children }: CountryCodesProviderProps) => {
  const [countryCodesPending, setCountryCodesPending] = useState<boolean>(false)
  const [countryCodes, setCountryCodes] = useState<InternationalCountryCode[]>([])
  const { isLoggedIn } = useAuth()

  useEffect(() => {
    if (isLoggedIn) {
      refreshCountryCodes()
    }
  }, [isLoggedIn])

  const refreshCountryCodes = async () => {
    setCountryCodesPending(true)
    return LocalStorage.get<InternationalCountryCode[]>('countryCodes')
      .then(data => {
        if (data === null) {
          return axios
            .get<InternationalCountryCode[]>('/api/country_codes')
            .then(response => {
              const { data } = response
              if (data.length > 0) {
                LocalStorage.set<InternationalCountryCode[]>('countryCodes', data)
                setCountryCodes(data)
                setCountryCodesPending(false)
              }
              return Promise.reject(new Error('Country codes are not setup'))
            })
            .catch(error => {
              console.error('Fetching from API failed:', error)
              return Promise.reject(error)
            })
        }
        setCountryCodes(data)
        setCountryCodesPending(false)
      })
      .catch(err => {
        console.error('Fetching from localStorage failed:', err)
        setCountryCodesPending(false)
        return Promise.reject()
      })
  }

  const getCountryOptions = () => {
    if (countryCodes.length === 0) return intlCountryList

    return _.chain(countryCodes)
      .map(({ countryName, countryCode }) => ({
        label: _.startCase(countryName.toLowerCase()),
        value: countryCode
      }))
      .sortBy('label')
      .value()
  }

  const contextValue = {
    countryCodesPending,
    countryCodes,
    refreshCountryCodes,
    setCountryCodes,
    getCountryOptions
  }

  return <CountryCodesContext.Provider value={contextValue}>{children}</CountryCodesContext.Provider>
}

export default CountryCodesContext
export { CountryCodesProvider }
