import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Button, LinkButton } from '~/components/forms'
import { Table, Thead, Tbody, Tr, Th, Td } from '~/components/table'
import User from '~/types/user'

const Wrapper = styled.div`
  width: 600px;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`

interface ItemProps {
  item: User
  onDelete: (item: User) => void
}
const Item = ({ item, onDelete }: ItemProps) => (
  <Tr key={item.id}>
    <Td>{item.kind}</Td>
    <Td>{item.fullName}</Td>
    <Td>{item.email}</Td>
    <Td className="action-buttons">
      <LinkButton to={`/users/${item.id}/edit`}>Edit</LinkButton>
      <Button variant="link" onClick={() => onDelete(item)}>
        Delete
      </Button>
    </Td>
  </Tr>
)

interface ListViewProps {
  items: User[]
  onDelete: (item: User) => void
}
const ListView = ({ items, onDelete }: ListViewProps) => (
  <Wrapper>
    <Header>
      <h1>Users</h1>
      <Link className="new-record-btn" to="/users/new">
        <Button>New</Button>
      </Link>
    </Header>

    <Table>
      <Thead>
        <Tr>
          <Th>Kind</Th>
          <Th>Name</Th>
          <Th>Email</Th>
          <Th />
        </Tr>
      </Thead>

      <Tbody>
        {items.map(item => (
          <Item key={item.id} item={item} onDelete={onDelete} />
        ))}
      </Tbody>
    </Table>
  </Wrapper>
)

export default ListView
