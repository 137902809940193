import styled from 'styled-components'

import useAuth from '~/contexts/useAuth'

const Intro = styled.div`
  border-radius: 4px;
  margin-top: 0px;
  margin-bottom: 25px;
  padding: 12px 20px 15px 20px;
  background: #f1f4f7;
`

const HomePage = () => {
  const { userProfile } = useAuth()

  if (!userProfile) {
    return null
  }

  return (
    <Intro>
      <h2>POST LABELS PLUS ... Keeping your eBay store organised</h2>
      <p>Welcome, {userProfile.fullName}</p>
      <p>Post Labels Plus is designed to help integrate your eBay Store with Australia Post.</p>
    </Intro>
  )
}

export default HomePage
