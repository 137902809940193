import ToggleActive from './toggle-active'
import MenuItem, { MenuItemKindEnum } from '~/types/menu-item'

import { fetchRecord } from '~/async-actions/menu-items-async-actions'

export const formConfig = {
  fetchRecord,
  recordPath: 'menuItems.recordToEdit'
}

export const getColumnsConfig = (kind: MenuItemKindEnum) => [
  {
    attr: 'item.label',
    heading: 'Label',
    locked: true,
    render: (record: MenuItem) => {
      if (record.searchLink === null) return ''
      return record.label || record.searchLink.name
    }
  },
  {
    attr: 'item.coverImage',
    heading: 'Image',
    locked: true,
    render: (record: MenuItem) =>
      record.searchLink && record.searchLink.coverImageUrl && record.parentId !== null ? (
        <img src={record.searchLink.coverImageUrl} alt={`item${record.id}CoverImage`} width="25" />
      ) : (
        ''
      )
  },
  {
    attr: 'item.categories',
    heading: 'Categories',
    locked: true,
    render: (record: MenuItem) => ((record.searchLink || {}).categories || []).join(', ')
  },
  {
    attr: 'item.brand',
    heading: 'Brand',
    locked: true,
    render: (record: MenuItem) => (record.searchLink || {}).brand
  },
  {
    attr: 'searchTerm',
    locked: true
  },
  {
    attr: 'active',
    locked: true,
    render: (record: MenuItem) => <ToggleActive record={record} kind={kind} />
  }
]
