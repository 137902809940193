import useSettings from '~/contexts/useSettings'
import Spinner from '~/components/spinner'
import EparcelsDefaultsForm from './form'

const EparcelDefaultsSettingsPage = () => {
  const { settings, settingsPending, updateAction } = useSettings()
  return settingsPending ? (
    <Spinner />
  ) : settings == null ? null : (
    <EparcelsDefaultsForm
      initialValues={settings.eparcelsDefaults}
      updateAction={updateAction}
      settingsKey="eparcelsDefaults"
      redirectPath="/settings"
    />
  )
}

export default EparcelDefaultsSettingsPage
