import styled from 'styled-components'

const H1 = styled.h1`
  font-family: 'Gill Sans', sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: #444;
`

interface PageHeadingProps {
  title: string
}
const PageHeading = ({ title }: PageHeadingProps) => (
  <div>
    <H1>{title}</H1>
  </div>
)

export default PageHeading
