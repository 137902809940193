import styled from 'styled-components'
import { useRef, useEffect } from 'react'
import type { XYCoord, Identifier } from 'dnd-core'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useDrag, useDrop } from 'react-dnd'

import Icon from '~/components/icon'
import { FlexibleInputGroup as _FlexibleInputGroup } from '~/components/forms/formik'
import { AuPostInternationalChargeCode } from '~/types/account-settings'

const FlexibleInputGroup = styled(_FlexibleInputGroup)`
  flex: 1;
`
const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
`
const StyledIcon = styled(Icon)`
  cursor: pointer;
  display: block;
  margin-right: 2rem;

  &:hover {
    color: ${props => props.theme.colors.primary.base};
  }
`

interface DraggableItemProps {
  index: number
  type: string
}

// const SortableDragHandle = SortableHandle(() => <StyledIcon icon="bars" />)

interface SortableItemProps {
  index: number
  onSortEnd?: (dragIndex: number, hoverIndex: number) => void
  chargeCode: AuPostInternationalChargeCode
}

const SortableItem = ({ chargeCode, index, onSortEnd }: SortableItemProps) => {
  const ref = useRef<HTMLDivElement>(null)

  const [{ handlerId }, drop] = useDrop<DraggableItemProps, void, { handlerId: Identifier | null }>({
    accept: 'div',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover(item: DraggableItemProps, monitor) {
      if (!ref.current) return

      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) return

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleX = hoverBoundingRect && (hoverBoundingRect.right - hoverBoundingRect.left) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientX = hoverBoundingRect && (clientOffset as XYCoord).x - hoverBoundingRect.left
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) return
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) return

      onSortEnd?.(dragIndex, hoverIndex)
      item.index = hoverIndex
    }
  })

  const [dragStyles, drag, preview] = useDrag({
    type: 'div',
    item: () => {
      return { index }
    },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  })

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview])

  drag(drop(ref))

  return (
    <ItemWrapper ref={ref} data-handler-id={handlerId} style={dragStyles}>
      <StyledIcon icon="bars" />
      <FlexibleInputGroup name={`international[${index}].val`} label={chargeCode.label} marginBottom={0} />
    </ItemWrapper>
  )
}

export default SortableItem
