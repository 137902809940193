import { useState, useCallback, useRef } from 'react'
import { useDropzone } from 'react-dropzone'
import axios, { AxiosResponse, CancelTokenSource } from 'axios'
import styled from 'styled-components'
import _ from 'lodash'
import { toast } from 'react-toastify'

import { Button } from '~/components/forms'
import Icon from '~/components/icon'
import UploadImagesButton from './upload-images-button'
import { ImageFile } from '~/types/product'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.53);
  z-index: 20;
`

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 40;

  @media (min-width: 992px) {
    // TODO: should use margin-left 45px if the sidebar is collapsed
    margin-left: 235px;
  }
`

const OuterList = styled.ul`
  list-style: none;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  overflow-y: auto;
  padding-left: 0;
`

const InnerList = styled.li`
  display: inline-block;
  margin-right: 11px;
  margin-bottom: 20px;
  width: 170px;
  vertical-align: top;
  position: relative;

  .mpl-photo-grid-item-remove-btn {
    display: none;
    cursor: pointer;
    position: absolute;
    top: 9px;
    right: 9px;
    color: #fff;
    background: #da0f0f;
    border-radius: 4px;
    font-size: 14px;
  }

  &:hover .mpl-photo-grid-item-remove-btn {
    display: block;
  }

  p.image-name {
    font-size: 11px;
    margin-top: 3px;
    margin-bottom: 0;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p.unmtch-imgtxt {
    margin-top: 3px;
    margin-bottom: 0;
    color: #da0f0f;
    font-weight: 600;
    font-size: 11px;
  }
`

const ImageContainer = styled.div`
  height: 170px;
  width: 170px;
  background-color: #f1f4f7;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-height: 100%;
  }
`

const DragImgDiv = styled.div`
  width: 100%;
  margin-top: 15px;

  div[type='file'] {
    height: 75px;
    width: 100%;
  }
`
const DropzoneSpan = styled.span`
  text-align: center;
  display: inherit;
  vertical-align: middle;
  padding: 28px 0;
`

interface UnmatchedImages {
  unmatchedImages: string[]
}

interface UploadedImage {
  name: string
  url: string
  status: 'success' | 'failed' | 'Not found'
}

interface UploadProductImagesProps {
  addExistingImage?: boolean
  tags?: string
}

const UploadProductImages = ({ addExistingImage = true, tags }: UploadProductImagesProps) => {
  const [allImages, setAllImages] = useState<ImageFile[]>([])
  const [mobileEnabledImages, setMobileEnabledImages] = useState<string[]>([])
  const [matchedImages, setMatchedImages] = useState<ImageFile[]>([])
  const [unmatchedImages, setUnmatchedImages] = useState<ImageFile[]>([])
  const [successImages, setSuccessImages] = useState<ImageFile[]>([])
  const [failedImages, setFailedImages] = useState<ImageFile[]>([])
  const [uploadInProgress, setUploadInProgress] = useState(false)
  const [progressCounter, setProgressCounter] = useState(1)
  const cancelTokenRef = useRef<CancelTokenSource | null>(null)

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const files: ImageFile[] = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )

      const imageNames: string[] = []

      files.forEach(file => {
        imageNames.push(file.name)
      })

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let response: AxiosResponse<UnmatchedImages, any>

      try {
        cancelTokenRef.current = axios.CancelToken.source()

        response = await axios.post<UnmatchedImages>('/api/products/check_products_for_images_exist', {
          images: imageNames
        })
      } catch (error) {
        console.error(error)
        return
      }

      const newAllImages = [...allImages, ...files]
      const newUnmatchedImages = unmatchedImages.concat(
        files.filter(image => response.data.unmatchedImages.includes(image.name))
      )
      const newMatchedImages = matchedImages.concat(
        files.filter(image => !newUnmatchedImages.map(image => image.name).includes(image.name))
      )

      setAllImages(newAllImages)
      setMatchedImages(newMatchedImages)
      setUnmatchedImages(newUnmatchedImages)
    },
    [allImages, matchedImages, unmatchedImages]
  )

  const isMobileEnabled = useCallback(
    (image: ImageFile) => {
      return mobileEnabledImages.includes(JSON.stringify(image))
    },
    [mobileEnabledImages]
  )

  const isMobileEnabledForAll = useCallback(() => {
    return allImages.length === mobileEnabledImages.length
  }, [allImages, mobileEnabledImages])

  const removeImage = useCallback(
    (imageToRemove: ImageFile) => {
      const newAllImages = allImages.filter(image => image !== imageToRemove)
      const newMatchedImages = matchedImages.filter(image => image.name !== imageToRemove.name)
      const newUnmatchedImages = unmatchedImages.filter(image => image.name !== imageToRemove.name)
      const newFailedImages = failedImages.filter(image => image.name !== imageToRemove.name)

      setAllImages(newAllImages)
      setMatchedImages(newMatchedImages)
      setUnmatchedImages(newUnmatchedImages)
      setFailedImages(newFailedImages)
    },
    [allImages, matchedImages, unmatchedImages, failedImages]
  )

  const cancelUploading = () => {
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel('Uploading was cancelled by the user')
      cancelTokenRef.current = null
    }
    setUploadInProgress(false)
  }

  const finishedUploading = useCallback(() => {
    const successCount = successImages.length
    const failCount = failedImages.length

    setUploadInProgress(false)
    setSuccessImages([])
    setProgressCounter(1)

    if (successCount) {
      toast.success('Successfully uploaded ' + successCount + ' images')
    }

    if (failCount) {
      toast.error('Failed to upload ' + failCount + ' images')
    }
  }, [failedImages, successImages])

  const uploadSingleImage = useCallback(
    (image: ImageFile) => {
      const imageData = new FormData()
      const mobileEnabled = isMobileEnabled(image)

      imageData.append('add_to_existing', addExistingImage ? '1' : '0')
      if (tags) {
        imageData.append('tag', tags)
      }
      imageData.append('image', image)
      imageData.append('mobile', mobileEnabled ? '1' : '0')

      return axios.post<UploadedImage>('/api/products/save_photo', imageData, {
        cancelToken: cancelTokenRef.current?.token
      })
    },
    [addExistingImage, tags, isMobileEnabled]
  )

  const processImageQueue = useCallback(
    async (queue: ImageFile[]) => {
      if (queue.length === 0 || !cancelTokenRef.current) {
        return
      }

      const currentImage = queue.pop()

      if (!currentImage) {
        return
      }

      try {
        await uploadSingleImage(currentImage)
        setProgressCounter(prev => prev + 1)
        setSuccessImages(prev => [...prev, currentImage])
        removeImage(currentImage)
      } catch (error) {
        console.error('Error uploading image', error)
        setProgressCounter(prev => prev + 1)
        setFailedImages(prev => [...prev, currentImage])
      }

      // Wait for the next event loop to process the next image.
      await new Promise(resolve => setTimeout(resolve, 0))

      await processImageQueue(queue)
    },
    [removeImage, uploadSingleImage]
  )

  const startUpload = useCallback(() => {
    if (matchedImages.length === 0) {
      toast.error('No matched images to upload')
      return
    }

    setUploadInProgress(true)
    setSuccessImages([])
    setFailedImages([])

    // setTimeout is so it will execute in the next event loop, i.e after all setXxxx have finished
    setTimeout(async () => {
      // Create a copy
      const matchedImagesQueue = matchedImages.slice()
      await processImageQueue(matchedImagesQueue)
      finishedUploading()
    }, 0)
  }, [matchedImages, finishedUploading, processImageQueue])

  // Not sure why it keeps track of mobile images using a completely separate array, rather than a '.mobile' property :-/
  const toggleMobileForImage = useCallback(
    (image: ImageFile) => {
      const enabled = isMobileEnabled(image)
      const images = enabled
        ? _.filter(mobileEnabledImages, JSON.stringify(image))
        : mobileEnabledImages.concat([JSON.stringify(image)])

      setMobileEnabledImages(images)
    },
    [mobileEnabledImages, isMobileEnabled]
  )

  const toggleMobileForAll = useCallback(() => {
    const result = isMobileEnabledForAll() ? [] : _.map(allImages, image => JSON.stringify(image))
    setMobileEnabledImages(result)
  }, [allImages, isMobileEnabledForAll])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'image/*': ['.jpg', '.jpeg', '.png', '.gif'] }
  })

  return (
    <div>
      {uploadInProgress && <ModalOverlay />}

      <DragImgDiv className="card mb-2" {...getRootProps()}>
        <div className="card-body">
          <input {...getInputProps()} />
          {isDragActive ? (
            <DropzoneSpan>Drop the images here ...</DropzoneSpan>
          ) : (
            <DropzoneSpan>Drag &apos;n&apos; drop some images here, or click to select images</DropzoneSpan>
          )}
        </div>
      </DragImgDiv>

      {allImages.length > 0 && (
        <div>
          <label htmlFor="mobileAll">
            <Icon icon="mobile" size="2x" />
            &nbsp; All &nbsp;
            <input
              name="mobileAll"
              type="checkbox"
              checked={isMobileEnabledForAll()}
              onChange={() => toggleMobileForAll()}
            />
          </label>

          <OuterList>
            {allImages.map((image, index) => {
              const key = index
              return (
                <InnerList key={key}>
                  <Button variant="link" className="mpl-photo-grid-item-remove-btn" onClick={() => removeImage(image)}>
                    <Icon icon="minus" aria-hidden="true" />
                  </Button>

                  <ImageContainer>
                    <img className="img-fluid" src={image.preview} alt="product" />
                  </ImageContainer>

                  <p className="image-name">{image.name}</p>

                  <label htmlFor="mobile">
                    <Icon icon="mobile" />{' '}
                    <input
                      name="mobile"
                      type="checkbox"
                      checked={isMobileEnabled(image)}
                      onChange={() => toggleMobileForImage(image)}
                    />
                  </label>

                  {unmatchedImages.map(image => image.name).includes(image.name) && (
                    <p className="unmtch-imgtxt">No Matching Product!</p>
                  )}
                  {failedImages.map(image => image.name).includes(image.name) && (
                    <p className="unmtch-imgtxt">Failed to upload!</p>
                  )}
                </InnerList>
              )
            })}
          </OuterList>

          <Footer>
            <UploadImagesButton
              matchedImageCount={matchedImages.length}
              unmatchedImageCount={unmatchedImages.length}
              failedImageCount={failedImages.length}
              progressCounter={progressCounter}
              isUploadInProgress={uploadInProgress}
              startUpload={startUpload}
              cancelUpload={cancelUploading}
            />
          </Footer>
        </div>
      )}
    </div>
  )
}

export default UploadProductImages
