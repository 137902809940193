import { useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Form, { PosFormValues } from './form'
import { getOrderDefaults, fetchOrder } from '~/async-actions/pos-async-actions'
import { setPosOrderToEdit } from '~/reducers/pos-reducer'
import { AppState, AppDispatch } from '~/config/store'

interface MatchParams {
  id?: string
}

const PosPage = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { loading, recordToEdit } = useSelector((state: AppState) => state.posPage)

  const isLoading = loading.fetchOrder || loading.getOrderDefaults

  const initialValues =
    recordToEdit ||
    ({
      reference: '',
      customerId: null,
      customerEmail: '',
      shippingContactName: '',
      shippingCity: '',
      shippingState: '',
      shippingCountry: '',
      shippingCountryName: '',
      shippingAddress1: '',
      shippingPostcode: '',
      orderDate: moment().format('DD/MM/YYYY'),
      lineItems: [],
      discountAmount: 0,
      shippingCost: 0,
      lineItemsContainGst: '1',
      shippingService: 'no_shipping',

      // Where do these props come from?
      // NOTE: I think they are calculated fields on the order, backend will just ignore them
      deletedLineItems: [],
      discount: {
        value: 0,
        type: '%'
      },
      subtotal: 0,
      total: 0,
      taxAmount: 0
    } as PosFormValues)

  const { id } = useParams<keyof MatchParams>()
  const recordId = id ? parseInt(id) : undefined

  useEffect(() => {
    if (recordId) {
      dispatch(fetchOrder(recordId))
    } else {
      dispatch(getOrderDefaults())
    }

    // Clean up redux sate when unmounting
    return () => {
      dispatch(setPosOrderToEdit(null))
    }
  }, [recordId, dispatch])

  return (
    <div>
      <div className="clearfix">
        <div className="row">
          <div className="col-sm-6">
            <h1>
              <>Point of Sale</>
              {isLoading && <Spinner className="ms-4" />}
            </h1>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">{!isLoading && <Form initialValues={initialValues} />}</div>
        </div>
      </div>
    </div>
  )
}

export default PosPage
