import axios, { AxiosRequestConfig } from 'axios'
import snakeToCamelCase from '~/lib/snake-to-camel-case'
import { BASE_URL } from './constants'

export async function get(id: string | number) {
  return axios.get(`${BASE_URL}/api/au_post_shipment_jobs/${id}`).then(res => snakeToCamelCase(res.data))
}

type CreateAuPostShipmentJobParams = {
  manifestId?: string | number
  orderIds: Array<string | number>
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any
}

export async function create({ manifestId, orderIds, params }: CreateAuPostShipmentJobParams) {
  const order_ids = orderIds.map(v => parseInt(v.toString(), 10))
  const data = {
    parcel_send_request: {
      manifest_id: manifestId,
      order_ids,
      params
    }
  }
  console.log('[DEBUG] order_ids =', order_ids)
  return axios
    .post(`${BASE_URL}/api/au_post_shipment_jobs`, data, {
      snake: false
    } as AxiosRequestConfig)
    .then(res => snakeToCamelCase(res.data))
}
