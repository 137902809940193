import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

import {
  fetchList,
  destroyRecord,
  createRecord,
  updateRecord,
  fetchRecord
} from '~/async-actions/product-search-links-async-actions'

import ProductSearchLink from '~/types/product-search-link'

export interface ListMeta {
  currentPage: number
  totalPages: number
  totalCount: number
  pageSize: number
}

export interface ProductSearchLinksState {
  list: ProductSearchLink[]
  listMeta?: ListMeta
  recordToEdit?: ProductSearchLink
  loading: {
    fetchList: boolean
    destroyRecord: boolean
    fetchRecord: boolean
    createRecord: boolean
    updateRecord: boolean
  }
  error: {
    fetchList: string | null
    destroyRecord: string | null
    fetchRecord: string | null
    createRecord: string | null
    updateRecord: string | null
  }
}

const initialState: ProductSearchLinksState = {
  list: [],
  listMeta: undefined,
  recordToEdit: undefined,
  loading: {
    fetchList: false,
    destroyRecord: false,
    fetchRecord: false,
    updateRecord: false,
    createRecord: false
  },
  error: {
    fetchList: null,
    destroyRecord: null,
    fetchRecord: null,
    updateRecord: null,
    createRecord: null
  }
}

const productSearchLinksSlice = createSlice({
  name: 'productSearchLinks',
  initialState,
  reducers: {
    loadList: (state, action: PayloadAction<{ items: ProductSearchLink[]; meta: ListMeta }>) => {
      state.list = action.payload.items
      state.listMeta = action.payload.meta
    },
    loadRecordToEdit: (state, action: PayloadAction<ProductSearchLink | undefined>) => {
      state.recordToEdit = action.payload
    },
    removeRecord: (state, action: PayloadAction<{ id: number }>) => {
      state.list = state.list.filter(record => record.id !== action.payload.id)
      const oldListMeta = state.listMeta
      const totalCount = (oldListMeta?.totalCount ?? 0) - 1
      state.listMeta = _.assign(oldListMeta, { totalCount })
    }
  },
  extraReducers: builder => {
    builder
      // fetchList async action
      .addCase(fetchList.pending, state => {
        state.loading.fetchList = true
        state.error.fetchList = null
      })
      .addCase(fetchList.fulfilled, (state, action) => {
        state.list = action.payload.items
        state.listMeta = action.payload.meta
        state.loading.fetchList = false
      })
      .addCase(fetchList.rejected, (state, action) => {
        state.loading.fetchList = false
        state.error.fetchList = action.payload?.message || 'Error fetching search links'
      })

      // destroyRecord async action
      .addCase(destroyRecord.pending, state => {
        state.loading.destroyRecord = true
        state.error.destroyRecord = null
      })
      .addCase(destroyRecord.fulfilled, (state, action) => {
        state.list = state.list.filter(record => record.id !== action.payload.id)
        const oldListMeta = state.listMeta
        const totalCount = (oldListMeta?.totalCount ?? 0) - 1
        state.listMeta = _.assign(oldListMeta, { totalCount })
        state.loading.destroyRecord = false
      })
      .addCase(destroyRecord.rejected, (state, action) => {
        state.loading.destroyRecord = false
        state.error.destroyRecord = action.payload?.message || 'Error destroying search link'
      })

      // fetchRecord async action
      .addCase(fetchRecord.pending, state => {
        state.loading.fetchRecord = true
        state.error.fetchRecord = null
      })
      .addCase(fetchRecord.fulfilled, (state, action) => {
        state.recordToEdit = action.payload
        state.loading.fetchRecord = false
      })
      .addCase(fetchRecord.rejected, (state, action) => {
        state.loading.fetchRecord = false
        state.error.fetchRecord = action.payload?.message || 'Error fetching search link'
      })

      // createRecord async action
      .addCase(createRecord.pending, state => {
        state.loading.createRecord = true
        state.error.createRecord = null
      })
      .addCase(createRecord.fulfilled, (state, action) => {
        state.recordToEdit = action.payload
        state.loading.createRecord = false
      })
      .addCase(createRecord.rejected, (state, action) => {
        state.loading.createRecord = false
        state.error.createRecord = action.payload?.message || 'Error creating search link'
      })

      // updateRecord async action
      .addCase(updateRecord.pending, state => {
        state.loading.updateRecord = true
        state.error.updateRecord = null
      })
      .addCase(updateRecord.fulfilled, (state, action) => {
        state.recordToEdit = action.payload
        state.loading.updateRecord = false
      })
      .addCase(updateRecord.rejected, (state, action) => {
        state.loading.updateRecord = false
        state.error.updateRecord = action.payload?.message || 'Error updating search link'
      })
  }
})

export const { loadList, loadRecordToEdit, removeRecord } = productSearchLinksSlice.actions

export default productSearchLinksSlice.reducer
