import { useState } from 'react'
import { toast } from 'react-toastify'

import { Button } from '~/components/forms'
import ExportButton from '~/components/export-button'
import ShipmentCreatorModal from '~/components/shipment-creator-modal'
import Order from '~/types/order'

const shipmentBtnStyles = {
  marginRight: '10px'
}

interface OrderActionsProps {
  selectedIds: number[]
  ordersList?: Order[]
}

const OrderActions = ({ selectedIds, ordersList }: OrderActionsProps) => {
  const [showShipmentModal, setShowShipmentModal] = useState(false)
  const unprocessedList = ordersList?.filter((o: Order) => o.status === 'unprocessed')
  const hasUnprocessed = unprocessedList?.filter((o: Order) => selectedIds.includes(o.id))?.length ?? 0 > 0
  const disableCreateShipment = selectedIds.length === 0
  const handleClickCreateShipment = () => {
    if (hasUnprocessed) {
      const alertMsg =
        'There are unprocessed orders among the selected orders. Please uncheck them or convert them to processed orders'
      toast.error(alertMsg)
      return
    }
    setShowShipmentModal(true)
  }

  return (
    <>
      <ShipmentCreatorModal
        orderIds={selectedIds}
        show={showShipmentModal}
        onHide={() => setShowShipmentModal(false)}
      />

      <div>
        <Button
          variant="info"
          style={shipmentBtnStyles}
          disabled={disableCreateShipment}
          onClick={handleClickCreateShipment}>
          Create Shipment
        </Button>
        <ExportButton
          disabled={!selectedIds.length}
          endpoint={`/api/orders/export?report=picklist-pdf&order_ids=${selectedIds.join(',')}`}
          label="Export Pick List (PDF)"
        />{' '}
        &nbsp;&nbsp;
        <ExportButton
          disabled={!selectedIds.length}
          endpoint={`/api/orders/export?report=eparcels-csv&order_ids=${selectedIds.join(',')}`}
          label="Export for eParcels (CSV)"
        />{' '}
        &nbsp;&nbsp;
        <ExportButton
          disabled={!selectedIds.length}
          endpoint={`/api/orders/export?report=parcel-send-csv&order_ids=${selectedIds.join(',')}`}
          label="Export for Parcel Send (CSV)"
        />{' '}
        &nbsp;&nbsp;
        <ExportButton
          disabled={!selectedIds.length}
          endpoint={`/api/orders/export?report=labels-pdf&order_ids=${selectedIds.join(',')}`}
          label="Print Labels (PDF)"
        />{' '}
        &nbsp;&nbsp;
        <Button variant="info" disabled>
          <strong>Print Invoices</strong>
        </Button>
      </div>
    </>
  )
}

export default OrderActions
