import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal } from 'react-bootstrap'

import { Button } from '~/components/forms'
import Product from '~/types/product'
import { AppState, AppDispatch } from '~/config/store' // Update this import path
import { closeProductExistsModal } from '~/reducers/products-reducer'

interface ProductExistsModalProps {
  onConfirm?: (product: Product) => Promise<void>
  children?: React.ReactNode
}

const ProductExistsModal = ({ onConfirm, children }: ProductExistsModalProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const productForModal = useSelector((state: AppState) => state.productsPage.productExistsModal)
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    setIsOpen(Boolean(productForModal))
  }, [productForModal])

  const close = useCallback(() => {
    setIsOpen(false)
    dispatch(closeProductExistsModal())
  }, [dispatch])

  const yes = useCallback(() => {
    if (productForModal != null) {
      onConfirm?.(productForModal)
    }
    close()
  }, [close, onConfirm, productForModal])

  // Ensure redux state is reset if modal is unloaded
  useEffect(() => {
    return () => {
      close()
    }
  }, [close])

  return (
    <Modal show={isOpen} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Product Already Exists</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {productForModal && (
          <table className="table table-borderless">
            <tbody>
              <tr>
                <th className="col-sm-4 text-end">SKU</th>
                <td className="col-sm-8">{productForModal.customSku}</td>
              </tr>
              <tr>
                <th className="col-sm-4 text-end">Shelf Location</th>
                <td className="col-sm-8">{productForModal.shelfLocation}</td>
              </tr>
              <tr>
                <th className="col-sm-4 text-end">Name</th>
                <td className="col-sm-8">{productForModal.name}</td>
              </tr>
            </tbody>
          </table>
        )}

        {children && <hr />}

        {children}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={yes}>
          Yes
        </Button>
        <Button variant="light" onClick={close}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ProductExistsModal
