import { Modal } from 'react-bootstrap'

import Customer from '~/types/customer'
import CustomerSearchInput from './customer-search-input'

interface SelectCustomerDialogProps {
  show: boolean
  onHide: () => void
  onSelect: (customer: Customer) => void
}

const SelectCustomerDialog = ({ show, onHide, onSelect }: SelectCustomerDialogProps) => (
  <Modal show={show} onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title>Select Customer</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <CustomerSearchInput onSelect={onSelect} onHide={onHide} />
    </Modal.Body>
  </Modal>
)

export default SelectCustomerDialog
