import { useState } from 'react'
import styled from 'styled-components'

import useAuth from '~/contexts/useAuth' // Import the useAuth hook
import UploadProductImages from './upload-product-images'
import UploadImagesInstruction from './upload-images-instruction'
import { UserKindEnum } from '~/types/user'

const OuterDiv = styled.div`
  display: inline-block;
  width: 100%;
`

interface Options {
  addExistingImage?: boolean
}

const ImageUploadPage = () => {
  const { userProfile } = useAuth() // Use the useAuth hook to get the userProfile
  const [addExistingImage, setAddExistingImage] = useState(true)
  const [tags, setTags] = useState('')

  const optionsChanged = (options: Options) => {
    if (options.addExistingImage !== undefined) {
      setAddExistingImage(options.addExistingImage)
    }
  }

  const uploadedTags = (uploadedTags: string) => {
    setTags(uploadedTags)
  }

  return (
    <div>
      {userProfile && (
        <OuterDiv>
          {userProfile.kind === UserKindEnum.OWNER && (
            <div>
              <UploadImagesInstruction optionsChanged={optionsChanged} uploadedTags={uploadedTags} />
              <UploadProductImages addExistingImage={addExistingImage} tags={tags} />
            </div>
          )}

          {userProfile.kind !== UserKindEnum.OWNER && <div>You do not have permission to upload photos...</div>}
        </OuterDiv>
      )}
    </div>
  )
}

export default ImageUploadPage
