import { object, string } from 'yup'
import '~/lib/yup'

export interface CreateCustomerSchemaProps {
  validateAddress?: boolean
  validateEmail?: boolean
}

const createCustomerSchema = ({ validateAddress, validateEmail }: CreateCustomerSchemaProps) => {
  return object().shape({
    name: string().required(),
    address1: validateAddress ? string().required() : string(),
    suburb: validateAddress ? string().required() : string(),
    postcode: validateAddress ? string().required() : string(),
    country: validateAddress ? string().required() : string(),
    email: validateEmail ? string().email().required() : string().email(),
    state: validateAddress
      ? string().test('state', 'Required', function (value) {
          const country = this.from?.[0]?.value?.country || ''
          if (country.toLowerCase() === 'australia' || country === 'AU') {
            return !!value
          }
          return true
        })
      : string()
  })
}

export default createCustomerSchema
