import { useCallback, useEffect } from 'react'
import { useFormikContext, Field } from 'formik'
import { useDebouncedCallback } from 'use-debounce'

import { calculateCubicWeight } from '~/lib/cubic-weight'
import { BsInput } from '~/components/form-inputs/formik/inputs-decorated'
import { OrderFormValues } from './use-order-details'

const PackageDetailsDomestic = () => {
  const { values: formValues, setFieldValue } = useFormikContext<OrderFormValues>()
  const { isDomestic, packageWidth, packageHeight, packageLength } = formValues
  const isInternational = !isDomestic

  const calcCubicWeight = useDebouncedCallback(
    useCallback(() => {
      if (isInternational) {
        return
      }

      setFieldValue('cubicWeight', calculateCubicWeight(packageLength, packageWidth, packageHeight) || '-')
    }, [packageLength, packageWidth, packageHeight, isInternational, setFieldValue]),
    500
  )

  useEffect(() => {
    calcCubicWeight()
  }, [calcCubicWeight])

  if (!isDomestic) return null

  return (
    <fieldset>
      <legend>Package Details</legend>

      <Field required name="packageLength" label="Length (cm)" component={BsInput} onChange={calcCubicWeight} />
      <Field required name="packageWidth" label="Width (cm)" component={BsInput} onChange={calcCubicWeight} />
      <Field required name="packageHeight" label="Height (cm)" component={BsInput} onChange={calcCubicWeight} />
      <Field required name="packageWeight" label="Weight (kg)" component={BsInput} />

      <Field label="Cubic Weight" name="cubicWeight" component={BsInput} readOnly disabled displayMode="plain" />
    </fieldset>
  )
}

export default PackageDetailsDomestic
