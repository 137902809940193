import styled from 'styled-components'
import { useFormik, FormikProvider } from 'formik'

import { ActionBar } from '~/components/forms'
import { BaseErrors, FlexibleInputGroup } from '~/components/forms/formik'
import useSubmitHandler from '~/containers/settings/submit-handler'
import { SettingsProviderState } from '~/contexts/settings'
import { ProductDefaults, AccountSettingKeys } from '~/types/account-settings'
import validations from './validations'
import marketplaceOptions from '~/containers/product-form-page/main-tab/marketplace-options'

const Container = styled.div`
  width: 600px;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

interface ProductDefaultsFormProps {
  redirectPath: string
  initialValues?: ProductDefaults
  settingsKey: AccountSettingKeys
  updateAction: SettingsProviderState['updateAction']
}

const ProductDefaultsForm = (props: ProductDefaultsFormProps) => {
  const { redirectPath, initialValues = {} } = props
  const { submitHandler } = useSubmitHandler()
  const formik = useFormik({
    initialValues: {
      scancodesEnabled: true,
      defaultScancodeMarketplace: 'ebay',
      ...initialValues
    } as ProductDefaults,
    validationSchema: validations,
    onSubmit: (values, helpers) => {
      return submitHandler<ProductDefaults, ProductDefaultsFormProps>(values, helpers, props)
    }
  })

  return (
    <FormikProvider value={formik}>
      <Wrapper>
        <Container>
          <h3 className="mb-4">Configure Product Defaults</h3>

          <BaseErrors />

          <FlexibleInputGroup name="scancodesEnabled" label="Scancodes Enabled?" type="checkbox" />
          <FlexibleInputGroup
            name="defaultScancodeMarketplace"
            label="Default Scancode Marketplace"
            type="select"
            options={marketplaceOptions}
          />
          <ActionBar isSubmitting={formik.isSubmitting} redirectPath={redirectPath} onSubmit={formik.handleSubmit} />
        </Container>
      </Wrapper>
    </FormikProvider>
  )
}

export default ProductDefaultsForm
