import styled from 'styled-components'

import { Button } from '~/components/forms'

const Wrapper = styled.div`
  & > button {
    margin-right: 10px;
  }
`

interface ActionButtonsProps {
  onDespatch?: () => void
  onDelete?: () => void
  onDownloadLabels?: () => void
  onDownload?: () => void
  disableManifestButton?: boolean
  disableDespatch?: boolean
  disableDelete?: boolean
  disableLabels?: boolean
}
const ActionButtons = ({
  onDespatch,
  onDelete,
  onDownloadLabels,
  onDownload,
  disableManifestButton,
  disableDespatch,
  disableDelete,
  disableLabels
}: ActionButtonsProps) => (
  <Wrapper>
    <Button variant="primary" disabled={disableLabels} onClick={onDownloadLabels}>
      Labels
    </Button>

    <Button variant="primary" disabled={disableDespatch} onClick={onDespatch}>
      Despatch
    </Button>

    <Button variant="primary" disabled={disableManifestButton} onClick={onDownload}>
      Download PDF
    </Button>

    <Button variant="danger" disabled={disableDelete} onClick={onDelete}>
      Delete
    </Button>
  </Wrapper>
)

export default ActionButtons
