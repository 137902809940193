import { ReactNode, Component } from 'react'

import FallbackError from '~/components/fallback-error'
import bugsnagClient from '~/config/bugsnag'

interface PageErrorBoundaryProps {
  children: ReactNode
}
// NOTE:
//
// componentDidCatch only works in class-based components, not functional ones
class PageErrorBoundary extends Component<PageErrorBoundaryProps> {
  state = {
    hasSomeError: false
  }

  static getDerivedStateFromError() {
    return { hasSomeError: true }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentDidCatch(error: any, errorInfo: any) {
    console.log('[DEBUG] error =', error)
    console.log('[DEBUG] errorInfo =', errorInfo)
    bugsnagClient.notify(error)
  }

  render() {
    return this.state.hasSomeError ? <FallbackError /> : this.props.children
  }
}

export default PageErrorBoundary
