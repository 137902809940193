import { toast } from 'react-toastify'
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'

import WeightBasedShippingPricingProfile from '~/types/weight-based-shipping-pricing-profile'

export function fetchAllWeightBasedShippingPricingProfiles() {
  return axios.get<WeightBasedShippingPricingProfile[]>(`/api/settings/weight_based_shipping_pricing_profiles`)
}

export function createWeightBasedShippingPricingProfile(params: Partial<WeightBasedShippingPricingProfile>) {
  return axios.post<WeightBasedShippingPricingProfile>(`/api/settings/weight_based_shipping_pricing_profiles`, {
    weightBasedShippingPricingProfile: params
  })
}

export function updateWeightBasedShippingPricingProfile(params: Partial<WeightBasedShippingPricingProfile>) {
  return axios.put<WeightBasedShippingPricingProfile>(
    `/api/settings/weight_based_shipping_pricing_profiles/${params.id}`,
    {
      weightBasedShippingPricingProfile: params
    }
  )
}

export function destroyWeightBasedShippingPricingProfile(id: number) {
  return axios.delete(`/api/settings/weight_based_shipping_pricing_profiles/${id}`)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const loadItems = createAsyncThunk<WeightBasedShippingPricingProfile[], void, { rejectValue: any }>(
  'weightBasedShippingPricingProfiles/loadItems',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchAllWeightBasedShippingPricingProfiles()
      return response.data
    } catch (error) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

export const addOrUpdateItem = createAsyncThunk<
  WeightBasedShippingPricingProfile,
  Partial<WeightBasedShippingPricingProfile>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { rejectValue: any }
>('weightBasedShippingPricingProfiles/addOrUpdateItem', async (item, { rejectWithValue }) => {
  try {
    const response = item.id
      ? await updateWeightBasedShippingPricingProfile(item)
      : await createWeightBasedShippingPricingProfile(item)
    return response.data
  } catch (error) {
    if (error instanceof AxiosError) {
      return rejectWithValue(error.response?.data)
    }
    return rejectWithValue('An unknown error occurred')
  }
})

export const deleteItem = createAsyncThunk<
  WeightBasedShippingPricingProfile,
  WeightBasedShippingPricingProfile,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { rejectValue: any }
>('weightBasedShippingPricingProfiles/deleteItem', async (weightBasedShippingPricingProfile, { rejectWithValue }) => {
  try {
    await destroyWeightBasedShippingPricingProfile(weightBasedShippingPricingProfile.id)
    return weightBasedShippingPricingProfile
  } catch (error) {
    toast.error('Error: failed to delete.')
    if (error instanceof AxiosError) {
      return rejectWithValue(error.response?.data)
    }
    return rejectWithValue('An unknown error occurred')
  }
})
