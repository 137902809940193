import { object, string, number, boolean } from 'yup'

export default object().shape({
  type: string().required('is required'),
  name: string().required('is required'),
  default: boolean().required('is required'),
  au: number().min(0),
  zone1: number().min(0),
  zone2: number().min(0),
  zone3: number().min(0),
  zone4: number().min(0),
  zone5: number().min(0),
  zone6: number().min(0),
  zone7: number().min(0),
  zone8: number().min(0),
  zone9: number().min(0)
})
