export interface Migration {
  version: Date
  up: () => void
}

const migrateFn = (migrations: Migration[] = []) => {
  const currentVersion = parseInt(localStorage.getItem('dbVersion') || '0')

  if (!currentVersion) {
    localStorage.setItem('dbVersion', '0')
  }

  migrations.forEach(m => {
    const { version } = m

    if (!version) {
      console.log('[ERROR] m =', m)
      throw new Error(`migration version is not set`)
    }

    if (version.getTime() > currentVersion) {
      m.up()
      localStorage.setItem('dbVersion', m.version.getTime().toString())
    }
  })
}

export default migrateFn
