import localforage from 'localforage'

class LocalStorage {
  static async isSet<T>(key: string) {
    return localforage.getItem<T>(key).then(value => value !== null)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static set<T>(key: string, value: any) {
    return localforage.setItem<T>(key, value)
  }

  static get<T>(key: string) {
    return localforage.getItem<T>(key)
  }
}

export default LocalStorage
