import { useState } from 'react'
import styled from 'styled-components'
import { Table } from 'react-bootstrap'
import { stringify } from 'yaml'
import _ from 'lodash'

import AuPostApiResponse, { AuPostApiResponseTypeEnum, AuPostSystemErrorDetails } from '~/types/au-post-api'
import { Button } from '~/components/forms'
import { ManifestItemResult } from '~/types/au-post-manifest-results'

const Div1 = styled.div`
  overflow: auto;
`

const Div2 = styled.div<{ status: AuPostApiResponseTypeEnum }>`
  overflow-x: hidden;

  font-style: ${props => (props.status === 'already_processed' ? 'italic' : 'normal')};
  color: ${props => (props.status === 'remote_server_error' ? 'red' : 'inherit')};
`

const Div3 = styled.div`
  overflow: auto;
`

const Div4 = styled.div`
  overflow: auto;
`

const Td1 = styled.td`
  width: 100px;
`

const Td2 = styled.td`
  width: 150px;
`

const Td3 = styled.td``

const Ul = styled.ul`
  margin-bottom: 10px;
`

const Preformatted = styled.div`
  font-family: monospace;
  white-space: pre;
`

const jsonToYaml = (json: object) => {
  let output = ''
  try {
    output = stringify(json)
    return output
  } catch (error) {
    return output
  }
}

interface ResponseProps {
  result: AuPostApiResponse
}
const Response = ({ result }: ResponseProps) => {
  const [showResponse, setShowResponse] = useState(false)
  const body = result.responseBody
  const toggleResponse = () => setShowResponse(prevShowResponse => !prevShowResponse)

  if (!body) {
    return null
  }

  let errorsStr = ''
  let shipmentsStr = ''
  errorsStr = body && body.errors && jsonToYaml(body.errors)
  shipmentsStr = body && body.shipments && jsonToYaml(body.shipments)

  return (
    <Div4>
      <div>
        {body?.errors?.length > 0 && body.errors[0].message}
        <Button variant="link" onClick={toggleResponse}>
          {showResponse ? 'hide' : 'show'}
        </Button>
      </div>
      {errorsStr && showResponse && (
        <>
          <Preformatted>{errorsStr}</Preformatted>
        </>
      )}
      {shipmentsStr && showResponse && (
        <>
          <Preformatted>{shipmentsStr}</Preformatted>
        </>
      )}
    </Div4>
  )
}

const renderError = (err: AuPostSystemErrorDetails) => (
  <Ul key={String(err.type + Math.random())}>
    <li>
      {err.message} in {err.origin}
    </li>
  </Ul>
)

const renderBody = (
  failed: ManifestItemResult[],
  onRemoveOrder: (orderId: number) => void,
  onViewOrder: (orderId: number) => void
) =>
  failed.map(result => (
    <tr key={result?.metadata?.orderId || _.uniqueId()}>
      <Td1>
        <Div1>{result?.metadata?.orderReference || '??'}</Div1>
      </Td1>
      <Td2>
        <Div2 status={result.type}>{result.type}</Div2>
      </Td2>
      <Td3>
        {result.errors && result.errors.length > 0 && <Div3>{result.errors.map(renderError)}</Div3>}
        <Response result={result} />
      </Td3>
      <td>
        <Button variant="link" onClick={() => onRemoveOrder(result?.metadata?.orderId)}>
          remove order
        </Button>
        {' | '}
        <Button variant="link" onClick={() => onViewOrder(result?.metadata?.orderId)}>
          view order
        </Button>
      </td>
    </tr>
  ))

interface FailedOrdersTableProps {
  onViewOrder: (orderId: number) => void
  onRemoveOrder: (orderId: number) => void
  onRetry: () => void
  failed: ManifestItemResult[]
}
const FailedOrdersTable = ({ onRetry, failed, onRemoveOrder, onViewOrder }: FailedOrdersTableProps) => (
  <>
    <Button style={{ marginBottom: '10px' }} onClick={onRetry}>
      Retry
    </Button>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Order</th>
          <th>Status</th>
          <th>Message</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>{renderBody(failed, onRemoveOrder, onViewOrder)}</tbody>
    </Table>
  </>
)

export default FailedOrdersTable
