import { Alert } from 'react-bootstrap'

const LoadError = () => (
  <Alert variant="danger">
    <p>
      <strong>Error:</strong> Failed to load settings from server.
    </p>
    <p>Please try refreshing the page.</p>
    <p>Notify the website admin if problem persists.</p>
  </Alert>
)

export default LoadError
