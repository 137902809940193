import styled from 'styled-components'
import { useFormik, FormikProvider } from 'formik'

import { ActionBar } from '~/components/forms'
import { BaseErrors, FlexibleInputGroup } from '~/components/forms/formik'
import { SettingsProviderState } from '~/contexts/settings'
import { EparcelsDefaults, AccountSettingKeys } from '~/types/account-settings'
import useSubmitHandler from '~/containers/settings/submit-handler'
import validations from './validations'

const isCommercialValueOptions = [
  { label: '---', value: '' },
  { label: 'Yes', value: '1' },
  { label: 'No', value: '0' }
]

const refOptions = [
  { label: '---', value: '' },
  { label: 'Sales Record Number', value: 'sales_record_number' },
  { label: 'Shelf Location (*first product only)', value: 'shelf_location' },
  { label: 'Shelf SKU (*first product only)', value: 'custom_sku' }
]

const Container = styled.div`
  width: 600px;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

interface EparcelsDefaultsFormProps {
  initialValues: EparcelsDefaults
  redirectPath: string
  settingsKey: AccountSettingKeys
  updateAction: SettingsProviderState['updateAction']
}

const EparcelsDefaultsForm = (props: EparcelsDefaultsFormProps) => {
  const { submitHandler } = useSubmitHandler()
  const { initialValues, redirectPath } = props
  const formik = useFormik({
    initialValues,
    validationSchema: validations,
    onSubmit: (values, helpers) => submitHandler<EparcelsDefaults, EparcelsDefaultsFormProps>(values, helpers, props)
  })

  return (
    <FormikProvider value={formik}>
      <Wrapper>
        <Container>
          <h3 className="mb-4">The settings effect the output of the eParcels CSV export</h3>

          <BaseErrors />

          <FlexibleInputGroup name="isCommercialValue" type="select" options={isCommercialValueOptions} />
          <FlexibleInputGroup name="deliveryInstructions" type="textarea" />
          <FlexibleInputGroup
            name="ref1"
            type="select"
            options={refOptions}
            label={`"Ref 1"`}
            hint={`Which order field to map to "Ref 1" in eParcels`}
          />
          <FlexibleInputGroup
            name="ref2"
            type="select"
            options={refOptions}
            label={`"Ref 2"`}
            hint={`Which order field to map to "Ref 2" in eParcels`}
          />

          <ActionBar isSubmitting={formik.isSubmitting} redirectPath={redirectPath} onSubmit={formik.handleSubmit} />
        </Container>
      </Wrapper>
    </FormikProvider>
  )
}

export default EparcelsDefaultsForm
