import styled from 'styled-components'
import _ from 'lodash'
import { useFormikContext } from 'formik'

import { printCurrency } from '../utils'
import PricingProfileSelect from './pricing-profile-select'
import ShippingPricingProfile from '~/types/shipping-pricing-profile'
import { ProductFormValues } from '../use-product-form'

const Box = styled.div`
  @media (max-width: 768px) {
    margin-top: 20px;
  }

  @media (min-width: 769px) {
    h4 {
      margin-top: 8px;
      margin-bottom: 0;
    }
  }

  margin-bottom: 20px;
  display: flex;

  button.add {
    margin-top: 5px;
    margin-left: 10px;
  }
`
export const Fieldset = styled.fieldset`
  width: 100%;
`

export const BaseRow = styled.div`
  display: flex;
  margin-bottom: 10px;
  position: relative;
`

export const FieldRow = styled(BaseRow)`
  input {
    flex-grow: 1;

    & + input {
      margin-left: 20px;
    }
  }

  button {
    height: 34px;
    margin-left: 10px;
  }
`

export const StyledField = styled.div`
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #444;
  background-color: #ccc;
  border: 1px solid #ccc;
  border-radius: 4px;
`

export const Col = styled.div`
  text-align: right;
  margin-right: 5px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 80px;

  > span {
    color: #aaa;
    font-size: 12px;
  }

  > ${StyledField} {
    text-align: right;
  }

  &.empty {
    min-width: 155px;
    max-width: 155px;
    flex-basis: 155px;
  }
`

const HeadingsRow = styled(BaseRow)`
  font-weight: 600;
  justify-content: space-evenly;
  margin-top: 15px;

  & > ${Col} {
    text-align: center;
  }
`
export type ProfileValue = 'weight_based_express' | 'weight_based_regular' | string | number | null | undefined

export enum RegionalPricingSectionTypeEnum {
  DISCOUNT = 'discount',
  REGULAR = 'regular',
  EXPRESS = 'express'
}

export type Profiles = {
  [key in RegionalPricingSectionTypeEnum]: ShippingPricingProfile[]
}

export type GetWeightBasedShippingProfileType = (type: RegionalPricingSectionTypeEnum) => ShippingPricingProfile

const getFieldNames = () => ['au', 'zone1', 'zone2', 'zone3', 'zone4', 'zone5', 'zone6', 'zone7', 'zone8', 'zone9']

const findDefaultProfile = (profiles: Profiles, type: RegionalPricingSectionTypeEnum) =>
  _.find(profiles[type], ['default', true])

const findProfile = (profiles: Profiles, type: RegionalPricingSectionTypeEnum, idStr: string | number) =>
  _.find(profiles[type], ['id', parseInt(idStr.toString(), 10)])

const resolveSelectedProfile = (
  profiles: Profiles,
  type: RegionalPricingSectionTypeEnum,
  value: ProfileValue,
  getWeightBasedShippingProfile: GetWeightBasedShippingProfileType
) => {
  if (value === 'weight_based_regular' || value === 'weight_based_express') {
    return getWeightBasedShippingProfile(type)
  }

  return value ? findProfile(profiles, type, value) : findDefaultProfile(profiles, type)
}

const calculateRegionalPrice = (regionalFee: string | boolean | number | null, salePrice: number | null) =>
  printCurrency(Number(regionalFee || 0) + Number(salePrice || 0))

interface RowComponentProps {
  type: RegionalPricingSectionTypeEnum
  label: string
  value: ProfileValue
}

const createRow = (
  profiles: Profiles,
  salePrice: number | null,
  getWeightBasedShippingProfile: GetWeightBasedShippingProfileType
) => {
  const RowComponent = ({ type, label, value }: RowComponentProps) => {
    const { setFieldValue } = useFormikContext<ProductFormValues>()

    const createOnChange = (type: RegionalPricingSectionTypeEnum) => (value: number | string | null | undefined) => {
      if (value === 'weight_based_regular' || value === 'weight_based_express') {
        setFieldValue(`${type}SingleItemWeightBasedShipping`, true)
        setFieldValue(`${type}ShippingProfileId`, null)
      } else {
        setFieldValue(`${type}SingleItemWeightBasedShipping`, false)
        setFieldValue(`${type}ShippingProfileId`, value)
      }
    }

    return (
      <FieldRow>
        <PricingProfileSelect
          type={type}
          label={label}
          profiles={profiles[type]}
          value={value || ''}
          onChange={createOnChange(type)}
        />

        {_.map(getFieldNames(), fieldName => {
          const shippingPricingProfile = resolveSelectedProfile(profiles, type, value, getWeightBasedShippingProfile)
          const regionalFee = shippingPricingProfile?.[fieldName as keyof ShippingPricingProfile]
          return (
            <Col key={fieldName}>
              <StyledField>{regionalFee}</StyledField>
              {type !== 'discount' && (
                <span className="text-mute">
                  {regionalFee != null && calculateRegionalPrice(regionalFee, salePrice)}
                </span>
              )}
            </Col>
          )
        })}
      </FieldRow>
    )
  }

  return RowComponent
}

export interface SelectedPricingProfileIds {
  regular: 'weight_based_regular' | number | null | undefined
  express: 'weight_based_express' | number | null | undefined
  discount: number | null | undefined
}

interface RegionalPricingSectionProps {
  profiles: {
    regular: ShippingPricingProfile[]
    express: ShippingPricingProfile[]
    discount: ShippingPricingProfile[]
  }
  selectedIds: SelectedPricingProfileIds
  salePrice: number | null
  getWeightBasedShippingProfile: GetWeightBasedShippingProfileType
}
const RegionalPricingSection = ({
  profiles,
  selectedIds,
  salePrice,
  getWeightBasedShippingProfile
}: RegionalPricingSectionProps) => {
  const TableRow = createRow(profiles, salePrice, getWeightBasedShippingProfile)

  return (
    <Box>
      <Fieldset>
        <h4>Single-Item Order Settings</h4>

        <HeadingsRow>
          <Col className="empty" />
          <Col className="empty" />
          <Col>AU</Col>
          <Col>Zone1</Col>
          <Col>Zone2</Col>
          <Col>Zone3</Col>
          <Col>Zone4</Col>
          <Col>Zone5</Col>
          <Col>Zone6</Col>
          <Col>Zone7</Col>
          <Col>Zone8</Col>
          <Col>Zone9</Col>
        </HeadingsRow>

        <TableRow
          type={RegionalPricingSectionTypeEnum.DISCOUNT}
          label="Multi-item Discount"
          value={selectedIds.discount}
        />

        <TableRow type={RegionalPricingSectionTypeEnum.REGULAR} label="Regular Shipping" value={selectedIds.regular} />

        <TableRow type={RegionalPricingSectionTypeEnum.EXPRESS} label="Express Shipping" value={selectedIds.express} />
      </Fieldset>
    </Box>
  )
}

export default RegionalPricingSection
