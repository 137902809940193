import { Link } from 'react-router-dom'
import { useFormik, FormikProvider } from 'formik'
import styled from 'styled-components'

import { Button } from '~/components/forms'
import { InputGroup, BaseErrors } from '~/components/forms/formik'
import { handleErrorResponse } from '~/lib/form-utils'
import validations from './validations'

const Wrapper = styled.div`
  background-color: #fff;
  border: 1px solid #888;
  padding: 20px 40px;
  margin-top: 40px;
  height: fit-content;
`

const H2 = styled.h1`
  font-weight: 800;
`
const H3 = styled.h3``

const ResetButton = styled(Link)`
  display: block;
  margin-top: 1rem;
`

interface LoginFormProps {
  loginAction: (values: { email: string; password: string }) => Promise<void>
}

const LoginForm = ({ loginAction }: LoginFormProps) => {
  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema: validations,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        await loginAction(values)
        setSubmitting(false)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setSubmitting(false)
        handleErrorResponse({
          response: error.response,
          setErrors,
          baseMessage: 'Error logging in. Please contact support if the problem persists.'
        })
      }
    }
  })

  const { isSubmitting, handleSubmit, getFieldProps } = formik

  return (
    <FormikProvider value={formik}>
      <Wrapper>
        <BaseErrors />

        <H2>{/* CompanyName */}</H2>
        <H3>Login to your account</H3>
        <hr />

        <InputGroup {...getFieldProps('email')} />
        <InputGroup type="password" {...getFieldProps('password')} />

        <Button disabled={isSubmitting} onClick={() => handleSubmit()} isSubmitting={isSubmitting}>
          Login
        </Button>

        <ResetButton to="/forgot_password">Forgotten your password?</ResetButton>
      </Wrapper>
    </FormikProvider>
  )
}

export default LoginForm
