import axios from 'axios'

import snakeToCamelCase from '~/lib/snake-to-camel-case'
import { BASE_URL } from './constants'

export async function fetchList() {
  return axios.get(`${BASE_URL}/api/au_post_manifests`).then(res => snakeToCamelCase(res.data))
}

export async function fetchRecord(id: number) {
  return axios.get(`${BASE_URL}/api/au_post_manifests/${id}`).then(res => snakeToCamelCase(res.data))
}

export async function despatch(id: number) {
  return axios.post(`${BASE_URL}/api/au_post_manifests/${id}/despatch`).then(res => snakeToCamelCase(res.data))
}

export async function destroyRecord(id: number) {
  return axios.delete(`${BASE_URL}/api/au_post_manifests/${id}`).then(res => snakeToCamelCase(res.data))
}

export async function removeOrder(id: number, orderId: number) {
  return axios
    .post(`${BASE_URL}/api/au_post_manifests/${id}/remove_order`, { orderId })
    .then(res => snakeToCamelCase(res.data))
}

export async function fetchLabels(id: number) {
  return axios.post(`${BASE_URL}/api/au_post_manifests/${id}/labels`).then(res => snakeToCamelCase(res.data))
}

export function getDownloadPdfUrl(id: number) {
  return `${BASE_URL}/api/au_post_manifests/${id}/download`
}
