import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { FormikHelpers } from 'formik'
import axios from 'axios'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { AppDispatch } from '~/config/store'
import { updateOrder } from '~/reducers/orders-reducer'
import { setErrorsFromResponse } from '~/lib/formik-utils'
import Order, { OrderStatusEnum } from '~/types/order'

export type OrderFormValues = Partial<Order>

interface UseOrderDetailsProps {
  afterSave?: (order: Order) => Promise<void>
}

const useOrderDetails = ({ afterSave }: UseOrderDetailsProps) => {
  const dispatch = useDispatch<AppDispatch>()

  const save = useCallback(
    async (formValues: OrderFormValues, formikHelpers: FormikHelpers<OrderFormValues>) => {
      const { setSubmitting, setFieldError } = formikHelpers

      // Use product id, because the productId will still be set, even if it is
      // later deleted (if a product is deleted, then we don't want to consider
      // any orders it used to link to as suddenly "unmatched")
      //
      // in the future we'll add 'soft delete' for products
      const hasMissingProducts = _.filter(formValues.lineItems || [], lineItem => !lineItem.productId).length > 0
      const formData = _.cloneDeep(formValues)

      if (!hasMissingProducts) {
        formData.status = OrderStatusEnum.PROCESSED
      }

      let response

      setSubmitting(true)

      try {
        response = await axios.patch<Order>(`/api/orders/${formData.id}`, { order: formData })
        setSubmitting(false)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setSubmitting(false)

        if (error.response) {
          setErrorsFromResponse(error.response.data, setFieldError)
        } else {
          console.error(error)
        }

        return
      }

      const order = response?.data

      if (hasMissingProducts) {
        toast.warn(
          'Your changes have been saved. But this order has missing products. Please go back and enter them in the unmatched orders page.'
        )
      } else {
        toast.success('Order successfully saved')
      }

      dispatch(updateOrder(order))

      return await afterSave?.(order)
    },
    [dispatch, afterSave]
  )

  return {
    save
  }
}

export default useOrderDetails
