import { InputHTMLAttributes } from 'react'

const SearchBox = ({ onChange, value, className, ...rest }: InputHTMLAttributes<HTMLInputElement>) => (
  <input
    className={`${className} form-control`}
    type="text"
    value={value}
    placeholder="search products by name, sku, description."
    onChange={onChange}
    {...rest}
  />
)

export default SearchBox
