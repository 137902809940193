import styled from 'styled-components'

import ImportCompleted from '~/components/csv-imports/import-completed'
import ImportFailed from '~/components/csv-imports/import-failed'
import CsvImport from '~/types/csv-import'
import Button from '~/components/forms/button'

const btnStyles = {
  marginTop: '0',
  marginBottom: '10px'
}

const Div = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
`

const B = styled.div`
  font-weight: 600;
  font-size: 14px;
`

interface ResultViewProps {
  lastCsvImport: CsvImport
  goBack: () => void
}
const ResultView = ({ lastCsvImport, goBack }: ResultViewProps) => (
  <>
    <div className="d-flex justify-content-between align-items-center">
      {lastCsvImport && <B>Job ID: {lastCsvImport.id}</B>}
      <Button style={btnStyles} variant="light" onClick={goBack}>
        Go back
      </Button>
    </div>

    {!lastCsvImport && <Div>No previous results</Div>}
    {lastCsvImport && (
      <>
        {lastCsvImport.completed && <ImportCompleted lastCsvImport={lastCsvImport} />}
        {lastCsvImport.failed && <ImportFailed lastCsvImport={lastCsvImport} />}
      </>
    )}
  </>
)

export default ResultView
