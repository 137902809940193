import styled from 'styled-components'
import { useFormik, FormikProvider, FieldArray } from 'formik'
import { Alert } from 'react-bootstrap'

import { ActionBar, Button } from '~/components/forms'
import { BaseErrors, InputGroup } from '~/components/forms/formik'
import countriesList from '~/lib/countries-list'
import useSubmitHandler from '~/containers/settings/submit-handler'
import Icon from '~/components/icon'
import validations from './validations'
import { SettingsProviderState } from '~/contexts/settings'
import { CountryNotes, AccountSettingKeys } from '~/types/account-settings'

interface CountryNotesFormProps {
  initialValues: CountryNotes
  redirectPath: string
  settingsKey: AccountSettingKeys
  updateAction: SettingsProviderState['updateAction']
}

const countryOptions = [{ label: '---', value: '' }, ...countriesList]

const Container = styled.div`
  width: 800px;
`
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`
const NotesBoxWrapper = styled.div``

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`
const Country = styled(InputGroup)`
  flex: 1;
  margin-right: 1rem;
`
const Note = styled(InputGroup)`
  flex: 3;
`
const NoteWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: 1rem;
`
const Remove = styled(Button)`
  height: 0%;
`

const CountryNotesForm = (props: CountryNotesFormProps) => {
  const { submitHandler } = useSubmitHandler()
  const { initialValues, redirectPath } = props

  const formik = useFormik({
    initialValues,
    validationSchema: validations,
    onSubmit: (values, helpers) => submitHandler<CountryNotes, CountryNotesFormProps>(values, helpers, props)
  })

  const { values, handleSubmit, isSubmitting, getFieldProps } = formik

  return (
    <FormikProvider value={formik}>
      <Wrapper>
        <Container>
          <Alert variant="light">
            <p>
              Add some country-specific notes here, which will be visible when processing order for the respective
              country
            </p>
            <p>Useful if you need to remind your self of anything for orders destined to certain countries.</p>
          </Alert>

          <BaseErrors />

          <FieldArray
            name="notes"
            render={arrayHelpers => (
              <div>
                <NotesBoxWrapper>
                  {values.notes.map((note, index) => (
                    <Row key={note.id}>
                      <NoteWrapper>
                        <Country
                          marginBottom={'0'}
                          {...getFieldProps(`notes[${index}].countryCode`)}
                          type="select"
                          options={countryOptions}
                          label=""
                        />

                        <Note
                          marginBottom={'0'}
                          {...getFieldProps(`notes[${index}].body`)}
                          label=""
                          placeholder="Enter some notes"
                        />
                      </NoteWrapper>
                      <Remove variant="danger" onClick={() => arrayHelpers.remove(index)}>
                        <Icon icon="trash" />
                      </Remove>
                    </Row>
                  ))}
                </NotesBoxWrapper>

                <Button
                  onClick={() =>
                    arrayHelpers.push({
                      id: values.notes.length + 10,
                      countryCode: '',
                      body: ''
                    })
                  }>
                  New Note
                </Button>
              </div>
            )}
          />

          <ActionBar isSubmitting={isSubmitting} redirectPath={redirectPath} onSubmit={handleSubmit} />
        </Container>
      </Wrapper>
    </FormikProvider>
  )
}

export default CountryNotesForm
