import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import { default as qs } from 'qs'

import { encodeAsFormData } from '~/containers/product-search-links-page/utils'
import { transformCustomData } from '~/containers/product-form-page/utils'
import ProductSearchLink, { ProductSearchLinkOriginal } from '~/types/product-search-link'
import { ListMeta } from '~/reducers/product-search-links-reducer'
import { CustomDataOriginal } from '~/types/product'

// TODO: type this properly
interface QueryParams {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

interface FetchListPayload {
  items: ProductSearchLink[]
  meta: ListMeta
}

export interface ProductSearchLinkPayload extends Omit<ProductSearchLink, 'id' | 'coverImageUrl' | 'alternateTerms'> {
  id?: number
  coverImage?: File
  alternateTerms: CustomDataOriginal
}

export const fetchList = createAsyncThunk<
  { items: ProductSearchLink[]; meta: ListMeta },
  QueryParams,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { rejectValue: any }
>('productSearchLinks/fetchAll', async (queryParams, { rejectWithValue }) => {
  try {
    const response = await axios.get<FetchListPayload>('/api/mobile_product_search_links?' + qs.stringify(queryParams))
    return response.data
  } catch (error) {
    console.error(error)
    if (error instanceof AxiosError) {
      return rejectWithValue(error.response?.data)
    }
    return rejectWithValue('An unknown error occurred')
  }
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const destroyRecord = createAsyncThunk<ProductSearchLink, ProductSearchLink, { rejectValue: any }>(
  'productSearchLinks/destroy',
  async (record, { rejectWithValue }) => {
    try {
      await axios.delete(`/api/mobile_product_search_links/${record.id}`)
      return record
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchRecord = createAsyncThunk<ProductSearchLink, number, { rejectValue: any }>(
  'productSearchLinks/fetch',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get<ProductSearchLinkOriginal>(`/api/mobile_product_search_links/${id}`)
      const record = response.data
      const recordTransformed: ProductSearchLink = {
        ...record,
        alternateTerms: transformCustomData(record.alternateTerms)
      }
      return recordTransformed
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createRecord = createAsyncThunk<ProductSearchLink, ProductSearchLinkPayload, { rejectValue: any }>(
  'productSearchLinks/create',
  async (params, { rejectWithValue }) => {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    const formData = encodeAsFormData(params)
    try {
      const response = await axios.post<ProductSearchLinkOriginal>(`/api/mobile_product_search_links`, formData, config)
      const record = response.data
      const recordTransformed: ProductSearchLink = {
        ...record,
        alternateTerms: transformCustomData(record.alternateTerms)
      }
      return recordTransformed
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateRecord = createAsyncThunk<ProductSearchLink, ProductSearchLinkPayload, { rejectValue: any }>(
  'productSearchLinks/update',
  async (params, { rejectWithValue }) => {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    const formData = encodeAsFormData(params)
    try {
      const response = await axios.patch<ProductSearchLinkOriginal>(
        `/api/mobile_product_search_links/${params.id}`,
        formData,
        config
      )
      const record = response.data
      const recordTransformed: ProductSearchLink = {
        ...record,
        alternateTerms: transformCustomData(record.alternateTerms)
      }
      return recordTransformed
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)
