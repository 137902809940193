import { useState } from 'react'
import { Modal } from 'react-bootstrap'

import { OrderShippingData } from './form'
import CustomerForm from '../customer-form-page/form'
import { CustomerFormValues } from '../customer-form-page/use-customer-form'
import createCustomerSchema from '../customer-form-page/validation'
import { createCustomerRecordFromOrderValues } from './utils'

interface CustomerFormDialog {
  show: boolean
  orderShippingData: OrderShippingData
  onSave: (customer: CustomerFormValues) => Promise<void>
  onHide: () => void
}

const CustomerFormDialog = ({ show, orderShippingData, onSave, onHide }: CustomerFormDialog) => {
  const [skipCustomerSave, setSkipCustomerSave] = useState(false)
  const customer = createCustomerRecordFromOrderValues(orderShippingData)
  const validationSchema = createCustomerSchema({ validateEmail: true, validateAddress: true })

  return (
    show && (
      <Modal size="lg" show={show} onHide={onHide}>
        <Modal.Header closeButton />
        <Modal.Body>
          <CustomerForm
            initialValues={customer}
            validationSchema={validationSchema}
            beforeSave={async customer => {
              // Always save the customer if it's a new record, otherwise use the radio button choice
              const allowDefaultCustomerSave = !skipCustomerSave || !customer.id

              if (!allowDefaultCustomerSave) {
                await onSave(customer)
                onHide()
              }

              return allowDefaultCustomerSave
            }}
            afterSave={async customer => {
              await onSave(customer)
              onHide()
            }}
            onCancel={onHide}
            additionalContent={({ values }) => (
              <>
                {values.id && (
                  <>
                    <hr />
                    <div className="form-check">
                      <input
                        type="radio"
                        checked={skipCustomerSave === true}
                        onChange={() => setSkipCustomerSave(true)}
                        className="form-check-input"
                        id="skipCustomerSave"
                      />{' '}
                      <label className="form-check-label" htmlFor="skipCustomerSave">
                        Save changes only against this invoice
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="radio"
                        checked={skipCustomerSave === false}
                        onChange={() => setSkipCustomerSave(false)}
                        className="form-check-input"
                        id="saveCustomer"
                      />{' '}
                      <label className="form-check-label" htmlFor="saveCustomer">
                        Save changes to invoice and address book
                      </label>
                    </div>
                  </>
                )}
              </>
            )}
          />
        </Modal.Body>
      </Modal>
    )
  )
}

export default CustomerFormDialog
