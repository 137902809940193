import _ from 'lodash'
import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import ImportInProgress from '~/components/csv-imports/import-in-progress'
import ImportContainer from '~/components/csv-imports/import-container'
import { setLastCsvImport } from '~/reducers/order-processing-reducer'
import { AppState, AppDispatch } from '~/config/store'
import { CsvTypeEnum } from './upload-csv-screen'
import CsvImport from '~/types/csv-import'

interface UploadInProgressScreenProps {
  csvType: CsvTypeEnum
  onComplete?: (csvImport: CsvImport) => Promise<void>
}

const UploadInProgressScreen = ({ csvType, onComplete }: UploadInProgressScreenProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const lastCsvImport = useSelector((state: AppState) => state.orderProcessing.lastCsvImport)
  const resourcePath = `order_csv_imports/${csvType}`

  const onUpdate = useCallback(
    async (csvImport?: CsvImport) => {
      dispatch(setLastCsvImport(csvImport))
    },
    [dispatch]
  )

  const onCompleted = useCallback(
    async (csvImport: CsvImport) => {
      await onComplete?.(csvImport)
    },
    [onComplete]
  )

  if (lastCsvImport == null) return null

  return (
    <>
      <h1>Order Data Importing</h1>
      <ImportContainer
        lastCsvImport={lastCsvImport}
        resourcePath={resourcePath}
        onUpdate={onUpdate}
        onFailed={onCompleted}
        onCompleted={onCompleted}>
        <ImportInProgress lastCsvImport={lastCsvImport} />
      </ImportContainer>
    </>
  )
}

export default UploadInProgressScreen
