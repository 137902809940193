import { useState, ChangeEvent } from 'react'
import styled from 'styled-components'
import { Modal, FormGroup, FormControl, FormLabel } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { Button } from '~/components/forms'
import * as jobsApi from '~/api/au-post-shipment-jobs'

const btnStyles = {
  marginRight: '10px'
}

const Wrapper = styled.div`
  .modal-footer {
    text-align: left !important;
  }
`

interface ShipmentCreatorModalProps {
  orderIds: number[]
  show: boolean
  onHide: () => void
  setError?: (error: Error) => void
}

const ShipmentCreatorModal = ({ orderIds, show, onHide, setError = console.log }: ShipmentCreatorModalProps) => {
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState(false)
  const [params, setParams] = useState<{ layout: string }>({ layout: 'A6' })

  const onSubmit = () => {
    setSubmitting(true)
    console.log('[INFO]', 'creating shipment')
    console.log('[DEBUG] orderIds =', orderIds)
    console.log('[DEBUG] params =', params)
    jobsApi
      .create({ orderIds, params })
      .then(data => navigate(`/manifests/${data.manifest.id}`))
      .catch(error => {
        setError(error)
        setSubmitting(false)
      })
  }

  const handleParamsChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => {
    const target = event?.currentTarget
    const value = target?.value ? target?.value : 'A4'
    setParams({ layout: value })
  }

  return (
    <Wrapper>
      <Modal size="sm" show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Create Shipments</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FormGroup style={{ maxWidth: '300px' }}>
            <FormLabel>Select Layout</FormLabel>
            <FormControl name="layout" as="select" value={params.layout} onChange={handleParamsChange}>
              <option value="A4">A4</option>
              <option value="A6">A6</option>
            </FormControl>
          </FormGroup>
        </Modal.Body>

        <Modal.Footer>
          <Button
            style={btnStyles}
            variant="primary"
            disabled={!orderIds.length || submitting}
            onClick={onSubmit}
            isSubmitting={submitting}>
            Create
          </Button>
          <Button style={btnStyles} variant="light" onClick={onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Wrapper>
  )
}

export default ShipmentCreatorModal
