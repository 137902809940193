import Spinner from '~/components/spinner'
import Form from './form'
import useSettings from '~/contexts/useSettings'

const CountryNotesPage = () => {
  const { settings, settingsPending, updateAction } = useSettings()
  return settingsPending ? (
    <Spinner />
  ) : settings == null ? null : (
    <Form
      initialValues={settings.countryNotes}
      updateAction={updateAction}
      settingsKey="countryNotes"
      redirectPath="/settings"
    />
  )
}

export default CountryNotesPage
