import { ReactNode, FocusEventHandler, FocusEvent } from 'react'
import CreatableSelect from 'react-select/creatable'
import { SingleValue, ActionMeta } from 'react-select'
import _ from 'lodash'

import FieldLayout, { FieldLayoutProps } from './field-layout'
import { fieldLayoutKeys } from './field-layout-keys'
import omitProps from '~/lib/omit-props'
import { denormalizeSelectField } from '~/lib/form-utils'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ReactSelectInputCreatableProps<V = any, FormValues = any>
  extends Omit<FieldLayoutProps<V, FormValues>, 'children'> {
  onChange?:
    | ((
        newValue: SingleValue<{
          value: string
          label: string
        }>,
        actionMeta: ActionMeta<{
          value: string
          label: string
        }>
      ) => void)
    | undefined
  onBlur?: FocusEventHandler<HTMLInputElement>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ReactSelectInputCreatable = <V = any, FormValues = any>(props: ReactSelectInputCreatableProps<V, FormValues>) => {
  const {
    field: { value, onChange: formikOnChange, onBlur: formikOnBlur, ...otherFields },
    onChange: customOnChange,
    onBlur: customOnBlur,
    ...allRest
  } = props
  const rest = omitProps<ReactSelectInputCreatableProps<V, FormValues>, keyof FieldLayoutProps<V, FormValues>>(
    allRest,
    fieldLayoutKeys
  )
  const { name } = otherFields

  const onChange = (
    newValue: SingleValue<{
      value: string
      label: string
    }>,
    actionMeta: ActionMeta<{
      value: string
      label: string
    }>
  ) => {
    const valuePayload = Array.isArray(newValue) ? newValue.map(v => v.value) : newValue ? newValue.value : ''
    const syntheticEvent = {
      target: {
        name: name,
        value: valuePayload
      }
    } as React.ChangeEvent<HTMLInputElement>

    formikOnChange(syntheticEvent)
    customOnChange?.(newValue, actionMeta)
  }

  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    formikOnBlur(e)
    customOnBlur?.(e)
  }

  return (
    <FieldLayout {...props}>
      {({ id, value }) => (
        <CreatableSelect
          id={id}
          formatCreateLabel={(label: string | ReactNode) => (
            <span>
              <strong>Add </strong>
              {label}
            </span>
          )}
          value={denormalizeSelectField(value)}
          onChange={onChange}
          onBlur={onBlur}
          {...otherFields} // name, onBlur, onChange, checked, multiple
          {...rest}
        />
      )}
    </FieldLayout>
  )
}

export default ReactSelectInputCreatable
