import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  fetchProduct,
  createProduct,
  updateProduct,
  uploadImages,
  checkIfProductExists,
  fetchProducts,
  destroyProduct,
  copyProductToAccount
} from '~/async-actions/products-async-actions'
import { ProductInventorySummary, ExistsInAccounts } from '~/types/product-inventory'
import Product from '~/types/product'

export interface ListMeta {
  currentPage: number
  totalPages: number
  totalCount: number
  pageSize: number
  search: string | null
  categories: string | null
}

export interface ProductsPageState {
  list: Product[]
  listMeta?: ListMeta

  // Data for the currently loaded product
  currentProduct?: Product
  currentProductInventorySummary?: ProductInventorySummary
  currentProductExistsInAccounts?: ExistsInAccounts[]

  // Data for the product exists modal
  //
  // This can be triggered when renaming an existing product SKU to a sku that
  // exists on another product. In this case, this should open the modal
  //
  // Or when creating a new product that clashes with an existing product SKU.
  // But in that case, modal is usually not opened, and a Yup error will present
  // instead
  productExistsModal?: Product

  loading: {
    fetchProduct: boolean
    createProduct: boolean
    updateProduct: boolean
    uploadImages: boolean
    checkIfProductExists: boolean
    fetchProducts: boolean
    destroyProduct: boolean
    copyProductToAccount: boolean
  }
  error: {
    fetchProduct: string | null
    createProduct: string | null
    updateProduct: string | null
    uploadImages: string | null
    checkIfProductExists: string | null
    fetchProducts: string | null
    destroyProduct: string | null
    copyProductToAccount: string | null
  }
}

const sortProducts = (products: Product[]): Product[] => {
  return _.orderBy(products, 'id', 'desc')
}

const initialState: ProductsPageState = {
  list: [],
  listMeta: undefined,
  currentProduct: undefined,
  currentProductInventorySummary: undefined,
  currentProductExistsInAccounts: undefined,
  loading: {
    fetchProduct: false,
    createProduct: false,
    updateProduct: false,
    uploadImages: false,
    checkIfProductExists: false,
    fetchProducts: false,
    destroyProduct: false,
    copyProductToAccount: false
  },
  error: {
    fetchProduct: null,
    createProduct: null,
    updateProduct: null,
    uploadImages: null,
    checkIfProductExists: null,
    fetchProducts: null,
    destroyProduct: null,
    copyProductToAccount: null
  }
}

const productsPageSlice = createSlice({
  name: 'productsPage',
  initialState,
  reducers: {
    hideProductForm: state => {
      state.currentProductInventorySummary = {
        quantityAvailable: 0,
        onlineQuantityAvailable: 0,
        otherQuantityAvailable: 0,
        quantitySoldUnprocessed: 0,
        stockOnHandValue: null,
        totalSales: null
      }
      state.currentProductExistsInAccounts = undefined
    },
    closeProductExistsModal: state => {
      state.productExistsModal = undefined
    },
    loadProducts: (state, action: PayloadAction<{ items: Product[]; meta: ListMeta }>) => {
      state.list = sortProducts(action.payload.items)
      state.listMeta = action.payload.meta
    },
    clearProducts: state => {
      state.list = []
      state.listMeta = undefined
    },
    clearCurrentProduct: state => {
      state.currentProduct = undefined
      state.currentProductInventorySummary = undefined
      state.currentProductExistsInAccounts = undefined
    },
    addProduct: (state, action: PayloadAction<Product>) => {
      state.list.push(action.payload)
      state.list = sortProducts(state.list)
      if (state.listMeta) {
        state.listMeta.totalCount++
      }
    },
    replaceProduct: (state, action: PayloadAction<Product>) => {
      const index = state.list.findIndex(product => product.id === action.payload.id)
      if (index !== -1) {
        state.list[index] = action.payload
        state.list = sortProducts(state.list)
      }
    },
    deleteProduct: (state, action: PayloadAction<Product>) => {
      state.list = state.list.filter(product => product.id !== action.payload.id)
      state.list = sortProducts(state.list)
      if (state.listMeta) {
        state.listMeta.totalCount--
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchProduct.pending, state => {
        state.loading.fetchProduct = true
        state.error.fetchProduct = null
      })
      .addCase(fetchProduct.fulfilled, (state, action) => {
        state.loading.fetchProduct = false
        state.currentProduct = action.payload.product // Not really using this, preferring to get it directly from dispatch response
        state.currentProductInventorySummary = action.payload.inventory
        state.currentProductExistsInAccounts = action.payload.existsInAccounts
      })
      .addCase(fetchProduct.rejected, (state, action) => {
        state.loading.fetchProduct = false
        state.error.fetchProduct = action.payload
      })
      .addCase(createProduct.pending, state => {
        state.loading.createProduct = true
        state.error.createProduct = null
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.list.push(action.payload)
        state.list = sortProducts(state.list)
        if (state.listMeta) {
          state.listMeta.totalCount++
        }
        state.loading.createProduct = false
      })
      .addCase(createProduct.rejected, (state, action) => {
        state.loading.createProduct = false
        state.error.createProduct = action.payload
      })
      .addCase(updateProduct.pending, state => {
        state.loading.updateProduct = true
        state.error.updateProduct = null
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        const index = state.list.findIndex(product => product.id === action.payload.id)
        if (index !== -1) {
          state.list[index] = action.payload
          state.list = sortProducts(state.list)
        }
        state.loading.updateProduct = false
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.loading.updateProduct = false
        state.error.updateProduct = action.payload
      })
      .addCase(uploadImages.pending, state => {
        state.loading.uploadImages = true
        state.error.uploadImages = null
      })
      .addCase(uploadImages.fulfilled, state => {
        state.loading.uploadImages = false
      })
      .addCase(uploadImages.rejected, (state, action) => {
        state.loading.uploadImages = false
        state.error.uploadImages = action.payload
      })
      .addCase(checkIfProductExists.pending, state => {
        state.loading.checkIfProductExists = true
        state.error.checkIfProductExists = null
      })
      .addCase(checkIfProductExists.fulfilled, (state, action) => {
        state.loading.checkIfProductExists = false
        state.productExistsModal = action.payload
      })
      .addCase(checkIfProductExists.rejected, (state, action) => {
        state.loading.checkIfProductExists = false
        state.error.checkIfProductExists = action.payload
      })
      .addCase(fetchProducts.pending, state => {
        state.loading.fetchProducts = true
        state.error.fetchProducts = null
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading.fetchProducts = false
        if (action.payload) {
          state.list = sortProducts(action.payload.items)
          state.listMeta = action.payload.meta
        }
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loading.fetchProducts = false
        state.error.fetchProducts = action.payload
      })
      .addCase(destroyProduct.pending, state => {
        state.loading.destroyProduct = true
        state.error.destroyProduct = null
      })
      .addCase(destroyProduct.fulfilled, (state, action) => {
        state.loading.destroyProduct = false
        state.list = state.list.filter(product => product.id !== action.payload.id)
        state.list = sortProducts(state.list)
        if (state.listMeta) {
          state.listMeta.totalCount--
        }
      })
      .addCase(destroyProduct.rejected, (state, action) => {
        state.loading.destroyProduct = false
        state.error.destroyProduct = action.payload
      })
      .addCase(copyProductToAccount.pending, state => {
        state.loading.copyProductToAccount = true
        state.error.copyProductToAccount = null
      })
      .addCase(copyProductToAccount.fulfilled, (state, action) => {
        state.loading.copyProductToAccount = false
        state.currentProductExistsInAccounts = [
          ...new Set([...(state.currentProductExistsInAccounts || []), { subdomain: action.payload.subdomain }])
        ]
      })
      .addCase(copyProductToAccount.rejected, (state, action) => {
        state.loading.copyProductToAccount = false
        state.error.copyProductToAccount = action.payload
      })
  }
})

export const {
  loadProducts,
  clearProducts,
  clearCurrentProduct,
  hideProductForm,
  closeProductExistsModal,
  addProduct,
  replaceProduct,
  deleteProduct
} = productsPageSlice.actions

export default productsPageSlice.reducer
