import { object, number, string } from 'yup'

export default object().shape({
  id: number(),
  fullName: string().required('Required'),
  email: string().email('Invalid Email').required('Required'),
  kind: string().required('Required'),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  password: string().when('id', (v: any) => {
    const id = v?.[0]
    if (typeof id === 'number') return string()
    else return string().required('Required')
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  passwordConfirmation: string().when('id', (v: any) => {
    const id = v?.[0]
    if (typeof id === 'number') return string()
    else return string().required('Required')
  }),
  timeZone: string().required('Required')
})
