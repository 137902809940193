// TODO: move these components in with the rest of the 'settings' section pages
import MobilePages from '~/containers/mobile-pages'
import ShopfrontPages from '~/containers/shopfront-pages'
import ShippingPricingProfiles from '~/containers/shipping-pricing-profiles'
import WeightBasedShippingPricingProfiles from '~/containers/weight-based-shipping-pricing-profiles'

import ProductDefaults from './product-defaults/index'
import SellerProfile from './seller-profile'
import Eparcel from './eparcel'
import Customs from './customs'
import CountryNotes from './country-notes'
import Tracking from './tracking'
import Ebay from './ebay'
import Email from './email'
import Payments from './payments'
import Mobile from './mobile'
import Shopfront from './shopfront'
import AupostChargeCodes from './aupost_charge_codes'
import Merchant from './merchant'
import SocialLinks from './social-links'
import Intercom from './intercom'
import ParcelSend from './parcel_send'
import Twilio from './twilio'
import ShopfrontSmsTemplates from './shopfront-sms-templates'

const SectionsIndex = [
  {
    path: '/seller_profile',
    label: 'Seller Profile',
    icon: 'user',
    component: SellerProfile
  },
  {
    path: '/product_defaults',
    label: 'Product Defaults',
    icon: 'tshirt',
    component: ProductDefaults
  },
  {
    path: '/customs',
    label: 'International Customs Defaults',
    icon: 'globe-asia',
    component: Customs
  },
  {
    path: '/aupost_charge_codes',
    label: 'Australia Post Charge Codes',
    icon: 'globe-asia',
    component: AupostChargeCodes
  },
  {
    path: '/country_notes',
    label: 'Country Notes',
    icon: 'flag',
    component: CountryNotes
  },
  {
    path: '/email',
    label: 'Email',
    icon: 'envelope',
    component: Email,
    userKind: ['owner']
  },
  {
    path: '/mobile',
    label: 'Mau Mobile App',
    icon: 'mobile',
    component: Mobile
  },
  {
    path: '/twilio',
    label: 'Twilio',
    icon: 'mobile',
    component: Twilio
  },
  {
    path: '/shopfront_sms_templates',
    label: 'Shopfront SMS Templates',
    icon: 'mobile',
    component: ShopfrontSmsTemplates,
    shopfrontOnly: true
  },
  {
    path: '/shopfront',
    label: 'Shopfront',
    icon: 'shopping-cart',
    component: Shopfront,
    shopfrontOnly: true
  },
  {
    path: '/payments',
    label: 'Payments',
    icon: 'money-bill-alt',
    component: Payments,
    userKind: ['owner']
  },
  {
    path: '/parcel_send',
    label: 'Parcel Send',
    icon: 'box',
    component: ParcelSend
  },
  {
    path: '/eparcels',
    label: 'eParcel Defaults',
    icon: 'box',
    component: Eparcel
  },
  {
    path: '/order-tracking',
    label: 'eParcels Order Tracking',
    icon: 'truck',
    component: Tracking
  },
  {
    path: '/ebay',
    label: 'eBay',
    icon: ['fab', 'ebay'],
    component: Ebay,
    ebayOnly: true
  },
  {
    path: '/shipping_pricing_profiles',
    label: 'Shipping Pricing Profiles',
    icon: 'dollar-sign',
    component: ShippingPricingProfiles
  },
  {
    path: '/weight_based_shipping_pricing_profiles',
    label: 'Weight-based Shipping Pricing Profiles',
    icon: 'dollar-sign',
    component: WeightBasedShippingPricingProfiles
  },
  {
    path: '/mobile_pages',
    label: 'Mobile Pages',
    icon: ['fas', 'file-alt'],
    component: MobilePages
  },
  {
    path: '/shopfront_pages',
    label: 'Shopfront Pages',
    icon: ['far', 'file-alt'],
    component: ShopfrontPages,
    shopfrontOnly: true
  },
  {
    path: '/merchant',
    label: 'Google Merchant',
    icon: 'cog',
    component: Merchant
  },
  {
    path: '/social-links',
    label: 'Social',
    icon: ['fab', 'facebook'],
    component: SocialLinks
  },
  {
    path: '/intercom',
    label: 'Intercom',
    icon: ['fab', 'intercom'],
    component: Intercom
  }
]

export default SectionsIndex
