import _ from 'lodash'
import { Link } from 'react-router-dom'

import useSettings from '~/contexts/useSettings'

const EbayWarning = () => {
  const { settings } = useSettings()
  const shouldShow = !_.get(settings, 'ebayUserAccessToken.accessToken')

  return shouldShow ? (
    <div className="alert alert-warning">
      Warning! eBay is not connected - we cannot add tracking numbers to ebay. You may correct this from{' '}
      <Link to="/settings/ebay">eBay settings page</Link>
    </div>
  ) : (
    <div className="row mb-3">
      <p>
        Note: Use <Link to="/settings/eparcels">eParcels settings page</Link> to change settings.
      </p>
    </div>
  )
}

export default EbayWarning
