import _ from 'lodash'

import CounterInput, { CounterInputProps } from '~/components/counter-input'
import FieldLayout, { FieldLayoutProps } from './field-layout'
import { fieldLayoutKeys } from './field-layout-keys'
import omitProps from '~/lib/omit-props'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface CounterInputFieldProps<V = any, FormValues = any>
  extends Omit<CounterInputProps, 'value'>,
    Omit<FieldLayoutProps<V, FormValues>, 'children'> {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CounterInputField = <V = any, FormValues = any>(props: CounterInputFieldProps<V, FormValues>) => {
  const {
    field: { value, onChange: formikOnChange, ...otherFields },
    form,
    onChange: customOnChange,
    ...allRest
  } = props
  const rest = omitProps<
    Omit<CounterInputFieldProps<V, FormValues>, 'onChange'>,
    keyof FieldLayoutProps<V, FormValues>
  >(allRest, fieldLayoutKeys)
  const { name } = otherFields

  const onChange = (newValue: number) => {
    const syntheticEvent = {
      target: {
        name: name,
        value: newValue != null ? newValue : ''
      }
    } as React.ChangeEvent<HTMLInputElement>

    formikOnChange(syntheticEvent)
    customOnChange?.(newValue)
  }

  return (
    <FieldLayout {...props}>
      {({ id, value }) => (
        <CounterInput
          id={id}
          value={(value as string | number | readonly string[] | undefined) ?? ''}
          onChange={onChange}
          {...otherFields} // name, onBlur, onChange, checked, multiple
          {...rest}
        />
      )}
    </FieldLayout>
  )
}

export default CounterInputField
