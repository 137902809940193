import axios, { InternalAxiosRequestConfig, AxiosError, AxiosResponse } from 'axios'
import { JWT } from '~/api/auth'
import keysToCamelCase from '~/lib/snake-to-camel-case'
import keysToSnakeCase from '~/lib/camel-to-snake-case'
import { toast } from 'react-toastify'

// Type guard to check if the error is an AxiosError
export function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError).config !== undefined
}

export interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  snake?: boolean
  camel?: boolean
  ignoreChildKeys?: string[]
}

const getDefaultApiPath = () => {
  const parts = document.location.hostname.split('.')
  if (parts.length === 4) {
    return `https://${parts[0]}.api.${parts[2]}.${parts[3]}`
  }
  return ''
}

export const getApiPath = () => import.meta.env.VITE_APP_API_HOST || getDefaultApiPath()

const onSuccessResponse = (response: AxiosResponse) => {
  if (response) {
    const config = response.config as CustomAxiosRequestConfig
    if (config.camel === undefined || config.camel) {
      response.data = keysToCamelCase(response.data, response.config)
    }
  }

  return response
}

const onErrorResponse = (error: AxiosError) => {
  const signinPath = '/signin'
  const notSignin = window.location.pathname !== signinPath

  if (error.response && error.response.status === 401 && notSignin) {
    window.location.href = signinPath
    return
  }

  if (error.response) {
    const config = error.response.config as CustomAxiosRequestConfig
    if (config.camel === undefined || config.camel) {
      error.response.data = keysToCamelCase(error.response.data, error.response.config)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const err: any = error.response.data

    if (err.message) {
      toast.error(err.message)

      // check if the error is not an empty object / array
    } else if (Object.keys(err).length === 0) {
      toast.error('Something went wrong')
    }
  } else {
    toast.error('Something went wrong')
  }

  return Promise.reject(error)
}

export const configureAxios = () => {
  axios.defaults.baseURL = getApiPath()
  axios.defaults.headers.common['Content-Type'] = 'application/json'
  axios.defaults.headers.Accept = 'application/json'
  axios.defaults.withCredentials = true

  // Transform data to snakecase before sending to the server
  axios.interceptors.request.use(
    (config: CustomAxiosRequestConfig) => {
      // Do something before request is sent
      if ((config.snake === undefined || config.snake) && !(config.data instanceof FormData)) {
        config.data = keysToSnakeCase(config.data, config)
      }

      if (JWT.token) {
        config.headers.Authorization = `Bearer ${JWT.token}`
      }

      return config
    },
    error => {
      // Do something with request error
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(onSuccessResponse, onErrorResponse)
}
