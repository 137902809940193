import styled from 'styled-components'
import { animateScroll } from 'react-scroll'

import { Button, CancelButton } from '~/components/forms'

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-top: 3rem;
  border-top: 1px solid ${props => props.theme.colors.gray.lightest};
  padding-top: 2rem;
`

const Or = styled.div`
  padding: 0 0.5rem 0 2rem;
`

interface ActionBarProps {
  onSubmit: () => void
  disabled?: boolean
  isSubmitting?: boolean
  redirectPath: string
  submitLabel?: string
  cancelLabel?: string
}
const ActionBar = ({
  onSubmit,
  disabled = false,
  isSubmitting = false,
  redirectPath,
  submitLabel = 'Save Changes',
  cancelLabel = 'Cancel'
}: ActionBarProps) => (
  <Wrapper>
    <Button disabled={disabled || isSubmitting} isSubmitting={isSubmitting} onClick={() => onSubmit?.()}>
      {submitLabel}
    </Button>

    <Or>or</Or>

    <CancelButton to={{ pathname: redirectPath }} onClick={() => animateScroll.scrollToTop()}>
      {cancelLabel}
    </CancelButton>
  </Wrapper>
)

export default ActionBar
