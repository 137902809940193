import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

import { UnmatchedLineItem } from '~/types/ebay-sales-record'
import { ProductFormValues } from '~/containers/product-form-page/use-product-form'

import { fetchUnmatchedSalesRecords } from '~/async-actions/unmatched-sales-records-async-actions'

export interface UnmatchedSalesRecordsState {
  list: UnmatchedLineItem[]
  selectedRecord: UnmatchedLineItem | null
  loading: {
    fetchUnmatchedSalesRecords: boolean
  }
  error: {
    fetchUnmatchedSalesRecords: string | null
  }
}

const initialState: UnmatchedSalesRecordsState = {
  list: [],
  selectedRecord: null,
  loading: {
    fetchUnmatchedSalesRecords: false
  },
  error: {
    fetchUnmatchedSalesRecords: null
  }
}

const unmatchedSalesRecordsSlice = createSlice({
  name: 'unmatchedSalesRecords',
  initialState,
  reducers: {
    changeSelectedUnmatchedRecord: (state, action: PayloadAction<UnmatchedLineItem | null>) => {
      state.selectedRecord = action.payload
    },

    // New product using this scancode was created, so remove it from the
    // 'unprocessed' list, and set the next record as the currentlyu selected
    // one
    newProductAddedAndChangeSelected: (state, action: PayloadAction<ProductFormValues>) => {
      const newList = _.cloneDeep(state.list)
      const scancodes = _.map(action.payload.productImportMatchers, matcher => {
        return (matcher.scancode || '').toLowerCase()
      })
      const matched = newList.filter(unmatchedRecord => {
        return scancodes.includes((unmatchedRecord.importedScancode || '').toLowerCase())
      })
      const unmatchedRecordToRemove = matched[0]

      const index = newList.indexOf(unmatchedRecordToRemove)
      newList.splice(index, 1)

      state.list = newList
      state.selectedRecord = newList[0]
    },

    // Scancode was linked to an existing product, so remove it from the
    // 'unprocessed' list, and set the next record as the currently selected
    scancodeLinkedAndChangeSelected: (state, action: PayloadAction<{ scancode: string }>) => {
      const newList = _.cloneDeep(state.list)
      const matched = newList.filter(unmatchedRecord => {
        return (unmatchedRecord.importedScancode || '').toLowerCase() === (action.payload.scancode || '').toLowerCase()
      })
      const unmatchedRecordToRemove = matched[0]

      const index = newList.indexOf(unmatchedRecordToRemove)
      newList.splice(index, 1)

      state.list = newList
      state.selectedRecord = newList[0]
    }
  },
  extraReducers: builder => {
    builder
      // fetchUnmatchedSalesRecords async action
      .addCase(fetchUnmatchedSalesRecords.pending, state => {
        state.loading.fetchUnmatchedSalesRecords = true
        state.error.fetchUnmatchedSalesRecords = null
      })
      .addCase(fetchUnmatchedSalesRecords.fulfilled, (state, action) => {
        state.list = action.payload
        state.loading.fetchUnmatchedSalesRecords = false
      })
      .addCase(fetchUnmatchedSalesRecords.rejected, (state, action) => {
        state.loading.fetchUnmatchedSalesRecords = false
        state.error.fetchUnmatchedSalesRecords = action.payload?.message || 'Error fetching orders'
      })
  }
})

export const { changeSelectedUnmatchedRecord, newProductAddedAndChangeSelected, scancodeLinkedAndChangeSelected } =
  unmatchedSalesRecordsSlice.actions

export default unmatchedSalesRecordsSlice.reducer
