import { Link } from 'react-router-dom'
import styled from 'styled-components'

const LinkButton = styled(Link)`
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  font-size: 14px;
  line-height: 1.42857143;
  user-select: none;

  padding: 6px 12px;

  background-color: transparent;
  border: none;
  color: ${props => props.theme.colors.primary.base};
  &:hover {
    text-decoration: underline;
  }
`

export default LinkButton
