import { addMethod, number, string, mixed, setLocale } from 'yup'
import _ from 'lodash'

setLocale({
  mixed: {
    default: 'is invalid',
    required: 'Required',
    defined: 'must be defined',
    // notNull: 'cannot be null',
    oneOf: 'must be one of the following values: ${values}',
    // notOneOf: 'must not be one of the following values: ${values}',
    notType: ({ type }) => {
      return type !== 'mixed' ? `must be a valid ${type}` : `must match the configured type. `
    }
  },
  string: {
    length: 'must be exactly ${length} characters',
    min: 'must be at least ${min} characters',
    max: 'must be at most ${max} characters',
    matches: 'must match the following: "${regex}"',
    email: 'must be a valid email',
    url: 'must be a valid URL',
    uuid: 'must be a valid UUID',
    trim: 'must be a trimmed string',
    lowercase: 'must be a lowercase string',
    uppercase: 'must be a upper case string'
  },
  number: {
    min: 'must be greater than or equal to ${min}',
    max: 'must be less than or equal to ${max}',
    lessThan: 'must be less than ${less}',
    moreThan: 'must be greater than ${more}',
    positive: 'must be a positive number',
    negative: 'must be a negative number',
    integer: 'must be an integer'
  },
  date: {
    min: 'field must be later than ${min}',
    max: 'field must be at earlier than ${max}'
  },
  boolean: {
    isValue: 'field must be ${value}'
  },
  object: {
    noUnknown: 'field has unspecified keys: ${unknown}'
  },
  array: {
    min: 'field must have at least ${min} items',
    max: 'field must have less than or equal to ${max} items'
    //length: 'must have ${length} items'
  }
  // tuple: {
  //   notType: params => {
  //     const { path, value, spec } = params
  //     const typeLen = spec.types.length
  //     if (Array.isArray(value)) {
  //       if (value.length < typeLen)
  //         return `tuple value has too few items, expected a length of ${typeLen} but got ${
  //           value.length
  //         } for value: \`${printValue(value, true)}\``
  //       if (value.length > typeLen)
  //         return `tuple value has too many items, expected a length of ${typeLen} but got ${
  //           value.length
  //         } for value: \`${printValue(value, true)}\``
  //     }

  //     return ValidationError.formatError(mixed.notType, params)
  //   }
  // }
})

addMethod(number, 'greaterThan', function (ref, message) {
  return this.test({
    name: 'greaterThan',
    exclusive: false,
    message: message || '${path} must be greater than ${reference}',
    params: {
      reference: ref.path
    },
    test: function (value) {
      const minValue = parseFloat((this.from?.[0].value[ref] || 0).toString())
      const thisValue = parseFloat((value || 0).toString())
      return thisValue > minValue
    }
  })
})

addMethod(string, 'equalTo', function (ref, message) {
  return this.test({
    name: 'equalTo',
    exclusive: false,
    message: message || '${path} must be the same as ${reference}',
    params: {
      reference: ref.path
    },
    test: function (value) {
      return value === this.from?.[0].value[ref]
    }
  })
})

function requiredIfDependenciesPresentMethod(dependencies: string[], message?: string) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore-next-line
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (this as any).test({
    name: 'requiredIfDependenciesPresentMethod',
    message: message || 'is required',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    test: function (value: any) {
      const otherValues = dependencies.map(field => this.parent[field])
      const anyFieldHasValue = otherValues.some(v => !!v)
      return !(anyFieldHasValue && !value)
    },
    exclusive: true
  })
}

function requiredIfDependenciesBlankMethod(dependencies: string[], message?: string) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore-next-line
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (this as any).test({
    name: 'requiredIfDependenciesBlankMethod',
    message: message || 'is required',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    test: function (value: any) {
      const otherValues = dependencies.map(field => this.parent[field])
      const anyFieldIsBlank = otherValues.some(v => !v)
      return !(anyFieldIsBlank && !value)
    },
    exclusive: true
  })
}

function notMatchesMethod(pattern: RegExp, options?: { excludeEmptyString?: boolean; message?: string }) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore-next-line
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (this as any).test({
    name: 'notMatches',
    message: options?.message || 'The value should not match the pattern',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    test: function (value: any) {
      // If excludeEmptyString is true and the value is an empty string, pass validation
      if (options?.excludeEmptyString && !value) {
        return true
      }
      // If the value does not match the pattern, pass validation
      return !pattern.test(value)
    },
    exclusive: true
  })
}

function isInCollectionMethod(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  collection: any[],
  message: string,
  options?: { path?: string; ifDependenciesPresent?: string[] }
) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore-next-line
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (this as any).test({
    name: 'isInCollection',
    exclusive: false,
    message: message || '${path} is not in the list',
    params: {
      collection: options?.path ? options.path : collection.join(', ')
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    test: function (value: any) {
      if (options?.path != null) {
        // If option set, only run the check if any of the dependencies are set
        const otherValues = options?.ifDependenciesPresent?.map(field => this.parent[field])
        const anyFieldHasValue = otherValues?.some(v => !!v)
        const dependencyCheck = (otherValues?.length || 0) > 0 ? !anyFieldHasValue : false

        // If path is provided in options, use _.find and _.get to check value
        return dependencyCheck || Boolean(_.find(collection, item => _.get(item, options.path || '') === value))
      }
      // Otherwise, simply check if the value is included in the collection
      return collection.includes(value)
    }
  })
}

const yupTypes = [mixed, number, string] // ... you can add more types as needed

yupTypes.forEach(type => {
  addMethod(type, 'requiredIfDependenciesPresent', requiredIfDependenciesPresentMethod)
  addMethod(type, 'requiredIfDependenciesBlank', requiredIfDependenciesBlankMethod)
  addMethod(type, 'notMatches', notMatchesMethod)
  addMethod(type, 'isInCollection', isInCollectionMethod)
})

addMethod(number, 'maxDecimalPlaces', function (max: number, message?: string) {
  return this.test({
    name: 'max-decimal-places',
    message: message || `max of ${max} decimal places`,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    test: function (val: any) {
      return /^-?\d*(\.\d{1,2})?$/.test(val?.toString() || '')
    }
  })
})
