import { Alert } from 'react-bootstrap'
import _ from '~/lib/lodash'
import { AxiosError } from 'axios'

interface ServerErrorProps {
  serverError: AxiosError | null
  clearServerError: () => void
}

const ServerError = ({ serverError, clearServerError }: ServerErrorProps) => {
  let details: {
    status?: number
    type?: string
    message?: string
    origin?: string
  } = {}
  details.status = serverError?.response?.status

  if (serverError?.response?.data) {
    const info = _.pick(serverError.response.data, ['type', 'message', 'origin'])
    details = { ...details, ...info }
  }

  if (!serverError) {
    return null
  }

  return (
    <Alert variant="danger" onClose={clearServerError}>
      <h4>There was an error</h4>
      <hr />

      <p>Status: {details.status}</p>
      <p>Type: {details.type}</p>
      <p>Message: {details.message}</p>
      <p>Origin: {details.origin}</p>
    </Alert>
  )
}

export default ServerError
