import styled from 'styled-components'
import _ from 'lodash'
import { FieldArray, useFormikContext } from 'formik'

import { newInventoryPojo } from '~/lib/utils/helpers'
import { Button } from '~/components/forms'
import Icon from '~/components/icon'
import ProductInventory from '~/types/product-inventory'
import Product from '~/types/product'
import RenderStockHistoryRow from './render-stock-history-row'

const Box = styled.div`
  margin-bottom: 30px;
`

const BaseRow = styled.div`
  display: flex;
  flex-direction: row;
`
const BaseCol = styled.div<{ width?: number | string; textAlign?: string }>`
  flex-basis: ${props => props.width || 150}px;
  ${props => (props.width ? 'min-width: ' + props.width + ';' : '')}
  ${props => (props.textAlign ? 'text-align: ' + props.textAlign + ';' : '')}
  margin-right: 5px;
`
const HRow = styled(BaseRow)``
const Row = styled(BaseRow)`
  margin-bottom: 10px;
`

const HCol = styled(BaseCol)`
  font-weight: 600;
`

const Col = styled(BaseCol)``

export type ProductFormValues = Pick<Product, 'inventories' | 'mobile'>

export interface RenderStockHistoryProps {
  readOnly?: boolean
  showTotals?: boolean
}
const RenderStockHistory = ({ readOnly = false, showTotals = true }: RenderStockHistoryProps) => {
  const { values: formValues, errors } = useFormikContext<ProductFormValues>()

  let totalQuantity = 0
  let totalSold = 0

  const defaultRecord = newInventoryPojo()

  return (
    <Box>
      <HRow>
        <HCol>Date</HCol>
        <HCol>Supplier Name</HCol>
        <HCol>Invoice / PO #</HCol>
        <HCol>Qty In</HCol>
        {formValues.mobile && <HCol>Qty In (Online)</HCol>}
        {formValues.mobile && <HCol>Qty In (Other)</HCol>}
        <HCol>
          Cost <small>(Ex GST)</small>
        </HCol>
        <HCol width="100">Invoice</HCol>
        <HCol width="100">Goods</HCol>
        {showTotals === null ||
          (showTotals && (
            <HCol width="40" textAlign="center">
              Sold
            </HCol>
          ))}
        {showTotals === null ||
          (showTotals && formValues.mobile && (
            <HCol width="40" textAlign="center">
              Mob
            </HCol>
          ))}
        {showTotals === null ||
          (showTotals && formValues.mobile && (
            <HCol width="40" textAlign="center">
              Oth
            </HCol>
          ))}
        <HCol width="40" />
      </HRow>

      <FieldArray name="inventories">
        {arrayHelpers => (
          <>
            {(formValues.inventories || []).map((inventory: ProductInventory, index: number) => {
              totalQuantity += Number(inventory.quantityIn) || 0
              totalSold += Number(inventory.quantitySold) || 0
              const existingRecord = Boolean(inventory.id)

              const key = formValues?.inventories?.[index].id || `idx${index}`

              return (
                <RenderStockHistoryRow
                  key={key}
                  index={index}
                  formValues={formValues}
                  showTotals={showTotals}
                  readOnly={readOnly}
                  existingRecord={existingRecord}
                  onRemove={() => arrayHelpers.remove(index)}
                />
              )
            })}

            {showTotals && (
              <Row>
                <Col>
                  <Button
                    variant="light"
                    onClick={() => arrayHelpers.push(_.cloneDeep(defaultRecord) as ProductInventory)}>
                    Add&nbsp;&nbsp;
                    <Icon icon="plus" />
                  </Button>
                  {typeof errors.inventories === 'string' && (
                    <span className="form-text text-danger">{errors.inventories}</span>
                  )}
                </Col>
                <Col>
                  <strong>Total Quantity: {totalQuantity}</strong>
                </Col>
                <Col>
                  <strong>Total Sold: {totalSold}</strong>
                </Col>
              </Row>
            )}
          </>
        )}
      </FieldArray>
    </Box>
  )
}

export default RenderStockHistory
