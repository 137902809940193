import { FocusEventHandler, FocusEvent } from 'react'
import AsyncSelect from 'react-select/async'
import { SingleValue, ActionMeta } from 'react-select'
import _ from 'lodash'

import FieldLayout, { FieldLayoutProps } from './field-layout'
import { fieldLayoutKeys } from './field-layout-keys'
import omitProps from '~/lib/omit-props'
import { denormalizeSelectField } from '~/lib/form-utils'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ReactAsyncSelectInputProps<V = any, FormValues = any>
  extends Omit<FieldLayoutProps<V, FormValues>, 'children'> {
  // TODO get these from react-select
  cacheOptions?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loadOptions?: (inputValue: string, callback: (options: any) => void) => void
  onChange?:
    | ((
        newValue: SingleValue<{
          value: string
          label: string
        }>,
        actionMeta: ActionMeta<{
          value: string
          label: string
        }>
      ) => void)
    | undefined
  onBlur?: FocusEventHandler<HTMLInputElement>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ReactAsyncSelectInput = <V = any, FormValues = any>(props: ReactAsyncSelectInputProps<V, FormValues>) => {
  const {
    field: { value, onChange: formikOnChange, onBlur: formikOnBlur, ...otherFields },
    onChange: customOnChange,
    onBlur: customOnBlur,
    ...allRest
  } = props
  const rest = omitProps<ReactAsyncSelectInputProps<V, FormValues>, keyof FieldLayoutProps<V, FormValues>>(
    allRest,
    fieldLayoutKeys
  )
  const { name } = otherFields

  const onChange = (
    newValue: SingleValue<{
      value: string
      label: string
    }>,
    actionMeta: ActionMeta<{
      value: string
      label: string
    }>
  ) => {
    const valuePayload = Array.isArray(newValue) ? newValue.map(v => v.value) : newValue ? newValue.value : ''
    const syntheticEvent = {
      target: {
        name: name,
        value: valuePayload
      }
    } as React.ChangeEvent<HTMLInputElement>

    formikOnChange(syntheticEvent)
    customOnChange?.(newValue, actionMeta)
  }

  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    formikOnBlur(e)
    customOnBlur?.(e)
  }

  return (
    <FieldLayout {...props}>
      {({ id, value }) => (
        <AsyncSelect
          id={id}
          value={denormalizeSelectField(value)}
          isClearable
          placeholder="Type to search"
          noOptionsMessage={({ inputValue }) => {
            return inputValue ? `No categories match "${inputValue}"` : 'Please enter a search term'
          }}
          onChange={onChange}
          onBlur={onBlur}
          {...rest}
        />
      )}
    </FieldLayout>
  )
}

export default ReactAsyncSelectInput
