import { useEffect } from 'react'
import { Field, useFormikContext } from 'formik'
import _ from 'lodash'
import classnames from 'classnames'

import { BsSelectInput, BsInput } from '~/components/form-inputs/formik/inputs-decorated'
import { OrderShippingData, PosFormValues } from './form'
import {
  calculateSubtotal,
  calculateDiscountAmount,
  calculateTaxAmount,
  calculateTotal,
  createCustomerRecordFromOrderValues,
  isDomestic,
  domesticShippingServices,
  intlShippingServices,
  printCurrency
} from './utils'

import styles from './calculations-box.module.css'

interface CalculationsBoxProps {
  orderShippingData: OrderShippingData
}
const CalculationsBox = ({ orderShippingData }: CalculationsBoxProps) => {
  const { values: formValues, setFieldValue } = useFormikContext<PosFormValues>()
  const customer = createCustomerRecordFromOrderValues(orderShippingData)
  const services = customer.country && isDomestic(customer.country) ? domesticShippingServices : intlShippingServices
  const options: { value: string; label: string }[] = []
  options.push({ label: 'Please select', value: '' })
  _.each(services, (label, value) => {
    options.push({ label, value: _.snakeCase(value) })
  })

  const { lineItems, subtotal, discount, discountAmount, lineItemsContainGst, shippingCost } = formValues

  useEffect(() => {
    const subtotal = calculateSubtotal(lineItems)
    setFieldValue('subtotal', subtotal)
  }, [lineItems, setFieldValue])

  useEffect(() => {
    const taxAmount = calculateTaxAmount(subtotal, lineItemsContainGst)
    setFieldValue('taxAmount', taxAmount)
  }, [subtotal, lineItemsContainGst, setFieldValue])

  useEffect(() => {
    const discountAmount = calculateDiscountAmount(subtotal, discount)
    setFieldValue('discountAmount', discountAmount)
  }, [subtotal, discount, setFieldValue])

  useEffect(() => {
    const total = calculateTotal(subtotal, discountAmount, shippingCost)
    setFieldValue('total', total)
  }, [subtotal, discountAmount, shippingCost, setFieldValue])
  const { shippingCountry } = orderShippingData

  useEffect(() => {
    if (shippingCountry) {
      const defaultShippingService = isDomestic(shippingCountry) ? 'regular_eparcel' : 'international_airmail'
      const defaultTax = isDomestic(shippingCountry) ? '1' : '0'
      setFieldValue('shippingService', defaultShippingService)
      setFieldValue('lineItemsContainGst', defaultTax)
    } else {
      setFieldValue('shippingService', 'no_shipping')
      setFieldValue('lineItemsContainGst', '0')
    }
  }, [shippingCountry, setFieldValue])

  return (
    <div className={styles.wrapper}>
      <div className={styles.subtotalContainer}>
        <div className={styles.label}>Subtotal</div>
        <div className={styles.output}>
          <Field
            name="subtotal"
            component={BsInput}
            label={null}
            inputWrapperClassName={classnames(styles.inputWrapper, styles.subtotal)}
            formatFn={(value: number) => printCurrency(value, '')}
          />
        </div>
      </div>

      <div className={styles.box}>
        <div className={styles.fieldset}>
          <div className={styles.label}>Discount</div>
          <div className={styles.optionsCol}>
            <Field
              name="discount.type"
              options={[
                { value: '', label: 'Please select' },
                { value: '%', label: '%' },
                { value: '$', label: '$' }
              ]}
              component={BsSelectInput}
              label={null}
            />
          </div>
          <div className={styles.valueCol}>
            <Field
              name="discount.value"
              component={BsInput}
              label={null}
              type="number"
              min={0}
              max={999}
              inputWrapperClassName={styles.inputWrapper}
            />
          </div>
        </div>
        <div className={styles.label}>Discount</div>
        <div className={styles.output}>
          <Field
            readOnly
            name="discountAmount"
            component={BsInput}
            label={null}
            disabled
            formatFn={(value: number) => printCurrency(value, '-')}
            inputWrapperClassName={styles.inputWrapper}
          />
        </div>
      </div>

      <div className={styles.box}>
        <div className={styles.fieldset}>
          <div className={styles.label}>Shipping</div>
          <div className={styles.optionsCol}>
            <Field name="shippingService" component={BsSelectInput} options={options} label={null} />
          </div>
          <div className={styles.valueCol}>
            <Field
              name="shippingCost"
              component={BsInput}
              label={null}
              type="number"
              min={0}
              max={999}
              inputWrapperClassName={styles.inputWrapper}
            />
          </div>
        </div>
        <div className={styles.label}>Shipping</div>
        <div className={styles.output}>
          <Field
            readOnly
            name="shippingCost"
            component={BsInput}
            label={null}
            disabled
            formatFn={(value: number) => printCurrency(value, '')}
            inputWrapperClassName={styles.inputWrapper}
          />
        </div>
      </div>

      <div className={styles.box}>
        <div className={styles.fieldset}>
          <div className={styles.label}>Tax</div>
          <div className={styles.fieldWrap}>
            <Field
              name="lineItemsContainGst"
              options={[
                { value: '', label: 'Please select' },
                { value: '1', label: 'Includes GST of' },
                { value: '0', label: 'No GST' }
              ]}
              component={BsSelectInput}
              label={null}
            />
          </div>
        </div>
        <div className={styles.label}>Tax</div>
        <div className={styles.output}>
          <Field
            readOnly
            name="taxAmount"
            component={BsInput}
            label={null}
            disabled
            formatFn={(value: number) => printCurrency(value, '')}
            inputWrapperClassName={styles.inputWrapper}
          />
        </div>
      </div>

      <div className={styles.totalContainer}>
        <div className={styles.totalLabel}>Total</div>
        <div className={styles.output}>{printCurrency(formValues.total, '$')}</div>
      </div>
    </div>
  )
}

export default CalculationsBox
