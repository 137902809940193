import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'

import { AppState } from '~/config/store'
import Tooltip from '../../components/tooltip'
import { Button } from '~/components/forms'
import Icon from '~/components/icon'
import { OrderFormValues } from './use-order-details'

interface SaveAndNextProps {
  saveBtnText?: string
  onCancel?: () => void
}

const SaveAndNext = ({ onCancel, saveBtnText = 'Save' }: SaveAndNextProps) => {
  const transientFormData = useSelector((state: AppState) => state.transientFormData)
  const { values: formValues, submitForm } = useFormikContext<OrderFormValues>()
  const { isDomestic } = formValues
  const isInternational = !isDomestic

  const { countryNote } = transientFormData.orderDetailsForm

  const _infoIcon = () => {
    if (isDomestic || !countryNote?.note) {
      return null
    }
    return <Icon icon="question-circle" size="2x" className="text-info" />
  }

  return (
    <>
      <Tooltip
        showTemplateOnHover={isInternational && Boolean(countryNote?.note)}
        hoverTemplate={
          <div className="well tooltip-text">
            <p className="mt-3">
              <strong>Notes for {countryNote?.countryName}</strong>
            </p>
            <p>{countryNote?.note || '-'}</p>
          </div>
        }>
        <div>
          {_infoIcon()}
          <Button onClick={submitForm}>{saveBtnText}</Button>
          &nbsp;&nbsp;
          <Button variant="light" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </Tooltip>
    </>
  )
}

export default SaveAndNext
