import { useEffect, useState, useCallback } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'

import standingDataService from '~/services/standing-data-service'
import Product from '~/types/product'
import Icon from '~/components/icon'

type Categories = Product['categories']

interface ProductCategoriesFilterProps {
  activeCategories: Categories
  onChange: (categories: Categories) => void
}

const ProductCategoriesFilter = ({ activeCategories, onChange }: ProductCategoriesFilterProps) => {
  const [allCategories, setAllCategories] = useState<Categories>([])

  const fetchAllCategories = useCallback(async () => {
    try {
      const result = await standingDataService.fetchProductCategoriesOptions()
      setAllCategories(result.map(item => item.value))
    } catch (error) {
      console.error(error)
    }
  }, [])

  const isActive = useCallback(
    (categoryName: string) => {
      return (activeCategories || []).includes(categoryName)
    },
    [activeCategories]
  )

  const clearFilters = useCallback(() => {
    onChange([])
  }, [onChange])

  const toggleCheckbox = useCallback(
    (categoryName: string) => {
      let newActiveCategories: Categories = []

      if (isActive(categoryName)) {
        newActiveCategories = activeCategories.filter(item => item !== categoryName)
      } else {
        newActiveCategories = activeCategories.concat([categoryName])
      }

      onChange(newActiveCategories)
    },
    [activeCategories, isActive, onChange]
  )

  useEffect(() => {
    fetchAllCategories()
  }, [fetchAllCategories])

  return (
    <Dropdown>
      <Dropdown.Toggle variant="light">Filter by category</Dropdown.Toggle>

      <Dropdown.Menu>
        {activeCategories.length > 0 ? (
          <Dropdown.Item key="clear-all">
            <span className="text-danger" onClick={() => clearFilters()}>
              <Icon icon="times" />
              &nbsp;&nbsp;<strong>Clear all</strong>
            </span>
          </Dropdown.Item>
        ) : null}

        <div className="mx-3">
          {allCategories.map(category => (
            <div className="form-check" key={category}>
              <input
                type="checkbox"
                checked={isActive(category)}
                onChange={() => toggleCheckbox(category)}
                className="form-check-input"
                id={`category-${category}`}
              />
              <label className="form-check-label" htmlFor={`category-${category}`}>
                {category}
              </label>
            </div>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ProductCategoriesFilter
