import { MouseEvent, ChangeEvent, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { AxiosError } from 'axios'
import { useFormik, FormikProvider } from 'formik'
import _ from 'lodash'
import { default as qs } from 'qs'
import styled from 'styled-components'
import { toast } from 'react-toastify'

import { Button } from '~/components/forms'
import { AppState, AppDispatch } from '~/config/store'
import { BaseErrors, FlexibleInputGroup } from '~/components/forms/formik'

import useAuth from '~/contexts/useAuth'
import {
  createRecord,
  updateRecord,
  ProductSearchLinkPayload
} from '~/async-actions/product-search-links-async-actions'
import validateForm from './validate-form'
import CategoriesField from './categories-field'
import AlternateTermsSection from './alternate-terms-section'
import { reverseTransformCustomData } from '~/containers/product-form-page/utils'
import ProductSearchLink from '~/types/product-search-link'

const Legend = styled.legend`
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px;
`

const ImagePreviewBox = styled.div`
  margin-left: 100px;
  margin-top: 20px;
  border: 1px solid #ccc;
  padding: 20px;
  text-align: center;
`
const Row = styled.div`
  display: flex;
`
const Col = styled.div``

interface ProductSearchLinkFormProps {
  initialValues: Partial<ProductSearchLink>
}

const ProductSearchLinkForm = ({ initialValues }: ProductSearchLinkFormProps) => {
  const { userProfile } = useAuth()

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const location = useLocation()

  const listMeta = useSelector((state: AppState) => state.productSearchLinks.listMeta)
  const prevLocation = useSelector((state: AppState) => state.router.prevLocation)

  const isEditing = useCallback(() => !_.isUndefined(initialValues.id), [initialValues])

  const formik = useFormik({
    initialValues,
    validationSchema: validateForm,
    onSubmit: (values, _helpers) => {
      save(values)
    }
  })

  const { setFieldValue, isSubmitting, values, submitForm } = formik

  const handleImageChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()

      const imageFile = e.target.files?.[0]
      if (imageFile == null) {
        return
      }
      const reader = new FileReader()

      reader.onloadend = () => {
        setFieldValue(`coverImageUrl`, reader.result)
        setFieldValue(`coverImage`, imageFile)
      }

      reader.readAsDataURL(imageFile)
    },
    [setFieldValue]
  )

  const addAlternateTermField = () => {
    const alternateTerms = [...(values.alternateTerms ?? []), { key: '', value: '' }]
    setFieldValue('alternateTerms', alternateTerms)
  }

  const removeAlternateTermField = (index: number) => {
    const alternateTerms = [...(values.alternateTerms ?? [])]
    alternateTerms.splice(index, 1)
    setFieldValue('alternateTerms', alternateTerms)
  }

  const handleCancel = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    redirectToSamePageWithFilters()
  }

  const update = useCallback(
    (values: ProductSearchLinkPayload) => {
      return dispatch(updateRecord(values))
    },
    [dispatch]
  )

  const create = useCallback(
    (values: ProductSearchLinkPayload) => {
      return dispatch(createRecord(values))
    },
    [dispatch]
  )

  const kind = useCallback(() => {
    const { pathname } = location
    const matches = pathname.match(/\/([a-z-]+)/)
    return matches?.[1] || 'mobile'
  }, [location])

  const redirectPath = useCallback(() => `/${_.kebabCase(kind())}/product-search-links`, [kind])

  const redirectToSamePageWithFilters = useCallback(() => {
    if (prevLocation && prevLocation.pathname.includes(`/${_.kebabCase(kind())}`)) {
      return navigate(prevLocation)
    }

    const page = listMeta?.currentPage || 1
    navigate({
      pathname: redirectPath(),
      search: qs.stringify({ page })
    })
  }, [navigate, listMeta, prevLocation, redirectPath, kind])

  const save = useCallback(
    async (_values: Partial<ProductSearchLink>) => {
      const values: ProductSearchLinkPayload = {
        ...(_.omit(_values, 'coverImageUrl') as ProductSearchLink),
        alternateTerms: reverseTransformCustomData(_values.alternateTerms)
      }

      const promise = isEditing() ? update(values) : create(values)

      return promise
        .then(() => {
          redirectToSamePageWithFilters()
        })
        .catch((error: AxiosError) => {
          if (error.response) {
            // TODO: render these errors
            console.error(error.response.data)
            // throw new SubmissionError(
            //   Object.assign({}, error.response.data, {
            //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
            //     _error: (error.response.data as any).base
            //   })
            // )
          } else {
            console.error(error)

            toast.error('An error occurred. Form was not saved')
          }
        })
    },
    [isEditing, redirectToSamePageWithFilters, update, create]
  )

  if (!userProfile || !['owner', 'staff_full'].includes(userProfile.kind)) return null

  return (
    <FormikProvider value={formik}>
      <div className="form form-horizontal">
        <Legend>{isEditing() ? 'Edit Product Search Link' : 'New Product Search Link'}</Legend>

        <BaseErrors />

        <Row>
          <Col>
            <FlexibleInputGroup name="name" />
            <FlexibleInputGroup name="searchTerm" />
            <AlternateTermsSection
              onAdd={addAlternateTermField}
              onRemove={removeAlternateTermField}
              alternateTerms={values.alternateTerms}
            />
          </Col>

          <Col>
            <FlexibleInputGroup name="featured" label="Featured?" type="checkbox" />
            <CategoriesField />
            <FlexibleInputGroup name="brand" />
            <FlexibleInputGroup name="coverImage" type="file" accept="image/*" onChange={handleImageChange} />

            {values.coverImageUrl && (
              <ImagePreviewBox>
                <img src={values.coverImageUrl} width="200" alt="product" />
              </ImagePreviewBox>
            )}
          </Col>
        </Row>

        <Row>
          <Button disabled={isSubmitting} onClick={submitForm} isSubmitting={isSubmitting}>
            Save
          </Button>
          &nbsp;&nbsp;
          <Button variant="light" onClick={handleCancel}>
            Cancel
          </Button>
        </Row>
      </div>
    </FormikProvider>
  )
}

export default ProductSearchLinkForm
