import { MutableRefObject } from 'react'
import { PayloadAction } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { default as qs } from 'qs'
import { FormikHelpers } from 'formik'

import { setErrorsFromResponse } from '~/lib/formik-utils'
import { reverseTransformCustomData } from './utils'
import {
  createProduct,
  updateProduct,
  checkIfProductExists,
  uploadImages
} from '~/async-actions/products-async-actions'
import { changeProductCategoryFilters } from '~/reducers/persistable-data-reducer'
import Product, { ProductImage, CustomDataOriginal } from '~/types/product'
import { AppDispatch, AppState } from '~/config/store'
import { PackageDimensionsAndCustomsInfoRef } from './main-tab/package-dimensions-and-customs-info'
import { ProductSpecificationsRef } from './main-tab/product-specifications'
import { InventoryManagementBasicRef } from './main-tab/inventory-management-basic'
import { hideProductForm } from '~/reducers/products-reducer'
import { useConfirm } from '~/hooks'

export interface ProductFormPayload extends Omit<ProductFormValues, 'customData'> {
  customData: CustomDataOriginal
}

export interface ProductFormValues extends Omit<Product, 'regularShippingProfileId' | 'expressShippingProfileId'> {
  regularShippingProfileId?: string | number | null
  expressShippingProfileId?: string | number | null
}

interface UseProductFormProps {
  redirectPath?: string
  productSpecificationsRef: MutableRefObject<ProductSpecificationsRef | undefined>
  packageDimensionsAndCustomsInfoRef: MutableRefObject<PackageDimensionsAndCustomsInfoRef | undefined>
  inventoryManagementBasicRef: MutableRefObject<InventoryManagementBasicRef | undefined>
}

const useProductForm = ({
  redirectPath,
  productSpecificationsRef,
  packageDimensionsAndCustomsInfoRef,
  inventoryManagementBasicRef
}: UseProductFormProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const listMeta = useSelector((state: AppState) => state.productsPage.listMeta)
  const navigate = useNavigate()
  const [confirm] = useConfirm()
  const prevLocation = useSelector((state: AppState) => state.router.prevLocation)

  const redirectToSamePageWithFilters = () => {
    if (prevLocation && prevLocation.pathname.match(/\/(mobile|shopfront)/)) {
      return navigate(prevLocation)
    }

    const page = listMeta?.currentPage || 1
    const search = listMeta?.search || ''
    const categories = listMeta?.categories || ''

    navigate({
      pathname: redirectPath,
      search: qs.stringify({ page, search, categories })
    })
  }

  const updateStandingDataFromProduct = (product: Product) => {
    if (product.categories && inventoryManagementBasicRef.current) {
      const val = product.categories
      val.forEach(category => {
        inventoryManagementBasicRef.current?.addCategories([{ value: category, label: category }])
      })
    }
    if (product.customsDescription && packageDimensionsAndCustomsInfoRef.current) {
      const val = product.customsDescription
      packageDimensionsAndCustomsInfoRef.current.addCustomsDescription([{ value: val, label: val }])
    }
    if (product.variantGroup && productSpecificationsRef.current) {
      const val = product.variantGroup
      productSpecificationsRef.current.addVariantGroups([{ value: val, label: val }])
    }
    if (product.color && productSpecificationsRef.current) {
      const val = product.color
      productSpecificationsRef.current.addColors([{ value: val, label: val }])
    }
    if (product.brand && productSpecificationsRef.current) {
      const val = product.brand
      productSpecificationsRef.current.addBrands([{ value: val, label: val }])
    }
  }

  const save = async (
    values: ProductFormValues,
    { setFieldError, setSubmitting }: FormikHelpers<ProductFormValues>
  ) => {
    setSubmitting(true)

    const formParams = _.cloneDeep(values)
    const newImages = _.filter(formParams.images, '_new') as ProductImage[]
    const isNew = !formParams.id

    const formPayload = {
      ...formParams,
      customData: reverseTransformCustomData(formParams.customData)
    } as ProductFormPayload

    let response
    let product: Product | undefined

    try {
      if (formPayload.id) {
        response = (await dispatch(updateProduct(formPayload))) as PayloadAction<Product>
        product = response.payload
      } else {
        const productCheckResponse = (await dispatch(
          checkIfProductExists({ customSku: formPayload.customSku })
        )) as PayloadAction<Product | undefined>
        if (productCheckResponse.payload) {
          // Return errors to formik
          return Promise.reject({ customSku: 'already exists' })
        }
        response = (await dispatch(createProduct(formPayload))) as PayloadAction<Product>
        product = response.payload
      }

      if (response.type.endsWith('/rejected')) {
        throw response.payload
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (errors: any) {
      console.error('failed to save product', errors)

      if (Object.keys(errors).length > 0) {
        setErrorsFromResponse(errors, setFieldError)
      }

      setSubmitting(false)
      toast.error('An error occurred when saving the product. Please review the form for any errors.')
      // Return errors to formik
      return Promise.reject(errors)
    }

    // Upload any images for the product
    if (product?.id != null && newImages.length > 0) {
      await dispatch(uploadImages({ productId: product.id, newImages }))
    }

    updateStandingDataFromProduct(product)

    setSubmitting(false)

    // Redirect after image upload
    if (redirectPath) {
      // Reset active category filters
      dispatch(changeProductCategoryFilters([]))
      redirectToSamePageWithFilters()
    }

    toast.success(isNew ? 'Product successfully created' : 'Product successfully updated')

    return product
  }

  const cancel = async (formValues: ProductFormValues) => {
    dispatch(hideProductForm())

    const hasUnsavedPhotos = _.filter(formValues.images, '_new').length > 0

    if (redirectPath) {
      if (hasUnsavedPhotos) {
        if (await confirm({ message: 'Unsaved photos found! Do you really want to cancal?' }))
          redirectToSamePageWithFilters()
      } else {
        redirectToSamePageWithFilters()
      }
    }
  }

  return {
    save,
    cancel
  }
}

export default useProductForm
