import { useState } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useFormik, FormikProvider } from 'formik'

import { AppDispatch } from '~/config/store'
import { Button } from '../../components/forms'
import ProductSearchRow from './product-search-row'
import { OrderLineItem, ShippingServiceEnum } from '~/types/order'
import Customer from '~/types/customer'
import Product from '~/types/product'
import RenderLineItem, { LineHeadings, ColView, ColGroup, ColA, ColB, Col } from './render-line-item'
import { validationSchema } from './validation'
import { createOrder, updateOrder } from '~/async-actions/pos-async-actions'
import OrderHeaderFields from './order-header-fields'
import SelectCustomerDialog from './select-customer-dialog'
import CustomerFormDialog from './customer-form-dialog'
import ProductDetailsDialog from './product-details-dialog'
import CustomerDetailsBox from './customer-details-box'
import CustomerLinks from './customer-links'
import { orderCustomerFieldsMap, getCustomerErrors } from './utils'
import { setErrorsFromResponse } from '~/lib/formik-utils'
import ConfirmDialog from './confirm-dialog'
import CalculationsBox from './calculations-box'

export type CustomerDialogType = 'SelectCustomer' | 'CustomerForm'

const orderCustomerFields = [
  'customerId',
  'customerEmail',
  'shippingContactName',
  'shippingContactPhone',
  'shippingAddress1',
  'shippingAddress2',
  'shippingAddress3',
  'shippingCity',
  'shippingState',
  'shippingCountry',
  'shippingCountryName',
  'shippingPostcode'
] as const

export interface Discount {
  value: number
  type: '%' | '$'
}

type OtherFormValues = {
  deletedLineItems: OrderLineItem[]
  discount: Discount
  subtotal: number
  total: number
  taxAmount: number
}

export interface PosFormLineItemValue {
  id?: number
  productId?: number | null
  quantity: number
  unitPrice: string | number
  subtotal: number
  product?: Product | null
}

export interface PosFormValues extends OtherFormValues {
  id?: number
  reference: string | null
  orderDate: string
  discountAmount: number | null
  shippingCost: number
  lineItemsContainGst: `0` | `1`
  shippingService: ShippingServiceEnum
  customerId: number | null
  customerEmail: string | null
  shippingContactName: string
  shippingContactPhone?: string | null
  shippingAddress1: string | null
  shippingAddress2?: string | null
  shippingAddress3?: string | null
  shippingAddress4?: string | null
  shippingCity: string | null
  shippingState: string | null
  shippingCountry: string
  shippingCountryName: string
  shippingPostcode: string
  createdWith?: string
  nettPrice?: number
  insuranceCost?: number
  customsClassification?: string | null
  totalPrice?: number
  lineItems: PosFormLineItemValue[]
}

export type OrderShippingData = Pick<PosFormValues, (typeof orderCustomerFields)[number]>

interface PosFormProps {
  initialValues: PosFormValues
}

const PosForm = ({ initialValues }: PosFormProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const [showSelectCustomerDialog, setShowSelectCustomerDialog] = useState(false)
  const [showCustomerFormDialog, setShowCustomerFormDialog] = useState(false)
  const [productDetailsLine, setProductDetailsLine] = useState<PosFormLineItemValue | undefined>(undefined)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)

  const navigate = useNavigate()

  const save = (values: PosFormValues) => {
    return values.id ? update(values) : create(values)
  }

  const handleSave = (values: PosFormValues) => {
    setShowConfirmDialog(true)
    save(values)
  }

  const formik = useFormik<PosFormValues>({
    initialValues,
    onSubmit: handleSave,
    enableReinitialize: true,
    validationSchema
  })

  const {
    values: formValues,
    errors,
    handleSubmit,
    setFieldValue,
    setFieldError,
    isSubmitting,
    isValid,
    resetForm
  } = formik
  const orderShippingData = _.pick(formValues, orderCustomerFields)
  const { lineItems } = formValues

  const create = async (values: PosFormValues) => {
    return dispatch(createOrder(values))
      .then(() => {
        toast.success('Successfuly created order')
        setShowConfirmDialog(false)
        // TODO: reset redux state?
        resetForm()
      })
      .catch(error => {
        if (error.payload) {
          setErrorsFromResponse(error.payload, setFieldError)
        } else {
          console.error(error)
          toast.error('A error occurred. Form was not saved')
        }
      })
  }

  const update = async (values: PosFormValues) => {
    return dispatch(updateOrder(values))
      .then(() => {
        setShowConfirmDialog(false)
        toast.success('Successfuly updated order')
      })
      .catch(error => {
        if (error.payload) {
          setErrorsFromResponse(error.payload, setFieldError)
        } else {
          console.error(error)
          toast.error('A error occurred. Form was not saved')
        }
      })
  }

  const toggleDialog = (type: CustomerDialogType, value: boolean) => {
    if (type === 'CustomerForm') setShowCustomerFormDialog(value)
    else if (type === 'SelectCustomer') setShowSelectCustomerDialog(value)
  }

  const handleRemoveCustomer = () => {
    copyValuesFromCustomerRecord({} as Customer)
  }

  const handleCancel = () => {
    navigate('/order-history')
  }

  const submitBtnLabel = () => {
    return formValues.id ? 'Update Order' : 'Create Order'
  }

  const copyValuesFromCustomerRecord = (customer: Customer) => {
    _.each(orderCustomerFieldsMap, (customerField, orderField) => {
      const value = customer[customerField as keyof Customer] || ''
      setFieldValue(orderField, value)
    })
  }

  return (
    <FormikProvider value={formik}>
      <div className="d-flex justify-content-between">
        <div>
          {orderShippingData.customerId && (
            <CustomerDetailsBox
              orderShippingData={orderShippingData}
              errors={getCustomerErrors(errors)}
              onEdit={() => toggleDialog('CustomerForm', true)}
              onRemove={handleRemoveCustomer}
            />
          )}

          {!orderShippingData.customerId && <CustomerLinks error={errors.customerId} toggleDialog={toggleDialog} />}
        </div>

        <OrderHeaderFields />
      </div>

      <hr />

      <LineHeadings>
        <ColView></ColView>
        <ColGroup>
          <ColA>Custom SKU</ColA>
          <ColB>Product</ColB>
        </ColGroup>
        <Col>Qty</Col>
        <Col>Price</Col>
        <Col className="line-total">Line Total</Col>
      </LineHeadings>

      <div>
        {lineItems.map((lineItem, index) => (
          <RenderLineItem
            index={index}
            lineItem={lineItem}
            onLineClicked={line => setProductDetailsLine(line)}
            key={index}
          />
        ))}
      </div>

      <ProductSearchRow />

      <hr />

      <CalculationsBox orderShippingData={orderShippingData} />

      <div className="d-flex justify-content-end gap-3">
        <Button variant="light" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className="submit"
          disabled={!isValid || isSubmitting || formValues.lineItems.length === 0}
          isSubmitting={isSubmitting}
          onClick={() => handleSubmit()}>
          {submitBtnLabel()}
        </Button>
      </div>

      <ProductDetailsDialog
        line={productDetailsLine}
        show={productDetailsLine != null}
        onHide={() => setProductDetailsLine(undefined)}
      />

      <ConfirmDialog show={showConfirmDialog} onHide={() => setShowConfirmDialog(false)} />

      <SelectCustomerDialog
        show={showSelectCustomerDialog}
        onHide={() => toggleDialog('SelectCustomer', false)}
        onSelect={copyValuesFromCustomerRecord}
      />

      <CustomerFormDialog
        show={showCustomerFormDialog}
        orderShippingData={orderShippingData}
        onSave={async customer => copyValuesFromCustomerRecord(customer as Customer)}
        onHide={() => toggleDialog('CustomerForm', false)}
      />
    </FormikProvider>
  )
}

export default PosForm
