export function calculateCubicWeight(
  length?: number | string | null,
  width?: number | string | null,
  height?: number | string | null
) {
  const l = parseInt(length?.toString() || '', 10)
  const w = parseInt(width?.toString() || '', 10)
  const h = parseInt(height?.toString() || '', 10)

  return l && w && h ? Math.round(l * w * h * 0.00025 * 100) / 100 : undefined
}
