import defaultTheme from './default'
import schema from './validations'

const currentTheme = defaultTheme

schema.validate(currentTheme).catch(error => {
  console.log('[DEBUG] error =', error)
  throw new Error('An error occurred!')
})

export default currentTheme
