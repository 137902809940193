import styled from 'styled-components'
import { getApiPath } from '~/config/axios'

import ExportButton from '~/components/export-button'

const Wrapper = styled.div``

const MerchantSettingsPage = () => {
  const endpoint = '/api/merchant/export'
  const url = `${getApiPath()}${endpoint}`

  return (
    <Wrapper>
      <p>
        <strong>Data Feed URL: </strong>
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      </p>
      <p>
        <strong>NOTE:</strong> Only products that match the following are included in the feed:
      </p>
      <ul>
        <li>Has at least 1 mobile image</li>
        <li>Has at least 1 mobile quantity available</li>
        <li>&quot;Mobile enabled&quot; is checked</li>
        <li>&quot;Show in Google Products Feed?&quot; is checked</li>
      </ul>

      <ExportButton endpoint={endpoint} icon="file-excel" label="Download Public Data Feed" />
    </Wrapper>
  )
}

export default MerchantSettingsPage
