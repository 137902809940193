import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { arrayMove } from '@dnd-kit/sortable'

import MobilePage from '~/types/mobile-page'

import { loadItems, addOrUpdateItem, deleteItem, reSortItems } from '~/async-actions/mobile-pages-async-actions'

export interface MobilePagesState {
  items: MobilePage[]
  loading: {
    loadItems: boolean
    addOrUpdateItem: boolean
    deleteItem: boolean
    reSortItems: boolean
  }
  error: {
    loadItems: string | null
    addOrUpdateItem: string | null
    deleteItem: string | null
    reSortItems: string | null
  }
}

export interface ReSortPayload {
  oldIndex: number
  newIndex: number
}

const initialState: MobilePagesState = {
  items: [],
  loading: {
    loadItems: false,
    addOrUpdateItem: false,
    deleteItem: false,
    reSortItems: false
  },
  error: {
    loadItems: null,
    addOrUpdateItem: null,
    deleteItem: null,
    reSortItems: null
  }
}

const mobilePagesSlice = createSlice({
  name: 'mobile-pages',
  initialState,
  reducers: {
    loadItems: (state, action: PayloadAction<MobilePage[]>) => {
      state.items = action.payload
    },
    addOrUpdateItem: (state, action: PayloadAction<MobilePage>) => {
      state.items = addToOrUpdateList(state.items, action.payload)
    },
    moveItem: (state, action: PayloadAction<ReSortPayload>) => {
      state.items = moveItemWithinList(state.items, action.payload)
    },
    deleteItem: (state, action: PayloadAction<MobilePage>) => {
      state.items = removeFromList(state.items, action.payload)
    }
  },
  extraReducers: builder => {
    builder
      // loadItems async action
      .addCase(loadItems.pending, state => {
        state.loading.loadItems = true
        state.error.loadItems = null
      })
      .addCase(loadItems.fulfilled, (state, action) => {
        state.items = action.payload
        state.loading.loadItems = false
      })
      .addCase(loadItems.rejected, (state, action) => {
        state.loading.loadItems = false
        state.error.loadItems = action.payload?.message || 'Error fetching mobile page'
      })

      // addOrUpdateItem async action
      .addCase(addOrUpdateItem.pending, state => {
        state.loading.addOrUpdateItem = true
        state.error.addOrUpdateItem = null
      })
      .addCase(addOrUpdateItem.fulfilled, (state, action) => {
        state.items = addToOrUpdateList(state.items, action.payload)
        state.loading.addOrUpdateItem = false
      })
      .addCase(addOrUpdateItem.rejected, (state, action) => {
        state.loading.addOrUpdateItem = false
        state.error.addOrUpdateItem = action.payload?.message || 'Error adding or updating mobile page'
      })

      // deleteItem async action
      .addCase(deleteItem.pending, state => {
        state.loading.deleteItem = true
        state.error.deleteItem = null
      })
      .addCase(deleteItem.fulfilled, (state, action) => {
        state.items = removeFromList(state.items, action.payload)
        state.loading.deleteItem = false
      })
      .addCase(deleteItem.rejected, (state, action) => {
        state.loading.deleteItem = false
        state.error.deleteItem = action.payload?.message || 'Error deleting mobile page'
      })

      // reSortItems async action
      .addCase(reSortItems.pending, state => {
        state.loading.reSortItems = true
        state.error.reSortItems = null
      })
      .addCase(reSortItems.fulfilled, (state, _action) => {
        // state.items = reSortList(state.items, action.payload)
        state.loading.reSortItems = false
      })
      .addCase(reSortItems.rejected, (state, action) => {
        state.loading.reSortItems = false
        state.error.reSortItems = action.payload?.message || 'Error deleting mobile page'
      })
  }
})

// export const { loadItems, addOrUpdateItem, reSortItems, deleteItem } = mobilePagesSlice.actions
export const { moveItem } = mobilePagesSlice.actions

export default mobilePagesSlice.reducer

const moveItemWithinList = (items: MobilePage[], { oldIndex, newIndex }: ReSortPayload) => {
  const newList: MobilePage[] = arrayMove(items, oldIndex, newIndex)
  newList.forEach((item, index) => {
    item.sortIndex = index
  })
  return newList
}

const addToOrUpdateList = (items: MobilePage[], newItem: MobilePage) => {
  const newList = _.clone(items)
  const index = _.findIndex(newList, ['id', newItem.id])
  if (index === -1) {
    newList.push(newItem)
  } else {
    newList.splice(index, 1, newItem)
  }
  return sortList(newList)
}

const removeFromList = (items: MobilePage[], itemToDelete: MobilePage) => _.filter(items, i => i.id !== itemToDelete.id)

const sortList = (items: MobilePage[]) => _.sortBy(items, ['sortIndex'])
