import useSettings from '~/contexts/useSettings'
import Spinner from '~/components/spinner'
import DetailsView from './details-view'

const EbaySettingsPage = () => {
  const { settings, settingsPending, setSettings } = useSettings()
  const { accessToken, expiresAt, refreshTokenExpiresAt } = settings?.ebayUserAccessToken || {}

  return settingsPending ? (
    <Spinner />
  ) : settings == null ? null : (
    <DetailsView
      accessToken={accessToken}
      expiresAt={expiresAt}
      refreshTokenExpiresAt={refreshTokenExpiresAt}
      ebayConnectUrl={settings.ebayConnectUrl}
      superDomain={settings.superDomain}
      setSettings={setSettings}
    />
  )
}

export default EbaySettingsPage
