import { ChangeEvent } from 'react'
import styled from 'styled-components'
import { useFormik, FormikProvider } from 'formik'
import { Alert } from 'react-bootstrap'

import { ActionBar } from '~/components/forms'
import { BaseErrors, FlexibleInputGroup } from '~/components/forms/formik'
import { SettingsProviderState } from '~/contexts/settings'
import { MobileConfig, AccountSettingKeys } from '~/types/account-settings'
import useSubmitHandler from '~/containers/settings/submit-handler'
import validations from './validations'

const Container = styled.div`
  width: 800px;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

const ImagePreviewBox = styled.div`
  margin-left: 100px;
  margin-top: 20px;
  border: 1px solid #ccc;
  padding: 20px;
  text-align: center;
`

const sortByOptions = [
  ['---', ''],
  ['Price', 'sale_price'],
  ['Quantity Available', 'online_quantity_available'],
  ['Created', 'id'],
  ['Popularity', 'quantity_sold']
].map(item => ({ label: item[0], value: item[1] }))

const sortDirectionOptions = [
  ['---', ''],
  ['Ascending', 'asc'],
  ['Descending', 'desc']
].map(item => ({ label: item[0], value: item[1] }))

interface MauMobileSettingsFormProps {
  redirectPath: string
  initialValues: MobileConfig
  settingsKey: AccountSettingKeys
  updateAction: SettingsProviderState['updateAction']
  imagePreviewUrl?: string
  imageFile?: File
  onImageChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const MauMobileSettingsForm = (props: MauMobileSettingsFormProps) => {
  const { submitHandler } = useSubmitHandler()
  const { redirectPath, initialValues, imagePreviewUrl, onImageChange } = props
  const formik = useFormik({
    initialValues,
    validationSchema: validations,
    onSubmit: (values, helpers) => {
      const newValues = props.imageFile
        ? {
            ...values,
            storeBannerImage: props.imageFile
          }
        : values
      return submitHandler<MobileConfig, MauMobileSettingsFormProps>(newValues, helpers, props)
    }
  })

  return (
    <FormikProvider value={formik}>
      <Wrapper>
        <Container>
          <Alert variant="info">
            <p>These settings apply to the Mau mobile app.</p>
            <p>If you change these settings, they may not take effect immediately in Mau for existing sessions.</p>
            <p>
              Mau caches settings, and only refreshes them max once per day. If you need Mau to update its settings
              immediately, then you can always log in+out in the app.
            </p>
          </Alert>

          <BaseErrors />

          <FlexibleInputGroup name="storeTitle" label="Store Title" />
          <FlexibleInputGroup name="supportEmail" label="Support Email" />
          <FlexibleInputGroup
            name="mobileDefaultSorting.sortBy"
            label="Sort By"
            type="select"
            options={sortByOptions}
          />
          <FlexibleInputGroup
            name="mobileDefaultSorting.sortDirection"
            label="Sort Direction"
            type="select"
            options={sortDirectionOptions}
          />
          <FlexibleInputGroup
            name="mobilePreferences.showSortBtn"
            label="Show the Sort Icon"
            type="checkbox"
            checked={formik.values.mobilePreferences.showSortBtn}
          />
          <FlexibleInputGroup
            name="mobilePreferences.showSearchBar"
            label="Show the Search Bar"
            type="checkbox"
            checked={formik.values.mobilePreferences.showSearchBar}
          />

          <h4>Store Banner Image</h4>

          <FlexibleInputGroup
            label=""
            name="tempStoreBannerImage"
            type="file"
            accept="image/*"
            onChange={onImageChange}
          />

          <ImagePreviewBox>
            {imagePreviewUrl ? (
              <img src={imagePreviewUrl} width="200" style={{ visibility: 'visible' }} alt="store banner" />
            ) : (
              <span>Please select an image to preview</span>
            )}
          </ImagePreviewBox>

          <ActionBar isSubmitting={formik.isSubmitting} redirectPath={redirectPath} onSubmit={formik.handleSubmit} />
        </Container>
      </Wrapper>
    </FormikProvider>
  )
}

export default MauMobileSettingsForm
