import styled from 'styled-components'
import { Navigate } from 'react-router-dom'

import Form from './form'
import useAuth from '~/contexts/useAuth'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #5e6673;
  min-height: 100vh;
`

const LoginPage = () => {
  const { userProfile, loginAction } = useAuth()

  if (userProfile) {
    return <Navigate to={'/'} />
  }

  return (
    <Wrapper>
      <Form loginAction={loginAction} />
    </Wrapper>
  )
}

export default LoginPage
