import useSettings from '~/contexts/useSettings'
import useAuth from '~/contexts/useAuth'
import Spinner from '~/components/spinner'
import SendGridPreferencesForm from './form'
import { UserKindEnum } from '~/types/user'

const SendGridPreferencesSettingsPage = () => {
  const { settings, settingsPending, updateAction } = useSettings()
  const { userProfile } = useAuth()

  if (userProfile?.kind !== UserKindEnum.OWNER) return null

  return settingsPending ? (
    <Spinner />
  ) : settings == null ? null : (
    <SendGridPreferencesForm
      initialValues={settings.sendgrid}
      updateAction={updateAction}
      settingsKey="sendgrid"
      redirectPath="/settings"
    />
  )
}

export default SendGridPreferencesSettingsPage
