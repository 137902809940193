import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import { AppState, AppDispatch } from '~/config/store'
import useAuth from '~/contexts/useAuth'
import Grid from '~/components/grid'
import Icon from '~/components/icon'
import { sortList, loadSearchResults } from '~/reducers/priority-items-reducer'
import {
  fetchList,
  destroyRecord,
  createRecord,
  sortListAsync,
  searchMenuEntries
} from '~/async-actions/priority-items-async-actions'
import ItemSearchBox from './item-search-box'
import ProductSearchLink from '~/types/product-search-link'
import PriorityItem, { PriorityItemKindEnum, PriorityItemTypeEnum } from '~/types/priority-item'
import { columnsConfig } from './config'

const Nav = styled.div`
  margin-bottom: 2rem;
  display: flex;
`
const NavItem = styled.div`
  margin-right: 2rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

function calcNextItemMobilePriority(list: PriorityItem[]) {
  return 1000 - list.length * 10
}

interface PriorityItemsPageProps {
  kind: PriorityItemKindEnum
}
const PriorityItemsPage = ({ kind }: PriorityItemsPageProps) => {
  const navigate = useNavigate()

  const dispatch = useDispatch<AppDispatch>()
  const { list, listMeta, searchResults } = useSelector((state: AppState) => state.priorityItems)

  const { userProfile } = useAuth()

  const handleSelect = useCallback(
    (item: ProductSearchLink) => {
      const itemType =
        /* eslint-disable-next-line no-prototype-builtins */
        (item.hasOwnProperty('customSku') ? 'Product' : 'MobileProductSearchLink') as PriorityItemTypeEnum
      const itemId = item.id
      const priority = calcNextItemMobilePriority(list)

      dispatch(createRecord({ params: { itemType, itemId, priority }, kind }))
    },
    [dispatch, kind, list]
  )

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      dispatch(sortList({ oldIndex, newIndex }))

      const orderedIds = _.map(list, item => item.id)
      dispatch(sortListAsync({ orderedIds, kind }))
    },
    [dispatch, kind, list]
  )

  const onEdit = useCallback(
    (record: PriorityItem) => {
      const path =
        record.itemType === 'MobileProductSearchLink'
          ? `/${_.kebabCase(kind)}/product-search-links/${record.itemId}/edit`
          : `/products/${record.itemId}/edit`
      navigate(path)
    },
    [kind, navigate]
  )

  const onDelete = useCallback(
    (record: PriorityItem) => {
      return dispatch(destroyRecord({ record, kind }))
    },
    [dispatch, kind]
  )

  // Grid expects fetchFn to be a thunk with a particular shape. For now, all we can do is return as 'any' to make it work
  //
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fetchFn: any = useCallback(
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    (queryParams: Record<string, any>) => {
      return fetchList({ kind, queryParams })
    },
    [kind]
  )

  if (!userProfile || !['owner', 'staff_full'].includes(userProfile.kind)) return null

  return (
    <div>
      <h1>{_.capitalize(kind)} Priority Items</h1>

      <p>Items that will appear at the top of the {_.capitalize(kind)} search results.</p>

      {userProfile && (userProfile.kind === 'owner' || userProfile.kind === 'staff_full') && (
        <div>
          <Nav>
            <NavItem onClick={() => navigate(`/${_.kebabCase(kind)}/product-search-links?page=1`)}>
              <Icon icon="external-link-alt" />
              &nbsp; Search Links Page
            </NavItem>

            {kind === 'shopfront' && (
              <NavItem onClick={() => navigate(`/${_.kebabCase(kind)}/menu-items`)}>
                <Icon icon="external-link-alt" />
                &nbsp; Menu Items Page
              </NavItem>
            )}
          </Nav>

          <ItemSearchBox<ProductSearchLink>
            searchResults={searchResults}
            searchMenuEntries={searchMenuEntries}
            onSelect={handleSelect}
            onHide={() => null}
            onReset={() => dispatch(loadSearchResults([]))}
          />

          <Grid<PriorityItem>
            config={{
              pagination: false,
              customizerModal: false,
              columns: columnsConfig,
              onSortEnd: onSortEnd,
              onEdit: onEdit,
              onDelete: onDelete
            }}
            fetchFn={fetchFn}
            recordType={`${kind}PriorityItems`}
            list={list}
            listMeta={listMeta}
          />
        </div>
      )}
    </div>
  )
}

export default PriorityItemsPage
