import { useState, useMemo, useRef, useEffect, ReactNode } from 'react'
import { useFormik, FormikProvider, FormikHelpers } from 'formik'
import { useDispatch } from 'react-redux'
import { Tabs, Tab } from 'react-bootstrap'
import { EventKey } from '@restart/ui/types'
import _ from 'lodash'

import { Button } from '~/components/forms'
import ScancodesInputs from './main-tab/scancodes-inputs'
import PhotoGrid from './photo-grid'
import DownloadPhotoGrid from './download-photo-grid'
import ProductDetails from './main-tab/product-details'
import PackageDimensionsAndCustomsInfo, {
  PackageDimensionsAndCustomsInfoRef
} from './main-tab/package-dimensions-and-customs-info'
import ProductSpecifications, { ProductSpecificationsRef } from './main-tab/product-specifications'
import MobileStore from './main-tab/mobile-store'
import InventoryManagementBasic, { InventoryManagementBasicRef } from './main-tab/inventory-management-basic'
import InventoryManagementAdvanced from './inventory-management-advanced'
import Product, { CustomDataOriginal } from '~/types/product'
import { AppDispatch } from '~/config/store'
import BaseErrors from '~/components/forms/formik/base-errors'

import { clearCurrentProduct, closeProductExistsModal } from '~/reducers/products-reducer'
import useSettings from '~/contexts/useSettings'
import useAuth from '~/contexts/useAuth'
import useProductForm, { ProductFormValues } from './use-product-form'
import createProductSchema from './validation'

export interface ProductFormPayload extends Omit<ProductFormValues, 'customData'> {
  customData: CustomDataOriginal
}

export enum ProductFormTabs {
  ProductDetails = 'product-details',
  InventoryManagement = 'inventory-management',
  Photos = 'photos',
  DownloadPhotos = 'download-photos'
}

type ProductFormProps = {
  initialValues: ProductFormValues
  initialTab?: ProductFormTabs
  saveBtnText?: string
  fixFirstMatcher?: boolean
  redirectPath?: string
  customHeading?: (product: ProductFormValues) => ReactNode
  afterSave?: (product: Product, formikHelpers: FormikHelpers<ProductFormValues>) => Promise<void>
  onCancel?: () => void
}

const ProductForm = ({
  initialTab = ProductFormTabs.ProductDetails,
  initialValues,
  fixFirstMatcher,
  redirectPath,
  saveBtnText = 'Save',
  customHeading,
  afterSave,
  onCancel
}: ProductFormProps) => {
  const dispatch = useDispatch<AppDispatch>()

  const { settings } = useSettings()
  const { userProfile } = useAuth()

  const [activeTab, setActiveTab] = useState<EventKey | undefined>(initialTab)

  const productSpecificationsRef = useRef<ProductSpecificationsRef>()
  const packageDimensionsAndCustomsInfoRef = useRef<PackageDimensionsAndCustomsInfoRef>()
  const inventoryManagementBasicRef = useRef<InventoryManagementBasicRef>()

  const { save, cancel } = useProductForm({
    redirectPath,
    productSpecificationsRef,
    packageDimensionsAndCustomsInfoRef,
    inventoryManagementBasicRef
  })

  //const isLoading = shippingProfilesLoading || weightBasedShippingPricingProfilesLoading

  const validationSchema = useMemo(() => createProductSchema({ settings }), [settings])
  const formik = useFormik<ProductFormValues>({
    initialValues,
    onSubmit: async (values: ProductFormValues, formikHelpers: FormikHelpers<ProductFormValues>) => {
      const product = await save(values, formikHelpers)
      await afterSave?.(product, formikHelpers)
    },
    enableReinitialize: true,
    validationSchema
  })

  const { values: formValues, isValid, isSubmitting, submitForm } = formik

  useEffect(() => {
    return () => {
      // Clear redux state related to the product form when unmounting
      dispatch(clearCurrentProduct())
      dispatch(closeProductExistsModal())
    }
  }, [dispatch])

  const isAccountMobileEnabled = (settings?.account || {}).mobileEnabled === true

  return (
    <FormikProvider value={formik}>
      {customHeading && customHeading(formValues)}
      {!customHeading && (formValues.id ? <h2>Edit product</h2> : <h2>Add new product</h2>)}

      <BaseErrors />

      <Tabs
        activeKey={activeTab}
        onSelect={eventKey => (eventKey != null ? setActiveTab(eventKey) : setActiveTab(undefined))}>
        <Tab eventKey={ProductFormTabs.ProductDetails} title="Product Details" className="mt-3">
          <div className="row">
            <div className="col-sm-5 col-md-5 col-lg-4">
              <ProductDetails />
            </div>

            <div className="col-sm-1" />

            <div className="col-sm-6 col-md-6 col-lg-7">
              <PackageDimensionsAndCustomsInfo ref={packageDimensionsAndCustomsInfoRef} />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <InventoryManagementBasic ref={inventoryManagementBasicRef} />
            </div>
          </div>

          {(settings?.productDefaults?.scancodesEnabled || (formValues.productImportMatchers?.length || 0) > 0) && (
            <div className="row scan-codes">
              <div className="col-sm-12 col-md-12 col-lg-10">
                <ScancodesInputs disableFirstMatcher={fixFirstMatcher} />
              </div>
            </div>
          )}

          <ProductSpecifications
            googleProductCategoryRequired={Boolean(formValues.showInGoogleProductsFeed)}
            ref={productSpecificationsRef}
          />

          {isAccountMobileEnabled && userProfile && userProfile.kind !== 'staff_limited' && (
            <MobileStore initialValues={initialValues} />
          )}
        </Tab>

        {formValues.id && (
          <Tab eventKey={ProductFormTabs.InventoryManagement} title="Inventory Management (advanced)" className="mt-3">
            <InventoryManagementAdvanced formValues={formValues} />
          </Tab>
        )}

        {formValues != null && formValues.id && (
          <Tab eventKey={ProductFormTabs.Photos} title="Photos" className="mt-3">
            <PhotoGrid isAccountMobileEnabled={isAccountMobileEnabled} />
          </Tab>
        )}

        {formValues?.id && (
          <Tab eventKey={ProductFormTabs.DownloadPhotos} title="Download Photos" className="mt-3">
            <DownloadPhotoGrid
              images={formValues.images?.filter(img => img?.id > 0) || []}
              productSku={formValues.customSku}
            />
          </Tab>
        )}
      </Tabs>

      {activeTab !== ProductFormTabs.DownloadPhotos && (
        <div className="row mb-3">
          <div className="col-sm-12 col-md-12 col-lg-10">
            <div className="d-flex justify-content-end">
              <Button disabled={isSubmitting || !isValid} onClick={() => submitForm()} isSubmitting={isSubmitting}>
                {saveBtnText}
              </Button>
              &nbsp;&nbsp;
              <Button
                variant="light"
                disabled={isSubmitting}
                onClick={() => {
                  cancel(formValues)
                  onCancel?.()
                }}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
    </FormikProvider>
  )
}

export default ProductForm
