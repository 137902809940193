import styled from 'styled-components'
import { useFormik, FormikProvider } from 'formik'
import { toast } from 'react-toastify'
import { useNavigate, Link } from 'react-router-dom'

import { Button } from '~/components/forms'
import { InputGroup, BaseErrors } from '~/components/forms/formik'
import { forgotPasswordRequest } from '~/api/auth'
import { handleErrorResponse } from '~/lib/form-utils'
import validations from './validations'

const Wrapper = styled.div`
  background-color: #fff;
  border: 1px solid #888;
  padding: 20px 40px;
  margin-top: 40px;
  height: fit-content;
`

const H2 = styled.h1`
  font-weight: 800;
`

const CancelButton = styled(Link)`
  display: block;
  margin-top: 1rem;
`

const InfoDiv = styled.div`
  margin-bottom: 1rem;
`

const ForgotPasswordForm = () => {
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: validations,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        await forgotPasswordRequest(values)
        setSubmitting(false)
        toast.success('Please check your email for your new password')
        navigate('/signin')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setSubmitting(false)
        handleErrorResponse({
          response: error.response,
          setErrors
        })
      }
    }
  })

  const { isSubmitting, handleSubmit } = formik

  return (
    <FormikProvider value={formik}>
      <Wrapper>
        <BaseErrors />

        <H2>Reset Password</H2>

        <InfoDiv>We will send you an email with your new password.</InfoDiv>

        <InputGroup name="email" />

        <Button disabled={isSubmitting} onClick={() => handleSubmit()} isSubmitting={isSubmitting}>
          Reset
        </Button>

        <CancelButton to="/signin">Cancel</CancelButton>
      </Wrapper>
    </FormikProvider>
  )
}

export default ForgotPasswordForm
