const colors = {
  white: '#ffffff',
  black: '#000',

  gray: {
    lightest4: 'hsl(210,10.6%,99%)',
    lightest3: 'hsl(210,10.6%,97%)',
    lightest2: 'hsl(210,10.6%,95%)',
    lightest: 'hsl(210,10.6%,85.8%)',
    lighter: 'hsl(210,10.6%,73.8%)',
    light: 'hsl(210,10.6%,61.8%)',
    base: 'hsl(210,10.6%,51.8%)',
    dark: 'hsl(210,10.6%,40.8%)',
    darker: 'hsl(210,10.6%,28.8%)',
    darkest: 'hsl(210,10.6%,15.8%)'
  },

  purple: {
    lightest4: 'hsl(261.3,50.6%,99%)',
    lightest3: 'hsl(261.3,50.6%,97%)',
    lightest2: 'hsl(261.3,50.6%,95%)',
    lightest: 'hsl(261.3,50.6%,85.8%)',
    lighter: 'hsl(261.3,50.6%,73.8%)',
    light: 'hsl(261.3,50.6%,61.8%)',
    base: 'hsl(261.3,50.6%,50.8%)',
    dark: 'hsl(261.3,50.6%,40.8%)',
    darker: 'hsl(261.3,50.6%,30.8%)',
    darkest: 'hsl(261.3,50.6%,20.8%)'
  },

  blue: {
    lightest4: 'hsl(211.1,100%,99%)',
    lightest3: 'hsl(211.1,100%,97%)',
    lightest2: 'hsl(211.1,100%,95%)',
    lightest: 'hsl(211.1,100%,90%)',
    lighter: 'hsl(211.1,100%,75%)',
    light: 'hsl(211.1,100%,65%)',
    base: 'hsl(211.1,100%,50%)',
    dark: 'hsl(211.1,100%,40%)',
    darker: 'hsl(211.1,100%,30%)',
    darkest: 'hsl(211.1,100%,20%)'
  },

  teal: {
    lightest4: 'hsl(162.2,72.5%,99%)',
    lightest3: 'hsl(162.2,72.5%,97%)',
    lightest2: 'hsl(162.2,72.5%,95%)',
    lightest: 'hsl(162.2,72.5%,85.7%)',
    lighter: 'hsl(162.2,72.5%,70.7%)',
    light: 'hsl(162.2,72.5%,58.7%)',
    base: 'hsl(162.2,72.5%,45.7%)',
    dark: 'hsl(162.2,72.5%,35.7%)',
    darker: 'hsl(162.2,72.5%,25.7%)',
    darkest: 'hsl(162.2,72.5%,15.7%)'
  },

  green: {
    lightest4: 'hsl(133.7,61.4%,99%)',
    lightest3: 'hsl(133.7,61.4%,97%)',
    lightest2: 'hsl(133.7,61.4%,95%)',
    lightest: 'hsl(133.7,61.4%,85.6%)',
    lighter: 'hsl(133.7,61.4%,70.6%)',
    light: 'hsl(133.7,61.4%,55.6%)',
    base: 'hsl(133.7,61.4%,40.6%)',
    dark: 'hsl(133.7,61.4%,31.6%)',
    darker: 'hsl(133.7,61.4%,22.6%)',
    darkest: 'hsl(133.7,61.4%,13.6%)'
  },

  cyan: {
    lightest4: 'hsl(188.2,77.8%,99%)',
    lightest3: 'hsl(188.2,77.8%,98%)',
    lightest2: 'hsl(188.2,77.8%,95.6%)',
    lightest: 'hsl(188.2,77.8%,85.6%)',
    lighter: 'hsl(188.2,77.8%,70.6%)',
    light: 'hsl(188.2,77.8%,55.6%)',
    base: 'hsl(188.2,77.8%,40.6%)',
    dark: 'hsl(188.2,77.8%,31.6%)',
    darker: 'hsl(188.2,77.8%,22.6%)',
    darkest: 'hsl(188.2,77.8%,13.6%)'
  },

  red: {
    lightest4: 'hsl(354.3,70.5%,99%)',
    lightest3: 'hsl(354.3,70.5%,97%)',
    lightest2: 'hsl(354.3,70.5%,95.5%)',
    lightest: 'hsl(354.3,70.5%,90.5%)',
    lighter: 'hsl(354.3,70.5%,78.5%)',
    light: 'hsl(354.3,70.5%,65.5%)',
    base: 'hsl(354.3,70.5%,53.5%)',
    dark: 'hsl(354.3,70.5%,42.5%)',
    darker: 'hsl(354.3,70.5%,30.5%)',
    darkest: 'hsl(354.3,70.5%,18.5%)'
  },

  yellow: {
    lightest4: 'hsl(45,100%,99%)',
    lightest3: 'hsl(45,100%,97.4%)',
    lightest2: 'hsl(45,100%,95.4%)',
    lightest: 'hsl(45,100%,90.4%)',
    lighter: 'hsl(45,100%,75.4%)',
    light: 'hsl(45,100%,62.4%)',
    base: 'hsl(45,100%,51.4%)',
    dark: 'hsl(45,100%,43.4%)',
    darker: 'hsl(45,100%,29.4%)',
    darkest: 'hsl(45,100%,15.4%)'
  },

  get primary() {
    return this.blue
  },

  get success() {
    return this.green
  },

  get info() {
    return this.cyan
  },

  get warning() {
    return this.yellow
  },

  get danger() {
    return this.red
  }
}

export default colors
