import { ReactNode, createContext, useState, useEffect, useMemo } from 'react'

import User, { UserCredentials } from '~/types/user'

import { fetchUserProfileRequest, loginRequest, logoutRequest, JWT, canPersistToken } from '~/api/auth'

interface AuthContextType {
  authPending: boolean
  userProfile: User | null
  isLoggedIn: boolean
  setUserProfile: (userProfile: User | null) => void
  logoutAction: () => Promise<void>
  loginAction: (values: UserCredentials) => Promise<void>
}

const defaultState: AuthContextType = {
  authPending: false,
  userProfile: null,
  isLoggedIn: false,
  setUserProfile: () => null,
  logoutAction: async () => undefined,
  loginAction: async () => undefined
}

const AuthContext = createContext<AuthContextType>(defaultState)

interface AuthProviderProps {
  children: ReactNode
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [authPending, setAuthPending] = useState<boolean>(true)
  const [userProfile, setUserProfile] = useState<User | null>(null)

  useEffect(() => {
    refreshAuth()
  }, [])

  const refreshAuth = () => {
    if (!canPersistToken || JWT.token) {
      setAuthPending(true)
      fetchUserProfileRequest()
        .then(userProfile => {
          setUserProfile(userProfile)
          setAuthPending(false)
        })
        .catch(error => {
          setAuthPending(false)
          console.log('ERROR', error)
        })
    }
  }

  const isLoggedIn = useMemo(() => userProfile !== null, [userProfile])

  const loginAction = async (values: UserCredentials) => {
    return loginRequest(values).then(setUserProfile)
  }

  const logoutAction = async () => {
    return logoutRequest().then(() => {
      setUserProfile(null)
    })
  }

  const contextValue: AuthContextType = {
    authPending,
    userProfile,
    isLoggedIn,
    setUserProfile,
    logoutAction,
    loginAction
  }

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

export { AuthProvider }
export default AuthContext
