import { useState } from 'react'
import styled from 'styled-components'
import { OptionsOrGroups, GroupBase } from 'react-select'
import { useFormik, FormikProvider, FormikHelpers } from 'formik'

import { Input, Fieldset } from '~/components/form-inputs/formik/react-bootstrap'
import getValidationSchema from './validations'
import PackageContents from './package-contents'
import ShipmentPrices from './shipments-prices'
import { Button as Button_ } from '../../components/forms'
import AuPostShipment from '~/types/au-post-shipment'
import { Option } from '~/components/form-inputs/formik/react-bootstrap/select-input'
import FormActions from './form-actions'

const Flex = styled.div`
  display: flex;
  justify-content: space-around;
  & > * {
    margin-right: 40px;
  }
`
const Column = styled.div`
  width: 500px;
`

interface SpacerProps {
  mb: string
}
const Spacer = styled.div<SpacerProps>`
  height: 0 !important;
  margin-bottom: ${props => props.mb};
`
const Button = styled(Button_)`
  outline: none;
`

const getCubicWeight = (values: {
  packageLength?: number | string | null
  packageWidth?: number | string | null
  packageHeight?: number | string | null
}) => {
  let result: number | null = null
  const length = values?.packageLength ? parseInt(values.packageLength.toString(), 10) : 0
  const width = values?.packageWidth ? parseInt(values.packageWidth.toString(), 10) : 0
  const height = values?.packageHeight ? parseInt(values.packageHeight.toString(), 10) : 0
  if (length && width && height) {
    result = Math.round(length * width * height * 0.00025 * 100) / 100
  }
  return result
}

const getShippingServiceOptions = (model: AuPostShipment) => {
  const opts = model.postageProducts.map(p => ({
    label: p.type,
    value: p.productId
  }))
  return [
    {
      label: '--',
      value: ''
    }
  ].concat(opts) as OptionsOrGroups<Option, GroupBase<Option>>
}

interface AuPostShipmentFormProps {
  model: AuPostShipment
  redirectPath: string
  onClickSubmit: (values: AuPostShipment, formikBag: FormikHelpers<AuPostShipment>) => void
  onClickBack: () => void
}

const AuPostShipmentForm = ({ model, onClickSubmit, onClickBack, redirectPath }: AuPostShipmentFormProps) => {
  const initialValues = {
    ...model
  } as AuPostShipment
  if (initialValues.intlToCountry === null) {
    delete initialValues.intlToCountry
  }
  if (model.toLines.length === 1) {
    initialValues.toLines.push('', '')
  }
  if (model.toLines.length === 2) {
    initialValues.toLines.push('')
  }

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(model),
    onSubmit: (values, formikBag) => {
      return onClickSubmit(values, formikBag)
    }
  })

  const { values } = formik

  const [showShipmentsPrices, setShowShipmentsPrices] = useState(false)

  return (
    <FormikProvider value={formik}>
      <Flex>
        <Column>
          <h4>Shipping Address</h4>
          <Fieldset>
            <Input hl name="toName" label="Name" />
            <Input hl name="toLines[0]" label="Address Line 1" />
            <Input hl name="toLines[1]" label="Address Line 2" />
            <Input hl name="toLines[2]" label="Address Line 3" />
            <Input hl name="toSuburb" label="Suburb" />
            <Input hl name="toState" label="State" />
            <Input hl name="toPostcode" label="Postcode" />
            <Input hl name="toPhone" label="Phone" />
            <Input hl name="toEmail" label="Email" />
            {model.isIntl === true && <Input hl readOnly name="intlToCountry" label="Country" />}
          </Fieldset>
        </Column>

        <Column>
          <h4>Shipment References</h4>
          <Fieldset>
            <Input hl readOnly={model.isCombined} name="senderReferences[0]" label="Sender Reference 1" />
            <Input hl name="senderReferences[1]" label=" Sender Reference 2" />
          </Fieldset>
          <Spacer mb="20px" />

          <h4>Shipping Service</h4>
          <Fieldset>
            <Input hl readOnly name="orderShippingService" label="Buyer requested" />
            <Input
              hl
              name="packageProductId"
              as="select"
              label="Shipping service"
              options={getShippingServiceOptions(model)}
            />
            <Button variant="link" onClick={() => setShowShipmentsPrices(true)}>
              Get Shipment Prices
            </Button>
          </Fieldset>
          <ShipmentPrices
            show={showShipmentsPrices}
            onHide={() => setShowShipmentsPrices(false)}
            name="packageProductId"
            model={model}
          />
          <Spacer mb="40px" />

          <h4>Package Details</h4>
          <Fieldset>
            <Input hl name="packageLength" label="Length" />
            <Input hl name="packageWidth" label="Width" />
            <Input hl name="packageHeight" label="Height" />
            <Input hl name="packageWeight" label="Weight" />
            <Input hl readOnly name="cubicWeight" label="Cubic Weight" value={getCubicWeight(values)} />
          </Fieldset>
        </Column>
      </Flex>

      <PackageContents model={model} />

      <FormActions onClickBack={onClickBack} redirectPath={redirectPath} />
    </FormikProvider>
  )
}

export default AuPostShipmentForm
