import { Navigate, Outlet } from 'react-router-dom'

import useAuth from '~/contexts/useAuth'

interface PrivateRouteProps {
  redirectPath?: string
}
const PrivateRoute = ({ redirectPath = '/signin' }: PrivateRouteProps) => {
  const { isLoggedIn, authPending } = useAuth()

  // If auth is pending, don't render anything (or a loading spinner)
  if (authPending) {
    return null // or a loading spinner
  }

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return isLoggedIn ? <Outlet /> : <Navigate to={redirectPath} />
}

export default PrivateRoute
