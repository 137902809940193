import { ReactNode } from 'react'
import classnames from 'classnames'
import styled from 'styled-components'

import { Button as Button_ } from '~/components/forms'
import { CustomerDialogType } from './form'

const Button = styled(Button_)`
  outline: none;
  text-wrap: nowrap;
  text-decoration: none;
`
const Wrapper = styled.div`
  margin-left: -20px;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
  padding: 0 10px 10px 10px;
  padding-bottom: 10px;
  height: auto;
`
const Error = styled.div`
  display: block;
  padding-left: 20px;
`

interface CustomerLinksProps {
  toggleDialog: (type: CustomerDialogType, value: boolean) => void
  error: string | ReactNode
}

const CustomerLinks = ({ toggleDialog, error }: CustomerLinksProps) => {
  const className = classnames({
    'has-error': Boolean(error)
  })

  return (
    <Wrapper className={className}>
      <ButtonWrapper>
        <Button variant="link" onClick={() => toggleDialog('SelectCustomer', true)}>
          Select Customer
        </Button>
        or
        <Button variant="link" onClick={() => toggleDialog('CustomerForm', true)}>
          New Customer
        </Button>
      </ButtonWrapper>
      {error && <Error className="text-danger">{error}</Error>}
    </Wrapper>
  )
}

export default CustomerLinks
