import { Fieldset } from '~/components/forms'
import { FlexibleInputGroup } from '~/components/forms/formik'

const StripeFields = () => (
  <Fieldset>
    <h3>Stripe Payments</h3>
    <h4 className="mb-4">Orders sent through Mau or Shopfront use Stripe for payment processing</h4>

    <FlexibleInputGroup name="stripeApiPublishableKey" label="Publishable Key" />
    <FlexibleInputGroup name="stripeApiSecretKey" label="Secret Key" />
    <p>For security reasons we do not show the current secret key, but you can update it if required.</p>
  </Fieldset>
)

export default StripeFields
