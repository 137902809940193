import { useEffect, useState, useCallback } from 'react'
import { useFormik, FormikProvider, Field, FormikProps } from 'formik'
import _ from 'lodash'
import axios from 'axios'

import useCountryCodes from '~/contexts/useCountryCodes'
import Spinner from '../../components/spinner'
import { Button } from '~/components/forms'
import { BsSelectInput, BsInput, ReactSelectInputCreatable } from '~/components/form-inputs/formik/inputs-decorated'
import BaseErrors from '~/components/forms/formik/base-errors'

import createCustomerSchema from './validation'

import useCustomerForm, { CustomerFormValues } from './use-customer-form'

const dropdownEmptyOption = { value: '', label: '' }

export type CustomerFormProps = {
  initialValues: CustomerFormValues
  validationSchema: ReturnType<typeof createCustomerSchema>
  onCancel?: () => void
  beforeSave?: (values: CustomerFormValues) => Promise<boolean> // return false if the save should not go ahead
  afterSave?: (values: CustomerFormValues) => Promise<void>
  additionalContent?: (formikBag: FormikProps<CustomerFormValues>) => React.ReactNode
}

const CustomerForm = ({
  beforeSave,
  afterSave,
  initialValues,
  validationSchema,
  onCancel,
  additionalContent
}: CustomerFormProps) => {
  const { countryCodes } = useCountryCodes()
  const [countryOptions, setCountryOptions] = useState([dropdownEmptyOption])
  const [groupsOptions, setGroupsOptions] = useState<{ label: string; value: string }[]>([])
  const [groupsLoading, setGroupsLoading] = useState(false)

  const isEditing = !!initialValues?.id

  const setCountryOptionsFromProps = useCallback(() => {
    let countries = _.map(countryCodes, entry => ({
      label: entry.countryName,
      value: entry.countryCode
    }))

    countries = _.sortBy(countries, 'label')
    countries.unshift(dropdownEmptyOption, {
      value: 'AU',
      label: 'AUSTRALIA'
    })

    setCountryOptions(countries)
  }, [countryCodes])

  const loadGroupsOptions = useCallback(async () => {
    setGroupsLoading(true)
    try {
      const response = await axios.get('/api/customers/groups')
      const options = _.map(response.data, entry => ({
        label: entry,
        value: entry
      }))

      setGroupsOptions(options)
    } finally {
      setGroupsLoading(false)
    }
  }, [])

  useEffect(() => {
    loadGroupsOptions()
    setCountryOptionsFromProps()
  }, [loadGroupsOptions, countryCodes, setCountryOptionsFromProps])

  const { loading, save } = useCustomerForm({
    beforeSave,
    afterSave
  })

  const formik = useFormik<CustomerFormValues>({
    initialValues,
    onSubmit: save,
    enableReinitialize: true,
    validationSchema
  })

  const { isValid, isSubmitting, submitForm } = formik

  return loading ? (
    <Spinner />
  ) : (
    <FormikProvider value={formik}>
      <div className="form form-horizontal">
        <legend>{isEditing ? 'Edit Customer' : 'New Customer'}</legend>

        <BaseErrors />

        <div className="row">
          <div className="col-sm-7 col-md-7 col-lg-6">
            <Field name="name" component={BsInput} />
            <Field name="address1" component={BsInput} />
            <Field name="address2" component={BsInput} />
            <Field name="address3" component={BsInput} />
            <Field name="suburb" component={BsInput} />
            <Field name="state" component={BsInput} />
            <Field name="postcode" component={BsInput} />
            <Field name="country" component={BsSelectInput} options={countryOptions} />
          </div>

          <div className="col-sm-5 col-md-5 col-lg-6">
            <Field name="email" component={BsInput} />
            <Field name="guestEmail" component={BsInput} />
            <Field name="phone" component={BsInput} />
            <Field
              isMulti
              name="groups"
              component={ReactSelectInputCreatable}
              isLoading={groupsLoading}
              options={groupsOptions}
            />

            {additionalContent?.(formik)}
          </div>
        </div>

        <div className="mt-4 d-flex justify-content-end">
          <Button
            variant="primary"
            onClick={submitForm}
            disabled={isSubmitting || !isValid}
            isSubmitting={isSubmitting}>
            Save
          </Button>
          &nbsp;&nbsp;
          <Button variant="light" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </FormikProvider>
  )
}

export default CustomerForm
