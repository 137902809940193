import styled from 'styled-components'

import { Button } from '~/components/forms'
import Icon from '~/components/icon'
import { FlexibleInputGroup } from '~/components/forms/formik'
import ProductSearchLink from '~/types/product-search-link'

const Heading = styled.div`
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  h5 {
    margin-bottom: 0;
  }
`

const Box = styled.div`
  @media (max-width: 768px) {
    margin-top: 20px;
  }

  @media (min-width: 769px) {
    h4 {
      margin-top: 8px;
      margin-bottom: 0;
    }
  }

  margin-bottom: 20px;

  button.add {
    margin-top: 5px;
    margin-left: 10px;
  }

  fieldset.right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: space-between;
  }
`

const FieldRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: space-between;
  margin-bottom: 5px;

  div {
    flex-grow: 1;

    & + div {
      margin-left: 20px;
    }
  }

  button {
    height: 34px;
    margin-left: 10px;
  }
`

// Supports below format for alternate terms
//
// [
//   { key: 'some key', value: 'some value' },
//   { key: 'some key', value: 'some value' },
// ]
interface AlternateTermsSectionProps {
  onAdd: () => void
  onRemove: (index: number) => void
  alternateTerms?: ProductSearchLink['alternateTerms']
}
const AlternateTermsSection = ({ alternateTerms = [], onAdd, onRemove }: AlternateTermsSectionProps) => {
  return (
    <Box>
      <fieldset className="right">
        <Heading>
          <h5>
            Alternate Search Terms <small>(will appear in mobile app)</small>
          </h5>
          <Button variant="light" className="add" onClick={onAdd}>
            <Icon icon="plus" />
          </Button>
        </Heading>

        {alternateTerms?.map((_field, index) => (
          <FieldRow key={index}>
            <FlexibleInputGroup
              name={`alternateTerms[${index}].key`}
              placeholder={'Enter a key, i.e. "Size"'}
              label={false}
            />
            <FlexibleInputGroup
              name={`alternateTerms[${index}].value`}
              placeholder={'Enter a value, i.e. "Small"'}
              label={false}
            />
            <Button variant="light" onClick={() => onRemove(index)}>
              <Icon icon="trash" className="text-danger" />
            </Button>
          </FieldRow>
        ))}
      </fieldset>

      {(alternateTerms?.length || 0) > 0 && (
        <fieldset>
          <FlexibleInputGroup
            name="alternateTermDisplay"
            label="Wrap Display (ideal for short options S,M,L,XL)"
            type="checkbox"
          />
        </fieldset>
      )}
    </Box>
  )
}

export default AlternateTermsSection
