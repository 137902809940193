import styled from 'styled-components'
import { Field, FormikProvider, useFormik } from 'formik'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'

import { Button } from '~/components/forms'
import { InputGroup, BaseErrors } from '~/components/forms/formik'
import { resetPasswordRequest } from '~/api/auth'
import { handleErrorResponse } from '~/lib/form-utils'
import validations from './validations'

const Wrapper = styled.div`
  background-color: #fff;
  border: 1px solid #888;
  padding: 20px 40px;
  margin-top: 40px;
  height: fit-content;
`

const H2 = styled.h1`
  font-weight: 800;
`

const CancelButton = styled(Link)`
  display: block;
  margin-top: 1rem;
`

const InfoDiv = styled.div`
  margin-bottom: 1rem;
`

interface ResetPasswordFormProps {
  token: string
}

const ResetPasswordForm = ({ token }: ResetPasswordFormProps) => {
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      token,
      password: '',
      passwordConfirmation: ''
    },
    validationSchema: validations,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      const onSuccess = () => {
        setSubmitting(false)
        toast.success('Successfully reset your password. Please login')
        navigate('/signin')
      }

      const onError = (error: AxiosError) => {
        setSubmitting(false)

        handleErrorResponse({
          response: error.response,
          setErrors
        })
      }

      resetPasswordRequest(values).then(onSuccess).catch(onError)
    }
  })

  const { isSubmitting, isValid, handleSubmit } = formik

  return (
    <FormikProvider value={formik}>
      <Wrapper>
        <BaseErrors />

        <H2>Reset Password</H2>

        <InfoDiv>Enter new password</InfoDiv>

        <Field name="token" type="hidden" />
        <InputGroup name="password" type="password" />
        <InputGroup name="passwordConfirmation" type="password" />

        <Button block disabled={isSubmitting || !isValid} onClick={() => handleSubmit()} isSubmitting={isSubmitting}>
          Confirm
        </Button>

        <CancelButton to="/signin">Cancel</CancelButton>
      </Wrapper>
    </FormikProvider>
  )
}

export default ResetPasswordForm
