import { ChangeEvent, useState, useCallback } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import { Button } from '~/components/forms'
import { AppDispatch } from '~/config/store'
import CsvImport from '~/types/csv-import'

const LeadText = styled.div`
  margin-top: 0;
`

const Wrapper = styled.div`
  border: 1px solid #ccc;
  padding: 1rem;
  margin-bottom: 20px;
`

const apiUpload = (file: File, resourcePath: string, paramName: string) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }

  const formData = new FormData()
  formData.set(`${paramName}[csv_file]`, file)

  return axios.post(`/api/${resourcePath}`, formData, config)
}

interface ImportFormProps<T = CsvImport> {
  title: string
  resourcePath: string
  paramName: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setLastCsvImport: (csvImport: T | undefined) => any // TODO: should use ReturnType of 'thunk'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFileUploading: (isUploading: boolean) => any // TODO: should use ReturnType of 'thunk'
  className?: string
}

const ImportForm = <T = CsvImport,>({
  title,
  resourcePath,
  paramName,
  setLastCsvImport,
  setFileUploading,
  className
}: ImportFormProps<T>) => {
  const [file, setFile] = useState<File | null>(null)
  const dispatch = useDispatch<AppDispatch>()

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0]
    if (selectedFile) {
      setFile(selectedFile)
    }
  }

  const startUpload = useCallback(() => {
    if (!file) {
      throw new Error('File is empty')
    }

    dispatch(setFileUploading(true))
    apiUpload(file, resourcePath, paramName).then(
      response => {
        dispatch(setFileUploading(false))
        dispatch(setLastCsvImport(response.data))
      },
      e => {
        console.log('[INFO]', e)
        dispatch(setFileUploading(false))
      }
    )
  }, [dispatch, file, paramName, resourcePath, setFileUploading, setLastCsvImport])

  return (
    <Wrapper className={className}>
      <LeadText className="lead">{title}</LeadText>

      <div className="row mb-3">
        <input type="file" onChange={handleFileInputChange} />
      </div>

      <br />

      <Button disabled={!file} onClick={startUpload}>
        Start
      </Button>
    </Wrapper>
  )
}

export default ImportForm
