import { UnmatchedLineItem } from '~/types/ebay-sales-record'

interface IncompleteRecordsTableProps {
  list: UnmatchedLineItem[]
  onRecordClick: (record: UnmatchedLineItem) => void
}

const IncompleteRecordsTable = ({ list, onRecordClick }: IncompleteRecordsTableProps) => {
  const getHeading = () => {
    const listLength = list.length
    let heading = ''

    if (listLength === 1) {
      heading = `1 sales record couldn't be matched to any products.`
    } else if (listLength > 1) {
      heading = `${listLength} sales records couldn't be matched to products.`
    }
    return heading
  }

  return (
    <div>
      <p className="fw-bold">{getHeading()}</p>
      <p>Click on an item to add it as a product to the database</p>

      <div className="table-wrapper">
        <table className="header table table-bordered table-striped table-hover" style={{ marginBottom: '0' }}>
          <thead>
            <tr>
              <th>Item Description / Scancode</th>
              <th>Scancode Source</th>
            </tr>
          </thead>
          <tbody>
            {list.map(record => (
              <tr key={record.id} style={{ cursor: 'pointer' }} onClick={() => onRecordClick(record)}>
                <td>{record.importedScancode}</td>
                <td>{record.importedSource}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default IncompleteRecordsTable
