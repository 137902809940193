import { useImperativeHandle, forwardRef, useCallback, useState, useEffect } from 'react'
import { Field } from 'formik'
import _ from 'lodash'

import { Button } from '~/components/forms'
import { BsInput, ReactSelectInputCreatable, BsSelectInput } from '~/components/form-inputs/formik/inputs-decorated'
import GoogleProductCategoryInput from '../google-product-category-input'
import standingDataService from '~/services/standing-data-service'

interface DropdownOption {
  label: string
  value: string
}

const conditionOptions: DropdownOption[] = [
  { value: '', label: 'Please select' },
  { value: 'new_with_tags', label: 'New (with tags)' },
  { value: 'new_without_tags', label: 'New (without tags)' },
  { value: 'new_with_defects', label: 'New (with defects)' },
  { value: 'used', label: 'Used' }
]

const genderOptions: DropdownOption[] = [
  { value: '', label: 'Please select' },
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'unisex', label: 'Unisex' }
]

const ageGroupOptions: DropdownOption[] = [
  { value: '', label: 'Please select' },
  { value: 'newborn', label: 'Newborn' },
  { value: 'infant', label: 'Infant' },
  { value: 'toddler', label: 'Toddler' },
  { value: 'kids', label: 'Kids' },
  { value: 'adult', label: 'Adult' }
]

export interface ProductSpecificationsRef {
  addBrands: (brands: DropdownOption[]) => void
  addColors: (colors: DropdownOption[]) => void
  addVariantGroups: (variantGroups: DropdownOption[]) => void
}

interface ProductSpecificationsProps {
  googleProductCategoryRequired?: boolean
}

const ProductSpecifications = forwardRef(
  ({ googleProductCategoryRequired = false }: ProductSpecificationsProps, ref) => {
    const [brandOptionsLoaded, setBrandOptionsLoaded] = useState(false)
    const [colorOptionsLoaded, setColorOptionsLoaded] = useState(false)
    const [variantGroupOptionsLoaded, setVariantGroupOptionsLoaded] = useState(false)
    const [showMore, setShowMore] = useState(false)
    const [brandOptions, setBrandOptions] = useState<DropdownOption[]>([])
    const [colorOptions, setColorOptions] = useState<DropdownOption[]>([])
    const [variantGroupOptions, setVariantGroupOptions] = useState<DropdownOption[]>([])

    useImperativeHandle(ref, () => ({
      addBrands: (brands: DropdownOption[]) => {
        setBrandOptions(prevBrands => _.uniqBy([...prevBrands, ...brands], 'value'))
        standingDataService.addBrandOptions(brands)
      },
      addColors: (colors: DropdownOption[]) => {
        setColorOptions(prevColors => _.uniqBy([...prevColors, ...colors], 'value'))
        standingDataService.addColorOptions(colors)
      },
      addVariantGroups: (variantGroups: DropdownOption[]) => {
        setVariantGroupOptions(prevVariantGroups => _.uniqBy([...prevVariantGroups, ...variantGroups], 'value'))
        standingDataService.addVariantGroupOptions(variantGroups)
      }
    }))

    const fetchBrandOptions = useCallback(async () => {
      if (brandOptionsLoaded) return
      const result = await standingDataService.fetchBrandOptions()
      setBrandOptions(result)
      setBrandOptionsLoaded(true)
    }, [brandOptionsLoaded])

    const fetchColorOptions = useCallback(async () => {
      if (colorOptionsLoaded) return
      const result = await standingDataService.fetchColorOptions()
      setColorOptions(result)
      setColorOptionsLoaded(true)
    }, [colorOptionsLoaded])

    const fetchVariantGroupOptions = useCallback(async () => {
      if (variantGroupOptionsLoaded) return
      const result = await standingDataService.fetchVariantGroupOptions()
      setVariantGroupOptions(result)
      setVariantGroupOptionsLoaded(true)
    }, [variantGroupOptionsLoaded])

    const toggleGoogleFields = () => {
      setShowMore(!showMore)
    }

    useEffect(() => {
      fetchBrandOptions()
      fetchColorOptions()
      fetchVariantGroupOptions()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <div className="row">
        <div className="col-sm-12">
          <fieldset>
            <legend>
              <span className="title">Product Specifications</span>
            </legend>

            <div className="row">
              {/* client prefers 2 columns for product specifications */}
              <div className="col-sm-6">
                <Field
                  required
                  name="condition"
                  label="Condition"
                  component={BsSelectInput}
                  options={conditionOptions}
                />
                <Field name="brand" label="Brand" component={ReactSelectInputCreatable} options={brandOptions} />
                <Field name="model" label="Model" component={BsInput} />

                {!showMore && (
                  <Button variant="link" onClick={toggleGoogleFields}>
                    Show More
                  </Button>
                )}

                {showMore && (
                  <>
                    <Field name="color" label="Color" component={ReactSelectInputCreatable} options={colorOptions} />
                    {/* NOTE: cannot pass 'require' prop here unfortunately, for some reason creates infinite loop :-( */}
                    <Field name="gender" label="Gender" component={BsSelectInput} options={genderOptions} />
                    <Field name="ageGroup" label="Age Group" component={BsSelectInput} options={ageGroupOptions} />
                  </>
                )}
              </div>

              <div className="col-sm-6">
                <Field name="sku" label="Manufacturers SKU" component={BsInput} />
                <Field name="barcode" label="Barcode / UPC" component={BsInput} />
                <Field name="rrp" label="RRP" component={BsInput} />
                {showMore && (
                  <>
                    <Field name="googleShoppingName" label="Google Shopping Name" component={BsInput} />
                    <Field name="gtin" label="GTIN" component={BsInput} />
                    <Field
                      name="variantGroup"
                      label="Variant Group"
                      component={ReactSelectInputCreatable}
                      options={variantGroupOptions}
                    />
                  </>
                )}
              </div>
            </div>

            {showMore && (
              <div className="row">
                <div className="col-sm-12">
                  <Field
                    required={googleProductCategoryRequired}
                    name="googleProductCategory"
                    placeholder="Type something to search"
                    format={
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (value: any) => ({ value: value || '', label: value })
                    }
                    component={GoogleProductCategoryInput}
                  />
                </div>
              </div>
            )}
          </fieldset>
        </div>
      </div>
    )
  }
)

ProductSpecifications.displayName = 'ProductSpecifications'

export default ProductSpecifications
