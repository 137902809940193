import { ChangeEvent, useState, useEffect, useRef, useCallback } from 'react'
import { AxiosError } from 'axios'
import { AsyncThunk } from '@reduxjs/toolkit'
import { useDebouncedCallback } from 'use-debounce'
import _ from 'lodash'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import Spinner from '../../components/spinner'
import { AppState, AppDispatch } from '~/config/store'

const Wrapper = styled.div`
  margin-bottom: 20px;
`

type ItemWithId = {
  id: number
  searchTerm?: string
  mobileName?: string
  name?: string
}

interface QueryParams {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

type ItemSearchBoxProps<Item extends ItemWithId, RV> = {
  selectedItem?: Item
  searchMenuEntries: AsyncThunk<Item[], QueryParams, { rejectValue: RV }>
  searchResults: Item[]
  onSelect: (item: Item) => void
  onHide: () => void
  onReset?: () => void
  className?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ItemSearchBox = <Item extends ItemWithId = any, RV = AxiosError>({
  selectedItem,
  searchMenuEntries,
  searchResults,
  onSelect,
  onHide,
  onReset,
  className
}: ItemSearchBoxProps<Item, RV>) => {
  const [searchText, setSearchText] = useState<string>('')
  const [selectedItemId, setSelectedItemId] = useState<number>()
  const [showSelectBox, setShowSelectBox] = useState<boolean>(false)
  const [options, setOptions] = useState<Item[]>([])
  const itemSelectInstance = useRef<HTMLSelectElement>(null)
  const loading = useSelector((state: AppState) => state.priorityItems.loading.searchMenuEntries)

  const dispatch = useDispatch<AppDispatch>()

  const findItem = useDebouncedCallback(
    useCallback(() => {
      if (searchText) {
        dispatch(searchMenuEntries({ q: searchText }))
      } else {
        onReset?.()
      }
    }, [dispatch, searchMenuEntries, searchText, onReset]),
    1000,
    { trailing: true }
  )

  useEffect(() => {
    const shouldShowSelectBox = searchResults?.length > 0
    setShowSelectBox(shouldShowSelectBox)
    setOptions(searchResults ?? [])

    if (itemSelectInstance.current) {
      itemSelectInstance.current.size = searchResults?.length > 16 ? 16 : searchResults?.length + 1
    }
  }, [searchResults])

  useEffect(() => {
    setSelectedItemId(selectedItem?.id)
    setSearchText('')
  }, [selectedItem])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const q = e.target.value
    setSearchText(q)

    if (_.trim(q)) {
      findItem()
    } else {
      setShowSelectBox(false)
      onReset?.()
    }
  }

  const handleItemSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const itemId = parseInt(e.target.value, 10)

    setSelectedItemId(itemId)
    const item = _.find(searchResults, item => item.id === itemId)
    if (item != null) {
      onSelect(item)
    }
    onHide()
    resetInput()
  }

  const itemName = () => {
    const { name } = selectedItem || {}
    return name || ''
  }

  const resetInput = () => {
    setSearchText('')
    setSelectedItemId(undefined)
    setShowSelectBox(false)
    onReset?.()
  }

  return (
    <Wrapper className={className}>
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control item-search-input"
          placeholder="Type to add an item by name"
          value={searchText || itemName()}
          onChange={handleInputChange}
        />
        {loading && (
          <span className="input-group-text" id="basic-addon1">
            <Spinner label="" size={0.3} />
          </span>
        )}
      </div>
      <select
        ref={itemSelectInstance}
        className="form-select item-search-results-select"
        value={(selectedItem || {}).id || selectedItemId}
        style={{ display: showSelectBox ? 'block' : 'none' }}
        onChange={handleItemSelectChange}>
        <option value="">Please select</option>
        {options.map(c => (
          <option key={c.id} value={c.id}>
            [{c.searchTerm === undefined ? 'Product' : 'Search Link'}] {c.mobileName || c.name}
          </option>
        ))}
      </select>
    </Wrapper>
  )
}

export default ItemSearchBox
