import { object, number, string, array } from 'yup'
import '~/lib/yup'

import { isDomestic } from './utils'

const LineItemSchema = object().shape({
  quantity: number().required(),
  unitPrice: number().required()
})

export const validationSchema = object({
  customerId: number().required(),
  shippingService: string().required(),
  customerEmail: string().required(),
  shippingContactName: string().required(),
  shippingAddress1: string().required(),
  shippingCity: string().required(),
  shippingCountry: string().required(),
  shippingPostcode: string().required(),
  shippingState: string()
    .nullable()
    .test('is-state-required', 'is required', function (value) {
      if (!value && isDomestic(this.from?.[0].value?.shippingCountry)) return false
      else return true
    }),
  lineItems: array().of(LineItemSchema)
})

export default validationSchema
