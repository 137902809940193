import { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { default as qs } from 'qs'

import CustomerForm from './form'
import createCustomerSchema from './validation'
import { AppState, AppDispatch } from '~/config/store'
import Spinner from '../../components/spinner'
import useCustomerForm from './use-customer-form'
import { setCustomerToEdit } from '~/reducers/customers-reducer'
const redirectPath = '/customers'

interface RouteParams {
  id?: string
}

const CustomerFormPage = () => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const recordToEdit = useSelector((state: AppState) => state.customersPage.recordToEdit)
  const listMeta = useSelector((state: AppState) => state.customersPage.listMeta)
  const { id } = useParams<keyof RouteParams>()
  const customerId = id ? parseInt(id, 10) : undefined

  const { loading, fetch } = useCustomerForm()

  const redirectToSamePageWithFilters = useCallback(() => {
    const page = listMeta?.currentPage || 1
    navigate(`${redirectPath}?${qs.stringify({ page })}`)
  }, [navigate, listMeta?.currentPage])

  useEffect(() => {
    if (customerId) {
      fetch(customerId)
    }

    // Clean up redux sate when unmounting
    return () => {
      dispatch(setCustomerToEdit(undefined))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId])

  const cancel = () => {
    redirectToSamePageWithFilters()
  }

  const afterSave = async () => {
    redirectToSamePageWithFilters()
  }

  const validationSchema = createCustomerSchema({ validateEmail: false, validateAddress: false })

  if (loading) {
    return <Spinner />
  }

  if (customerId && !recordToEdit) {
    return null
  }

  return (
    <CustomerForm
      initialValues={recordToEdit || {}}
      validationSchema={validationSchema}
      onCancel={cancel}
      afterSave={afterSave}
    />
  )
}
export default CustomerFormPage
