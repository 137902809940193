import styled from 'styled-components'
import { useState, useCallback, ChangeEvent } from 'react'
import _ from 'lodash'
import { useDebouncedCallback } from 'use-debounce'

const Label = styled.label`
  margin-right: 10px;
  white-space: nowrap;
`

const Wrapper = styled.div`
  width: 800px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 100px;
  padding: 20px;
  background-color: #f1f4f7;
`

interface FiltersProps {
  className?: string
  query: {
    loginStatus: string
    search: string
  }
  onChange: (query: { loginStatus?: string; search?: string }) => void
}
const Filters = ({ className, query, onChange }: FiltersProps) => {
  const [inputValue, setInputValue] = useState(query.search || '')

  const formatSearch = useCallback((value: string) => _.trimStart(value).replace(/\s+/g, ' '), [])
  const performSearch = useDebouncedCallback((value: string) => onChange({ search: formatSearch(value) }), 500)

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setInputValue(value) // Immediate UI feedback
    performSearch(value) // Debounced processing
  }

  return (
    <Wrapper className={className}>
      <div className="d-flex align-items-center">
        <Label>Login Status</Label>
        <select
          className="form-select"
          value={query.loginStatus}
          onChange={e => onChange({ loginStatus: e.target.value })}>
          <option value="">All</option>
          <option value="invited">Invited</option>
          <option value="accepted">Accepted</option>
        </select>
      </div>

      <div>
        <input
          className="form-control"
          type="text"
          value={inputValue}
          placeholder="search customers by name, address, email etc"
          onChange={handleInputChange}
        />
      </div>
    </Wrapper>
  )
}

export default Filters
