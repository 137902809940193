import { ComponentType } from 'react'

import PageErrorBoundary from '~/components/page-error-boundary'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ComponentTypeProps = any

const createPageHoc = function (Component: ComponentType<ComponentTypeProps>) {
  const WrappedComponent = (props: ComponentTypeProps) => (
    <PageErrorBoundary>
      <Component {...props} />
    </PageErrorBoundary>
  )

  return WrappedComponent
}

export default createPageHoc
