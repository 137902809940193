import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

import User from '~/types/user'

export interface UsersState {
  pending: boolean
  items: User[]
}

const initialState: UsersState = {
  pending: true,
  items: []
}

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    loadItems: (state, action: PayloadAction<User[]>) => {
      state.pending = false
      state.items = action.payload
    },
    addOrUpdateItem: (state, action: PayloadAction<User>) => {
      state.items = addToOrUpdateList(state.items, action.payload)
    },
    deleteItem: (state, action: PayloadAction<User>) => {
      state.items = removeFromList(state.items, action.payload)
    }
  }
})

export const { loadItems, addOrUpdateItem, deleteItem } = usersSlice.actions

export default usersSlice.reducer

const addToOrUpdateList = (items: User[], newItem: User) => {
  const newList = _.clone(items)
  const index = _.findIndex(newList, ['id', newItem.id])
  if (index === -1) {
    newList.push(newItem)
  } else {
    newList.splice(index, 1, newItem)
  }
  return newList
}

const removeFromList = (items: User[], itemToDelete: User) => _.filter(items, i => i.id !== itemToDelete.id)
