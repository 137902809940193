export enum ProductInventoryStatusEnum {
  ON_ORDER = 'on_order',
  RECEIVED = 'received'
}

export interface ProductInventorySummary {
  quantityAvailable: number
  onlineQuantityAvailable: number
  otherQuantityAvailable: number
  quantitySoldUnprocessed: number
  stockOnHandValue: number | null
  totalSales: number | null
}

export interface ExistsInAccounts {
  subdomain: string
}

interface ProductInventory {
  id?: number
  invoiceNo?: string | null
  purchaseDate?: string | null
  quantityIn: number | null
  costPricePerUnit: number | null
  quantitySold: number
  quantitySoldMobile: number
  quantitySoldOther: number
  supplierName?: string | null
  status: ProductInventoryStatusEnum
  isPaid: boolean
  deliveryDate?: string | null
  onlineQuantity: number
}

export default ProductInventory
