import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

import ShippingPricingProfile from '~/types/shipping-pricing-profile'

import { loadItems, addOrUpdateItem, deleteItem } from '~/async-actions/shipping-pricing-profiles-async-actions'

export interface ShippingPricingProfilesState {
  items: ShippingPricingProfile[]
  loading: {
    loadItems: boolean
    addOrUpdateItem: boolean
    deleteItem: boolean
  }
  error: {
    loadItems: string | null
    addOrUpdateItem: string | null
    deleteItem: string | null
  }
}

const initialState: ShippingPricingProfilesState = {
  items: [],
  loading: {
    loadItems: false,
    addOrUpdateItem: false,
    deleteItem: false
  },
  error: {
    loadItems: null,
    addOrUpdateItem: null,
    deleteItem: null
  }
}

const shippingPricingProfilesSlice = createSlice({
  name: 'shipping_pricing_profiles',
  initialState,
  // Not currently used
  reducers: {
    loadItems: (state, action: PayloadAction<ShippingPricingProfile[]>) => {
      state.items = action.payload
    },
    addOrUpdateItem: (state, action: PayloadAction<ShippingPricingProfile>) => {
      state.items = addToOrUpdateList(state.items, action.payload)
    },
    deleteItem: (state, action: PayloadAction<ShippingPricingProfile>) => {
      state.items = removeFromList(state.items, action.payload)
    }
  },
  extraReducers: builder => {
    builder
      // loadItems async action
      .addCase(loadItems.pending, state => {
        state.loading.loadItems = true
        state.error.loadItems = null
      })
      .addCase(loadItems.fulfilled, (state, action) => {
        state.items = action.payload
        state.loading.loadItems = false
      })
      .addCase(loadItems.rejected, (state, action) => {
        state.loading.loadItems = false
        state.error.loadItems = action.payload?.message || 'Error fetching shipping pricing profiles'
      })

      // addOrUpdateItem async action
      .addCase(addOrUpdateItem.pending, state => {
        state.loading.addOrUpdateItem = true
        state.error.addOrUpdateItem = null
      })
      .addCase(addOrUpdateItem.fulfilled, (state, action) => {
        state.items = addToOrUpdateList(state.items, action.payload)
        state.loading.addOrUpdateItem = false
      })
      .addCase(addOrUpdateItem.rejected, (state, action) => {
        state.loading.addOrUpdateItem = false
        state.error.addOrUpdateItem = action.payload?.message || 'Error adding or updating shipping pricing profile'
      })

      // deleteItem async action
      .addCase(deleteItem.pending, state => {
        state.loading.deleteItem = true
        state.error.deleteItem = null
      })
      .addCase(deleteItem.fulfilled, (state, action) => {
        state.items = removeFromList(state.items, action.payload)
        state.loading.deleteItem = false
      })
      .addCase(deleteItem.rejected, (state, action) => {
        state.loading.deleteItem = false
        state.error.deleteItem = action.payload?.message || 'Error deleting shipping pricing profile'
      })
  }
})

// export const { loadItems, addOrUpdateItem, deleteItem } = shippingPricingProfilesSlice.actions

export default shippingPricingProfilesSlice.reducer

const addToOrUpdateList = (items: ShippingPricingProfile[], newItem: ShippingPricingProfile) => {
  const newList = resetDefaultProfileIfNecessary(_.clone(items), newItem)
  const index = _.findIndex(newList, ['id', newItem.id])
  if (index === -1) {
    newList.push(newItem)
  } else {
    newList.splice(index, 1, newItem)
  }
  return newList
}

const removeFromList = (items: ShippingPricingProfile[], itemToDelete: ShippingPricingProfile) =>
  _.filter(items, i => i.id !== itemToDelete.id)

const resetDefaultProfileIfNecessary = (items: ShippingPricingProfile[], newItem: ShippingPricingProfile) => {
  if (newItem.default === false) {
    return items
  }
  return _.map(items, i => {
    if (i.type === newItem.type) {
      i.default = false
    }
    return i
  })
}
