import { useCallback } from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { arrayMove } from '@dnd-kit/sortable'
import _ from 'lodash'
import { useFormikContext, FieldArray } from 'formik'

import { ProductImage, ImageFile } from '~/types/product'

import Icon from '~/components/icon'
import RenderImages from './render-images'
import { ProductFormValues } from './use-product-form'
import useAuth from '~/contexts/useAuth'

const DragImgDiv = styled.div`
  width: 100%;
  margin-top: 15px;
`

const DropzoneSpan = styled.span`
  text-align: center;
  display: inherit;
  vertical-align: middle;
  padding: 28px 0;
`

const ItemList = styled.div`
  display: grid;
  grid-template-columns: 20% 20% 20% 20%;
  grid-template-rows: auto;
  grid-gap: 1em;
`

interface PhotoGridProps {
  isAccountMobileEnabled: boolean
}
const PhotoGrid = ({ isAccountMobileEnabled }: PhotoGridProps) => {
  const { values: formValues, setFieldValue } = useFormikContext<ProductFormValues>()
  const { userProfile } = useAuth()
  const imageValues = formValues?.images

  const mobileEnabledImages = _.filter(imageValues, 'mobile')
  const isMobileEnabledForAllImages =
    imageValues != null && imageValues.length > 0 && imageValues.length === mobileEnabledImages.length

  const toggleMobileForAllImages = useCallback(() => {
    const imageValues = formValues.images
    const result = !isMobileEnabledForAllImages

    _.each(imageValues, (_image, index) => {
      setFieldValue(`images[${index}].mobile`, result)
    })
  }, [formValues, isMobileEnabledForAllImages, setFieldValue])

  const onSortEnd = useCallback(
    (oldIndex: number, newIndex: number) => {
      if (oldIndex === newIndex) return

      // Sort items in frontend only
      const sortedList = arrayMove(formValues.images || [], oldIndex, newIndex)
      setFieldValue('images', sortedList)

      // Set sort_index to be saved in the server
      setTimeout(() => {
        _.each(formValues.images, (_image, index) => {
          setFieldValue(`images[${index}].sortIndex`, index)
        })
      })
    },
    [formValues, setFieldValue]
  )

  const onRemoveImage = (_image: ProductImage, imageField: string) => {
    // if '_new' property on the _image is true, remove the image from the 'formValues.images' array, otherwise set '_destroy' property to true on the _image
    if (_image._new) {
      const imageIndex = _.findIndex(formValues.images, _image)
      const images = _.filter(formValues.images, (_image, index) => index !== imageIndex)
      setFieldValue('images', images)
    } else {
      setFieldValue(`${imageField}._destroy`, true)
    }
  }

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const images: ImageFile[] = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )

      const existingImages = formValues.images || []
      const newImages = images.map((image, index) => ({
        _new: true,
        _file: image,
        mobile: false,
        sortIndex: existingImages.length + index
      }))

      setFieldValue('images', [...existingImages, ...newImages])
    },
    [formValues, setFieldValue]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'image/*': ['.jpg', '.jpeg', '.png', '.gif'] }
  })

  if (!userProfile) return null

  return (
    <div>
      {userProfile && userProfile.kind !== 'staff_limited' && (
        <div>
          <label htmlFor="mobileAll">
            <Icon icon="mobile" size="2x" />
            &nbsp; All &nbsp;
            <input
              name="mobileAll"
              type="checkbox"
              checked={isMobileEnabledForAllImages}
              onChange={() => toggleMobileForAllImages()}
            />
          </label>
        </div>
      )}

      <DragImgDiv className="card mb-2" {...getRootProps()}>
        <div className="card-body">
          <input {...getInputProps()} />
          {isDragActive ? (
            <DropzoneSpan>Drop the images here ...</DropzoneSpan>
          ) : (
            <DropzoneSpan>Drag &apos;n&apos; drop some images here, or click to select images</DropzoneSpan>
          )}
        </div>
      </DragImgDiv>

      <FieldArray
        name="images"
        render={() => (
          <ItemList>
            {(imageValues || []).map((image, index) => (
              <RenderImages
                key={index}
                index={index}
                image={image}
                isAccountMobileEnabled={isAccountMobileEnabled}
                userProfile={userProfile}
                onSortEnd={onSortEnd}
                onRemoveImage={onRemoveImage}
              />
            ))}
          </ItemList>
        )}
      />
    </div>
  )
}

export default PhotoGrid
