import { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import axios, { AxiosError } from 'axios'
import { Alert } from 'react-bootstrap'

import FileImport, { EbayPackingSlipsImportSummary } from '~/types/file-import'
import Spinner from '~/components/spinner'
import Form from './form'
import Pending from './pending'
import Failed from './failed'
import Success from './success'
import OrderDetails from './order-details'
import createErrorMsg from './create-error-msg'

interface PageErrorProps {
  msg: string | number | undefined
  onClose: () => void
}
const PageError = ({ msg, onClose }: PageErrorProps) => (
  <div className="alert alert-danger">
    <div className="close" aria-label="close" onClick={onClose}>
      &times;
    </div>
    {msg}
  </div>
)
/* eslint-enable */

const Wrapper = styled.div``

let timer: ReturnType<typeof setInterval> | null = null

const EbayPackingSlipsPage = () => {
  const [loading, setLoading] = useState(true)
  const [importRecord, setImportRecord] = useState<FileImport<EbayPackingSlipsImportSummary>>()
  const [pageError, setPageError] = useState<number | string>()
  const [selectedOrderId, setSelectedOrderId] = useState<number>()

  const showLoading = loading === true
  const showPending = importRecord?.pending === true
  const showSuccess = Boolean(importRecord?.completedAt) && !selectedOrderId
  const showOrderDetails = Boolean(importRecord?.completedAt) && selectedOrderId
  const showFailed = importRecord && Boolean(importRecord?.failedAt)
  const showForm = !loading && !importRecord?.pending && !selectedOrderId
  const showPageError = Boolean(pageError)
  const importRecordRef = useRef<FileImport<EbayPackingSlipsImportSummary>>()

  const fetchImport = useCallback(
    async (id: number) => {
      return axios
        .get(`/api/ebay_packing_slips_imports/${id}`)
        .then(res => res.data)
        .catch(error => {
          if (loading === true) {
            setLoading(false)
          }
          console.log('[DEBUG] error =', error)
          if (error.response && error.response.status === 404) {
            setPageError(404)
          } else {
            console.error(error)
            setPageError(500)
          }
        })
    },
    [loading]
  )

  const startPollingImportStatus = useCallback(() => {
    console.log('[INFO]', 'startPollingImportStatus')
    if (importRecordRef.current?.pending !== true) {
      return
    }
    timer = setInterval(() => {
      console.log('[INFO]', 'Polling func')
      if (importRecordRef.current?.pending === true) {
        console.log('[INFO]', 'reFetching')
        fetchImport(importRecordRef?.current?.id).then(record => {
          setImportRecord(record)
          importRecordRef.current = record
        })
      } else if (timer) {
        clearInterval(timer)
        console.log('[INFO]', 'cleared timer')
      }
    }, 5000)
  }, [fetchImport])

  const onOrderClose = () => {
    setSelectedOrderId(undefined)
  }

  const onOrderError = (error: AxiosError) => {
    if (error.response && error.response.status === 404) {
      setPageError(`Order with id ${selectedOrderId} Not Found`)
      setSelectedOrderId(undefined)
    } else {
      setPageError(`Error: Could not show order`)
      setSelectedOrderId(undefined)
    }
  }

  const onUploadStart = () => {
    setImportRecord(undefined)
    setLoading(true)
    setPageError(undefined)
  }

  const onUploadError = (error: AxiosError) => {
    setLoading(false)
    setPageError(createErrorMsg(error))
  }

  const onUploadSuccess = (record: FileImport<EbayPackingSlipsImportSummary>) => {
    setLoading(false)
    setImportRecord(record)
    importRecordRef.current = record
    startPollingImportStatus()
  }

  useEffect(() => {
    console.log('[INFO]', 'useEffect')
    fetchImport(-1).then(record => {
      setLoading(false)
      setImportRecord(record)
      importRecordRef.current = record
      startPollingImportStatus()
    })

    return () => {
      if (timer) {
        clearInterval(timer)
      }
      console.log('[INFO]', 'ran cleanup inside useEffect')
    }
  }, [fetchImport, startPollingImportStatus]) // Run only on first page load

  return (
    <Wrapper>
      <h1>eBay Packing Slips</h1>

      <Alert variant="info">
        <p>Upload eBay Packing Slip PDFs here.</p>
        <p>The user email address will be extracted, and assigned to the associated order.</p>
        <p>
          <strong>NOTE:</strong> Make sure you have imported the sales records first!
        </p>
      </Alert>

      {showLoading && <Spinner />}
      {showPending && <Pending />}
      {showPageError && <PageError msg={pageError} onClose={() => setPageError(undefined)} />}
      {showFailed && <Failed record={importRecord} />}
      {showSuccess && importRecord != null && <Success record={importRecord} setSelectedOrderId={setSelectedOrderId} />}
      {showOrderDetails && <OrderDetails orderId={selectedOrderId} onError={onOrderError} onClose={onOrderClose} />}
      {showForm && (
        <Form onUploadStart={onUploadStart} onUploadSuccess={onUploadSuccess} onUploadError={onUploadError} />
      )}
    </Wrapper>
  )
}

export default EbayPackingSlipsPage
