import { useCallback } from 'react'
import Select, { PropsValue } from 'react-select'
import { FieldInputProps, FormikProps } from 'formik'

import _ from '~/lib/lodash'

type Option = { value: string; label: string }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface SelectInputProps<V = any, FormValues = any> {
  field: FieldInputProps<V>
  form: FormikProps<FormValues>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (value: any) => void
  options: Option[]
  isMulti?: boolean
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SelectInput = <V = any, FormValues = any>({
  field,
  form,
  options,
  isMulti = false,
  value,
  onChange,
  ...otherProps
}: SelectInputProps<V, FormValues>) => {
  const onChangeSingle = useCallback(
    (option: Option | null): void => {
      form.setFieldValue(field.name, option?.value)
    },
    [form, field]
  )

  const onChangeMulti = useCallback(
    (options: readonly Option[]): void => {
      form.setFieldValue(
        field.name,
        options.map(i => i.value)
      )
    },
    [form, field]
  )

  let selected: PropsValue<Option> | undefined

  if (options) {
    selected = isMulti
      ? _.filter(options, (option: Option) => ((field.value as string[]) || []).indexOf(option.value) >= 0)
      : _.find(options, (option: Option) => option.value === field.value)
  } else {
    selected = isMulti ? [] : null
  }

  return isMulti ? (
    <Select<Option, true> isMulti options={options} defaultValue={selected} onChange={onChangeMulti} {...otherProps} />
  ) : (
    <Select<Option, false> options={options} defaultValue={selected} onChange={onChangeSingle} {...otherProps} />
  )
}

export default SelectInput
