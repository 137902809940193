import styled from 'styled-components'
import { Link } from 'react-router-dom'

import Icon from '~/components/icon'
import sections from './sections'

const StyledLink = styled(Link)`
  text-decoration: none !important;
  cursor: pointer;
`
const Wrapper = styled.div`
  display: flex;
  margin-top: -10px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${props => props.theme.colors.gray.lightest};
  align-items: center;
`
const SubTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 3rem;
`
const Title = styled.h1`
  margin-bottom: 0;
  font-size: 3rem;
`
const Chevron = styled(Icon)`
  font-size: 3rem;
  margin: 0 2rem;
`
const Button = styled.div`
  display: flex;
  align-items: center;

  & ${Icon} {
    margin-right: 10px;
  }
`

const getItem = (path: string) => sections.find(i => path.includes(i.path))

interface TitleBarProps {
  pagePath: string
}

const TitleBar = ({ pagePath }: TitleBarProps) => (
  <Wrapper>
    <StyledLink to="/settings">
      <Button>
        <Icon icon="cogs" size="2x" />
        <Title>Settings</Title>
      </Button>
    </StyledLink>

    <Chevron icon="chevron-right" />

    <SubTitle>{getItem(pagePath)?.label}</SubTitle>
  </Wrapper>
)

export default TitleBar
