import { useCallback } from 'react'
import { useFormikContext } from 'formik'
import _ from 'lodash'

import { OrderFormValues } from './use-order-details'
import Icon from '~/components/icon'

const OrderWarnings = () => {
  const { values: formValues } = useFormikContext<OrderFormValues>()

  const hasMissingProducts = useCallback(() => {
    const result = _.filter(
      formValues.lineItems || [],
      lineItem =>
        // Use product id, because the productId will still be set, even if it is
        // later deleted (if a product is deleted, then we don't want to consider
        // any orders it used to link to as suddenly "unmatched")
        //
        // in the future we'll add 'soft delete' for products
        !lineItem.productId
    )

    return result.length > 0
  }, [formValues.lineItems])

  const hasMissingAddress = useCallback(() => {
    return (
      formValues.mainSalesRecord &&
      ((!formValues.mainSalesRecord.shipToCountry && formValues.mainSalesRecord.buyerCountry) ||
        (!formValues.mainSalesRecord.shipToAddress1 &&
          formValues.mainSalesRecord.buyerAddress1 &&
          formValues.mainSalesRecord.buyerCountry))
    )
  }, [formValues.mainSalesRecord])

  return (
    <>
      {hasMissingProducts() && (
        <div className="alert alert-warning">
          <Icon icon="exclamation-circle" /> Some items have not been linked to products. Please go back and enter these
          products.
        </div>
      )}
      {hasMissingAddress() && (
        <div className="alert alert-warning">
          <Icon icon="exclamation-circle" /> The &quot;post to&quot; address was missing, so the &quote;buyer
          address&quote; was used instead
        </div>
      )}
    </>
  )
}

export default OrderWarnings
