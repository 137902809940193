import { useCallback } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { animateScroll } from 'react-scroll'
import { FormikHelpers } from 'formik'
import { AxiosError } from 'axios'

import { handleErrorResponse } from '~/lib/form-utils'
import { AccountSettingKeys, AccountSettingValues } from '~/types/account-settings'
import { SettingsProviderState } from '~/contexts/settings'

interface BaseProps {
  settingsKey: AccountSettingKeys
  updateAction: SettingsProviderState['updateAction']
  redirectPath: string
}

const useSubmitHandler = () => {
  const navigate = useNavigate()
  const submitHandler = useCallback(
    <T extends AccountSettingValues, P extends BaseProps>(
      values: T,
      { setSubmitting, setErrors }: FormikHelpers<T>,
      props: P
    ) => {
      const { settingsKey, updateAction, redirectPath } = props

      const onSuccess = () => {
        setSubmitting(false)
        navigate(redirectPath)
        toast.success('Changes Saved!')
        animateScroll.scrollToTop()
      }

      const onError = (error: AxiosError) => {
        setSubmitting(false)
        toast.error('Sorry, there were errors processing the form. Please review!')
        animateScroll.scrollToTop()

        handleErrorResponse({
          response: error.response,
          setErrors
        })
      }

      console.log('[DEBUG] saving values =', values)
      updateAction(settingsKey, values, onSuccess, onError)
    },
    [navigate]
  )

  return {
    submitHandler
  }
}

export default useSubmitHandler
