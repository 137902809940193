import styled from 'styled-components'

export const Table = styled.table`
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  background-color: ${props => props.theme.Table.backgroundColors};
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid ${props => props.theme.Table.borderColor};

  & > thead:first-child > tr:first-child > th {
    border-top: 0;
  }

  & > tbody > tr:nth-of-type(odd) {
    background-color: ${props => props.theme.Table.backgroundColorAlt};
  }
`

export const Thead = styled.thead``
export const Tbody = styled.tbody``
export const Tfoot = styled.tfoot``
export const Tr = styled.tr``

export const Th = styled.th`
  border-bottom-width: 2px;
  border: 1px solid ${props => props.theme.Table.borderColor};
  vertical-align: bottom;
  padding: ${props => props.theme.Table.cellPadding};
  line-height: 1.42857143;
  text-align: left;
`
export const Td = styled.td`
  border: 1px solid ${props => props.theme.Table.borderColor};
  padding: ${props => props.theme.Table.cellPadding};
  line-height: 1.42857143;
  vertical-align: top;
`
