import { Row, Alert } from 'react-bootstrap'
import styled from 'styled-components'

const Flex = styled.div`
  display: flex;
  justify-content: space-around;
  & > * {
    margin-right: 40px;
  }
  margin: 15px 20px 20px 20px;
  font-size: 15px;
`

export interface ShipmentErrorDetails {
  code: string
  name: string
  message: string
  errors: ShipmentErrorDetails[]
}

export interface ServerError {
  message: string
  origin: string
  type: string
}

interface ShipmentErrorProps {
  shipmentErrors?: ShipmentErrorDetails
  serverError?: ServerError[]
}
const ShipmentError = ({ shipmentErrors, serverError }: ShipmentErrorProps) => {
  if (shipmentErrors?.code && shipmentErrors?.message) {
    return (
      <Row>
        <Flex>
          <Alert variant="danger">
            <h4>The remote server errors:</h4>
            <hr />
            <p>Code: {shipmentErrors.code}</p>
            <p>Name: {shipmentErrors.name}</p>
            <p>Message: {shipmentErrors.message}</p>
          </Alert>
        </Flex>
      </Row>
    )
  }

  if (shipmentErrors?.errors) {
    return (
      <>
        {shipmentErrors.errors.map((error, i) => (
          <Row key={i}>
            <Flex>
              <Alert variant="danger">
                <h4>The remote server errors:</h4>
                <hr />
                <p>Code: {error.code}</p>
                <p>Name: {error.name}</p>
                <p>Message: {error.message}</p>
              </Alert>
            </Flex>
          </Row>
        ))}
      </>
    )
  }

  if (serverError) {
    return (
      <>
        {serverError.map((error, i) => (
          <Row key={i}>
            <Flex>
              <Alert variant="danger">
                <h4>The local server returned the following errors</h4>
                <hr />
                <p>Message: {error.message}</p>
                <p>Origin: {error.origin}</p>
                <p>type: {error.type}</p>
              </Alert>
            </Flex>
          </Row>
        ))}
      </>
    )
  }

  return null
}

export default ShipmentError
