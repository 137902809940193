import { useState } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;

  .fa-question-circle {
    margin-right: 10px;
  }

  /* Tooltip container */
  .tooltip-content {
    position: absolute;
    display: inline-block;
    z-index: 1;

    .well {
      background: #f8eb97;
    }

    .tooltip-text {
      width: 200px;
      color: #000;
      text-align: center;
      padding: 5px 0;
      border-radius: 6px;
    }
  }
`

interface TooltipProps {
  showTemplateOnHover?: boolean
  children: React.ReactNode
  hoverTemplate: React.ReactNode
}

const Tooltip = ({ showTemplateOnHover = false, children, hoverTemplate }: TooltipProps) => {
  const [hover, setHover] = useState(false)

  const handleMouseOver = () => {
    setHover(true)
  }

  const handleMouseOut = () => {
    setHover(false)
  }

  const tooltipStyle = {
    display: hover ? 'inline-block' : 'none',
    top: '-170px'
  }

  return (
    <Wrapper onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      {showTemplateOnHover && (
        <div className="tooltip-content" style={tooltipStyle}>
          {hoverTemplate}
        </div>
      )}
      {children}
    </Wrapper>
  )
}

export default Tooltip
