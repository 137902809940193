import { FieldInputProps, FormikProps } from 'formik'
import { useState, useEffect, useCallback } from 'react'
import RichTextEditor, { EditorValue } from 'react-rte'

// NOTE: 'react-rte' is deprecated, and should be replaced with something else

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface MarkdownInputProps<V = any, FormValues = any> {
  field: FieldInputProps<V>
  form: FormikProps<FormValues>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MarkdownInput = <V = any, FormValues = any>({ field, form }: MarkdownInputProps<V, FormValues>) => {
  const [rteValue, setRteValue] = useState<EditorValue>(
    RichTextEditor.createValueFromString((field.value as string) || '', 'markdown')
  )

  useEffect(() => {
    setRteValue(RichTextEditor.createValueFromString((field.value as string) || '', 'markdown'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const customOnChange = useCallback(
    (value: EditorValue) => {
      setRteValue(value)
      form.setFieldValue(field.name, value.toString('markdown'))
    },
    [field.name, form]
  )

  return <RichTextEditor value={rteValue} onChange={customOnChange} />
}

export default MarkdownInput
