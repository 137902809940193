import { ReactNode, useState } from 'react'
import { saveAs } from 'file-saver'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { JWT } from '~/api/auth'
import { getApiPath } from '~/config/axios'
import Icon from '~/components/icon'
import { Button } from '~/components/forms'

interface ExportButtonProps {
  className?: string
  progressLabel?: string
  label?: string
  children?: ReactNode
  endpoint: string
  icon?: IconProp
  disabled?: boolean
}

const ExportButton = (props: ExportButtonProps) => {
  const [downloading, setDownloading] = useState(false)

  const downloadFile = () => {
    const url = `${getApiPath()}${props.endpoint}`
    console.log(`Downloading file at ${url}`)
    setDownloading(true)

    // Use XMLHttpRequest rather than axios, because responseType: 'blob',
    // isn't working with axios, which is needed in order to download and save
    // ISO-8859-1 content from the server
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.withCredentials = true
    xhr.setRequestHeader('Authorization', 'Bearer ' + JWT.token)
    xhr.addEventListener('load', () => {
      const headers = xhr.getAllResponseHeaders()
      let fileName = 'unknown'

      const fileNameMatch = headers.match(/filename="(.+)"/)
      if (fileNameMatch && fileNameMatch.length === 2) fileName = fileNameMatch[1]

      saveAs(xhr.response, fileName)
      setDownloading(false)
    })
    xhr.addEventListener('error', error => {
      console.error('could not download file', error)
      setDownloading(false)
    })
    xhr.send()
  }

  const { className, progressLabel = 'Downloading...', label = 'Export', children, icon, disabled } = props

  return (
    <Button className={className} disabled={disabled || downloading} onClick={downloadFile}>
      {downloading && !children && progressLabel && <>{progressLabel}</>}
      {!downloading && !children && label && (
        <>
          {icon && <Icon icon={icon} />} {label}
        </>
      )}
      {children}
    </Button>
  )
}

export default ExportButton
