import styled from 'styled-components'
import { Navigate } from 'react-router-dom'
import useAuth from '~/contexts/useAuth'

import Form from './form'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #5e6673;
`

const ForgotPasswordPage = () => {
  const { userProfile } = useAuth()

  if (userProfile) {
    return <Navigate to={'/'} />
  }

  return (
    <Wrapper>
      <Form />
    </Wrapper>
  )
}

export default ForgotPasswordPage
