import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import styled from 'styled-components'
import { AxiosError } from 'axios'

import { UserKindEnum } from '~/types/user'
import useAuth from '~/contexts/useAuth'
import Grid from '~/components/grid'
import Icon from '~/components/icon'
import { Button } from '~/components/forms'
import { fetchList, destroyRecord } from '~/async-actions/product-search-links-async-actions'
import { AppState, AppDispatch } from '~/config/store'
import ProductSearchLink from '~/types/product-search-link'
import { ListMeta } from '~/reducers/product-search-links-reducer'
import columnsConfig from './config'

const Nav = styled.div`
  margin-bottom: 2rem;
  display: flex;
`
const NavItem = styled.div`
  margin-right: 2rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

const ProductSearchLinksPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { userProfile } = useAuth()
  const dispatch = useDispatch<AppDispatch>()
  const { list, listMeta } = useSelector((state: AppState) => state.productSearchLinks)

  const kind = (): string => {
    const { pathname } = location
    const matches = pathname.match(/\/([a-z-]+)/)
    return matches ? matches[1] : 'mobile'
  }

  const pagePath = (): string => `/${_.kebabCase(kind())}/product-search-links`

  const onEdit = (record: ProductSearchLink): void => {
    const path = `${pagePath()}/${record.id}/edit`
    navigate(path)
  }

  const onDelete = (record: ProductSearchLink): void => {
    dispatch(destroyRecord(record))
  }

  if (!userProfile || ![UserKindEnum.OWNER, UserKindEnum.STAFF_FULL].includes(userProfile.kind)) return null

  return (
    <div>
      <h1>Product Search Links</h1>

      <p>Your list of search links available to both the mobile app and the Shopfront.</p>

      {userProfile && (userProfile.kind === UserKindEnum.OWNER || userProfile.kind === UserKindEnum.STAFF_FULL) && (
        <div>
          <Nav>
            <NavItem onClick={() => navigate(`/${_.kebabCase(kind())}?page=1`)}>
              <Icon icon="external-link-alt" />
              &nbsp; Priority Items Page
            </NavItem>
            {kind() === 'shopfront' && (
              <NavItem onClick={() => navigate(`/${_.kebabCase(kind())}/menu-items`)}>
                <Icon icon="external-link-alt" />
                &nbsp; Menu Items Page
              </NavItem>
            )}
          </Nav>

          <Button className="new-record-btn" onClick={() => navigate(`${pagePath()}/new`)}>
            <Icon icon="plus" /> New
          </Button>
          <br />
          <br />

          <Grid<ProductSearchLink, ListMeta, AxiosError>
            config={{
              columns: columnsConfig,
              onEdit: onEdit,
              onDelete: onDelete
            }}
            recordType="productSearchLinks"
            fetchFn={fetchList}
            list={list}
            listMeta={listMeta}
          />
        </div>
      )}
    </div>
  )
}

export default ProductSearchLinksPage
