import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import Customer from '~/types/customer'

import {
  fetchCustomer,
  createCustomer,
  updateCustomer,
  fetchCustomers,
  destroyCustomer,
  inviteCustomer
} from '~/async-actions/customers-async-actions'

export interface ListMeta {
  currentPage: number
  totalPages: number
  totalCount: number
  pageSize: number
  search: string | null
  categories: string | null
}

export interface CustomerState {
  list: Customer[]
  listMeta?: ListMeta
  recordToEdit?: Customer
  inviteCustomerError: string | null
  loading: {
    fetchCustomer: boolean
    createCustomer: boolean
    updateCustomer: boolean
    fetchCustomers: boolean
    destroyCustomer: boolean
    inviteCustomer: boolean
  }
  error: {
    fetchCustomer: string | null
    createCustomer: string | null
    updateCustomer: string | null
    fetchCustomers: string | null
    destroyCustomer: string | null
    inviteCustomer: string | null
  }
}

const initialState: CustomerState = {
  list: [],
  listMeta: undefined,
  recordToEdit: undefined,
  inviteCustomerError: null,
  loading: {
    fetchCustomer: false,
    createCustomer: false,
    updateCustomer: false,
    fetchCustomers: false,
    destroyCustomer: false,
    inviteCustomer: false
  },
  error: {
    fetchCustomer: null,
    createCustomer: null,
    updateCustomer: null,
    fetchCustomers: null,
    destroyCustomer: null,
    inviteCustomer: null
  }
}

const sortCustomers = (items: Customer[]) =>
  _.orderBy(items, item => {
    return (item.name || '').toLowerCase()
  })

const customerSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    loadCustomers: (state, action: PayloadAction<{ items: Customer[]; meta: ListMeta }>) => {
      state.list = sortCustomers(action.payload.items)
      state.listMeta = action.payload.meta
    },
    deleteCustomer: (state, action: PayloadAction<Customer>) => {
      const newList = state.list.filter(customer => customer.id !== action.payload.id)
      state.list = sortCustomers(newList)
      if (state.listMeta) {
        state.listMeta.totalCount -= 1
      }
    },
    addCustomer: (state, action: PayloadAction<Customer>) => {
      state.list.push(action.payload)
      state.list = sortCustomers(state.list)
      if (state.listMeta) {
        state.listMeta.totalCount += 1
      }
    },
    setCustomerToEdit: (state, action: PayloadAction<Customer | undefined>) => {
      state.recordToEdit = action.payload
    },
    inviteCustomerError: (state, action: PayloadAction<string | null>) => {
      state.inviteCustomerError = action.payload
    },
    inviteCustomer: (state, action: PayloadAction<Customer>) => {
      const tempList = state.list.filter(customer => customer.id !== action.payload.id)
      tempList.push(action.payload)
      state.list = sortCustomers(tempList)
    },
    replaceCustomer: (state, action: PayloadAction<Customer>) => {
      const tempList = state.list.filter(customer => customer.id !== action.payload.id)
      tempList.push(action.payload)
      state.list = sortCustomers(tempList)
    }
  },
  extraReducers: builder => {
    builder
      // fetchCustomer async action
      .addCase(fetchCustomer.pending, state => {
        state.loading.fetchCustomer = true
        state.error.fetchCustomer = null
      })
      .addCase(fetchCustomer.fulfilled, (state, action) => {
        state.recordToEdit = action.payload
        state.loading.fetchCustomer = false
      })
      .addCase(fetchCustomer.rejected, (state, action) => {
        state.loading.fetchCustomer = false
        state.error.fetchCustomer = action.payload?.message || 'Error fetching customer'
      })

      // createCustomer async action
      .addCase(createCustomer.pending, state => {
        state.loading.createCustomer = true
        state.error.createCustomer = null
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        const record = action.payload
        state.list.push(record)
        state.list = sortCustomers(state.list)
        if (state.listMeta) {
          state.listMeta.totalCount += 1
        }
        state.loading.createCustomer = false
      })
      .addCase(createCustomer.rejected, (state, action) => {
        state.loading.createCustomer = false
        state.error.createCustomer = action.payload?.message || 'Error fetching customer'
      })

      // updateCustomer async action
      .addCase(updateCustomer.pending, state => {
        state.loading.updateCustomer = true
        state.error.updateCustomer = null
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        const record = action.payload
        const tempList = state.list.filter(customer => customer.id !== record.id)
        tempList.push(record)
        state.list = sortCustomers(tempList)
        state.loading.updateCustomer = false
      })
      .addCase(updateCustomer.rejected, (state, action) => {
        state.loading.updateCustomer = false
        state.error.updateCustomer = action.payload?.message || 'Error fetching customer'
      })

      // fetchCustomers async action
      .addCase(fetchCustomers.pending, state => {
        state.loading.fetchCustomers = true
        state.error.fetchCustomers = null
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.list = sortCustomers(action.payload.items)
        state.listMeta = action.payload.meta
        state.loading.fetchCustomers = false
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.loading.fetchCustomers = false
        state.error.fetchCustomers = action.error.message || 'Error fetching customers'
      })

      // destroyCustomer async action
      .addCase(destroyCustomer.pending, state => {
        state.loading.destroyCustomer = true
        state.error.destroyCustomer = null
      })
      .addCase(destroyCustomer.fulfilled, (state, action) => {
        state.list = state.list.filter(customer => customer.id !== action.payload.id)
        state.list = sortCustomers(state.list)
        if (state.listMeta) {
          state.listMeta.totalCount--
        }
        state.loading.destroyCustomer = false
      })
      .addCase(destroyCustomer.rejected, (state, action) => {
        state.loading.destroyCustomer = false
        state.error.destroyCustomer = action.error.message || 'Error deleting customer'
      })

      // inviteCustomer async action
      .addCase(inviteCustomer.pending, state => {
        state.loading.inviteCustomer = true
        state.error.inviteCustomer = null
      })
      .addCase(inviteCustomer.fulfilled, (state, action) => {
        const record = action.payload
        const tempList = state.list.filter(customer => customer.id !== record.id)
        tempList.push(record)
        state.list = sortCustomers(tempList)
        state.loading.inviteCustomer = false
      })
      .addCase(inviteCustomer.rejected, (state, action) => {
        state.loading.inviteCustomer = false
        state.error.inviteCustomer = action.error.message || 'Error inviting customer'
      })
  }
})

export const { loadCustomers, deleteCustomer, addCustomer, setCustomerToEdit, inviteCustomerError, replaceCustomer } =
  customerSlice.actions

export default customerSlice.reducer
