import { useState, useEffect } from 'react'
import axios, { AxiosError } from 'axios'

import OrderDetailsForm from '~/containers/order-details-form-page/form'
import Order from '~/types/order'

const fetchOrder = (id: number) => axios.get(`/api/orders/${id}`).then(res => res.data)

interface OrderDetailsProps {
  orderId: number
  onError: (error: AxiosError) => void
  onClose: () => void
}
const OrderDetails = ({ orderId, onError, onClose }: OrderDetailsProps) => {
  const [order, setOrder] = useState<Order>()

  useEffect(() => {
    fetchOrder(orderId)
      .then(record => setOrder(record))
      .catch(onError)
  }, [onError, orderId])

  return (
    <>
      {order && (
        <OrderDetailsForm
          initialValues={order as Partial<Order>}
          onCancel={onClose}
          afterSave={async () => onClose()}
          saveBtnText="Save and Close"
        />
      )}
    </>
  )
}

export default OrderDetails
