import { ReactNode } from 'react'
import styled from 'styled-components'

const FieldsetStyled = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: ${props => props.theme.Fieldset.backgroundColor};
  border: 1px solid ${props => props.theme.Fieldset.borderColor};
  border-radius: ${props => props.theme.borderRadius};
  margin-bottom: 2rem;

  & > h1,
  & > h2,
  & > h3,
  & > h4 {
    &:first-child {
      margin-top: 0;
      margin-bottom: 3rem;
    }
  }
`
const Legend = styled.legend`
  font-size: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
`

interface FieldsetProps {
  className?: string
  legend?: ReactNode
  children: ReactNode
}
const Fieldset = ({ children, legend, ...otherProps }: FieldsetProps) => (
  <FieldsetStyled {...otherProps}>
    {legend && <Legend>{legend}</Legend>}
    {children}
  </FieldsetStyled>
)

export default Fieldset
