import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Routes, Route, Navigate, useParams } from 'react-router-dom'
import { arrayMove } from '@dnd-kit/sortable'
import _ from 'lodash'

import { NotFound } from '~/components/error-messages'
import Spinner from '~/components/spinner'
import { loadItems, addOrUpdateItem, deleteItem, reSortItems } from '~/async-actions/shopfront-pages-async-actions'
import { moveItem } from '~/reducers/shopfront-pages-reducer'
import { useConfirm } from '~/hooks'
import Form from './form'
import ListView from './list-view'
import Preview from './preview'
import { AppDispatch, AppState } from '~/config/store'
import ShopfrontPage from '~/types/shopfront-page'

interface MatchParams {
  recordId?: string
}

interface RenderFormProps {
  items?: ShopfrontPage[]
}
const RenderForm = ({ items }: RenderFormProps) => {
  const findItemById = (id: number) => _.find(items, ['id', id])
  const { recordId } = useParams<keyof MatchParams>()
  const emptyItem = { title: '', active: true }
  let item = {}

  if (recordId) {
    const result = findItemById(parseInt(recordId, 10))
    item = result || emptyItem
  } else {
    item = emptyItem
  }

  if (recordId && item === emptyItem) {
    return <Navigate to="/settings/shopfront_pages" />
  }

  return <Form addOrUpdateItem={addOrUpdateItem} item={item as ShopfrontPage} />
}

interface RenderPreviewProps {
  items?: ShopfrontPage[]
  isLoading?: boolean
}
const RenderPreview = ({ items, isLoading = false }: RenderPreviewProps) => {
  const findItemById = (id: number) => _.find(items, ['id', id])
  const { recordId } = useParams<keyof MatchParams>()
  const item = findItemById(parseInt(recordId || '', 10))

  if (!item) {
    return <NotFound />
  }

  return <Preview pending={isLoading} item={item} />
}

const SettingsShopfrontPages = () => {
  const [confirm] = useConfirm()
  const dispatch = useDispatch<AppDispatch>()
  const { items, loading } = useSelector((state: AppState) => state.shopfrontPages)

  useEffect(() => {
    dispatch(loadItems())
  }, [dispatch])

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    const newList = arrayMove(items, oldIndex, newIndex)
    const orderedIds = _.map(newList, item => item.id)
    dispatch(moveItem({ oldIndex, newIndex }))
    dispatch(reSortItems(orderedIds))
  }

  const onDelete = async (item: ShopfrontPage) => {
    if (!(await confirm({ message: 'Are you sure you want to delete this shopfront page?' }))) return
    return dispatch(deleteItem(item))
  }

  const renderListView = () => {
    return <ListView pending={loading.loadItems} items={items} onDelete={onDelete} onSortEnd={onSortEnd} />
  }

  return loading.loadItems ? (
    <Spinner />
  ) : (
    <Routes>
      <Route path="/new" element={<RenderForm />} />
      <Route path="/:recordId/edit" element={<RenderForm items={items} />} />
      <Route path="/:recordId" element={<RenderPreview items={items} isLoading={loading.loadItems} />} />
      <Route path="/" element={renderListView()} />
    </Routes>
  )
}

export default SettingsShopfrontPages
