import { object, string, number } from 'yup'
import '~/lib/yup'

export default object().shape({
  type: string().required('is required'),
  name: string().required('is required'),
  minWeight: number().required('is required'),
  maxWeight: number().required('is required').greaterThan('minWeight', 'Must be greater than min-weight'),
  piAu: number().min(0),
  piZone1: number().min(0),
  piZone2: number().min(0),
  piZone3: number().min(0),
  piZone4: number().min(0),
  piZone5: number().min(0),
  piZone6: number().min(0),
  piZone7: number().min(0),
  piZone8: number().min(0),
  piZone9: number().min(0),
  pkAu: number().min(0),
  pkZone1: number().min(0),
  pkZone2: number().min(0),
  pkZone3: number().min(0),
  pkZone4: number().min(0),
  pkZone5: number().min(0),
  pkZone6: number().min(0),
  pkZone7: number().min(0),
  pkZone8: number().min(0),
  pkZone9: number().min(0)
})
