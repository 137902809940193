import { useState, useEffect, useCallback, ChangeEvent } from 'react'
import _ from 'lodash'
import { useFormikContext, Field } from 'formik'
import { useDispatch } from 'react-redux'
import axios from 'axios'

import useAuPostcodes from '~/contexts/useAuPostcodes'
import { BsInput, BsSelectInput } from '~/components/form-inputs/formik/inputs-decorated'
import { AppDispatch } from '~/config/store'
import Icon from '~/components/icon'
import Switch from '../../components/forms/switch'
import { IntlShippingServiceOption } from '~/types/account-settings'
import { Option } from './form'
import { OrderFormValues } from './use-order-details'
import { OrderCountryNote, setCountryNote, setIntlShippingServiceOptions } from '~/reducers/transient-form-data-reducer'

const dropdownEmptyOption = { value: '', label: 'Please select' }

interface OrderShippingAddressProps {
  countryOptions: Option[]
}

const OrderShippingAddress = ({ countryOptions }: OrderShippingAddressProps) => {
  const { auPostcodes } = useAuPostcodes()
  const [parcelZone, setParcelZone] = useState<string | undefined>(undefined)
  const [cityOptions, setCityOptions] = useState<Option[]>([dropdownEmptyOption])
  const [showOrigAddressValues, setShowOrigAddressValues] = useState<boolean>(false)
  const dispatch = useDispatch<AppDispatch>()

  const { values: formValues, setFieldValue, errors } = useFormikContext<OrderFormValues>()

  const {
    shippingVirtualTrackingNum,
    isDomestic,
    shippingAddress1,
    shippingCity,
    shippingState,
    shippingPostcode,
    shippingCountry
  } = formValues

  const isInternational = !isDomestic

  const viewOnMapQueryParams = _.compact([
    shippingAddress1,
    shippingCity,
    shippingState,
    shippingPostcode,
    shippingCountry
  ]).join(', ')

  const showOriginalAddressFieldsIfInError = useCallback(() => {
    if (
      errors.shippingAddress1 ||
      errors.shippingAddress2 ||
      errors.shippingAddress3 ||
      errors.shippingContactName ||
      errors.shippingCity
    ) {
      setShowOrigAddressValues(true)
    }
  }, [
    errors.shippingAddress1,
    errors.shippingAddress2,
    errors.shippingAddress3,
    errors.shippingContactName,
    errors.shippingCity
  ])

  const lookupShippingStateBasedOnPostcodeOrCity = () => {
    if (isInternational) {
      return
    }

    const result = _.filter(auPostcodes, auPostcode => auPostcode.postcode === formValues.shippingPostcode)
    const resultByLocality = _.filter(
      auPostcodes,
      auPostcode =>
        auPostcode.postcode === formValues.shippingPostcode && auPostcode.locality === formValues.shippingCity
    )
    const shippingState = resultByLocality[0] ? resultByLocality[0].state : result[0] ? result[0].state : null
    setFieldValue('shippingState', shippingState)
  }

  const loadCountryNote = useCallback(
    async (shippingCountry?: string) => {
      if (!shippingCountry) {
        return
      }

      const response = await axios.get<OrderCountryNote>(`/api/country_notes?country_code=${shippingCountry}`)
      return dispatch(setCountryNote(response.data))
    },
    [dispatch]
  )

  const populateCityOptions = useCallback(() => {
    if (isInternational) {
      return
    }

    const cityOptions = _.filter(auPostcodes, auPostcode => auPostcode.postcode === shippingPostcode).map(
      auPostcode => ({
        value: auPostcode.locality,
        label: auPostcode.locality
      })
    )
    cityOptions.unshift(dropdownEmptyOption)

    setCityOptions(cityOptions)

    const isInList = _.find(cityOptions, option => option.value === shippingCity)
    if (!isInList) {
      setFieldValue('shippingCity', '')
    }
  }, [shippingPostcode, shippingCity, isInternational, setFieldValue, auPostcodes])

  const loadIntlShippingServiceOptions = useCallback(
    async (shippingCountry?: string) => {
      if (!shippingCountry) {
        return
      }

      const response = await axios.get<IntlShippingServiceOption[]>(
        `/api/intl_shipping_services?country_code=${shippingCountry}`
      )
      const options = response.data.map(service => {
        return { ...service, value: service.id } as IntlShippingServiceOption
      })
      options.unshift(dropdownEmptyOption as IntlShippingServiceOption)

      dispatch(setIntlShippingServiceOptions(options))

      // TODO: This is a workaround for re-running validation after mounting form
      // const currentInsuranceCostValue = insuranceCost || 0
      // setFieldValue('insuranceCost', currentInsuranceCostValue + 1)
      // setFieldValue('insuranceCost', currentInsuranceCostValue)
    },
    [dispatch]
  )

  const lookupParcelZone = useCallback(() => {
    if (isInternational) {
      return
    }

    const result = _.filter(
      auPostcodes,
      auPostcode =>
        auPostcode.postcode === shippingPostcode &&
        auPostcode.locality === shippingCity &&
        auPostcode.state === shippingState
    )
    const parcelZone = result[0] ? result[0].parcelZone : undefined

    setParcelZone(parcelZone)
  }, [auPostcodes, shippingPostcode, shippingCity, shippingState, isInternational])

  const handleChangeShippingPostcode = () => {
    lookupShippingStateBasedOnPostcodeOrCity()
    populateCityOptions()
    lookupParcelZone()
  }

  const handleChangeShippingCity = () => {
    lookupShippingStateBasedOnPostcodeOrCity()
    lookupParcelZone()
  }

  const handleChangeShippingCountry = async (event: ChangeEvent<HTMLSelectElement>) => {
    const shippingCountry = event.target.value
    await loadIntlShippingServiceOptions(shippingCountry)
    await loadCountryNote(shippingCountry)
  }

  useEffect(() => {
    if (isDomestic) {
      return
    }

    loadCountryNote(shippingCountry)
    loadIntlShippingServiceOptions(shippingCountry)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    populateCityOptions()
  }, [populateCityOptions])

  useEffect(() => {
    lookupParcelZone()
  }, [lookupParcelZone])

  useEffect(() => {
    showOriginalAddressFieldsIfInError()
  }, [showOriginalAddressFieldsIfInError])

  const labelClassName = 'col-sm-4 col-md-3 col-md-3'
  const inputClassName = 'col-sm-8 col-md-9 col-lg-9'

  return (
    <fieldset>
      <legend className="d-flex justify-content-between">
        <span className="d-flex gap-3 align-items-center">
          <span>Shipping Address</span>
          <Switch
            tooltip="original address values"
            checked={showOrigAddressValues}
            onChange={() => setShowOrigAddressValues(!showOrigAddressValues)}
          />
        </span>
        <div>
          <a
            href={`http://www.google.com/maps/place/${viewOnMapQueryParams}`}
            target="_blank"
            rel="noopener noreferrer"
            className="fs-6">
            View on Map <Icon icon="map-marker" />
          </a>
        </div>
      </legend>

      <Field
        required
        name="shippingContactName"
        label="Name"
        component={BsInput}
        helpBlock={showOrigAddressValues && (formValues.mainSalesRecord || {}).shipToName}
        labelClassName={labelClassName}
        inputWrapperClassName={inputClassName}
      />
      <Field
        required
        name="shippingAddress1"
        label="Addr1"
        component={BsInput}
        helpBlock={showOrigAddressValues && (formValues.mainSalesRecord || {}).shipToAddress1}
        labelClassName={labelClassName}
        inputWrapperClassName={inputClassName}
      />
      <Field
        name="shippingAddress2"
        label="Addr2"
        component={BsInput}
        helpBlock={showOrigAddressValues && (formValues.mainSalesRecord || {}).shipToAddress2}
        readOnly={Boolean(shippingVirtualTrackingNum)}
        labelClassName={labelClassName}
        inputWrapperClassName={inputClassName}
      />
      <Field
        name="shippingAddress3"
        label="Addr3"
        component={BsInput}
        readOnly={Boolean(shippingVirtualTrackingNum)}
        labelClassName={labelClassName}
        inputWrapperClassName={inputClassName}
      />

      {isDomestic && (
        <>
          <Field
            required
            name="shippingCity"
            label="Surburb"
            component={BsSelectInput}
            options={cityOptions}
            helpBlock={showOrigAddressValues && (formValues.mainSalesRecord || {}).shipToCity}
            onChange={handleChangeShippingCity}
            labelClassName={labelClassName}
            inputWrapperClassName={inputClassName}
          />

          <div className="row mb-3">
            <label htmlFor="orderShippingState" className="col-sm-4 col-md-3 col-lg-3 col-form-label fw-bold text-end">
              State
            </label>

            <div className="col-sm-8 col-md-9 col-lg-9 d-flex justify-content-between align-items-start">
              <Field
                name="shippingState"
                id="orderShippingState"
                label={null}
                component={BsInput}
                readOnly
                disabled
                wrapperClassName="flex-grow-1"
                inputWrapperClassName="dummy"
                style={{ minWidth: '80px', maxWidth: '120px' }}
              />
              <Field
                name="parcel-zone"
                label="Zone:"
                component={BsInput}
                readOnly
                disabled
                displayMode="plain"
                value={parcelZone || '-'}
                className="form-control ps-1 pe-1"
                style={{ maxWidth: '35px', minWidth: '35px' }}
                wrapperClassName="flex-grow-0 d-flex justify-content-start align-items-start"
                labelClassName="ms-3 pe-1"
                inputWrapperClassName="flex-grow-0 pe-1"
              />
              <Field
                required
                name="shippingPostcode"
                label="Postcode:"
                component={BsInput}
                onChange={handleChangeShippingPostcode}
                wrapperClassName="flex-grow-1 d-flex justify-content-end align-items-start"
                labelClassName="ps-1 pe-1"
                inputWrapperClassName="dummy"
                style={{ minWidth: '55px', maxWidth: '100px' }}
              />
            </div>
          </div>

          <Field
            name="shippingCountry"
            label="Country"
            component={BsInput}
            value="Australia"
            readOnly
            disabled
            labelClassName={labelClassName}
            inputWrapperClassName={inputClassName}
          />
          <Field
            name="shippingContactPhone"
            label="Phone"
            component={BsInput}
            labelClassName={labelClassName}
            inputWrapperClassName={inputClassName}
          />
        </>
      )}

      {isInternational && (
        <>
          <Field
            required
            name="shippingCity"
            label="City"
            component={BsInput}
            helpBlock={showOrigAddressValues && (formValues.mainSalesRecord || {}).shipToCity}
            labelClassName={labelClassName}
            inputWrapperClassName={inputClassName}
          />

          <div className="row mb-3">
            <label className="col-sm-4 col-md-3 col-lg-3 col-form-label fw-bold text-end" htmlFor="orderShippingState">
              State
            </label>
            <div className="col-sm-8 col-md-9 col-lg-9 d-flex justify-content-between align-items-start">
              <Field
                name="shippingState"
                id="orderShippingState"
                label={null}
                component={BsInput}
                wrapperClassName="flex-grow-1 align-items-start"
                inputWrapperClassName="dummy"
                style={{ minWidth: '85px' }}
              />
              <Field
                required
                name="shippingPostcode"
                label="Postcode"
                component={BsInput}
                wrapperClassName="flex-grow-1 d-flex justify-content-end align-items-start"
                labelClassName="ms-3 align-items-start"
                inputWrapperClassName="dummy"
                style={{ minWidth: '85px' }}
              />
            </div>
          </div>

          <Field
            required
            name="shippingCountry"
            label="Country"
            component={BsSelectInput}
            options={countryOptions}
            onChange={handleChangeShippingCountry}
            labelClassName={labelClassName}
            inputWrapperClassName={inputClassName}
          />
          <Field
            name="shippingContactPhone"
            label="Phone"
            component={BsInput}
            labelClassName={labelClassName}
            inputWrapperClassName={inputClassName}
          />
        </>
      )}
    </fieldset>
  )
}

export default OrderShippingAddress
