import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import { updateRecord } from '~/async-actions/menu-items-async-actions'
import { AppDispatch } from '~/config/store'
import MenuItem, { MenuItemKindEnum } from '~/types/menu-item'

const Button = styled.span`
  cursor: pointer;
  color: #337ab7;
  &:hover {
    text-decoration: underline;
  }
`

type ToggleActiveProps = {
  record: MenuItem
  kind: MenuItemKindEnum
}

const ToggleActive = (props: ToggleActiveProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const { record, kind } = props

  const toggled = () => {
    const params = { ...record, active: !record.active } as MenuItem
    dispatch(updateRecord({ params, kind }))
  }

  return <Button onClick={toggled}>{record.active ? 'Yes' : 'No'}</Button>
}

export default ToggleActive
