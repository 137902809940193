import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { arrayMove } from '@dnd-kit/sortable'
import _ from 'lodash'

import PriorityItem from '~/types/priority-item'
import ProductSearchLink from '~/types/product-search-link'

import {
  fetchList,
  fetchRecord,
  createRecord,
  updateRecord,
  destroyRecord,
  searchMenuEntries,
  sortListAsync
} from '~/async-actions/priority-items-async-actions'

const sortRecords = (list: PriorityItem[]) => _.orderBy(list, 'priority', 'desc')

export interface ListMeta {
  currentPage: number
  totalPages: number
  totalCount: number
  pageSize: number
}

export interface PriorityItemsState {
  list: PriorityItem[]
  listMeta: ListMeta | undefined
  recordToEdit: PriorityItem | null
  searchResults: ProductSearchLink[]
  loading: {
    fetchList: boolean
    fetchRecord: boolean
    createRecord: boolean
    updateRecord: boolean
    destroyRecord: boolean
    searchMenuEntries: boolean
    sortListAsync: boolean
  }
  error: {
    fetchList: string | null
    fetchRecord: string | null
    createRecord: string | null
    updateRecord: string | null
    destroyRecord: string | null
    searchMenuEntries: string | null
    sortListAsync: string | null
  }
}

const initialState: PriorityItemsState = {
  list: [],
  listMeta: undefined,
  recordToEdit: null,
  searchResults: [],
  loading: {
    fetchList: false,
    fetchRecord: false,
    createRecord: false,
    updateRecord: false,
    destroyRecord: false,
    searchMenuEntries: false,
    sortListAsync: false
  },
  error: {
    fetchList: null,
    fetchRecord: null,
    createRecord: null,
    updateRecord: null,
    destroyRecord: null,
    searchMenuEntries: null,
    sortListAsync: null
  }
}

const priorityItemsSlice = createSlice({
  name: 'priorityItems',
  initialState,
  reducers: {
    sortList: (state, action: PayloadAction<{ oldIndex: number; newIndex: number }>) => {
      const { oldIndex, newIndex } = action.payload
      state.list = arrayMove(state.list, oldIndex, newIndex)
    },
    loadList: (state, action: PayloadAction<{ items: PriorityItem[]; meta: ListMeta }>) => {
      state.list = action.payload.items
      state.listMeta = action.payload.meta
    },
    loadRecordToEdit: (state, action: PayloadAction<PriorityItem>) => {
      state.recordToEdit = action.payload
    },
    addRecord: (state, action: PayloadAction<PriorityItem>) => {
      const newList = state.list.concat([action.payload])
      const oldListMeta = state.listMeta
      const totalCount = (oldListMeta?.totalCount ?? 0) + 1
      state.listMeta = _.assign(oldListMeta, { totalCount })
      state.list = sortRecords(newList)
    },
    reloadRecordInList: (state, action: PayloadAction<PriorityItem>) => {
      const newList = state.list.filter(record => record.id !== action.payload.id).concat([action.payload])
      state.list = sortRecords(newList)
    },
    removeRecord: (state, action: PayloadAction<{ id: number }>) => {
      state.list = state.list.filter(record => record.id !== action.payload.id)
      const oldListMeta = state.listMeta
      const totalCount = (oldListMeta?.totalCount ?? 0) - 1
      state.listMeta = _.assign(oldListMeta, { totalCount })
    },
    loadSearchResults: (state, action: PayloadAction<ProductSearchLink[]>) => {
      state.searchResults = action.payload
    }
  },
  extraReducers: builder => {
    builder
      // fetchOrders async action
      .addCase(fetchList.pending, state => {
        state.loading.fetchList = true
        state.error.fetchList = null
      })
      .addCase(fetchList.fulfilled, (state, action) => {
        state.list = action.payload.items
        state.listMeta = action.payload.meta
        state.loading.fetchList = false
      })
      .addCase(fetchList.rejected, (state, action) => {
        state.loading.fetchList = false
        state.error.fetchList = action.payload?.message || 'Error fetching menu items'
      })

      // fetchRecord async action
      .addCase(fetchRecord.pending, state => {
        state.loading.fetchRecord = true
        state.error.fetchRecord = null
      })
      .addCase(fetchRecord.fulfilled, (state, action) => {
        state.recordToEdit = action.payload
        state.loading.fetchRecord = false
      })
      .addCase(fetchRecord.rejected, (state, action) => {
        state.loading.fetchRecord = false
        state.error.fetchRecord = action.payload?.message || 'Error fetching menu items'
      })

      // createRecord async action
      .addCase(createRecord.pending, state => {
        state.loading.createRecord = true
        state.error.createRecord = null
      })
      .addCase(createRecord.fulfilled, (state, action) => {
        const newList = state.list.concat([action.payload])
        const oldListMeta = state.listMeta
        const totalCount = (oldListMeta?.totalCount ?? 0) + 1
        state.listMeta = _.assign(oldListMeta, { totalCount })
        state.list = sortRecords(newList)
        state.loading.createRecord = false
      })
      .addCase(createRecord.rejected, (state, action) => {
        state.loading.createRecord = false
        state.error.createRecord = action.payload?.message || 'Error fetching menu items'
      })

      // updateRecord async action
      .addCase(updateRecord.pending, state => {
        state.loading.updateRecord = true
        state.error.updateRecord = null
      })
      .addCase(updateRecord.fulfilled, (state, action) => {
        const newList = state.list.filter(record => record.id !== action.payload.id).concat([action.payload])
        state.list = sortRecords(newList)
        state.recordToEdit = action.payload
        state.loading.updateRecord = false
      })
      .addCase(updateRecord.rejected, (state, action) => {
        state.loading.updateRecord = false
        state.error.updateRecord = action.payload?.message || 'Error fetching menu items'
      })

      // destroyRecord async action
      .addCase(destroyRecord.pending, state => {
        state.loading.destroyRecord = true
        state.error.destroyRecord = null
      })
      .addCase(destroyRecord.fulfilled, (state, action) => {
        state.list = state.list.filter(record => record.id !== action.payload.id)
        const oldListMeta = state.listMeta
        const totalCount = (oldListMeta?.totalCount ?? 0) - 1
        state.listMeta = _.assign(oldListMeta, { totalCount })
        state.loading.destroyRecord = false
      })
      .addCase(destroyRecord.rejected, (state, action) => {
        state.loading.destroyRecord = false
        state.error.destroyRecord = action.payload?.message || 'Error fetching menu items'
      })

      // searchMenuEntries async action
      .addCase(searchMenuEntries.pending, state => {
        state.loading.searchMenuEntries = true
        state.error.searchMenuEntries = null
      })
      .addCase(searchMenuEntries.fulfilled, (state, action) => {
        state.searchResults = action.payload
        state.loading.searchMenuEntries = false
      })
      .addCase(searchMenuEntries.rejected, (state, action) => {
        state.loading.searchMenuEntries = false
        state.error.searchMenuEntries = action.payload?.message || 'Error fetching menu items'
      })

      // sortListAsync async action
      .addCase(sortListAsync.pending, state => {
        state.loading.sortListAsync = true
        state.error.sortListAsync = null
      })
      .addCase(sortListAsync.fulfilled, (state, action) => {
        state.list = action.payload.items
        state.listMeta = action.payload.meta
        state.loading.sortListAsync = false
      })
      .addCase(sortListAsync.rejected, (state, action) => {
        state.loading.sortListAsync = false
        state.error.sortListAsync = action.payload?.message || 'Error fetching menu items'
      })
  }
})

export const { sortList, loadList, loadRecordToEdit, addRecord, reloadRecordInList, removeRecord, loadSearchResults } =
  priorityItemsSlice.actions

export default priorityItemsSlice.reducer
